import React from "react";
import AppbarDrawer from "../../../components/dashboard/drawer";
import GeneralReport from "../../../components/dashboard/reports/reportGeral";



const GeneralReportsPage = () => {
  return (
    <div >
      <AppbarDrawer>
        <GeneralReport/>
      </AppbarDrawer>
    </div>
  );
};
export default GeneralReportsPage;

