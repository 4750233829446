const caboDelgado = [
  [40.5241227, -13.513352],
  [40.4571533, -13.5044848],
  [40.4296875, -13.4537372],
  [40.3829956, -13.4537372],
  [40.2209473, -13.4991435],
  [40.1165771, -13.451066],
  [40.0671387, -13.5044848],
  [40.0094604, -13.5124966],
  [39.9435425, -13.5819209],
  [39.861145, -13.6860192],
  [39.7650146, -13.6726758],
  [39.5178223, -13.7740664],
  [39.4519043, -13.8514139],
  [39.3640137, -13.8727466],
  [39.2102051, -13.9660541],
  [39.1552734, -13.9393986],
  [39.0921021, -13.9713848],
  [39.039917, -14.0166909],
  [38.9849854, -14.0246852],
  [38.8174438, -14.1312488],
  [38.789978, -14.0992849],
  [38.7103271, -14.1632081],
  [38.6773682, -14.1419024],
  [38.5400391, -14.160545],
  [38.4356689, -14.1232582],
  [38.40065, -14.1289182],
  [38.40065, -14.1289182],
  [38.2859802, -13.999369],
  [38.2901001, -13.978048],
  [38.2598877, -13.9380658],
  [38.261261, -13.9154061],
  [38.1994629, -13.8314127],
  [38.2063293, -13.8100762],
  [38.1651306, -13.7527247],
  [38.1706238, -13.6806819],
  [38.143158, -13.6499904],
  [38.152771, -13.6006086],
  [38.1390381, -13.543206],
  [38.1184387, -13.5178377],
  [38.1060791, -13.4991435],
  [38.0690002, -13.5071555],
  [38.0374146, -13.4136657],
  [38.0950928, -13.3588905],
  [38.1513977, -13.346865],
  [38.1816101, -13.3000935],
  [38.1774902, -13.2559864],
  [38.2200623, -13.1650739],
  [38.2131958, -13.1249546],
  [38.1829834, -13.1089051],
  [38.1610107, -13.1196049],
  [38.1266785, -13.1450151],
  [38.0717468, -13.1209424],
  [38.0593872, -13.1075676],
  [37.9962158, -13.091517],
  [37.9399109, -13.0353314],
  [37.9962158, -12.9777949],
  [38.001709, -12.9563826],
  [38.0223083, -12.9028437],
  [38.0209351, -12.8800862],
  [38.0484009, -12.8425988],
  [38.0305481, -12.8238529],
  [38.0470276, -12.7729644],
  [38.0374146, -12.7434979],
  [38.0415344, -12.6925932],
  [38.034668, -12.6711566],
  [38.0181885, -12.6564179],
  [38.0511475, -12.589413],
  [38.0456543, -12.5693081],
  [38.0456543, -12.5492017],
  [38.0703735, -12.5304342],
  [38.0813599, -12.4821688],
  [38.0703735, -12.4647374],
  [38.0854797, -12.4312122],
  [38.110199, -12.4178009],
  [38.1335449, -12.3882937],
  [38.1802368, -12.3641489],
  [38.2035828, -12.3225609],
  [38.2035828, -12.2782827],
  [38.2228088, -12.2554697],
  [38.2214355, -12.2407074],
  [38.2344818, -12.2225888],
  [38.2221222, -12.1897038],
  [38.2928467, -12.1467458],
  [38.3889771, -12.0876673],
  [38.4082031, -11.9829051],
  [38.4164429, -11.8673509],
  [38.4246826, -11.8243415],
  [38.40271, -11.7383024],
  [38.4109497, -11.6307157],
  [38.4329224, -11.5338522],
  [38.4631348, -11.4665662],
  [38.486867, -11.4216859],
  [38.4864807, -11.4163014],
  [38.5908508, -11.3332926],
  [38.6581421, -11.2713463],
  [38.6773682, -11.2740399],
  [38.7171936, -11.265959],
  [38.7515259, -11.2726931],
  [38.7680054, -11.2471028],
  [38.83255, -11.2120808],
  [38.8833618, -11.1703183],
  [38.964386, -11.1716656],
  [38.9918518, -11.1649292],
  [39.0097046, -11.1703183],
  [39.0275574, -11.1595399],
  [39.0742493, -11.1568453],
  [39.1264343, -11.1460664],
  [39.1635132, -11.1460664],
  [39.2459106, -11.1743601],
  [39.3502808, -11.0827323],
  [39.4052124, -11.0652119],
  [39.460144, -11.0328639],
  [39.516449, -10.9843351],
  [39.5878601, -10.9641125],
  [39.6427917, -10.9411918],
  [39.7897339, -10.9047847],
  [39.8693848, -10.8535375],
  [39.8995972, -10.8481426],
  [39.9160767, -10.8319572],
  [39.9819946, -10.814422],
  [40.0341797, -10.7820466],
  [40.0369263, -10.7658576],
  [40.0616455, -10.7496677],
  [40.0794983, -10.7550644],
  [40.1289368, -10.6956952],
  [40.1742554, -10.6700549],
  [40.1797485, -10.6498111],
  [40.2017212, -10.6457621],
  [40.2154541, -10.6133687],
  [40.2580261, -10.5904213],
  [40.2923584, -10.5674722],
  [40.337677, -10.5661222],
  [40.3747559, -10.5539719],
  [40.3761292, -10.53102],
  [40.3981018, -10.5013151],
  [40.4334641, -10.4711009],
  [40.4420471, -10.4776841],
  [40.4473686, -10.4743081],
  [40.4535484, -10.4754897],
  [40.4667664, -10.4830856],
  [40.4677963, -10.4807225],
  [40.4827309, -10.4846048],
  [40.4926872, -10.4859551],
  [40.5010986, -10.4862927],
  [40.5079651, -10.4840984],
  [40.5089951, -10.4817353],
  [40.512085, -10.4808913],
  [40.5198097, -10.4808913],
  [40.5261612, -10.4859551],
  [40.5319977, -10.4920317],
  [40.5361176, -10.4972642],
  [40.5361176, -10.4991208],
  [40.5340576, -10.5030029],
  [40.5368042, -10.5171806],
  [40.5393791, -10.5188683],
  [40.5488205, -10.5391209],
  [40.5452156, -10.5423274],
  [40.5447006, -10.5504279],
  [40.542469, -10.552453],
  [40.5371475, -10.5526218],
  [40.5359459, -10.5502592],
  [40.531826, -10.5495841],
  [40.5294228, -10.5509342],
  [40.5325127, -10.552453],
  [40.5319977, -10.5561657],
  [40.5330276, -10.5644346],
  [40.5350876, -10.577597],
  [40.5390358, -10.5786094],
  [40.5414391, -10.5774282],
  [40.544014, -10.5794532],
  [40.5568886, -10.5851904],
  [40.5656433, -10.5934585],
  [40.5680466, -10.593796],
  [40.5735397, -10.5976769],
  [40.5747414, -10.5959896],
  [40.5826378, -10.598858],
  [40.5846977, -10.6015577],
  [40.5841827, -10.6045949],
  [40.5798912, -10.6066196],
  [40.5795479, -10.6081382],
  [40.5807495, -10.6111753],
  [40.5771446, -10.6137062],
  [40.5738831, -10.6138749],
  [40.5711365, -10.616237],
  [40.5699348, -10.6228171],
  [40.5687332, -10.6361457],
  [40.5773163, -10.6626323],
  [40.6075287, -10.6673558],
  [40.6322479, -10.6761278],
  [40.641861, -10.6929963],
  [40.6322479, -10.7044664],
  [40.6178284, -10.6990688],
  [40.5937958, -10.6983941],
  [40.5814362, -10.6882733],
  [40.5601501, -10.7004182],
  [40.5182648, -10.7375247],
  [40.5010986, -10.7422471],
  [40.4866791, -10.7537153],
  [40.4853058, -10.7665321],
  [40.4962921, -10.7719286],
  [40.5505371, -10.804305],
  [40.5601501, -10.8009326],
  [40.5670166, -10.8150964],
  [40.6034088, -10.825213],
  [40.6123352, -10.825213],
  [40.6198883, -10.8326316],
  [40.6095886, -10.836678],
  [40.6102753, -10.8454451],
  [40.5821228, -10.8717448],
  [40.5484772, -10.8859052],
  [40.5141449, -10.9162467],
  [40.4949188, -10.9459109],
  [40.4956055, -10.9775944],
  [40.488739, -10.974224],
  [40.4825592, -11.0018603],
  [40.4983521, -10.995794],
  [40.5093384, -11.0092745],
  [40.5189514, -11.0126445],
  [40.5388641, -11.0234284],
  [40.56427, -11.030168],
  [40.5615234, -11.0604947],
  [40.5505371, -11.0854276],
  [40.531311, -11.1015992],
  [40.5038452, -11.1332659],
  [40.4921722, -11.1494348],
  [40.4921722, -11.1716656],
  [40.4969788, -11.1837908],
  [40.5107117, -11.1938947],
  [40.4640198, -11.2221838],
  [40.4358673, -11.2356538],
  [40.4255676, -11.2585514],
  [40.424881, -11.2774069],
  [40.4070282, -11.3036678],
  [40.3836823, -11.3299263],
  [40.370636, -11.3258867],
  [40.3617096, -11.3299263],
  [40.3699493, -11.3521431],
  [40.3720093, -11.3770509],
  [40.3836823, -11.3521431],
  [40.4104614, -11.3837824],
  [40.4214478, -11.4033027],
  [40.4626465, -11.4039758],
  [40.4736328, -11.4093604],
  [40.4509735, -11.4450312],
  [40.4482269, -11.468585],
  [40.4358673, -11.4780059],
  [40.4386139, -11.5197235],
  [40.4173279, -11.5311611],
  [40.424881, -11.5311611],
  [40.4214478, -11.5580712],
  [40.4262543, -11.572198],
  [40.4324341, -11.5863241],
  [40.4592133, -11.5997769],
  [40.4640198, -11.6179371],
  [40.4427338, -11.6334059],
  [40.4241943, -11.6522364],
  [40.4413605, -11.6482014],
  [40.4434204, -11.6703932],
  [40.4447937, -11.6912384],
  [40.436554, -11.6959451],
  [40.4372406, -11.7275456],
  [40.4626465, -11.75242],
  [40.4653931, -11.7873749],
  [40.4640198, -11.8149323],
  [40.4756927, -11.8350945],
  [40.5045319, -11.8465191],
  [40.5155182, -11.8532392],
  [40.4921722, -11.8767583],
  [40.4859924, -11.8875092],
  [40.4853058, -11.902963],
  [40.4873657, -11.9231187],
  [40.4956055, -11.9580516],
  [40.5113983, -11.98089],
  [40.554657, -11.999025],
  [40.5148315, -12.0218599],
  [40.4997253, -12.0473789],
  [40.5052185, -12.0708812],
  [40.5127716, -12.085653],
  [40.4880524, -12.1454033],
  [40.5182648, -12.2051401],
  [40.5065918, -12.2427205],
  [40.4935455, -12.2695603],
  [40.5017853, -12.284321],
  [40.5134583, -12.3225609],
  [40.510025, -12.3393311],
  [40.5017853, -12.3413434],
  [40.5065918, -12.3547586],
  [40.4921722, -12.361466],
  [40.5134583, -12.3802456],
  [40.5265045, -12.3963414],
  [40.5134583, -12.4332238],
  [40.4949188, -12.4593737],
  [40.4798126, -12.4908841],
  [40.4585266, -12.4982583],
  [40.4338074, -12.5203797],
  [40.4399872, -12.5203797],
  [40.45784, -12.5284233],
  [40.4811859, -12.5800309],
  [40.4921722, -12.5585849],
  [40.4846191, -12.5337856],
  [40.512085, -12.5324451],
  [40.5244446, -12.5411586],
  [40.5374908, -12.5492017],
  [40.5457306, -12.5773503],
  [40.5615234, -12.6222476],
  [40.606842, -12.6403383],
  [40.6226349, -12.6604376],
  [40.6157684, -12.6711566],
  [40.6130219, -12.6852246],
  [40.620575, -12.7314425],
  [40.6082153, -12.7555528],
  [40.6308746, -12.7455071],
  [40.6281281, -12.7783216],
  [40.6047821, -12.8084535],
  [40.5828094, -12.8164881],
  [40.5615234, -12.830548],
  [40.5457306, -12.8579961],
  [40.5292511, -12.8948119],
  [40.5127716, -12.9302838],
  [40.5038452, -12.9182373],
  [40.5038452, -12.8941426],
  [40.488739, -12.8666985],
  [40.479126, -12.872723],
  [40.4605865, -12.8794168],
  [40.4303741, -12.9142217],
  [40.4159546, -12.9276069],
  [40.4063416, -12.9550443],
  [40.4118347, -12.9711038],
  [40.4303741, -12.9891695],
  [40.4427338, -13.024628],
  [40.4592133, -13.024628],
  [40.4866791, -13.0293108],
  [40.5031586, -13.0339935],
  [40.5072784, -13.02329],
  [40.512085, -12.985155],
  [40.4853058, -12.9704346],
  [40.479126, -12.9603975],
  [40.5017853, -12.9516984],
  [40.5285645, -12.9603975],
  [40.5422974, -12.9684273],
  [40.5704498, -12.9577209],
  [40.5848694, -12.9664198],
  [40.5889893, -12.9885004],
  [40.5745697, -13.0252969],
  [40.5635834, -13.0460344],
  [40.5553436, -13.0687767],
  [40.5519104, -13.1095739],
  [40.5615234, -13.1456837],
  [40.5608368, -13.205855],
  [40.5656433, -13.2686847],
  [40.5635834, -13.3114531],
  [40.5814362, -13.3294938],
  [40.5841827, -13.3522098],
  [40.5780029, -13.4003071],
  [40.5594635, -13.4136657],
  [40.5491638, -13.4243521],
  [40.5409241, -13.44372],
  [40.533371, -13.4757737],
  [40.5268478, -13.4948036],
  [40.5254745, -13.5020645],
  [40.5251527, -13.504151],
  [40.5252171, -13.5062374],
  [40.5246377, -13.5082821],
  [40.5246806, -13.5106398],
  [40.524509, -13.5115995],
  [40.5242944, -13.5123506],
  [40.5239725, -13.5127053],
  [40.5241227, -13.513352],
];

const niassa = [
  [35.8515644, -15.4416016],
  [35.8978271, -15.4470619],
  [36.0845947, -15.4510329],
  [36.12854, -15.4219104],
  [36.2686157, -15.4801514],
  [36.2713623, -15.3821913],
  [36.3565063, -15.3053795],
  [36.4581299, -15.2682879],
  [36.5611267, -15.2073374],
  [36.6311646, -15.1278103],
  [36.6383743, -15.106598],
  [36.6562271, -15.1109069],
  [36.6610336, -15.1075924],
  [36.6658401, -15.0933393],
  [36.6651535, -15.0797482],
  [36.6795731, -15.0731181],
  [36.6898727, -15.0598572],
  [36.6996574, -15.0447099],
  [36.6990352, -15.0328357],
  [36.6976833, -15.0309913],
  [36.6975331, -15.0294163],
  [36.69734, -15.0268466],
  [36.6982198, -15.0245255],
  [36.7021251, -15.0237587],
  [36.7026401, -15.0229712],
  [36.7012668, -15.0210645],
  [36.7002797, -15.0213547],
  [36.6989708, -15.0209609],
  [36.6982841, -15.0200076],
  [36.697855, -15.0184947],
  [36.702919, -15.0092305],
  [36.7028332, -15.0078419],
  [36.7020392, -15.0066191],
  [36.7019749, -15.0058108],
  [36.7013955, -15.0054377],
  [36.7006874, -15.0052719],
  [36.6991425, -15.0047952],
  [36.697576, -15.003448],
  [36.6963315, -15.0031164],
  [36.6960311, -15.0025775],
  [36.6962028, -15.0016241],
  [36.6955376, -15.0011889],
  [36.6955376, -15.0011889],
  [36.7001724, -14.9951988],
  [36.7080688, -14.9928774],
  [36.7187119, -14.9832598],
  [36.7355347, -14.96734],
  [36.7451477, -14.951419],
  [36.7657471, -14.9408043],
  [36.7650604, -14.9102843],
  [36.7932129, -14.8970134],
  [36.812439, -14.8744509],
  [36.8357849, -14.8625051],
  [36.8261719, -14.842594],
  [36.8426514, -14.842594],
  [36.8385315, -14.8173706],
  [36.8467712, -14.804094],
  [36.8687439, -14.7921443],
  [36.8865967, -14.7708988],
  [36.940155, -14.7509793],
  [36.9415283, -14.7230889],
  [36.9703674, -14.7098065],
  [37.0101929, -14.6805824],
  [37.048645, -14.67394],
  [37.0802307, -14.648697],
  [37.157135, -14.5982025],
  [37.1818542, -14.5888996],
  [37.2134399, -14.6128205],
  [37.2409058, -14.5968735],
  [37.3301697, -14.5596593],
  [37.3425293, -14.5397206],
  [37.4139404, -14.4759045],
  [37.416687, -14.4479792],
  [37.4386597, -14.4320203],
  [37.4716187, -14.4147302],
  [37.4812317, -14.3894577],
  [37.4963379, -14.3828065],
  [37.5567627, -14.4054196],
  [37.6171875, -14.3522087],
  [37.6020813, -14.3083002],
  [37.6158142, -14.2923313],
  [37.6419067, -14.2910005],
  [37.6885986, -14.2736996],
  [37.7229309, -14.3016466],
  [37.7764893, -14.265714],
  [37.7957153, -14.2524042],
  [37.8135681, -14.2524042],
  [37.8671265, -14.2524042],
  [37.8781128, -14.2790231],
  [37.9275513, -14.3189455],
  [37.9714966, -14.2790231],
  [38.0209351, -14.2537352],
  [38.0827332, -14.2617212],
  [38.1005859, -14.2603902],
  [38.1253052, -14.2630521],
  [38.1472778, -14.2390936],
  [38.1898499, -14.2390936],
  [38.261261, -14.1698658],
  [38.3065796, -14.147229],
  [38.3381653, -14.1632081],
  [38.3573914, -14.1445657],
  [38.40065, -14.1289182],
  [38.2859802, -13.999369],
  [38.2901001, -13.978048],
  [38.2598877, -13.9380658],
  [38.261261, -13.9154061],
  [38.1994629, -13.8314127],
  [38.2063293, -13.8100762],
  [38.1651306, -13.7527247],
  [38.1706238, -13.6806819],
  [38.143158, -13.6499904],
  [38.152771, -13.6006086],
  [38.1390381, -13.543206],
  [38.1184387, -13.5178377],
  [38.1060791, -13.4991435],
  [38.0690002, -13.5071555],
  [38.0374146, -13.4136657],
  [38.0950928, -13.3588905],
  [38.1513977, -13.346865],
  [38.1816101, -13.3000935],
  [38.1774902, -13.2559864],
  [38.2200623, -13.1650739],
  [38.2131958, -13.1249546],
  [38.1829834, -13.1089051],
  [38.1610107, -13.1196049],
  [38.1266785, -13.1450151],
  [38.0717468, -13.1209424],
  [38.0593872, -13.1075676],
  [37.9962158, -13.091517],
  [37.9399109, -13.0353314],
  [37.9962158, -12.9777949],
  [38.001709, -12.9563826],
  [38.0223083, -12.9028437],
  [38.0209351, -12.8800862],
  [38.0484009, -12.8425988],
  [38.0305481, -12.8238529],
  [38.0470276, -12.7729644],
  [38.0374146, -12.7434979],
  [38.0415344, -12.6925932],
  [38.034668, -12.6711566],
  [38.0181885, -12.6564179],
  [38.0511475, -12.589413],
  [38.0456543, -12.5693081],
  [38.0456543, -12.5492017],
  [38.0703735, -12.5304342],
  [38.0813599, -12.4821688],
  [38.0703735, -12.4647374],
  [38.0854797, -12.4312122],
  [38.110199, -12.4178009],
  [38.1335449, -12.3882937],
  [38.1802368, -12.3641489],
  [38.2035828, -12.3225609],
  [38.2035828, -12.2782827],
  [38.2228088, -12.2554697],
  [38.2214355, -12.2407074],
  [38.2344818, -12.2225888],
  [38.2221222, -12.1897038],
  [38.2928467, -12.1467458],
  [38.3889771, -12.0876673],
  [38.4082031, -11.9829051],
  [38.4164429, -11.8673509],
  [38.4246826, -11.8243415],
  [38.40271, -11.7383024],
  [38.4109497, -11.6307157],
  [38.4329224, -11.5338522],
  [38.4631348, -11.4665662],
  [38.486867, -11.4216859],
  [38.4864807, -11.4163014],
  [38.4821033, -11.4155022],
  [38.4772968, -11.4182365],
  [38.4733915, -11.4187413],
  [38.4700441, -11.4193723],
  [38.4682846, -11.4187413],
  [38.4672546, -11.4190357],
  [38.4599161, -11.4160911],
  [38.4573412, -11.4126416],
  [38.4473419, -11.4088556],
  [38.4404755, -11.39674],
  [38.4354973, -11.3952255],
  [38.4315491, -11.3918599],
  [38.4262276, -11.391355],
  [38.4217644, -11.3940475],
  [38.4190178, -11.392533],
  [38.4173012, -11.3905136],
  [38.4061432, -11.3893357],
  [38.4015083, -11.3869797],
  [38.3924103, -11.3810898],
  [38.3886337, -11.3815947],
  [38.3870888, -11.3831092],
  [38.3855438, -11.3794069],
  [38.3817673, -11.3780606],
  [38.3791924, -11.3755363],
  [38.3695793, -11.3746949],
  [38.3601379, -11.3666168],
  [38.3599663, -11.3605582],
  [38.358593, -11.3573605],
  [38.359108, -11.354836],
  [38.3568764, -11.3496186],
  [38.3534431, -11.3497869],
  [38.347435, -11.3484405],
  [38.3452034, -11.3450743],
  [38.3434868, -11.3406983],
  [38.3403969, -11.3380053],
  [38.335762, -11.3359856],
  [38.3347321, -11.3321144],
  [38.3312988, -11.3304312],
  [38.3275223, -11.3309362],
  [38.3208275, -11.3274015],
  [38.3153343, -11.3270649],
  [38.3041763, -11.3270649],
  [38.2997131, -11.32656],
  [38.2962799, -11.3299263],
  [38.2890701, -11.3302629],
  [38.2834053, -11.3285798],
  [38.2811737, -11.3247084],
  [38.2813454, -11.317639],
  [38.2816887, -11.3110743],
  [38.2787704, -11.3068661],
  [38.2763672, -11.2976078],
  [38.2712173, -11.2940727],
  [38.271389, -11.2918843],
  [38.2655525, -11.2875075],
  [38.261776, -11.2875075],
  [38.2552528, -11.2910426],
  [38.2521629, -11.2886859],
  [38.2523346, -11.2853191],
  [38.2446098, -11.2849824],
  [38.2391167, -11.2819522],
  [38.2343102, -11.2770702],
  [38.2315636, -11.2777436],
  [38.2303619, -11.2757234],
  [38.2228088, -11.27505],
  [38.2195473, -11.2743766],
  [38.2152557, -11.271178],
  [38.2052994, -11.2706729],
  [38.2034111, -11.2718514],
  [38.2003212, -11.271178],
  [38.1972313, -11.2721881],
  [38.1937981, -11.2769018],
  [38.1910515, -11.2765651],
  [38.185215, -11.2774069],
  [38.1762886, -11.2742083],
  [38.1718254, -11.2720197],
  [38.1661606, -11.2710096],
  [38.1613541, -11.2706729],
  [38.1565475, -11.2706729],
  [38.1529427, -11.2725248],
  [38.1519127, -11.2701679],
  [38.1479645, -11.2694944],
  [38.1455612, -11.268821],
  [38.1422997, -11.2662958],
  [38.1383514, -11.2636021],
  [38.132, -11.2634337],
  [38.1261635, -11.2706729],
  [38.1220436, -11.2637704],
  [38.119297, -11.2637704],
  [38.1098557, -11.2612451],
  [38.1059074, -11.263097],
  [38.0607605, -11.2780802],
  [38.0085754, -11.2740399],
  [37.8643799, -11.2861608],
  [37.8039551, -11.4208025],
  [37.7655029, -11.5338522],
  [37.5897217, -11.6360961],
  [37.4688721, -11.6468564],
  [37.3590088, -11.6898936],
  [37.2821045, -11.6307157],
  [37.2161865, -11.6683758],
  [37.0404053, -11.5984316],
  [36.930542, -11.5984316],
  [36.8096924, -11.5553803],
  [36.7657471, -11.6522364],
  [36.6229248, -11.7651925],
  [36.5625, -11.6898936],
  [36.5075684, -11.7436806],
  [36.5075684, -11.6629961],
  [36.3977051, -11.6845143],
  [36.293335, -11.7275456],
  [36.2109375, -11.6952727],
  [36.1999512, -11.5769068],
  [35.9912109, -11.4692579],
  [35.8703613, -11.4315711],
  [35.7330322, -11.496174],
  [35.6231689, -11.5715253],
  [35.3979492, -11.5822882],
  [34.9804688, -11.5715253],
  [34.6508789, -11.5715253],
  [34.6508789, -11.722167],
  [34.5849609, -11.8834777],
  [34.4915771, -12.0124575],
  [34.387207, -12.157486],
  [34.4311523, -12.3239026],
  [34.4476318, -12.5706485],
  [34.5410156, -12.720726],
  [34.5410156, -12.87607],
  [34.5959473, -13.2078605],
  [34.5684814, -13.3522098],
  [34.6069336, -13.4857896],
  [34.8651123, -13.4964728],
  [35.1013184, -13.6780133],
  [35.4968262, -14.1738603],
  [35.5957031, -14.3229373],
  [35.8648682, -14.6898814],
  [35.8703613, -14.886396],
  [35.9197998, -14.8970134],
  [35.7879639, -15.1675776],
  [35.8319092, -15.3212739],
  [35.8535385, -15.4172769],
  [35.8525085, -15.4341556],
  [35.8515644, -15.4416016],
];
const nampula = [
  [36.6955376, -15.0011889],
  [37.1914673, -15.0005049],
  [37.2093201, -15.0310121],
  [37.2395325, -15.0204014],
  [37.2669983, -15.1012946],
  [37.2821045, -15.0986429],
  [37.2972107, -15.1172044],
  [37.331543, -15.13709],
  [37.3617554, -15.1039463],
  [37.4043274, -15.1344387],
  [37.4043274, -15.1887838],
  [37.4551392, -15.168903],
  [37.5045776, -15.1304617],
  [37.525177, -15.1463693],
  [37.5952148, -15.1954103],
  [37.6158142, -15.2007113],
  [37.624054, -15.2364898],
  [37.7119446, -15.2457648],
  [37.7641296, -15.2126382],
  [37.8300476, -15.2033618],
  [37.8781128, -15.2338398],
  [37.9234314, -15.2311897],
  [38.001709, -15.2643134],
  [38.0072021, -15.2974319],
  [38.0360413, -15.3265718],
  [38.0923462, -15.3199494],
  [38.1088257, -15.3464376],
  [38.1472778, -15.3411402],
  [38.1349182, -15.3874876],
  [38.1706238, -15.4007278],
  [38.218689, -15.4510329],
  [38.2598877, -15.4325009],
  [38.2873535, -15.4602983],
  [38.313446, -15.4523566],
  [38.4082031, -15.5026492],
  [38.4329224, -15.510589],
  [38.4439087, -15.5423452],
  [38.478241, -15.5727736],
  [38.5057068, -15.5714507],
  [38.5359192, -15.6098109],
  [38.5949707, -15.6772544],
  [38.6801147, -15.6719655],
  [38.7007141, -15.7208824],
  [38.747406, -15.7618578],
  [38.7762451, -15.768466],
  [38.8037109, -15.7922536],
  [38.8243103, -15.8807717],
  [38.8517761, -15.8953009],
  [38.8490295, -15.9151116],
  [38.8737488, -15.9428434],
  [38.8737488, -15.9930149],
  [38.9163208, -16.0431739],
  [38.9163208, -16.0682486],
  [38.9300537, -16.111792],
  [38.9479065, -16.1197079],
  [38.9231873, -16.1421347],
  [38.9259338, -16.1711539],
  [38.931427, -16.2344536],
  [38.9533997, -16.2700507],
  [38.9863586, -16.2766421],
  [39.0083313, -16.3372721],
  [39.0151978, -16.3978834],
  [39.039917, -16.4268647],
  [39.0385437, -16.4584758],
  [39.0234375, -16.4834976],
  [39.039917, -16.5164161],
  [39.0097046, -16.5361645],
  [38.9918518, -16.6388235],
  [39.039917, -16.659875],
  [39.0509033, -16.7282763],
  [39.0715027, -16.7322217],
  [39.0907288, -16.7756166],
  [39.1127014, -16.796653],
  [39.1187096, -16.8203162],
  [39.1374207, -16.8413476],
  [39.1293526, -16.8546554],
  [39.1288376, -16.8643481],
  [39.1333008, -16.8689478],
  [39.1305542, -16.8727261],
  [39.1334724, -16.87634],
  [39.1339874, -16.888824],
  [39.1387939, -16.8911236],
  [39.1391802, -16.8939981],
  [39.1386223, -16.8963797],
  [39.1391373, -16.8977759],
  [39.1403818, -16.8989256],
  [39.1416693, -16.8985561],
  [39.1426992, -16.8975295],
  [39.1433859, -16.8967904],
  [39.1438258, -16.8966261],
  [39.1444373, -16.8964721],
  [39.2678833, -16.7835056],
  [39.3804932, -16.7440577],
  [39.5397949, -16.6361919],
  [39.6166992, -16.5940814],
  [39.710083, -16.5335315],
  [39.7897339, -16.4676947],
  [39.9105835, -16.4176439],
  [39.9078369, -16.3833911],
  [39.9462891, -16.3069594],
  [39.9133301, -16.2700507],
  [39.8226929, -16.3016872],
  [39.8638916, -16.2515937],
  [39.9682617, -16.2436831],
  [40.0231934, -16.19885],
  [40.045166, -16.1408156],
  [40.1083374, -16.0748467],
  [40.144043, -15.9639698],
  [40.2703857, -15.8213235],
  [40.3692627, -15.7288138],
  [40.4241943, -15.633617],
  [40.56427, -15.4960324],
  [40.5834961, -15.4192627],
  [40.6274414, -15.3424646],
  [40.6741333, -15.2603388],
  [40.663147, -15.1808317],
  [40.6219482, -15.1463693],
  [40.5917358, -15.1622757],
  [40.5697632, -15.2099878],
  [40.5230713, -15.1861331],
  [40.531311, -15.1278103],
  [40.5807495, -15.133113],
  [40.6329346, -15.0880355],
  [40.6988525, -15.0800797],
  [40.7043457, -15.0747756],
  [40.6521606, -14.9872395],
  [40.6507874, -14.9699933],
  [40.696106, -14.9819332],
  [40.7276917, -14.9991784],
  [40.7455444, -14.9885661],
  [40.7730103, -15.0177486],
  [40.7730103, -14.9938723],
  [40.748291, -14.9633598],
  [40.738678, -14.932843],
  [40.6988525, -14.9248814],
  [40.7098389, -14.9474385],
  [40.6535339, -14.9209004],
  [40.6480408, -14.9009947],
  [40.6933594, -14.8956862],
  [40.7647705, -14.8678144],
  [40.771637, -14.8558683],
  [40.8018494, -14.8479038],
  [40.8197021, -14.8771054],
  [40.8320618, -14.8333015],
  [40.8361816, -14.7722267],
  [40.8348083, -14.6819108],
  [40.8306885, -14.6619832],
  [40.8114624, -14.6433824],
  [40.7991028, -14.6261088],
  [40.8306885, -14.5583301],
  [40.8306885, -14.5131328],
  [40.8293152, -14.4413298],
  [40.8059692, -14.4160603],
  [40.7661438, -14.4227104],
  [40.7359314, -14.4413298],
  [40.705719, -14.4519688],
  [40.6768799, -14.4612774],
  [40.6809998, -14.5038263],
  [40.6658936, -14.5516841],
  [40.6288147, -14.5982025],
  [40.6315613, -14.5530133],
  [40.6411743, -14.5290859],
  [40.6246948, -14.5157917],
  [40.6645203, -14.4705857],
  [40.6384277, -14.4340152],
  [40.6425476, -14.398769],
  [40.6501007, -14.385467],
  [40.6233215, -14.3315861],
  [40.6356812, -14.3269291],
  [40.6748199, -14.3828065],
  [40.7084656, -14.3515435],
  [40.7324982, -14.3335819],
  [40.7338715, -14.3182802],
  [40.7407379, -14.2750304],
  [40.7359314, -14.2690414],
  [40.7180786, -14.2550662],
  [40.715332, -14.2390936],
  [40.7187653, -14.2098075],
  [40.7077789, -14.193166],
  [40.6947327, -14.1871747],
  [40.6796265, -14.2018197],
  [40.6693268, -14.1958287],
  [40.6432343, -14.2064793],
  [40.6356812, -14.2164638],
  [40.6198883, -14.2171294],
  [40.6130219, -14.245749],
  [40.6192017, -14.2524042],
  [40.6123352, -14.2663795],
  [40.6013489, -14.2750304],
  [40.5979156, -14.2630521],
  [40.6020355, -14.2563972],
  [40.5889893, -14.238428],
  [40.5979156, -14.2224542],
  [40.5869293, -14.2038167],
  [40.5697632, -14.2131356],
  [40.5718231, -14.2297757],
  [40.5450439, -14.2058136],
  [40.5285645, -14.174526],
  [40.5402374, -14.1565503],
  [40.5567169, -14.1545529],
  [40.5800629, -14.1532213],
  [40.606842, -14.1485607],
  [40.6109619, -14.1419024],
  [40.606842, -14.1312488],
  [40.6150818, -14.1219265],
  [40.6027222, -14.1039466],
  [40.5834961, -14.0852993],
  [40.6027222, -14.0932912],
  [40.6240082, -14.0839673],
  [40.6391144, -14.0286822],
  [40.6377411, -14.0186895],
  [40.6226349, -14.0007015],
  [40.6178284, -13.9260698],
  [40.5903625, -13.8914111],
  [40.587616, -13.7954062],
  [40.5752563, -13.7207084],
  [40.5477905, -13.6926906],
  [40.5381775, -13.6473213],
  [40.56427, -13.6019434],
  [40.5889893, -13.585425],
  [40.597229, -13.5685717],
  [40.5939674, -13.5607286],
  [40.5817795, -13.569406],
  [40.5623817, -13.5652342],
  [40.531311, -13.5311898],
  [40.5283928, -13.5215096],
  [40.5271912, -13.5151254],
  [40.5262041, -13.5152715],
  [40.525496, -13.5149377],
  [40.5248523, -13.5148959],
  [40.5244446, -13.513811],
  [40.5241227, -13.513352],
  [40.4571533, -13.5044848],
  [40.4296875, -13.4537372],
  [40.3829956, -13.4537372],
  [40.2209473, -13.4991435],
  [40.1165771, -13.451066],
  [40.0671387, -13.5044848],
  [40.0094604, -13.5124966],
  [39.9435425, -13.5819209],
  [39.861145, -13.6860192],
  [39.7650146, -13.6726758],
  [39.5178223, -13.7740664],
  [39.4519043, -13.8514139],
  [39.3640137, -13.8727466],
  [39.2102051, -13.9660541],
  [39.1552734, -13.9393986],
  [39.0921021, -13.9713848],
  [39.039917, -14.0166909],
  [38.9849854, -14.0246852],
  [38.8174438, -14.1312488],
  [38.789978, -14.0992849],
  [38.7103271, -14.1632081],
  [38.6773682, -14.1419024],
  [38.5400391, -14.160545],
  [38.4356689, -14.1232582],
  [38.40065, -14.1289182],
  [38.3573914, -14.1445657],
  [38.3381653, -14.1632081],
  [38.3065796, -14.147229],
  [38.261261, -14.1698658],
  [38.1898499, -14.2390936],
  [38.1472778, -14.2390936],
  [38.1253052, -14.2630521],
  [38.1005859, -14.2603902],
  [38.0827332, -14.2617212],
  [38.0209351, -14.2537352],
  [37.9714966, -14.2790231],
  [37.9275513, -14.3189455],
  [37.8781128, -14.2790231],
  [37.8671265, -14.2524042],
  [37.8135681, -14.2524042],
  [37.7957153, -14.2524042],
  [37.7764893, -14.265714],
  [37.7229309, -14.3016466],
  [37.6885986, -14.2736996],
  [37.6419067, -14.2910005],
  [37.6158142, -14.2923313],
  [37.6020813, -14.3083002],
  [37.6171875, -14.3522087],
  [37.5567627, -14.4054196],
  [37.4963379, -14.3828065],
  [37.4812317, -14.3894577],
  [37.4716187, -14.4147302],
  [37.4386597, -14.4320203],
  [37.416687, -14.4479792],
  [37.4139404, -14.4759045],
  [37.3425293, -14.5397206],
  [37.3301697, -14.5596593],
  [37.2409058, -14.5968735],
  [37.2134399, -14.6128205],
  [37.1818542, -14.5888996],
  [37.157135, -14.5982025],
  [37.0802307, -14.648697],
  [37.048645, -14.67394],
  [37.0101929, -14.6805824],
  [36.9703674, -14.7098065],
  [36.9415283, -14.7230889],
  [36.940155, -14.7509793],
  [36.8865967, -14.7708988],
  [36.8687439, -14.7921443],
  [36.8467712, -14.804094],
  [36.8385315, -14.8173706],
  [36.8426514, -14.842594],
  [36.8261719, -14.842594],
  [36.8357849, -14.8625051],
  [36.812439, -14.8744509],
  [36.7932129, -14.8970134],
  [36.7650604, -14.9102843],
  [36.7657471, -14.9408043],
  [36.7451477, -14.951419],
  [36.7355347, -14.96734],
  [36.7187119, -14.9832598],
  [36.7080688, -14.9928774],
  [36.7001724, -14.9951988],
  [36.6955376, -15.0011889],
];
const zambezia = [
  [36.1342049, -18.8976791],
  [36.1388397, -18.8751028],
  [36.1237335, -18.8439132],
  [36.1278534, -18.8198673],
  [36.1388397, -18.8081681],
  [36.1621857, -18.7899676],
  [36.1601257, -18.7737155],
  [36.1930847, -18.7704649],
  [36.1910248, -18.7600626],
  [36.1752319, -18.7425072],
  [36.1683655, -18.7269008],
  [36.1553192, -18.7360047],
  [36.149826, -18.7184468],
  [36.1429596, -18.7138945],
  [36.1127472, -18.7269008],
  [36.0942078, -18.7080413],
  [36.1203003, -18.704139],
  [36.1203003, -18.6930822],
  [36.1065674, -18.6807236],
  [36.1161804, -18.6540522],
  [36.1058807, -18.6319313],
  [36.107254, -18.6241232],
  [36.1292267, -18.6150133],
  [36.1278534, -18.6046014],
  [36.1319733, -18.5974428],
  [36.1539459, -18.600046],
  [36.1587524, -18.5876807],
  [36.1422729, -18.586379],
  [36.1457062, -18.5486265],
  [36.1326599, -18.5258408],
  [36.1326599, -18.5258408],
  [36.1257935, -18.4685376],
  [36.1031342, -18.420336],
  [36.0839081, -18.3838501],
  [36.0619354, -18.3649526],
  [36.0468292, -18.3473565],
  [36.0303497, -18.343446],
  [36.0028839, -18.3095515],
  [35.9671783, -18.287386],
  [35.9403992, -18.2580451],
  [35.9246063, -18.2567409],
  [35.8875275, -18.257393],
  [35.8834076, -18.2391338],
  [35.8889008, -18.2202205],
  [35.8875275, -18.2000006],
  [35.8765411, -18.1934775],
  [35.8559418, -18.1863019],
  [35.8586884, -18.1719497],
  [35.8545685, -18.1589012],
  [35.8435822, -18.154334],
  [35.8325958, -18.1517241],
  [35.8291626, -18.1399792],
  [35.8394623, -18.1269284],
  [35.8325958, -18.1171396],
  [35.8085632, -18.1093082],
  [35.7900238, -18.08777],
  [35.7632446, -18.0851591],
  [35.7495117, -18.0623123],
  [35.7261658, -18.0518671],
  [35.7048798, -18.0525199],
  [35.6911469, -18.0440327],
  [35.6362152, -18.0270572],
  [35.5874634, -18.0107329],
  [35.5524445, -18.0120389],
  [35.5332184, -17.9970194],
  [35.4892731, -17.9741612],
  [35.4741669, -17.9578321],
  [35.4721069, -17.9441145],
  [35.4528809, -17.9388884],
  [35.4508209, -17.9271293],
  [35.460434, -17.8911937],
  [35.448761, -17.8696289],
  [35.4501343, -17.8519829],
  [35.4288483, -17.8193007],
  [35.4055023, -17.8075336],
  [35.3917694, -17.7983809],
  [35.3581238, -17.7781126],
  [35.3594971, -17.7591498],
  [35.37117, -17.7513025],
  [35.3622437, -17.7238343],
  [35.3537464, -17.7029852],
  [35.3518581, -17.6970979],
  [35.3467083, -17.6914557],
  [35.334692, -17.6901474],
  [35.3268814, -17.6907198],
  [35.3236198, -17.6923552],
  [35.3217745, -17.6939395],
  [35.3211522, -17.6946448],
  [35.3207365, -17.6950409],
  [35.3202537, -17.6955392],
  [35.3202537, -17.6955392],
  [35.3158951, -17.6921099],
  [35.3134918, -17.6813158],
  [35.3292847, -17.6832784],
  [35.3388977, -17.6741194],
  [35.330658, -17.6571084],
  [35.3258514, -17.6381328],
  [35.316925, -17.6211185],
  [35.3265381, -17.608684],
  [35.318985, -17.5903578],
  [35.3340912, -17.5681021],
  [35.3251648, -17.5536999],
  [35.3457642, -17.4960796],
  [35.3340912, -17.4810168],
  [35.3375244, -17.4594027],
  [35.3334045, -17.4482672],
  [35.340271, -17.4292697],
  [35.3182983, -17.4161668],
  [35.3196716, -17.3965107],
  [35.3155518, -17.3925793],
  [35.3231049, -17.3814397],
  [35.3361511, -17.3722653],
  [35.3450775, -17.3598138],
  [35.3388977, -17.3349081],
  [35.3265381, -17.3211429],
  [35.328598, -17.3113101],
  [35.3217316, -17.2975432],
  [35.3128052, -17.2896759],
  [35.3162384, -17.2791857],
  [35.3018188, -17.272629],
  [35.2928925, -17.2765631],
  [35.2970123, -17.264105],
  [35.2949524, -17.2555806],
  [35.3025055, -17.2516461],
  [35.2963257, -17.2418095],
  [35.3052521, -17.2398421],
  [35.2963257, -17.2326282],
  [35.2997589, -17.2227906],
  [35.3134918, -17.2057376],
  [35.3066254, -17.2050817],
  [35.3093719, -17.1958986],
  [35.2990723, -17.1913068],
  [35.2989006, -17.1745789],
  [35.2939224, -17.1753989],
  [35.2910042, -17.1724468],
  [35.2920341, -17.1699866],
  [35.2959824, -17.1699866],
  [35.3016472, -17.1686745],
  [35.3055954, -17.1614579],
  [35.3025055, -17.1562092],
  [35.3019905, -17.1496482],
  [35.3054237, -17.1439071],
  [35.3035355, -17.1394781],
  [35.297184, -17.1425948],
  [35.2928925, -17.1424308],
  [35.2889442, -17.1383298],
  [35.2954674, -17.1339007],
  [35.2954674, -17.1288153],
  [35.3066254, -17.1211049],
  [35.3011322, -17.0948544],
  [35.3100586, -17.0817278],
  [35.3079987, -17.0672874],
  [35.3121185, -17.0626925],
  [35.2990723, -17.0600668],
  [35.3031921, -17.0495636],
  [35.2928925, -17.0331512],
  [35.2949524, -17.0075449],
  [35.3031921, -16.998352],
  [35.307312, -16.9832485],
  [35.2922058, -16.9628897],
  [35.2702332, -16.9628897],
  [35.274353, -16.9497538],
  [35.26474, -16.941215],
  [35.2853394, -16.899172],
  [35.2722931, -16.898515],
  [35.2839661, -16.8853746],
  [35.2812195, -16.8755187],
  [35.2949524, -16.8742045],
  [35.3079987, -16.8406904],
  [35.3182983, -16.827546],
  [35.3059387, -16.8176871],
  [35.3045654, -16.806513],
  [35.2887726, -16.7979677],
  [35.2880859, -16.7821908],
  [35.2963257, -16.7683848],
  [35.286026, -16.7480029],
  [35.2935791, -16.7374823],
  [35.2819061, -16.7361671],
  [35.2894592, -16.7111782],
  [35.2674866, -16.6993402],
  [35.2578735, -16.6796086],
  [35.2482605, -16.6796086],
  [35.231781, -16.663164],
  [35.2276611, -16.6460602],
  [35.2159882, -16.655928],
  [35.2043152, -16.635534],
  [35.167923, -16.6204015],
  [35.1720428, -16.6078999],
  [35.1624298, -16.6085579],
  [35.1459503, -16.5756552],
  [35.1528168, -16.569074],
  [35.1432037, -16.5585435],
  [35.15625, -16.5532781],
  [35.1761627, -16.5401139],
  [35.1761627, -16.5302402],
  [35.187149, -16.5144411],
  [35.2084351, -16.5197076],
  [35.2187347, -16.4966657],
  [35.2400208, -16.4940321],
  [35.2626801, -16.4716456],
  [35.2661133, -16.4446466],
  [35.2613068, -16.4156679],
  [35.2674866, -16.3939311],
  [35.3004456, -16.3464968],
  [35.2812195, -16.3254112],
  [35.2990723, -16.2621408],
  [35.2908325, -16.2469792],
  [35.3086853, -16.2199492],
  [35.3979492, -16.1381772],
  [35.4830933, -16.1223465],
  [35.524292, -16.1698349],
  [35.5764771, -16.1276235],
  [35.6259155, -16.1276235],
  [35.6671143, -16.1012369],
  [35.7110596, -16.0933202],
  [35.8209229, -16.0326151],
  [35.8126831, -15.9930149],
  [35.8515644, -15.4416016],
  [35.8978271, -15.4470619],
  [36.0845947, -15.4510329],
  [36.12854, -15.4219104],
  [36.2686157, -15.4801514],
  [36.2713623, -15.3821913],
  [36.3565063, -15.3053795],
  [36.4581299, -15.2682879],
  [36.5611267, -15.2073374],
  [36.6311646, -15.1278103],
  [36.6383743, -15.106598],
  [36.6562271, -15.1109069],
  [36.6610336, -15.1075924],
  [36.6658401, -15.0933393],
  [36.6651535, -15.0797482],
  [36.6795731, -15.0731181],
  [36.6898727, -15.0598572],
  [36.6996574, -15.0447099],
  [36.6990352, -15.0328357],
  [36.6976833, -15.0309913],
  [36.6975331, -15.0294163],
  [36.69734, -15.0268466],
  [36.6982198, -15.0245255],
  [36.7021251, -15.0237587],
  [36.7026401, -15.0229712],
  [36.7012668, -15.0210645],
  [36.7002797, -15.0213547],
  [36.6989708, -15.0209609],
  [36.6982841, -15.0200076],
  [36.697855, -15.0184947],
  [36.702919, -15.0092305],
  [36.7028332, -15.0078419],
  [36.7020392, -15.0066191],
  [36.7019749, -15.0058108],
  [36.7013955, -15.0054377],
  [36.7006874, -15.0052719],
  [36.6991425, -15.0047952],
  [36.697576, -15.003448],
  [36.6963315, -15.0031164],
  [36.6960311, -15.0025775],
  [36.6962028, -15.0016241],
  [36.6955376, -15.0011889],
  [37.1914673, -15.0005049],
  [37.2093201, -15.0310121],
  [37.2395325, -15.0204014],
  [37.2669983, -15.1012946],
  [37.2821045, -15.0986429],
  [37.2972107, -15.1172044],
  [37.331543, -15.13709],
  [37.3617554, -15.1039463],
  [37.4043274, -15.1344387],
  [37.4043274, -15.1887838],
  [37.4551392, -15.168903],
  [37.5045776, -15.1304617],
  [37.525177, -15.1463693],
  [37.5952148, -15.1954103],
  [37.6158142, -15.2007113],
  [37.624054, -15.2364898],
  [37.7119446, -15.2457648],
  [37.7641296, -15.2126382],
  [37.8300476, -15.2033618],
  [37.8781128, -15.2338398],
  [37.9234314, -15.2311897],
  [38.001709, -15.2643134],
  [38.0072021, -15.2974319],
  [38.0360413, -15.3265718],
  [38.0923462, -15.3199494],
  [38.1088257, -15.3464376],
  [38.1472778, -15.3411402],
  [38.1349182, -15.3874876],
  [38.1706238, -15.4007278],
  [38.218689, -15.4510329],
  [38.2598877, -15.4325009],
  [38.2873535, -15.4602983],
  [38.313446, -15.4523566],
  [38.4082031, -15.5026492],
  [38.4329224, -15.510589],
  [38.4439087, -15.5423452],
  [38.478241, -15.5727736],
  [38.5057068, -15.5714507],
  [38.5359192, -15.6098109],
  [38.5949707, -15.6772544],
  [38.6801147, -15.6719655],
  [38.7007141, -15.7208824],
  [38.747406, -15.7618578],
  [38.7762451, -15.768466],
  [38.8037109, -15.7922536],
  [38.8243103, -15.8807717],
  [38.8517761, -15.8953009],
  [38.8490295, -15.9151116],
  [38.8737488, -15.9428434],
  [38.8737488, -15.9930149],
  [38.9163208, -16.0431739],
  [38.9163208, -16.0682486],
  [38.9300537, -16.111792],
  [38.9479065, -16.1197079],
  [38.9231873, -16.1421347],
  [38.9259338, -16.1711539],
  [38.931427, -16.2344536],
  [38.9533997, -16.2700507],
  [38.9863586, -16.2766421],
  [39.0083313, -16.3372721],
  [39.0151978, -16.3978834],
  [39.039917, -16.4268647],
  [39.0385437, -16.4584758],
  [39.0234375, -16.4834976],
  [39.039917, -16.5164161],
  [39.0097046, -16.5361645],
  [38.9918518, -16.6388235],
  [39.039917, -16.659875],
  [39.0509033, -16.7282763],
  [39.0715027, -16.7322217],
  [39.0907288, -16.7756166],
  [39.1127014, -16.796653],
  [39.1187096, -16.8203162],
  [39.1374207, -16.8413476],
  [39.1293526, -16.8546554],
  [39.1288376, -16.8643481],
  [39.1333008, -16.8689478],
  [39.1305542, -16.8727261],
  [39.1334724, -16.87634],
  [39.1339874, -16.888824],
  [39.1387939, -16.8911236],
  [39.1391802, -16.8939981],
  [39.1386223, -16.8963797],
  [39.1391373, -16.8977759],
  [39.1403818, -16.8989256],
  [39.1416693, -16.8985561],
  [39.1426992, -16.8975295],
  [39.1433859, -16.8967904],
  [39.1438258, -16.8966261],
  [39.1444373, -16.8964721],
  [39.1319275, -16.9201947],
  [39.1236877, -16.9293913],
  [39.0797424, -17.0121411],
  [39.0028381, -17.0121411],
  [38.9259338, -17.030525],
  [38.8188171, -17.0633489],
  [38.7199402, -17.0817278],
  [38.6869812, -17.0777896],
  [38.6004639, -17.1119177],
  [38.5798645, -17.1224174],
  [38.5139465, -17.1381658],
  [38.4439087, -17.1617859],
  [38.2406616, -17.2418095],
  [38.1898499, -17.2588592],
  [38.1898499, -17.282464],
  [38.143158, -17.2981988],
  [38.1335449, -17.2863978],
  [38.0882263, -17.3139322],
  [38.0895996, -17.3191764],
  [38.0580139, -17.3257314],
  [37.9962158, -17.3401517],
  [37.7723694, -17.4423716],
  [37.7572632, -17.4541625],
  [37.7105713, -17.479052],
  [37.6913452, -17.4816717],
  [37.6583862, -17.5104864],
  [37.6171875, -17.5314397],
  [37.5842285, -17.5392966],
  [37.5704956, -17.5589372],
  [37.4482727, -17.6165375],
  [37.4290466, -17.6191552],
  [37.350769, -17.6754278],
  [37.3260498, -17.6806616],
  [37.3123169, -17.692437],
  [37.2917175, -17.692437],
  [37.2889709, -17.7094446],
  [37.2505188, -17.7356069],
  [37.2203064, -17.7669967],
  [37.2010803, -17.7669967],
  [37.1873474, -17.7774587],
  [37.1420288, -17.8166859],
  [37.1022034, -17.8715894],
  [37.0678711, -17.9134093],
  [37.0294189, -17.9669766],
  [36.9895935, -18.0113859],
  [36.973114, -18.0192217],
  [36.9676208, -18.0401154],
  [36.9744873, -18.0557841],
  [36.9277954, -18.1066976],
  [36.8989563, -18.1328014],
  [36.9017029, -18.1549865],
  [36.8920898, -18.1693401],
  [36.8440247, -18.1928252],
  [36.8151855, -18.2345687],
  [36.7520142, -18.3010768],
  [36.6751099, -18.3792889],
  [36.6297913, -18.4157757],
  [36.5666199, -18.4874238],
  [36.5267944, -18.5290961],
  [36.5089417, -18.5303982],
  [36.4993286, -18.5655511],
  [36.4746094, -18.5629474],
  [36.4595032, -18.5720601],
  [36.4855957, -18.5928872],
  [36.4759827, -18.6150133],
  [36.4334106, -18.6267259],
  [36.4196777, -18.6852769],
  [36.3812256, -18.683976],
  [36.3771057, -18.7034886],
  [36.3468933, -18.754211],
  [36.3922119, -18.7164958],
  [36.3867188, -18.7555114],
  [36.3908386, -18.7802166],
  [36.2974548, -18.8686054],
  [36.2782288, -18.8841986],
  [36.2160873, -18.8906953],
  [36.1685371, -18.9002776],
  [36.1544609, -18.9015768],
  [36.1493111, -18.899628],
  [36.1467361, -18.8976791],
  [36.139698, -18.896867],
  [36.1367369, -18.8971106],
  [36.1342049, -18.8976791],
];
const tete = [
  [35.3202537, -17.6955392],
  [35.2846527, -17.6957078],
  [35.2702332, -17.685241],
  [35.2503204, -17.6911287],
  [35.1940155, -17.6728109],
  [35.177536, -17.6322434],
  [35.1644897, -17.6113018],
  [35.144577, -17.5667929],
  [35.123291, -17.5569732],
  [35.1095581, -17.5543546],
  [35.1040649, -17.5307849],
  [35.0992584, -17.5032832],
  [35.0862122, -17.4829816],
  [35.0765991, -17.4528525],
  [35.0642395, -17.4384411],
  [35.0436401, -17.3938898],
  [35.0244141, -17.3866819],
  [35.0010681, -17.3630906],
  [34.9852753, -17.3335971],
  [34.9749756, -17.2673835],
  [34.9770355, -17.2555806],
  [34.9667358, -17.2378747],
  [34.9523163, -17.2378747],
  [34.9372101, -17.2155761],
  [34.9324036, -17.185403],
  [34.9262238, -17.1663784],
  [34.9255371, -17.1565373],
  [34.9152374, -17.1466956],
  [34.9124908, -17.1316041],
  [34.8973846, -17.1250422],
  [34.8987579, -17.1046988],
  [34.8918915, -17.0915729],
  [34.9042511, -17.073195],
  [34.8994446, -17.0613797],
  [34.8836517, -17.0416858],
  [34.8417664, -17.0029485],
  [34.8218536, -16.9766814],
  [34.821167, -16.9602626],
  [34.8088074, -16.952381],
  [34.7909546, -16.949097],
  [34.7511292, -16.9109975],
  [34.7270966, -16.9018],
  [34.6515656, -16.8801182],
  [34.6222973, -16.8600768],
  [34.6071911, -16.8452907],
  [34.5729446, -16.8112785],
  [34.5451355, -16.8038838],
  [34.5397101, -16.801377],
  [34.5396618, -16.8013584],
  [34.4641113, -16.7335369],
  [34.367981, -16.7309066],
  [34.2938232, -16.7072323],
  [34.1921997, -16.7098629],
  [34.1235352, -16.6204015],
  [34.0054321, -16.6098739],
  [33.7774658, -16.3939311],
  [33.7088013, -16.4255475],
  [33.5577393, -16.4334508],
  [33.4451294, -16.5282653],
  [33.3462524, -16.6993402],
  [33.2830811, -16.8571197],
  [33.2171631, -16.9832485],
  [33.1430054, -17.0278989],
  [33.0578613, -17.3270424],
  [32.9919434, -17.3165543],
  [32.9837036, -17.1749069],
  [32.829895, -16.9201947],
  [32.9177856, -16.893916],
  [32.9919434, -16.6993402],
  [32.8463745, -16.7072323],
  [32.7008057, -16.6835551],
  [32.7062988, -16.5993457],
  [32.2723389, -16.4334508],
  [32.0278931, -16.4413538],
  [31.9042969, -16.4018357],
  [31.8988037, -16.33859],
  [31.7147827, -16.1909372],
  [31.4044189, -16.1408156],
  [31.3000488, -16.0088559],
  [31.187439, -16.0035757],
  [31.1380005, -15.9850939],
  [31.055603, -16.011496],
  [30.9979248, -16.066929],
  [30.9512329, -16.0352549],
  [30.9265137, -15.9982954],
  [30.4238892, -15.9930149],
  [30.4293823, -15.6362619],
  [30.3607178, -15.5410221],
  [30.4074097, -15.4748574],
  [30.3689575, -15.4430908],
  [30.3826904, -15.3848395],
  [30.3634644, -15.3239229],
  [30.2783203, -15.2603388],
  [30.2124023, -15.0906874],
  [30.2288818, -15.0376435],
  [30.2096558, -14.9978519],
  [30.8001709, -14.7908165],
  [31.0803223, -14.7297298],
  [31.2698364, -14.6553402],
  [31.4016724, -14.644711],
  [31.5142822, -14.6048472],
  [31.6873169, -14.5091444],
  [31.8164063, -14.4931899],
  [31.9509888, -14.4080798],
  [32.0581055, -14.3841368],
  [32.4481201, -14.2936621],
  [33.2336426, -14.0033665],
  [33.2666016, -14.0233528],
  [33.3036804, -14.0273499],
  [33.3242798, -14.0872973],
  [33.2940674, -14.1445657],
  [33.3435059, -14.2151325],
  [33.3695984, -14.2044823],
  [33.4492493, -14.344226],
  [33.4506226, -14.366843],
  [33.4712219, -14.4014292],
  [33.5124207, -14.4227104],
  [33.5343933, -14.4213804],
  [33.565979, -14.4812232],
  [33.6016846, -14.4918603],
  [33.6236572, -14.5317446],
  [33.6222839, -14.5689635],
  [33.6401367, -14.5875706],
  [33.674469, -14.6154782],
  [33.6936951, -14.5968735],
  [33.7156677, -14.5769381],
  [33.7046814, -14.5357326],
  [33.7088013, -14.5051558],
  [33.7348938, -14.4998377],
  [33.7609863, -14.5211095],
  [33.8049316, -14.5530133],
  [33.85849, -14.5184506],
  [33.8749695, -14.5304152],
  [33.891449, -14.5171212],
  [33.8832092, -14.4985081],
  [33.9326477, -14.4732451],
  [34.0892029, -14.4892011],
  [34.0892029, -14.4612774],
  [34.1880798, -14.4413298],
  [34.2210388, -14.4439896],
  [34.2375183, -14.4240404],
  [34.2663574, -14.4346802],
  [34.2979431, -14.4027594],
  [34.3418884, -14.3974388],
  [34.3707275, -14.3841368],
  [34.3913269, -14.3907879],
  [34.4750977, -14.5317446],
  [34.4943237, -14.5397206],
  [34.4943237, -14.5503548],
  [34.5135498, -14.5503548],
  [34.5492554, -14.6101628],
  [34.5492554, -14.6354101],
  [34.5492554, -14.644711],
  [34.538269, -14.6819108],
  [34.5217896, -14.6845677],
  [34.552002, -14.7483232],
  [34.5808411, -14.8147154],
  [34.5602417, -14.8996676],
  [34.6206665, -15.0084637],
  [34.5684814, -15.0774277],
  [34.5877075, -15.0827317],
  [34.571228, -15.133113],
  [34.5849609, -15.2073374],
  [34.6096802, -15.2682879],
  [34.5602417, -15.3424646],
  [34.5245361, -15.3477619],
  [34.5162964, -15.4007278],
  [34.4586182, -15.4245581],
  [34.4338989, -15.488092],
  [34.4448853, -15.6071656],
  [34.4366455, -15.6574203],
  [34.3652344, -15.7552494],
  [34.3130493, -15.7631795],
  [34.2581177, -15.8160384],
  [34.2636108, -15.9322793],
  [34.3295288, -15.9534068],
  [34.3569946, -15.9613291],
  [34.3954468, -16.0246957],
  [34.4311523, -16.053732],
  [34.3954468, -16.1830241],
  [34.4284058, -16.2595041],
  [34.4998169, -16.3016872],
  [34.5245361, -16.2647775],
  [34.5684814, -16.3122316],
  [34.5877075, -16.4097399],
  [34.6536255, -16.4439881],
  [34.6838379, -16.4492565],
  [34.6948242, -16.4992992],
  [34.7305298, -16.5019326],
  [34.7854614, -16.5493289],
  [34.7717285, -16.5756552],
  [34.8348999, -16.6098739],
  [34.8678589, -16.6809241],
  [34.9200439, -16.6940787],
  [34.9200439, -16.7466878],
  [34.972229, -16.7466878],
  [34.9887085, -16.7953383],
  [35.0312805, -16.8137434],
  [35.0669861, -16.8400332],
  [35.1123047, -16.8216307],
  [35.1397705, -16.8216307],
  [35.1644897, -16.8466051],
  [35.1521301, -16.8768329],
  [35.1713562, -16.9425287],
  [35.1507568, -16.9464697],
  [35.1397705, -16.9688006],
  [35.0587463, -17.0068883],
  [35.0587463, -17.0423423],
  [35.1081848, -17.0922292],
  [35.0834656, -17.1106052],
  [35.0917053, -17.1329165],
  [35.177536, -17.1291434],
  [35.2633667, -17.127667],
  [35.2805328, -17.1291434],
  [35.2898026, -17.1283232],
  [35.2954674, -17.1288153],
  [35.2954674, -17.1339007],
  [35.2889442, -17.1383298],
  [35.2928925, -17.1424308],
  [35.297184, -17.1425948],
  [35.3035355, -17.1394781],
  [35.3054237, -17.1439071],
  [35.3019905, -17.1496482],
  [35.3025055, -17.1562092],
  [35.3055954, -17.1614579],
  [35.3016472, -17.1686745],
  [35.2959824, -17.1699866],
  [35.2920341, -17.1699866],
  [35.2910042, -17.1724468],
  [35.2939224, -17.1753989],
  [35.2989006, -17.1745789],
  [35.2990723, -17.1913068],
  [35.3093719, -17.1958986],
  [35.3066254, -17.2050817],
  [35.3134918, -17.2057376],
  [35.2997589, -17.2227906],
  [35.2963257, -17.2326282],
  [35.3052521, -17.2398421],
  [35.2963257, -17.2418095],
  [35.3025055, -17.2516461],
  [35.2949524, -17.2555806],
  [35.2970123, -17.264105],
  [35.2928925, -17.2765631],
  [35.3018188, -17.272629],
  [35.3162384, -17.2791857],
  [35.3128052, -17.2896759],
  [35.3217316, -17.2975432],
  [35.328598, -17.3113101],
  [35.3265381, -17.3211429],
  [35.3388977, -17.3349081],
  [35.3450775, -17.3598138],
  [35.3361511, -17.3722653],
  [35.3231049, -17.3814397],
  [35.3155518, -17.3925793],
  [35.3196716, -17.3965107],
  [35.3182983, -17.4161668],
  [35.340271, -17.4292697],
  [35.3334045, -17.4482672],
  [35.3375244, -17.4594027],
  [35.3340912, -17.4810168],
  [35.3457642, -17.4960796],
  [35.3251648, -17.5536999],
  [35.3340912, -17.5681021],
  [35.318985, -17.5903578],
  [35.3265381, -17.608684],
  [35.316925, -17.6211185],
  [35.3258514, -17.6381328],
  [35.330658, -17.6571084],
  [35.3388977, -17.6741194],
  [35.3292847, -17.6832784],
  [35.3134918, -17.6813158],
  [35.3158951, -17.6921099],
  [35.3202537, -17.6955392],
];
const manica = [
  [32.4055997, -21.3159275],
  [32.4091852, -21.3168227],
  [32.4107516, -21.3167927],
  [32.4123394, -21.3175223],
  [32.4157834, -21.3183119],
  [32.4190235, -21.318082],
  [32.4211049, -21.3175323],
  [32.4219954, -21.3174524],
  [32.425096, -21.3178522],
  [32.4268556, -21.3178422],
  [32.427671, -21.3176023],
  [32.4302888, -21.3164929],
  [32.4326706, -21.3156933],
  [32.4337113, -21.3151635],
  [32.4358356, -21.3144839],
  [32.4417579, -21.3130546],
  [32.4444723, -21.3125448],
  [32.4475944, -21.3118452],
  [32.4479914, -21.3118152],
  [32.4493968, -21.3114754],
  [32.4500191, -21.3112655],
  [32.4512744, -21.3104758],
  [32.4520469, -21.3104658],
  [32.4528837, -21.3107457],
  [32.4539459, -21.3109256],
  [32.4549007, -21.3109456],
  [32.4567354, -21.3111655],
  [32.4588811, -21.3114754],
  [32.460115, -21.3118452],
  [32.4613059, -21.3124349],
  [32.4626684, -21.3135044],
  [32.4642456, -21.3145239],
  [32.4646103, -21.3148937],
  [32.4648893, -21.3153434],
  [32.4650502, -21.3158232],
  [32.4650931, -21.3163429],
  [32.4650288, -21.3171725],
  [32.4649858, -21.3188416],
  [32.4659729, -21.3201609],
  [32.4693847, -21.3233991],
  [32.4712729, -21.325398],
  [32.4734402, -21.3257178],
  [32.4750066, -21.3272169],
  [32.4765515, -21.3279965],
  [32.4790621, -21.3285761],
  [32.4794698, -21.3296355],
  [32.4799848, -21.3299953],
  [32.4806714, -21.3302951],
  [32.4813795, -21.3306149],
  [32.4821305, -21.3315943],
  [32.4827099, -21.3328335],
  [32.4830532, -21.335252],
  [32.4838257, -21.3361314],
  [32.4868941, -21.3383699],
  [32.4884391, -21.3391694],
  [32.490027, -21.339789],
  [32.4946189, -21.3406484],
  [32.4993825, -21.3419075],
  [32.5031376, -21.3448655],
  [32.5044894, -21.3468041],
  [32.5076437, -21.3484229],
  [32.5118923, -21.3492223],
  [32.5157976, -21.3526797],
  [32.5170851, -21.3546182],
  [32.5204325, -21.3581155],
  [32.5223422, -21.3601738],
  [32.5254536, -21.3639107],
  [32.5263119, -21.3645702],
  [32.5300455, -21.365909],
  [32.5317621, -21.3663486],
  [32.5339079, -21.3660289],
  [32.5370622, -21.3672679],
  [32.5386715, -21.3683669],
  [32.5394654, -21.3689464],
  [32.5400448, -21.3697257],
  [32.5403237, -21.3732426],
  [32.5410104, -21.3767394],
  [32.5421262, -21.378258],
  [32.5438643, -21.379297],
  [32.5481558, -21.3853112],
  [32.5506234, -21.3868896],
  [32.553606, -21.3878886],
  [32.5548077, -21.3877288],
  [32.5560737, -21.3865499],
  [32.5575757, -21.3859505],
  [32.5598073, -21.3857308],
  [32.5624895, -21.3848516],
  [32.5635195, -21.3847717],
  [32.5649786, -21.3849915],
  [32.5661373, -21.3856508],
  [32.570622, -21.3899266],
  [32.5719309, -21.3917047],
  [32.5729609, -21.3926038],
  [32.5747633, -21.3932831],
  [32.5780463, -21.3933031],
  [32.5814366, -21.3937626],
  [32.5877881, -21.3952211],
  [32.5892043, -21.3949813],
  [32.5907707, -21.3935228],
  [32.5916719, -21.3929834],
  [32.5925946, -21.3927636],
  [32.5968218, -21.3912252],
  [32.5999331, -21.3896269],
  [32.601521, -21.3886478],
  [32.6020575, -21.3886079],
  [32.6054907, -21.3900864],
  [32.6061773, -21.3905259],
  [32.6080656, -21.3923441],
  [32.6100397, -21.3936427],
  [32.6133442, -21.3960802],
  [32.6172066, -21.3979182],
  [32.6214981, -21.3983977],
  [32.6279998, -21.3952011],
  [32.6292014, -21.3951412],
  [32.6309609, -21.3949414],
  [32.6326561, -21.395261],
  [32.6360893, -21.3960002],
  [32.6392865, -21.3967994],
  [32.64184, -21.3966396],
  [32.6449299, -21.3946017],
  [32.646389, -21.3926238],
  [32.6485562, -21.3919245],
  [32.652483, -21.3915649],
  [32.6548862, -21.3915649],
  [32.6590705, -21.393343],
  [32.6600146, -21.3940423],
  [32.6611948, -21.3967195],
  [32.6623106, -21.3991768],
  [32.6650786, -21.4023933],
  [32.6651859, -21.4034122],
  [32.6645851, -21.4064488],
  [32.6698637, -21.408906],
  [32.6716232, -21.4088461],
  [32.6734686, -21.4083666],
  [32.6766658, -21.4060892],
  [32.6778245, -21.4056097],
  [32.6790905, -21.4058095],
  [32.6829314, -21.4079271],
  [32.683661, -21.4086463],
  [32.6891327, -21.41408],
  [32.6963425, -21.4118426],
  [32.7076721, -21.4211115],
  [32.722435, -21.4374105],
  [32.7351379, -21.442204],
  [32.7399445, -21.4524296],
  [32.7505875, -21.4476364],
  [32.7622604, -21.4533882],
  [32.7684402, -21.4524296],
  [32.77668, -21.4616959],
  [32.7814865, -21.4763929],
  [32.7880096, -21.4834214],
  [32.7952194, -21.4840604],
  [32.8024292, -21.4882134],
  [32.8106689, -21.4866161],
  [32.8116989, -21.4961997],
  [32.8195953, -21.5016301],
  [32.8271484, -21.5009912],
  [32.8360748, -21.5006718],
  [32.8388214, -21.5054632],
  [32.8456879, -21.5064215],
  [32.8484344, -21.4977969],
  [32.8525543, -21.4965191],
  [32.863884, -21.5038661],
  [32.8721237, -21.5041855],
  [32.8755569, -21.5029078],
  [32.8995895, -21.4834214],
  [32.9037094, -21.4799072],
  [32.9085159, -21.4805462],
  [32.9129791, -21.4840604],
  [32.9201889, -21.4878939],
  [32.9188156, -21.4946025],
  [32.9363251, -21.4968386],
  [32.9445648, -21.5067409],
  [32.9555511, -21.5134485],
  [32.9610443, -21.5195171],
  [32.9558945, -21.5290985],
  [32.9565811, -21.5370825],
  [32.9534912, -21.5543265],
  [32.959671, -21.5565616],
  [32.9802704, -21.5718877],
  [33.0231857, -21.5763575],
  [33.0331421, -21.5747611],
  [33.0413818, -21.5856159],
  [33.0657578, -21.5824234],
  [33.0698776, -21.5757189],
  [33.0794907, -21.567737],
  [33.0939102, -21.5504946],
  [33.0997467, -21.5409147],
  [33.1086731, -21.5351664],
  [33.1141663, -21.5389986],
  [33.122406, -21.5246272],
  [33.1323624, -21.5191977],
  [33.1474686, -21.5169619],
  [33.1591415, -21.5057826],
  [33.1752777, -21.5022689],
  [33.1852341, -21.4888523],
  [33.2054901, -21.4834214],
  [33.2089233, -21.4779903],
  [33.2243729, -21.4747955],
  [33.2305527, -21.475754],
  [33.2460022, -21.4741565],
  [33.253212, -21.4767124],
  [33.2607651, -21.4853382],
  [33.2669449, -21.4946025],
  [33.2714081, -21.4998732],
  [33.2742405, -21.5011509],
  [33.2847548, -21.497717],
  [33.29072, -21.4985555],
  [33.2938635, -21.5006319],
  [33.2956928, -21.5018946],
  [33.2971466, -21.5023189],
  [33.2971466, -21.5023189],
  [33.3005905, -21.5027082],
  [33.3043671, -21.5025884],
  [33.3117056, -21.4999531],
  [33.3163404, -21.4979566],
  [33.3224773, -21.4939236],
  [33.3270693, -21.4890919],
  [33.3301163, -21.4811851],
  [33.3414459, -21.4725591],
  [33.3551788, -21.4700031],
  [33.3867645, -21.4783098],
  [33.391571, -21.4700031],
  [33.4066772, -21.4610568],
  [33.4224701, -21.4581811],
  [33.4300232, -21.4543468],
  [33.4434128, -21.4537077],
  [33.445816, -21.4457191],
  [33.4506226, -21.439967],
  [33.4602356, -21.4361322],
  [33.4664154, -21.429421],
  [33.4643555, -21.4198331],
  [33.4767151, -21.4096052],
  [33.4859848, -21.4096052],
  [33.5086441, -21.4000159],
  [33.5364532, -21.3766794],
  [33.5450363, -21.3747612],
  [33.5704422, -21.387549],
  [33.5972214, -21.3907457],
  [33.610611, -21.3926637],
  [33.6181641, -21.3891473],
  [33.629837, -21.390426],
  [33.6610794, -21.3833931],
  [33.6682892, -21.3833931],
  [33.6820221, -21.4022535],
  [33.6930084, -21.4012945],
  [33.717041, -21.418235],
  [33.7252808, -21.4156781],
  [33.7318039, -21.4214311],
  [33.7414169, -21.4172762],
  [33.7475967, -21.4198331],
  [33.7544632, -21.4102445],
  [33.7661362, -21.4086463],
  [33.7771225, -21.390426],
  [33.7953186, -21.3830734],
  [33.8166046, -21.3872293],
  [33.8368607, -21.3968194],
  [33.847847, -21.4076874],
  [33.870163, -21.4118426],
  [33.8900757, -21.4060892],
  [33.9038086, -21.4025731],
  [33.9333344, -21.3507812],
  [33.9299011, -21.3443858],
  [33.9415741, -21.3379902],
  [33.9388275, -21.318162],
  [33.9752197, -21.3015298],
  [33.9992523, -21.3130446],
  [34.0078354, -21.3066476],
  [34.0086937, -21.3002503],
  [34.0098953, -21.2914537],
  [34.010067, -21.2789774],
  [34.010582, -21.2682598],
  [34.0128136, -21.2541818],
  [34.0171051, -21.2405824],
  [34.0250015, -21.2308221],
  [34.0358162, -21.219621],
  [34.0370178, -21.21386],
  [34.0358162, -21.2055383],
  [34.0438843, -21.1644028],
  [34.1017342, -21.1264584],
  [34.1125488, -21.0780932],
  [33.9903259, -20.9999069],
  [33.891449, -20.9973427],
  [33.9051819, -20.8639448],
  [33.8969421, -20.7972014],
  [33.8392639, -20.7368502],
  [33.8186646, -20.744556],
  [33.7252808, -20.7060233],
  [33.7211609, -20.6443507],
  [33.6717224, -20.6225023],
  [33.6470032, -20.5942232],
  [33.6634827, -20.5376494],
  [33.6058044, -20.5376494],
  [33.4684753, -20.4141429],
  [33.4973145, -20.3523525],
  [33.4643555, -20.3072814],
  [33.4753418, -20.2969776],
  [33.5124207, -20.271215],
  [33.4973145, -20.2570438],
  [33.4602356, -20.1694112],
  [33.4039307, -20.1165501],
  [33.3847046, -20.084309],
  [33.4121704, -20.0727005],
  [33.4355164, -20.0443207],
  [33.4629822, -20.0469009],
  [33.4835815, -19.9888363],
  [33.5041809, -19.99787],
  [33.5110474, -19.9888363],
  [33.5289001, -19.9914174],
  [33.5591125, -19.9656045],
  [33.5865784, -19.9591506],
  [33.6058044, -19.9281682],
  [33.6346436, -19.9514055],
  [33.6497498, -19.9359143],
  [33.684082, -19.9359143],
  [33.7225342, -19.9823834],
  [33.7609863, -19.99787],
  [33.7705994, -19.9656045],
  [33.7939453, -19.9436601],
  [33.8296509, -19.9359143],
  [33.8227844, -19.8648936],
  [33.8337708, -19.8106382],
  [33.8516235, -19.7667036],
  [33.825531, -19.716293],
  [33.815918, -19.6852633],
  [33.7664795, -19.6710393],
  [33.7678528, -19.6516409],
  [33.7472534, -19.6322402],
  [33.7376404, -19.6154243],
  [33.7486267, -19.5960192],
  [33.9312744, -19.6011942],
  [33.9408875, -19.5817873],
  [33.9381409, -19.3953633],
  [33.943634, -19.3526109],
  [33.9408875, -19.2437362],
  [33.9889526, -19.1036483],
  [34.0274048, -19.0023998],
  [34.0438843, -18.9699353],
  [34.052124, -18.945258],
  [34.0356445, -18.9231753],
  [34.019165, -18.9296705],
  [33.9834595, -18.9036881],
  [33.9285278, -18.8751028],
  [33.9134216, -18.8153177],
  [33.9068985, -18.8162926],
  [33.9048386, -18.8110929],
  [33.9007187, -18.8039431],
  [33.8983154, -18.7909427],
  [33.8917923, -18.7896426],
  [33.8873291, -18.783792],
  [33.8849258, -18.7815167],
  [33.8794327, -18.7854172],
  [33.870163, -18.7844421],
  [33.8636398, -18.7828169],
  [33.8571167, -18.7785913],
  [33.8523102, -18.783792],
  [33.8451004, -18.7873674],
  [33.8378906, -18.7847671],
  [33.8368607, -18.7795665],
  [33.8303375, -18.7730654],
  [33.8162613, -18.7685145],
  [33.8107681, -18.7620131],
  [33.8056183, -18.7564866],
  [33.8063049, -18.7451081],
  [33.7997818, -18.7421821],
  [33.7977219, -18.7379555],
  [33.7905121, -18.7347042],
  [33.7843323, -18.7324283],
  [33.7798691, -18.7269008],
  [33.7757492, -18.7252751],
  [33.7705994, -18.7197475],
  [33.7654495, -18.7164958],
  [33.7575531, -18.7119434],
  [33.7530899, -18.7174713],
  [33.7469101, -18.7177965],
  [33.7410736, -18.7174713],
  [33.738327, -18.7096672],
  [33.7335205, -18.7060901],
  [33.7283707, -18.7038138],
  [33.7259674, -18.6995863],
  [33.7215042, -18.694383],
  [33.7235641, -18.6921065],
  [33.7187576, -18.6885291],
  [33.7194443, -18.6826751],
  [33.7197876, -18.6761703],
  [33.7211609, -18.6709663],
  [33.7252808, -18.6716168],
  [33.7259674, -18.6677137],
  [33.7311172, -18.6651116],
  [33.729744, -18.659582],
  [33.729744, -18.6534016],
  [33.7324905, -18.6524258],
  [33.7342072, -18.6455945],
  [33.7321472, -18.6429921],
  [33.7311172, -18.6381124],
  [33.7362671, -18.6384377],
  [33.7421036, -18.6368111],
  [33.7445068, -18.6325819],
  [33.7434769, -18.6290033],
  [33.7465668, -18.6293286],
  [33.7465668, -18.6224964],
  [33.7438202, -18.6182669],
  [33.7441635, -18.6127357],
  [33.7434769, -18.6065537],
  [33.7421036, -18.5977682],
  [33.7438202, -18.5928872],
  [33.75, -18.590284],
  [33.75, -18.5847519],
  [33.740387, -18.5733619],
  [33.7390137, -18.5681547],
  [33.7475967, -18.5574145],
  [33.7448502, -18.548952],
  [33.7479401, -18.536583],
  [33.7551498, -18.5268174],
  [33.7668228, -18.5173768],
  [33.7743759, -18.5183534],
  [33.7771225, -18.5238876],
  [33.782959, -18.5251898],
  [33.7915421, -18.527794],
  [33.8011551, -18.527794],
  [33.8073349, -18.5287706],
  [33.8152313, -18.5297472],
  [33.8220978, -18.5261664],
  [33.8279343, -18.5225855],
  [33.8337708, -18.5264919],
  [33.8392639, -18.5251898],
  [33.8389206, -18.5196556],
  [33.8447571, -18.5203067],
  [33.8519669, -18.5137957],
  [33.85746, -18.5079356],
  [33.8651848, -18.5170512],
  [33.8684464, -18.5173768],
  [33.8715363, -18.5164001],
  [33.8725662, -18.5185162],
  [33.8727379, -18.5220972],
  [33.8766861, -18.5233993],
  [33.8778877, -18.5220972],
  [33.8856125, -18.5175396],
  [33.8766861, -18.5094007],
  [33.8741112, -18.500773],
  [33.8684464, -18.4958893],
  [33.870163, -18.4857957],
  [33.8658714, -18.4792834],
  [33.8674164, -18.47619],
  [33.8670731, -18.4706543],
  [33.8653564, -18.4687004],
  [33.8669014, -18.4660953],
  [33.8718796, -18.4646299],
  [33.870163, -18.4631645],
  [33.8656998, -18.4594194],
  [33.8650131, -18.4506264],
  [33.8545418, -18.4489981],
  [33.8519669, -18.4472068],
  [33.856945, -18.4408559],
  [33.8560867, -18.4292934],
  [33.8607216, -18.4271763],
  [33.85952, -18.4235933],
  [33.8557434, -18.4204989],
  [33.8620949, -18.414147],
  [33.8572884, -18.4047002],
  [33.8505936, -18.4069805],
  [33.8481903, -18.4170787],
  [33.8402939, -18.4213132],
  [33.8310242, -18.4134955],
  [33.836174, -18.4056774],
  [33.8365173, -18.3897145],
  [33.8413239, -18.3815695],
  [33.8444138, -18.3757049],
  [33.8354874, -18.3757049],
  [33.8286209, -18.3675593],
  [33.8279343, -18.3587616],
  [33.8248444, -18.3555031],
  [33.826561, -18.3457272],
  [33.8238144, -18.3362766],
  [33.8193512, -18.3317141],
  [33.8241577, -18.3235664],
  [33.8238144, -18.3150924],
  [33.8279343, -18.3115071],
  [33.8248444, -18.2958614],
  [33.8214111, -18.2945575],
  [33.8107681, -18.2961874],
  [33.804245, -18.2851041],
  [33.8135147, -18.2785843],
  [33.8217545, -18.2785843],
  [33.8341141, -18.26913],
  [33.848877, -18.2352209],
  [33.8729095, -18.221525],
  [33.902092, -18.2140243],
  [33.9353943, -18.1500929],
  [33.9453506, -18.1360641],
  [33.9491272, -18.1223603],
  [33.9511871, -18.1269284],
  [33.9817429, -18.1344327],
  [33.9903259, -18.1328014],
  [34.0002823, -18.1337802],
  [34.0044022, -18.1259495],
  [34.0081787, -18.1236655],
  [34.0274048, -18.1295386],
  [34.0284348, -18.1350852],
  [34.0263748, -18.1429155],
  [34.0322113, -18.1468305],
  [34.0325546, -18.1540078],
  [34.029808, -18.1611848],
  [34.0490341, -18.1673828],
  [34.0504074, -18.1781474],
  [34.0438843, -18.1885851],
  [34.0483475, -18.1876066],
  [34.052124, -18.192499],
  [34.053154, -18.2042404],
  [34.0600204, -18.2110892],
  [34.0613937, -18.2231555],
  [34.0689468, -18.2182639],
  [34.0737534, -18.2192422],
  [34.0912628, -18.2388078],
  [34.0995026, -18.2414163],
  [34.1022491, -18.2515242],
  [34.1111755, -18.2554367],
  [34.1146088, -18.2616315],
  [34.1316462, -18.2640767],
  [34.1512156, -18.2571485],
  [34.1654205, -18.231634],
  [34.1788101, -18.205545],
  [34.1884232, -18.1915206],
  [34.1956329, -18.1905421],
  [34.1976929, -18.1931514],
  [34.2055893, -18.1931514],
  [34.2165756, -18.2000006],
  [34.2220688, -18.2078279],
  [34.2272186, -18.205545],
  [34.2306519, -18.2101108],
  [34.2358017, -18.2078279],
  [34.2440414, -18.2078279],
  [34.248848, -18.2117415],
  [34.2502213, -18.2143505],
  [34.2546844, -18.21859],
  [34.2629242, -18.2042404],
  [34.2622375, -18.1918467],
  [34.2697906, -18.1631421],
  [34.2848969, -18.0858118],
  [34.2934799, -18.0734097],
  [34.2886734, -18.0512142],
  [34.2848969, -18.042727],
  [34.2848969, -18.022813],
  [34.2794037, -18.0188952],
  [34.2818069, -18.0055089],
  [34.2773438, -17.9976725],
  [34.2790604, -17.991795],
  [34.2687607, -17.9895092],
  [34.2601776, -17.9819987],
  [34.2632675, -17.97808],
  [34.2625809, -17.9695892],
  [34.2721939, -17.9669766],
  [34.2821503, -17.9519533],
  [34.2869568, -17.9516266],
  [34.2917633, -17.9408482],
  [34.2773438, -17.9248427],
  [34.2773438, -17.9134093],
  [34.2687607, -17.908509],
  [34.2636108, -17.9124292],
  [34.2622375, -17.9039353],
  [34.2577744, -17.9026285],
  [34.2546844, -17.8983814],
  [34.2471313, -17.8980547],
  [34.2399216, -17.8869463],
  [34.2416382, -17.8826988],
  [34.2402649, -17.8804116],
  [34.2327118, -17.8781244],
  [34.2176056, -17.8787779],
  [34.2066193, -17.8706091],
  [34.1873932, -17.8715894],
  [34.1983795, -17.8395644],
  [34.1956329, -17.8199544],
  [34.2079926, -17.7735355],
  [34.2086792, -17.7480327],
  [34.203186, -17.7369149],
  [34.2038727, -17.7212181],
  [34.1609573, -17.6960349],
  [34.1633606, -17.6371513],
  [34.1407013, -17.5972303],
  [34.1688538, -17.5373324],
  [34.1863632, -17.4885484],
  [34.1963196, -17.453835],
  [34.204216, -17.4171495],
  [34.1873932, -17.3860266],
  [34.2090225, -17.3221262],
  [34.204216, -17.3109823],
  [34.205246, -17.2959042],
  [34.2320251, -17.2660721],
  [34.2289352, -17.2562363],
  [34.2176056, -17.2532855],
  [34.2124557, -17.2427932],
  [34.1743469, -17.2250861],
  [34.2670441, -17.0590821],
  [34.3130493, -17.0308533],
  [34.4709778, -16.9894871],
  [34.4884872, -16.9563219],
  [34.5025635, -16.9300482],
  [34.5152664, -16.9100121],
  [34.5272827, -16.8860316],
  [34.5351791, -16.8692764],
  [34.5217896, -16.870262],
  [34.5097733, -16.8676336],
  [34.5077133, -16.8554768],
  [34.5101166, -16.8452907],
  [34.5200729, -16.837733],
  [34.5277977, -16.8276282],
  [34.5324326, -16.8213021],
  [34.5336342, -16.817112],
  [34.5344925, -16.8127164],
  [34.5367455, -16.8069444],
  [34.537915, -16.8046541],
  [34.5387545, -16.8030801],
  [34.5393285, -16.8019991],
  [34.5396618, -16.8013584],
  [34.4641113, -16.7335369],
  [34.367981, -16.7309066],
  [34.2938232, -16.7072323],
  [34.1921997, -16.7098629],
  [34.1235352, -16.6204015],
  [34.0054321, -16.6098739],
  [33.7774658, -16.3939311],
  [33.7088013, -16.4255475],
  [33.5577393, -16.4334508],
  [33.4451294, -16.5282653],
  [33.3462524, -16.6993402],
  [33.2830811, -16.8571197],
  [33.2171631, -16.9832485],
  [33.1430054, -17.0278989],
  [33.0578613, -17.3270424],
  [33.0056763, -17.403063],
  [32.9644775, -17.4842914],
  [33.0001831, -17.5628651],
  [33.0056763, -17.8088411],
  [32.961731, -17.9186361],
  [32.961731, -17.9630576],
  [32.9397583, -18.0257513],
  [33.0166626, -18.3075958],
  [33.0688477, -18.3493117],
  [33.0249023, -18.4222904],
  [32.8820801, -18.5160746],
  [32.9644775, -18.7060901],
  [32.8875732, -18.8049181],
  [32.8353882, -18.784117],
  [32.6980591, -18.8569096],
  [32.7282715, -18.9218762],
  [32.6925659, -18.9426602],
  [32.7200317, -19.0257703],
  [32.8353882, -19.0231737],
  [32.8793335, -19.0958621],
  [32.8518677, -19.2826283],
  [32.7832031, -19.3603849],
  [32.7804565, -19.4691818],
  [32.8408813, -19.4743608],
  [32.8326416, -19.6710393],
  [32.9699707, -19.6555208],
  [32.9837036, -19.7020718],
  [32.961731, -19.7253422],
  [33.0578613, -19.7847957],
  [33.0331421, -20.0378701],
  [32.9205322, -20.0533512],
  [32.9425049, -20.089468],
  [32.8903198, -20.1126815],
  [32.8683472, -20.1926129],
  [32.8491211, -20.2209658],
  [32.8656006, -20.2596205],
  [32.6678467, -20.5607957],
  [32.5552368, -20.5659389],
  [32.5030518, -20.6019362],
  [32.4865723, -20.6661959],
  [32.527771, -20.9024372],
  [32.4755859, -21.0075992],
  [32.3602295, -21.138307],
  [32.4151611, -21.1869727],
  [32.3986816, -21.2100193],
  [32.4275208, -21.2324222],
  [32.4251175, -21.2420224],
  [32.4419403, -21.2655403],
  [32.4539566, -21.2863353],
  [32.4654579, -21.3060079],
  [32.4751568, -21.3224397],
  [32.4789762, -21.3285961],
];
const sofala = [
  [34.0078354, -21.3066476],
  [34.0181351, -21.3060079],
  [34.0270615, -21.3063278],
  [34.0401077, -21.2980112],
  [34.0878296, -21.296092],
  [34.0984726, -21.2884147],
  [34.1067123, -21.287455],
  [34.1279984, -21.2999305],
  [34.1348648, -21.3015298],
  [34.1427612, -21.3117652],
  [34.1523743, -21.3111255],
  [34.1630173, -21.316243],
  [34.193573, -21.3066476],
  [34.1994095, -21.2992907],
  [34.2073059, -21.2871351],
  [34.2124557, -21.2736987],
  [34.2248154, -21.2618609],
  [34.2457581, -21.2596212],
  [34.2588043, -21.2682598],
  [34.2653275, -21.2692197],
  [34.29039, -21.2967317],
  [34.2934799, -21.3018497],
  [34.3058395, -21.303449],
  [34.3284988, -21.316243],
  [34.3384552, -21.3028093],
  [34.3432617, -21.3015298],
  [34.354248, -21.2973715],
  [34.3528748, -21.2832962],
  [34.355278, -21.2797772],
  [34.3690109, -21.2784976],
  [34.3738174, -21.272419],
  [34.3796539, -21.2708193],
  [34.389267, -21.261221],
  [34.389267, -21.2497022],
  [34.4187927, -21.2346623],
  [34.4301224, -21.2253817],
  [34.431839, -21.2177007],
  [34.4503784, -21.2023375],
  [34.4593048, -21.1677645],
  [34.4778442, -21.1639226],
  [34.5049667, -21.1597603],
  [34.5101166, -21.1440709],
  [34.5207596, -21.1379868],
  [34.5389557, -21.1399081],
  [34.5537186, -21.1331834],
  [34.562645, -21.1376666],
  [34.5705414, -21.1331834],
  [34.5777512, -21.1293406],
  [34.5863342, -21.131262],
  [34.5949173, -21.1226154],
  [34.5983505, -21.1158899],
  [34.6203232, -21.1053206],
  [34.6247864, -21.1082032],
  [34.6323395, -21.0963521],
  [34.637146, -21.0937896],
  [34.6419525, -21.0947505],
  [34.6515656, -21.0963521],
  [34.6663284, -21.0896254],
  [34.6711349, -21.0893051],
  [34.6848679, -21.1005161],
  [34.7040939, -21.106922],
  [34.7150803, -21.119733],
  [34.7236633, -21.1203736],
  [34.7487259, -21.1315822],
  [34.767952, -21.1245369],
  [34.7724152, -21.1133277],
  [34.7834015, -21.0947505],
  [34.7957611, -21.0800153],
  [34.8026276, -21.0755304],
  [34.8091507, -21.0755304],
  [34.8215103, -21.0787339],
  [34.8270035, -21.0691231],
  [34.8403931, -21.0524629],
  [34.854126, -21.0527833],
  [34.8709488, -21.0454138],
  [34.8730087, -21.0383643],
  [34.8802185, -21.0319554],
  [34.8894882, -21.031635],
  [34.8991013, -21.0268281],
  [34.906311, -21.0265077],
  [34.9142075, -21.0143296],
  [34.9183273, -21.0066377],
  [34.9269104, -21.0050351],
  [34.9317169, -21.0095222],
  [34.9457932, -21.000548],
  [34.9530029, -20.9925348],
  [34.9602127, -20.9886883],
  [34.9677658, -20.9918937],
  [34.9777222, -20.9870856],
  [34.993515, -20.9938169],
  [35.0079346, -20.9877267],
  [35.0182343, -20.9809951],
  [35.0312805, -20.9825979],
  [35.0559998, -20.9636838],
  [35.0525665, -20.9457293],
  [35.0415802, -20.9428435],
  [35.0388336, -20.940599],
  [35.0439835, -20.9370719],
  [35.0601196, -20.9319413],
  [35.0697327, -20.9181521],
  [35.0660218, -20.9108348],
  [35.0660218, -20.9108348],
  [35.0620079, -20.9107759],
  [35.0553131, -20.903239],
  [35.0527382, -20.8973055],
  [35.0523949, -20.8937773],
  [35.0559998, -20.8896075],
  [35.058918, -20.8883245],
  [35.059433, -20.8830319],
  [35.0559998, -20.8774183],
  [35.0529099, -20.8692381],
  [35.0511932, -20.8689173],
  [35.0484467, -20.8610575],
  [35.0333405, -20.840203],
  [35.0251007, -20.8222337],
  [35.0106812, -20.8214314],
  [35.0026131, -20.8135691],
  [35.0026131, -20.8113227],
  [35.0206375, -20.8024971],
  [35.0233841, -20.8021761],
  [35.0256157, -20.7955967],
  [35.0172043, -20.7681524],
  [35.0069046, -20.7522613],
  [35.0075912, -20.7456797],
  [35.0099945, -20.744877],
  [35.0099945, -20.7423085],
  [35.004158, -20.730589],
  [34.9998665, -20.7135701],
  [34.993, -20.7129278],
  [34.9861336, -20.715818],
  [34.9760056, -20.7026512],
  [34.972744, -20.7013666],
  [34.9694824, -20.7032935],
  [34.9557495, -20.6925346],
  [34.9428749, -20.6894834],
  [34.9598694, -20.6681233],
  [34.9564362, -20.6572012],
  [34.9385834, -20.6404953],
  [34.9166107, -20.6186463],
  [34.9131775, -20.6263581],
  [34.8918915, -20.617361],
  [34.885025, -20.6199317],
  [34.8905182, -20.6327843],
  [34.9111176, -20.6501335],
  [34.8863983, -20.6462783],
  [34.8513794, -20.6353546],
  [34.8143005, -20.5910093],
  [34.7950745, -20.5794389],
  [34.7834015, -20.558867],
  [34.7662354, -20.5466511],
  [34.7518158, -20.5421503],
  [34.7470093, -20.517715],
  [34.7284698, -20.5157857],
  [34.6858978, -20.518358],
  [34.7174835, -20.474622],
  [34.7092438, -20.4418119],
  [34.6975708, -20.4295867],
  [34.6845245, -20.4424553],
  [34.6707916, -20.4424553],
  [34.6762848, -20.4340908],
  [34.6721649, -20.4199345],
  [34.6577454, -20.4134994],
  [34.658432, -20.4032027],
  [34.668045, -20.4019155],
  [34.668045, -20.4019155],
  [34.6838379, -20.391618],
  [34.6927643, -20.3800325],
  [34.7113037, -20.3761705],
  [34.6920776, -20.3362572],
  [34.7003174, -20.3182284],
  [34.7092438, -20.313077],
  [34.7209167, -20.2744356],
  [34.7394562, -20.2370731],
  [34.7380829, -20.2151667],
  [34.7064972, -20.203568],
  [34.7064972, -20.1906795],
  [34.7154236, -20.1848794],
  [34.7106171, -20.1726339],
  [34.7058105, -20.1629657],
  [34.7161102, -20.1584537],
  [34.733963, -20.1584537],
  [34.7470093, -20.1584537],
  [34.7531891, -20.1739229],
  [34.7621155, -20.1739229],
  [34.7758484, -20.1520077],
  [34.7820282, -20.1397596],
  [34.7545624, -20.0643161],
  [34.7525024, -20.0178713],
  [34.7559357, -20.0043223],
  [34.7648621, -19.9359143],
  [34.7593689, -19.8920144],
  [34.7408295, -19.8829746],
  [34.7579956, -19.879746],
  [34.757309, -19.8707056],
  [34.7593689, -19.8623104],
  [34.7593689, -19.8623104],
  [34.7717285, -19.860373],
  [34.8403931, -19.8506857],
  [34.8609924, -19.8519773],
  [34.8863983, -19.8545607],
  [34.9049377, -19.8416436],
  [34.94133, -19.8125762],
  [34.9536896, -19.8125762],
  [34.9708557, -19.8099922],
  [34.9948883, -19.7919028],
  [35.0676727, -19.7279276],
  [35.080719, -19.7253422],
  [35.1171112, -19.7007789],
  [35.1294708, -19.6956072],
  [35.2324677, -19.6108967],
  [35.3361511, -19.5222586],
  [35.4130554, -19.451054],
  [35.4576874, -19.3921248],
  [35.5167389, -19.326047],
  [35.5270386, -19.3059592],
  [35.5524445, -19.2806839],
  [35.599823, -19.2404947],
  [35.6060028, -19.2203965],
  [35.7371521, -19.0880756],
  [35.7543182, -19.0660118],
  [35.8202362, -19.0186296],
  [35.8930206, -18.9816232],
  [35.9060669, -18.973182],
  [35.9156799, -18.9679872],
  [35.9342194, -18.9666885],
  [35.9548187, -18.95435],
  [35.9589386, -18.9446086],
  [35.9829712, -18.9394129],
  [35.9925842, -18.9407118],
  [36.0379028, -18.9270724],
  [36.0990143, -18.9186285],
  [36.1175537, -18.9166798],
  [36.1209869, -18.9023888],
  [36.1342049, -18.8976791],
  [36.1388397, -18.8751028],
  [36.1237335, -18.8439132],
  [36.1278534, -18.8198673],
  [36.1388397, -18.8081681],
  [36.1621857, -18.7899676],
  [36.1601257, -18.7737155],
  [36.1930847, -18.7704649],
  [36.1910248, -18.7600626],
  [36.1752319, -18.7425072],
  [36.1683655, -18.7269008],
  [36.1553192, -18.7360047],
  [36.149826, -18.7184468],
  [36.1429596, -18.7138945],
  [36.1127472, -18.7269008],
  [36.0942078, -18.7080413],
  [36.1203003, -18.704139],
  [36.1203003, -18.6930822],
  [36.1065674, -18.6807236],
  [36.1161804, -18.6540522],
  [36.1058807, -18.6319313],
  [36.107254, -18.6241232],
  [36.1292267, -18.6150133],
  [36.1278534, -18.6046014],
  [36.1319733, -18.5974428],
  [36.1539459, -18.600046],
  [36.1587524, -18.5876807],
  [36.1422729, -18.586379],
  [36.1457062, -18.5486265],
  [36.1326599, -18.5258408],
  [36.1326599, -18.5258408],
  [36.1257935, -18.4685376],
  [36.1031342, -18.420336],
  [36.0839081, -18.3838501],
  [36.0619354, -18.3649526],
  [36.0468292, -18.3473565],
  [36.0303497, -18.343446],
  [36.0028839, -18.3095515],
  [35.9671783, -18.287386],
  [35.9403992, -18.2580451],
  [35.9246063, -18.2567409],
  [35.8875275, -18.257393],
  [35.8834076, -18.2391338],
  [35.8889008, -18.2202205],
  [35.8875275, -18.2000006],
  [35.8765411, -18.1934775],
  [35.8559418, -18.1863019],
  [35.8586884, -18.1719497],
  [35.8545685, -18.1589012],
  [35.8435822, -18.154334],
  [35.8325958, -18.1517241],
  [35.8291626, -18.1399792],
  [35.8394623, -18.1269284],
  [35.8325958, -18.1171396],
  [35.8085632, -18.1093082],
  [35.7900238, -18.08777],
  [35.7632446, -18.0851591],
  [35.7495117, -18.0623123],
  [35.7261658, -18.0518671],
  [35.7048798, -18.0525199],
  [35.6911469, -18.0440327],
  [35.6362152, -18.0270572],
  [35.5874634, -18.0107329],
  [35.5524445, -18.0120389],
  [35.5332184, -17.9970194],
  [35.4892731, -17.9741612],
  [35.4741669, -17.9578321],
  [35.4721069, -17.9441145],
  [35.4528809, -17.9388884],
  [35.4508209, -17.9271293],
  [35.460434, -17.8911937],
  [35.448761, -17.8696289],
  [35.4501343, -17.8519829],
  [35.4288483, -17.8193007],
  [35.4055023, -17.8075336],
  [35.3917694, -17.7983809],
  [35.3581238, -17.7781126],
  [35.3594971, -17.7591498],
  [35.37117, -17.7513025],
  [35.3622437, -17.7238343],
  [35.3537464, -17.7029852],
  [35.3518581, -17.6970979],
  [35.3467083, -17.6914557],
  [35.334692, -17.6901474],
  [35.3268814, -17.6907198],
  [35.3236198, -17.6923552],
  [35.3217745, -17.6939395],
  [35.3211522, -17.6946448],
  [35.3207365, -17.6950409],
  [35.3202537, -17.6955392],
  [35.2846527, -17.6957078],
  [35.2702332, -17.685241],
  [35.2503204, -17.6911287],
  [35.1940155, -17.6728109],
  [35.177536, -17.6322434],
  [35.1644897, -17.6113018],
  [35.144577, -17.5667929],
  [35.123291, -17.5569732],
  [35.1095581, -17.5543546],
  [35.1040649, -17.5307849],
  [35.0992584, -17.5032832],
  [35.0862122, -17.4829816],
  [35.0765991, -17.4528525],
  [35.0642395, -17.4384411],
  [35.0436401, -17.3938898],
  [35.0244141, -17.3866819],
  [35.0010681, -17.3630906],
  [34.9852753, -17.3335971],
  [34.9749756, -17.2673835],
  [34.9770355, -17.2555806],
  [34.9667358, -17.2378747],
  [34.9523163, -17.2378747],
  [34.9372101, -17.2155761],
  [34.9324036, -17.185403],
  [34.9262238, -17.1663784],
  [34.9255371, -17.1565373],
  [34.9152374, -17.1466956],
  [34.9124908, -17.1316041],
  [34.8973846, -17.1250422],
  [34.8987579, -17.1046988],
  [34.8918915, -17.0915729],
  [34.9042511, -17.073195],
  [34.8994446, -17.0613797],
  [34.8836517, -17.0416858],
  [34.8417664, -17.0029485],
  [34.8218536, -16.9766814],
  [34.821167, -16.9602626],
  [34.8088074, -16.952381],
  [34.7909546, -16.949097],
  [34.7511292, -16.9109975],
  [34.7270966, -16.9018],
  [34.6515656, -16.8801182],
  [34.6222973, -16.8600768],
  [34.6071911, -16.8452907],
  [34.5729446, -16.8112785],
  [34.5451355, -16.8038838],
  [34.5397101, -16.801377],
  [34.5396618, -16.8013584],
  [34.5393285, -16.8019991],
  [34.5387545, -16.8030801],
  [34.537915, -16.8046541],
  [34.5367455, -16.8069444],
  [34.5344925, -16.8127164],
  [34.5336342, -16.817112],
  [34.5324326, -16.8213021],
  [34.5277977, -16.8276282],
  [34.5200729, -16.837733],
  [34.5101166, -16.8452907],
  [34.5077133, -16.8554768],
  [34.5097733, -16.8676336],
  [34.5217896, -16.870262],
  [34.5351791, -16.8692764],
  [34.5272827, -16.8860316],
  [34.5152664, -16.9100121],
  [34.5025635, -16.9300482],
  [34.4884872, -16.9563219],
  [34.4709778, -16.9894871],
  [34.3130493, -17.0308533],
  [34.2670441, -17.0590821],
  [34.1743469, -17.2250861],
  [34.2124557, -17.2427932],
  [34.2176056, -17.2532855],
  [34.2289352, -17.2562363],
  [34.2320251, -17.2660721],
  [34.205246, -17.2959042],
  [34.204216, -17.3109823],
  [34.2090225, -17.3221262],
  [34.1873932, -17.3860266],
  [34.204216, -17.4171495],
  [34.1963196, -17.453835],
  [34.1863632, -17.4885484],
  [34.1688538, -17.5373324],
  [34.1407013, -17.5972303],
  [34.1633606, -17.6371513],
  [34.1609573, -17.6960349],
  [34.2038727, -17.7212181],
  [34.203186, -17.7369149],
  [34.2086792, -17.7480327],
  [34.2079926, -17.7735355],
  [34.1956329, -17.8199544],
  [34.1983795, -17.8395644],
  [34.1873932, -17.8715894],
  [34.2066193, -17.8706091],
  [34.2176056, -17.8787779],
  [34.2327118, -17.8781244],
  [34.2402649, -17.8804116],
  [34.2416382, -17.8826988],
  [34.2399216, -17.8869463],
  [34.2471313, -17.8980547],
  [34.2546844, -17.8983814],
  [34.2577744, -17.9026285],
  [34.2622375, -17.9039353],
  [34.2636108, -17.9124292],
  [34.2687607, -17.908509],
  [34.2773438, -17.9134093],
  [34.2773438, -17.9248427],
  [34.2917633, -17.9408482],
  [34.2869568, -17.9516266],
  [34.2821503, -17.9519533],
  [34.2721939, -17.9669766],
  [34.2625809, -17.9695892],
  [34.2632675, -17.97808],
  [34.2601776, -17.9819987],
  [34.2687607, -17.9895092],
  [34.2790604, -17.991795],
  [34.2773438, -17.9976725],
  [34.2818069, -18.0055089],
  [34.2794037, -18.0188952],
  [34.2848969, -18.022813],
  [34.2848969, -18.042727],
  [34.2886734, -18.0512142],
  [34.2934799, -18.0734097],
  [34.2848969, -18.0858118],
  [34.2697906, -18.1631421],
  [34.2622375, -18.1918467],
  [34.2629242, -18.2042404],
  [34.2546844, -18.21859],
  [34.2502213, -18.2143505],
  [34.248848, -18.2117415],
  [34.2440414, -18.2078279],
  [34.2358017, -18.2078279],
  [34.2306519, -18.2101108],
  [34.2272186, -18.205545],
  [34.2220688, -18.2078279],
  [34.2165756, -18.2000006],
  [34.2055893, -18.1931514],
  [34.1976929, -18.1931514],
  [34.1956329, -18.1905421],
  [34.1884232, -18.1915206],
  [34.1788101, -18.205545],
  [34.1654205, -18.231634],
  [34.1512156, -18.2571485],
  [34.1316462, -18.2640767],
  [34.1146088, -18.2616315],
  [34.1111755, -18.2554367],
  [34.1022491, -18.2515242],
  [34.0995026, -18.2414163],
  [34.0912628, -18.2388078],
  [34.0737534, -18.2192422],
  [34.0689468, -18.2182639],
  [34.0613937, -18.2231555],
  [34.0600204, -18.2110892],
  [34.053154, -18.2042404],
  [34.052124, -18.192499],
  [34.0483475, -18.1876066],
  [34.0438843, -18.1885851],
  [34.0504074, -18.1781474],
  [34.0490341, -18.1673828],
  [34.029808, -18.1611848],
  [34.0325546, -18.1540078],
  [34.0322113, -18.1468305],
  [34.0263748, -18.1429155],
  [34.0284348, -18.1350852],
  [34.0274048, -18.1295386],
  [34.0081787, -18.1236655],
  [34.0044022, -18.1259495],
  [34.0002823, -18.1337802],
  [33.9903259, -18.1328014],
  [33.9817429, -18.1344327],
  [33.9511871, -18.1269284],
  [33.9491272, -18.1223603],
  [33.9453506, -18.1360641],
  [33.9353943, -18.1500929],
  [33.902092, -18.2140243],
  [33.8729095, -18.221525],
  [33.848877, -18.2352209],
  [33.8341141, -18.26913],
  [33.8217545, -18.2785843],
  [33.8135147, -18.2785843],
  [33.804245, -18.2851041],
  [33.8107681, -18.2961874],
  [33.8214111, -18.2945575],
  [33.8248444, -18.2958614],
  [33.8279343, -18.3115071],
  [33.8238144, -18.3150924],
  [33.8241577, -18.3235664],
  [33.8193512, -18.3317141],
  [33.8238144, -18.3362766],
  [33.826561, -18.3457272],
  [33.8248444, -18.3555031],
  [33.8279343, -18.3587616],
  [33.8286209, -18.3675593],
  [33.8354874, -18.3757049],
  [33.8444138, -18.3757049],
  [33.8413239, -18.3815695],
  [33.8365173, -18.3897145],
  [33.836174, -18.4056774],
  [33.8310242, -18.4134955],
  [33.8402939, -18.4213132],
  [33.8481903, -18.4170787],
  [33.8505936, -18.4069805],
  [33.8572884, -18.4047002],
  [33.8620949, -18.414147],
  [33.8557434, -18.4204989],
  [33.85952, -18.4235933],
  [33.8607216, -18.4271763],
  [33.8560867, -18.4292934],
  [33.856945, -18.4408559],
  [33.8519669, -18.4472068],
  [33.8545418, -18.4489981],
  [33.8650131, -18.4506264],
  [33.8656998, -18.4594194],
  [33.870163, -18.4631645],
  [33.8718796, -18.4646299],
  [33.8669014, -18.4660953],
  [33.8653564, -18.4687004],
  [33.8670731, -18.4706543],
  [33.8674164, -18.47619],
  [33.8658714, -18.4792834],
  [33.870163, -18.4857957],
  [33.8684464, -18.4958893],
  [33.8741112, -18.500773],
  [33.8766861, -18.5094007],
  [33.8856125, -18.5175396],
  [33.8778877, -18.5220972],
  [33.8766861, -18.5233993],
  [33.8727379, -18.5220972],
  [33.8725662, -18.5185162],
  [33.8715363, -18.5164001],
  [33.8684464, -18.5173768],
  [33.8651848, -18.5170512],
  [33.85746, -18.5079356],
  [33.8519669, -18.5137957],
  [33.8447571, -18.5203067],
  [33.8389206, -18.5196556],
  [33.8392639, -18.5251898],
  [33.8337708, -18.5264919],
  [33.8279343, -18.5225855],
  [33.8220978, -18.5261664],
  [33.8152313, -18.5297472],
  [33.8073349, -18.5287706],
  [33.8011551, -18.527794],
  [33.7915421, -18.527794],
  [33.782959, -18.5251898],
  [33.7771225, -18.5238876],
  [33.7743759, -18.5183534],
  [33.7668228, -18.5173768],
  [33.7551498, -18.5268174],
  [33.7479401, -18.536583],
  [33.7448502, -18.548952],
  [33.7475967, -18.5574145],
  [33.7390137, -18.5681547],
  [33.740387, -18.5733619],
  [33.75, -18.5847519],
  [33.75, -18.590284],
  [33.7438202, -18.5928872],
  [33.7421036, -18.5977682],
  [33.7434769, -18.6065537],
  [33.7441635, -18.6127357],
  [33.7438202, -18.6182669],
  [33.7465668, -18.6224964],
  [33.7465668, -18.6293286],
  [33.7434769, -18.6290033],
  [33.7445068, -18.6325819],
  [33.7421036, -18.6368111],
  [33.7362671, -18.6384377],
  [33.7311172, -18.6381124],
  [33.7321472, -18.6429921],
  [33.7342072, -18.6455945],
  [33.7324905, -18.6524258],
  [33.729744, -18.6534016],
  [33.729744, -18.659582],
  [33.7311172, -18.6651116],
  [33.7259674, -18.6677137],
  [33.7252808, -18.6716168],
  [33.7211609, -18.6709663],
  [33.7197876, -18.6761703],
  [33.7194443, -18.6826751],
  [33.7187576, -18.6885291],
  [33.7235641, -18.6921065],
  [33.7215042, -18.694383],
  [33.7259674, -18.6995863],
  [33.7283707, -18.7038138],
  [33.7335205, -18.7060901],
  [33.738327, -18.7096672],
  [33.7410736, -18.7174713],
  [33.7469101, -18.7177965],
  [33.7530899, -18.7174713],
  [33.7575531, -18.7119434],
  [33.7654495, -18.7164958],
  [33.7705994, -18.7197475],
  [33.7757492, -18.7252751],
  [33.7798691, -18.7269008],
  [33.7843323, -18.7324283],
  [33.7905121, -18.7347042],
  [33.7977219, -18.7379555],
  [33.7997818, -18.7421821],
  [33.8063049, -18.7451081],
  [33.8056183, -18.7564866],
  [33.8107681, -18.7620131],
  [33.8162613, -18.7685145],
  [33.8303375, -18.7730654],
  [33.8368607, -18.7795665],
  [33.8378906, -18.7847671],
  [33.8451004, -18.7873674],
  [33.8523102, -18.783792],
  [33.8571167, -18.7785913],
  [33.8636398, -18.7828169],
  [33.870163, -18.7844421],
  [33.8794327, -18.7854172],
  [33.8849258, -18.7815167],
  [33.8873291, -18.783792],
  [33.8917923, -18.7896426],
  [33.8983154, -18.7909427],
  [33.9007187, -18.8039431],
  [33.9048386, -18.8110929],
  [33.9068985, -18.8162926],
  [33.9134216, -18.8153177],
  [33.9285278, -18.8751028],
  [33.9834595, -18.9036881],
  [34.019165, -18.9296705],
  [34.0356445, -18.9231753],
  [34.052124, -18.945258],
  [34.0438843, -18.9699353],
  [34.0274048, -19.0023998],
  [34.0150452, -19.0608199],
  [33.9408875, -19.2437362],
  [33.943634, -19.3526109],
  [33.9381409, -19.3953633],
  [33.9408875, -19.5817873],
  [33.9312744, -19.6011942],
  [33.7486267, -19.5960192],
  [33.7376404, -19.6154243],
  [33.7472534, -19.6322402],
  [33.7678528, -19.6516409],
  [33.7664795, -19.6710393],
  [33.815918, -19.6852633],
  [33.825531, -19.716293],
  [33.8516235, -19.7667036],
  [33.8337708, -19.8106382],
  [33.8227844, -19.8648936],
  [33.8296509, -19.9359143],
  [33.7939453, -19.9436601],
  [33.7705994, -19.9656045],
  [33.7609863, -19.99787],
  [33.7225342, -19.9823834],
  [33.684082, -19.9359143],
  [33.6497498, -19.9359143],
  [33.6346436, -19.9514055],
  [33.6058044, -19.9281682],
  [33.5865784, -19.9591506],
  [33.5591125, -19.9656045],
  [33.5289001, -19.9914174],
  [33.5110474, -19.9888363],
  [33.5041809, -19.99787],
  [33.4835815, -19.9888363],
  [33.4629822, -20.0469009],
  [33.4355164, -20.0443207],
  [33.4121704, -20.0727005],
  [33.3847046, -20.084309],
  [33.4039307, -20.1165501],
  [33.4602356, -20.1694112],
  [33.4973145, -20.2570438],
  [33.5124207, -20.271215],
  [33.4753418, -20.2969776],
  [33.4643555, -20.3072814],
  [33.4973145, -20.3523525],
  [33.4684753, -20.4141429],
  [33.6058044, -20.5376494],
  [33.6634827, -20.5376494],
  [33.6470032, -20.5942232],
  [33.6717224, -20.6225023],
  [33.7211609, -20.6443507],
  [33.7252808, -20.7060233],
  [33.8186646, -20.744556],
  [33.8392639, -20.7368502],
  [33.8969421, -20.7972014],
  [33.9051819, -20.8639448],
  [33.891449, -20.9973427],
  [33.9903259, -20.9999069],
  [34.1125488, -21.0780932],
  [34.1017342, -21.1264584],
  [34.0438843, -21.1644028],
  [34.0358162, -21.2055383],
  [34.0370178, -21.21386],
  [34.0358162, -21.219621],
  [34.0250015, -21.2308221],
  [34.0171051, -21.2405824],
  [34.0128136, -21.2541818],
  [34.010582, -21.2682598],
  [34.010067, -21.2789774],
  [34.0098953, -21.2914537],
  [34.0086937, -21.3002503],
  [34.0078354, -21.3066476],
];
const inhambane = [
  [33.2971466, -21.5023189],
  [33.2916641, -21.5092163],
  [33.2885742, -21.5128097],
  [33.283596, -21.5176007],
  [33.2801628, -21.5235892],
  [33.2778454, -21.526304],
  [33.2762146, -21.5279807],
  [33.2749271, -21.5297372],
  [33.2733822, -21.530855],
  [33.2708931, -21.5316534],
  [33.2674599, -21.5319728],
  [33.2664299, -21.5306155],
  [33.2665157, -21.5286194],
  [33.2671165, -21.5264636],
  [33.2665157, -21.5251861],
  [33.2647991, -21.5256652],
  [33.2635975, -21.5261443],
  [33.2624817, -21.5272621],
  [33.2610226, -21.527821],
  [33.2561302, -21.5295775],
  [33.2453156, -21.5302163],
  [33.2406807, -21.5301364],
  [33.2374191, -21.5306155],
  [33.2353592, -21.5335696],
  [33.2338142, -21.5371623],
  [33.2329559, -21.5401163],
  [33.2329559, -21.542192],
  [33.2338142, -21.542671],
  [33.2351875, -21.5465031],
  [33.2389641, -21.5532887],
  [33.2417965, -21.5591959],
  [33.243084, -21.5656617],
  [33.241539, -21.5704509],
  [33.2399082, -21.573484],
  [33.2357025, -21.578273],
  [33.2314968, -21.5797097],
  [33.215189, -21.5833811],
  [33.2116699, -21.5860149],
  [33.210125, -21.5872121],
  [33.2072067, -21.5927189],
  [33.2056618, -21.5960708],
  [33.2046318, -21.600061],
  [33.2047176, -21.6026945],
  [33.2054043, -21.6050885],
  [33.2062626, -21.6086795],
  [33.2073784, -21.6113128],
  [33.2072926, -21.6157016],
  [33.2064342, -21.6183348],
  [33.2052326, -21.6200104],
  [33.2013702, -21.6216861],
  [33.1999111, -21.620569],
  [33.1987095, -21.6193721],
  [33.1955338, -21.6172177],
  [33.1922722, -21.61634],
  [33.1890965, -21.6173773],
  [33.1876373, -21.6176167],
  [33.1869507, -21.6202498],
  [33.1854916, -21.6228829],
  [33.1743336, -21.6519238],
  [33.1612873, -21.6856646],
  [33.1719303, -21.7068781],
  [33.1897831, -21.741483],
  [33.1749344, -21.7695435],
  [33.1690979, -21.7820573],
  [33.1678963, -21.7848469],
  [33.166523, -21.787477],
  [33.1671238, -21.7908243],
  [33.1683254, -21.7931356],
  [33.181715, -21.8259667],
  [33.1946754, -21.8571175],
  [33.1957054, -21.8598259],
  [33.1957054, -21.8607818],
  [33.2214546, -21.8989332],
  [33.2403374, -21.9275202],
  [33.2477188, -21.9393037],
  [33.2499504, -21.9420902],
  [33.2525253, -21.9473446],
  [33.2538986, -21.9500513],
  [33.2781029, -21.9701113],
  [33.2902908, -21.9802198],
  [33.3423042, -22.0020263],
  [33.3407593, -22.0049708],
  [33.3402443, -22.0098249],
  [33.3374119, -22.0181005],
  [33.3365536, -22.0219994],
  [33.3346653, -22.0275691],
  [33.3307171, -22.0319451],
  [33.3285713, -22.0356845],
  [33.3262539, -22.0370371],
  [33.3245373, -22.0414128],
  [33.3253098, -22.0492888],
  [33.3265114, -22.0531868],
  [33.3251381, -22.0594712],
  [33.3265972, -22.0617781],
  [33.3290005, -22.0628917],
  [33.3312321, -22.0664712],
  [33.3305454, -22.0681416],
  [33.3314037, -22.0711642],
  [33.3290863, -22.0749026],
  [33.3283997, -22.0786409],
  [33.3296013, -22.0812655],
  [33.3308029, -22.0831744],
  [33.3350945, -22.086992],
  [33.3430767, -22.0872305],
  [33.3473682, -22.0889007],
  [33.349514, -22.0918433],
  [33.3515739, -22.0935929],
  [33.3526897, -22.0958197],
  [33.3529472, -22.0994779],
  [33.3554363, -22.101466],
  [33.3565521, -22.1037722],
  [33.3553505, -22.1053626],
  [33.3562088, -22.1070326],
  [33.359127, -22.1075892],
  [33.3598137, -22.1094977],
  [33.3582687, -22.1142688],
  [33.3578396, -22.1166542],
  [33.359127, -22.1184036],
  [33.3592987, -22.1206299],
  [33.3612728, -22.1222997],
  [33.3620453, -22.124685],
  [33.3616161, -22.1264343],
  [33.3630753, -22.128263],
  [33.3626461, -22.1304892],
  [33.361187, -22.1335105],
  [33.3632469, -22.1381217],
  [33.3628178, -22.1401888],
  [33.3605003, -22.1435278],
  [33.3613586, -22.1461513],
  [33.3611012, -22.1480592],
  [33.354578, -22.1516366],
  [33.3516598, -22.1517956],
  [33.3497715, -22.1521136],
  [33.3483982, -22.1520341],
  [33.3467674, -22.1527495],
  [33.3427334, -22.1516366],
  [33.340416, -22.1515571],
  [33.339386, -22.1538624],
  [33.3383989, -22.1543792],
  [33.3376694, -22.1548164],
  [33.3382702, -22.1572012],
  [33.3381844, -22.1603013],
  [33.3374119, -22.1618116],
  [33.3379698, -22.1636001],
  [33.3392143, -22.1653091],
  [33.3395898, -22.1656072],
  [33.340137, -22.1674255],
  [33.3394718, -22.1683893],
  [33.3399117, -22.1704459],
  [33.3411992, -22.1717078],
  [33.3417785, -22.1724927],
  [33.3428299, -22.1756223],
  [33.341682, -22.1760793],
  [33.3402014, -22.1770828],
  [33.3399868, -22.1800136],
  [33.3390427, -22.1815237],
  [33.3390427, -22.1827159],
  [33.3390856, -22.1841067],
  [33.3375835, -22.1882792],
  [33.3387852, -22.1920145],
  [33.3383131, -22.1942],
  [33.3362103, -22.1965046],
  [33.3353949, -22.1982529],
  [33.3344507, -22.2016701],
  [33.3337212, -22.2049679],
  [33.3337212, -22.2079081],
  [33.3332062, -22.2096166],
  [33.3323479, -22.2110469],
  [33.3297729, -22.2128348],
  [33.3280993, -22.2149405],
  [33.3268976, -22.2165296],
  [33.3263826, -22.2203833],
  [33.3261681, -22.2235616],
  [33.3287859, -22.2267397],
  [33.3291292, -22.2283685],
  [33.3284426, -22.2292027],
  [33.3284426, -22.2305137],
  [33.329258, -22.2318246],
  [33.3284855, -22.2350423],
  [33.3304596, -22.2372668],
  [33.3302879, -22.2390147],
  [33.329215, -22.2394516],
  [33.3304596, -22.2422719],
  [33.3308029, -22.2436622],
  [33.338871, -22.2477137],
  [33.3431625, -22.2504941],
  [33.3443642, -22.2518843],
  [33.3492994, -22.2546249],
  [33.3500719, -22.2560151],
  [33.3538485, -22.2591925],
  [33.3553076, -22.2599471],
  [33.3572388, -22.2599868],
  [33.3616161, -22.2593911],
  [33.3636332, -22.2599471],
  [33.3660793, -22.2609401],
  [33.3689117, -22.2643159],
  [33.3699417, -22.2664209],
  [33.3715725, -22.2690818],
  [33.3721733, -22.2716235],
  [33.3719158, -22.2738077],
  [33.3694696, -22.2753962],
  [33.3688688, -22.2772627],
  [33.3697271, -22.2788115],
  [33.3721733, -22.2799631],
  [33.3729029, -22.2819089],
  [33.3725595, -22.2832591],
  [33.3708858, -22.284093],
  [33.3681393, -22.2844107],
  [33.3659077, -22.285602],
  [33.364234, -22.2873889],
  [33.3644056, -22.2900097],
  [33.361659, -22.2952114],
  [33.361187, -22.2968394],
  [33.3574533, -22.3029541],
  [33.3564234, -22.3069642],
  [33.3543634, -22.3111331],
  [33.3549213, -22.3145077],
  [33.3544493, -22.3168104],
  [33.3547926, -22.3190336],
  [33.356123, -22.3208201],
  [33.359642, -22.3222493],
  [33.3598995, -22.3243931],
  [33.3595133, -22.3256634],
  [33.360672, -22.3282438],
  [33.360157, -22.3303478],
  [33.3596849, -22.3319753],
  [33.3590841, -22.3341586],
  [33.359127, -22.3368579],
  [33.3605862, -22.3395572],
  [33.3612299, -22.3414228],
  [33.361659, -22.34567],
  [33.3659506, -22.3510681],
  [33.366766, -22.3529733],
  [33.3664656, -22.3551562],
  [33.3656502, -22.3574582],
  [33.367238, -22.3596014],
  [33.3693838, -22.3613874],
  [33.3720875, -22.3628956],
  [33.3733749, -22.3645624],
  [33.3733749, -22.3654355],
  [33.3752203, -22.3683724],
  [33.3756924, -22.3706345],
  [33.3759928, -22.3736902],
  [33.3775377, -22.3761506],
  [33.3801556, -22.3772221],
  [33.3832455, -22.3797222],
  [33.3837605, -22.3815476],
  [33.3829021, -22.3830952],
  [33.3820009, -22.3835317],
  [33.3804989, -22.3836507],
  [33.3786106, -22.3849602],
  [33.376894, -22.3856348],
  [33.3749199, -22.3848015],
  [33.3712721, -22.3810714],
  [33.3695126, -22.3809126],
  [33.3648777, -22.3815872],
  [33.3626032, -22.3811904],
  [33.3586121, -22.3782142],
  [33.3571959, -22.3767062],
  [33.3549213, -22.3757935],
  [33.353548, -22.3747617],
  [33.3494711, -22.3737299],
  [33.3469391, -22.3741664],
  [33.3453941, -22.3752776],
  [33.3420467, -22.3781745],
  [33.339386, -22.3798015],
  [33.3349228, -22.3826984],
  [33.3331633, -22.3849602],
  [33.3293438, -22.3857142],
  [33.3273697, -22.385238],
  [33.3246231, -22.3830158],
  [33.3227777, -22.3813492],
  [33.3206749, -22.3809523],
  [33.3190441, -22.3808333],
  [33.3165979, -22.3824206],
  [33.3164692, -22.3835317],
  [33.3161259, -22.3840079],
  [33.3166838, -22.3853174],
  [33.3177137, -22.3859523],
  [33.3184004, -22.3879363],
  [33.3176279, -22.3893251],
  [33.316083, -22.38996],
  [33.3154392, -22.3911901],
  [33.3157825, -22.3924995],
  [33.3167267, -22.3934518],
  [33.3166409, -22.3949993],
  [33.3158255, -22.3961896],
  [33.3146667, -22.3967848],
  [33.3129501, -22.3985306],
  [33.3111477, -22.4012287],
  [33.309474, -22.4038077],
  [33.3091736, -22.4079736],
  [33.3046246, -22.4116633],
  [33.3043242, -22.4130519],
  [33.3053541, -22.4145991],
  [33.307972, -22.4161464],
  [33.3087444, -22.4176539],
  [33.3106327, -22.4185664],
  [33.3116627, -22.4197169],
  [33.3114481, -22.4208277],
  [33.3117485, -22.4219385],
  [33.3127356, -22.4222558],
  [33.3130789, -22.4238427],
  [33.3103752, -22.4262229],
  [33.3096886, -22.4273336],
  [33.308959, -22.4300311],
  [33.3061266, -22.4313798],
  [33.3054399, -22.4327682],
  [33.305912, -22.4340772],
  [33.3067703, -22.4356243],
  [33.3063841, -22.4368143],
  [33.3055258, -22.4378853],
  [33.3061695, -22.4411379],
  [33.3102465, -22.4435178],
  [33.3108044, -22.444906],
  [33.3096457, -22.4464529],
  [33.3087015, -22.4470479],
  [33.3068562, -22.4470876],
  [33.3062124, -22.4483171],
  [33.3061695, -22.4491897],
  [33.307457, -22.4506573],
  [33.3077145, -22.4516092],
  [33.3070278, -22.4526404],
  [33.3056545, -22.4533543],
  [33.3050108, -22.4545441],
  [33.3045816, -22.4565669],
  [33.3053541, -22.4580343],
  [33.305397, -22.4594224],
  [33.3050966, -22.4608502],
  [33.3042812, -22.4612865],
  [33.3043671, -22.4624366],
  [33.3022642, -22.4649748],
  [33.3019638, -22.4666801],
  [33.30235, -22.4687819],
  [33.300333, -22.4767132],
  [33.2992172, -22.4778236],
  [33.2977581, -22.478696],
  [33.2969856, -22.4799649],
  [33.2963419, -22.4822649],
  [33.2957411, -22.4848423],
  [33.2953119, -22.487737],
  [33.2934237, -22.4909487],
  [33.29175, -22.4924555],
  [33.2904625, -22.4940415],
  [33.2899904, -22.4951914],
  [33.2899904, -22.4961033],
  [33.2905912, -22.4969756],
  [33.2910204, -22.4978082],
  [33.2917929, -22.4986409],
  [33.2929516, -22.5001475],
  [33.2925653, -22.5015749],
  [33.2920074, -22.5024471],
  [33.2918787, -22.5047467],
  [33.2905483, -22.5064911],
  [33.2886171, -22.5074427],
  [33.2867289, -22.5082752],
  [33.2857847, -22.5091078],
  [33.2853556, -22.5103368],
  [33.2853985, -22.5119623],
  [33.2860851, -22.515451],
  [33.2867718, -22.5172746],
  [33.2864714, -22.5199704],
  [33.2850122, -22.5214768],
  [33.2820511, -22.5232607],
  [33.2817507, -22.5252824],
  [33.2829094, -22.5281366],
  [33.2828236, -22.5305943],
  [33.2810211, -22.5322988],
  [33.2789183, -22.533369],
  [33.27703, -22.535351],
  [33.2769442, -22.5371743],
  [33.2784462, -22.5388787],
  [33.2793474, -22.5397508],
  [33.2797337, -22.5411777],
  [33.2796049, -22.5422082],
  [33.279047, -22.5425253],
  [33.2771587, -22.5422082],
  [33.2762146, -22.5423271],
  [33.275013, -22.5430802],
  [33.2738972, -22.543437],
  [33.2726955, -22.5452999],
  [33.2715797, -22.5470042],
  [33.2707214, -22.5496201],
  [33.2705498, -22.5520774],
  [33.2712364, -22.5535834],
  [33.2711935, -22.554931],
  [33.2700348, -22.5558029],
  [33.2681465, -22.5561199],
  [33.2667303, -22.5566352],
  [33.265872, -22.5581412],
  [33.2681894, -22.5607965],
  [33.2695627, -22.5630951],
  [33.2723951, -22.5646407],
  [33.2741117, -22.5669788],
  [33.2757854, -22.567494],
  [33.2769871, -22.5684451],
  [33.2787037, -22.5693962],
  [33.2803345, -22.5694755],
  [33.281579, -22.570268],
  [33.2821798, -22.5716154],
  [33.2823515, -22.5734779],
  [33.2817936, -22.5748648],
  [33.2811499, -22.5766084],
  [33.2802486, -22.5781539],
  [33.2801628, -22.57962],
  [33.2809353, -22.5837807],
  [33.2816219, -22.585643],
  [33.2833815, -22.5861977],
  [33.2850981, -22.5856034],
  [33.286171, -22.5867128],
  [33.2856131, -22.5877034],
  [33.2848406, -22.5882185],
  [33.2850981, -22.5891299],
  [33.2863855, -22.5897638],
  [33.2897758, -22.589962],
  [33.2906771, -22.591428],
  [33.2905483, -22.5927355],
  [33.2912779, -22.59436],
  [33.2934237, -22.5957071],
  [33.293767, -22.597292],
  [33.2932091, -22.5978863],
  [33.2929516, -22.5987975],
  [33.2930803, -22.6003427],
  [33.2939386, -22.6018483],
  [33.2938099, -22.6030368],
  [33.2952261, -22.6042254],
  [33.2955694, -22.6056913],
  [33.2974148, -22.606761],
  [33.298316, -22.6075137],
  [33.2981443, -22.6087022],
  [33.2979298, -22.6099304],
  [33.2993889, -22.6116735],
  [33.3009768, -22.6122678],
  [33.301878, -22.6135751],
  [33.3024359, -22.6171009],
  [33.3026505, -22.6212604],
  [33.3042812, -22.6233203],
  [33.3053541, -22.6234787],
  [33.3059978, -22.6216961],
  [33.307972, -22.6221319],
  [33.3086586, -22.6236768],
  [33.309989, -22.6245879],
  [33.3103323, -22.6254198],
  [33.3102894, -22.6273212],
  [33.3113194, -22.6279549],
  [33.3139372, -22.6285887],
  [33.3161259, -22.6331044],
  [33.3161688, -22.6343323],
  [33.3154821, -22.6353226],
  [33.3158684, -22.6366693],
  [33.3177137, -22.639838],
  [33.3215332, -22.6501358],
  [33.3232498, -22.6561557],
  [33.3283997, -22.6666107],
  [33.339386, -22.6596408],
  [33.3562088, -22.6593239],
  [33.3634186, -22.6732634],
  [33.3768082, -22.673897],
  [33.3884811, -22.6795991],
  [33.3963776, -22.6862512],
  [33.4204102, -22.7036719],
  [33.4506226, -22.7058889],
  [33.4571457, -22.7210904],
  [33.4598923, -22.7340736],
  [33.4743118, -22.7499052],
  [33.4933662, -22.7567122],
  [33.4883881, -22.7806132],
  [33.5041809, -22.788843],
  [33.5385132, -22.8268204],
  [33.6312103, -22.9040084],
  [33.6470032, -22.8945205],
  [33.6476898, -22.9179229],
  [33.6950684, -22.9982195],
  [33.6888885, -23.0279235],
  [33.7184143, -23.0424571],
  [33.7362671, -23.0645704],
  [33.7369537, -23.0885751],
  [33.7719727, -23.0885751],
  [33.7733459, -23.113207],
  [33.8049316, -23.1277314],
  [33.8131714, -23.1491993],
  [33.8303375, -23.1624572],
  [33.8262177, -23.1832884],
  [33.848877, -23.1801324],
  [33.8433838, -23.241977],
  [33.8605499, -23.2463934],
  [33.8756561, -23.2722578],
  [33.8784027, -23.3315378],
  [33.9024353, -23.3271243],
  [33.8887024, -23.345408],
  [33.870163, -23.3479297],
  [33.8626099, -23.36495],
  [33.8454437, -23.352973],
  [33.8413239, -23.3939424],
  [33.8653564, -23.4033951],
  [33.8660431, -23.4348991],
  [33.8811493, -23.440569],
  [33.8852692, -23.4834007],
  [33.9202881, -23.4846602],
  [33.9463806, -23.5463631],
  [33.9292145, -23.5828674],
  [33.9470673, -23.5872725],
  [33.9278412, -23.6181034],
  [33.8955688, -23.6633929],
  [33.9250946, -23.6615062],
  [33.9470673, -23.6904333],
  [33.9546204, -23.7099241],
  [33.9367676, -23.7206114],
  [33.9353943, -23.738212],
  [33.9202881, -23.7790614],
  [33.9333344, -23.7960259],
  [33.9257813, -23.8192699],
  [33.9196014, -23.8393694],
  [33.9415741, -23.8638615],
  [33.9161682, -23.8808149],
  [33.924408, -23.9015327],
  [33.9264679, -23.9322893],
  [33.9257813, -23.9856252],
  [33.9374542, -23.9900166],
  [33.9237213, -24.0025626],
  [33.934021, -24.0188706],
  [33.9216614, -24.0245152],
  [33.9415741, -24.0796936],
  [33.9745331, -24.0753053],
  [33.9937592, -24.0872161],
  [33.9992523, -24.3546034],
  [34.0500641, -24.4208934],
  [34.096756, -24.4734003],
  [34.1441345, -24.5402525],
  [34.1757202, -24.5227619],
  [34.1860199, -24.5177642],
  [34.2169189, -24.5140157],
  [34.2670441, -24.4890232],
  [34.3027496, -24.4821494],
  [34.3281555, -24.4877734],
  [34.3453217, -24.4690256],
  [34.3803406, -24.4665257],
  [34.4139862, -24.4546504],
  [34.4263458, -24.4602757],
  [34.3528748, -24.5415018],
  [34.3700409, -24.5421264],
  [34.3796539, -24.5533689],
  [34.3913269, -24.5739775],
  [34.4112396, -24.5927097],
  [34.4490051, -24.6382796],
  [34.2986298, -24.7125292],
  [34.3013763, -24.796085],
  [34.4345427, -24.8076163],
  [34.444263, -24.8212304],
  [34.4537687, -24.8345314],
  [34.4636607, -24.848532],
  [34.4730806, -24.8628035],
  [34.4904613, -24.8595716],
  [34.5039368, -24.8553661],
  [34.5110607, -24.8531076],
  [34.5156097, -24.849525],
  [34.5338058, -24.8434499],
  [34.5863342, -24.8238206],
  [34.7219467, -24.7805005],
  [34.8077774, -24.748702],
  [34.9035645, -24.7069152],
  [34.9660492, -24.6828971],
  [35.0244141, -24.6579382],
  [35.0656128, -24.6364072],
  [35.1511002, -24.5799097],
  [35.1548767, -24.5708552],
  [35.165863, -24.5561794],
  [35.2036285, -24.5212002],
  [35.2005386, -24.5015201],
  [35.2214813, -24.470588],
  [35.3004456, -24.3805607],
  [35.4113388, -24.251347],
  [35.4261017, -24.2294333],
  [35.425415, -24.2156571],
  [35.4673004, -24.1718137],
  [35.4841232, -24.1414277],
  [35.5002594, -24.1022598],
  [35.4988861, -24.0937979],
  [35.5012894, -24.0740515],
  [35.4947662, -24.069036],
  [35.5026627, -24.0191842],
  [35.4975128, -24.0075807],
  [35.4975128, -24.0075807],
  [35.5294418, -23.9285236],
  [35.5263519, -23.9228748],
  [35.5545044, -23.8723385],
  [35.5507278, -23.8644895],
  [35.5538177, -23.853814],
  [35.5407715, -23.8431377],
  [35.5383682, -23.794141],
  [35.5328751, -23.7906854],
  [35.5167389, -23.7928845],
  [35.5050659, -23.7862873],
  [35.4961395, -23.783774],
  [35.48069, -23.7759196],
  [35.4765701, -23.7630374],
  [35.4556274, -23.7558102],
  [35.4501343, -23.7470114],
  [35.4103088, -23.725326],
  [35.4322815, -23.6583615],
  [35.4305649, -23.6319435],
  [35.4230118, -23.6099245],
  [35.4233551, -23.5498251],
  [35.4243851, -23.5095337],
  [35.4377747, -23.4739537],
  [35.4480743, -23.4396241],
  [35.4889297, -23.3271243],
  [35.4944229, -23.2741502],
  [35.4947662, -23.2347212],
  [35.4878998, -23.2265185],
  [35.4861832, -23.180448],
  [35.5023193, -23.1223638],
  [35.5740738, -23.0016959],
  [35.5963898, -22.9647157],
  [35.6018829, -22.9422699],
  [35.6015396, -22.9368949],
  [35.6035995, -22.9274093],
  [35.600853, -22.9226662],
  [35.5957031, -22.9255121],
  [35.5781937, -22.9204527],
  [35.5545044, -22.9017946],
  [35.5545044, -22.9074872],
  [35.5586243, -22.9112821],
  [35.5627441, -22.9147607],
  [35.5692673, -22.9232986],
  [35.579567, -22.9293065],
  [35.5781937, -22.9334169],
  [35.5781937, -22.9394243],
  [35.5730438, -22.931836],
  [35.567894, -22.9331007],
  [35.5541611, -22.9245634],
  [35.5442047, -22.9289903],
  [35.5393982, -22.940689],
  [35.5404282, -22.9334169],
  [35.5414581, -22.9258283],
  [35.5496979, -22.92235],
  [35.5462646, -22.9122308],
  [35.5431747, -22.9043247],
  [35.5438614, -22.8995808],
  [35.5393982, -22.8926228],
  [35.5387115, -22.8878785],
  [35.5284119, -22.8651036],
  [35.523262, -22.8511837],
  [35.5315018, -22.8613074],
  [35.5376816, -22.872063],
  [35.5424881, -22.8840829],
  [35.5452347, -22.8942042],
  [35.5400848, -22.8739609],
  [35.5373383, -22.8670016],
  [35.5304718, -22.8568783],
  [35.523262, -22.8470707],
  [35.5198288, -22.8331489],
  [35.5150223, -22.7682675],
  [35.5133057, -22.7198236],
  [35.5139923, -22.6669275],
  [35.5057526, -22.6168632],
  [35.5026627, -22.6060875],
  [35.5119324, -22.5414155],
  [35.5376816, -22.4437161],
  [35.5438614, -22.3297523],
  [35.5352783, -22.2906848],
  [35.5342484, -22.2703526],
  [35.5290985, -22.2560548],
  [35.5297852, -22.2404844],
  [35.5332184, -22.2087027],
  [35.5345917, -22.199286],
  [35.5357504, -22.1908621],
  [35.5381536, -22.1820006],
  [35.5408144, -22.1733372],
  [35.544076, -22.1577973],
  [35.5463934, -22.1413813],
  [35.546608, -22.1388372],
  [35.5462646, -22.1349018],
  [35.5454493, -22.131483],
  [35.5439472, -22.1289786],
  [35.543046, -22.1285413],
  [35.5431318, -22.1295351],
  [35.5425739, -22.130688],
  [35.5430889, -22.1313638],
  [35.5435181, -22.1310458],
  [35.5436897, -22.1320396],
  [35.5431318, -22.1324371],
  [35.543046, -22.1334707],
  [35.5433893, -22.134703],
  [35.544548, -22.1367701],
  [35.5451918, -22.139831],
  [35.5447197, -22.1420173],
  [35.544548, -22.1443228],
  [35.5434752, -22.14635],
  [35.5421877, -22.1469463],
  [35.5410719, -22.1477413],
  [35.5404711, -22.1484965],
  [35.5409431, -22.149212],
  [35.5411577, -22.1497684],
  [35.5401278, -22.1503249],
  [35.5401278, -22.1511596],
  [35.5395269, -22.1531072],
  [35.5401278, -22.1544984],
  [35.5422306, -22.1574396],
  [35.5421019, -22.1607783],
  [35.541029, -22.1614539],
  [35.5408573, -22.1630835],
  [35.539999, -22.1650309],
  [35.5384541, -22.1732179],
  [35.537467, -22.1746486],
  [35.5378532, -22.1777484],
  [35.5372095, -22.1778279],
  [35.5360508, -22.1816429],
  [35.5363083, -22.1828748],
  [35.5354071, -22.1837491],
  [35.5348921, -22.185736],
  [35.5351925, -22.187405],
  [35.533905, -22.1874845],
  [35.53442, -22.1887561],
  [35.5330896, -22.1901469],
  [35.531888, -22.1902263],
  [35.5299997, -22.1911403],
  [35.5306435, -22.1914582],
  [35.5306864, -22.1918158],
  [35.528841, -22.1942],
  [35.529871, -22.1950344],
  [35.5296135, -22.1967828],
  [35.5270386, -22.1976569],
  [35.5287552, -22.2017893],
  [35.5278111, -22.2024647],
  [35.527339, -22.2054447],
  [35.5260086, -22.2069943],
  [35.5267382, -22.2085438],
  [35.5266094, -22.2094179],
  [35.5277252, -22.211762],
  [35.5267382, -22.2122785],
  [35.5265236, -22.2133115],
  [35.525322, -22.2141061],
  [35.5267811, -22.2165694],
  [35.526309, -22.2199066],
  [35.5266953, -22.2245547],
  [35.5256224, -22.2253493],
  [35.5259228, -22.2266603],
  [35.5268669, -22.2307123],
  [35.5246353, -22.231189],
  [35.523777, -22.2344464],
  [35.523777, -22.2372271],
  [35.5201721, -22.2371477],
  [35.5223179, -22.2299973],
  [35.5209446, -22.2250712],
  [35.5207729, -22.2216546],
  [35.5208588, -22.219112],
  [35.5184555, -22.2132321],
  [35.519228, -22.2091795],
  [35.5165672, -22.2032197],
  [35.5139065, -22.1898687],
  [35.5130482, -22.1838286],
  [35.5109882, -22.1791393],
  [35.5066967, -22.1682502],
  [35.5040359, -22.1618911],
  [35.497427, -22.1505236],
  [35.4959679, -22.1414608],
  [35.4930496, -22.1351801],
  [35.4902172, -22.1296941],
  [35.4922771, -22.120789],
  [35.4915905, -22.1139507],
  [35.4888439, -22.106635],
  [35.4885006, -22.1050445],
  [35.4846382, -22.1015455],
  [35.4785442, -22.0983646],
  [35.4724503, -22.0970921],
  [35.4633522, -22.0974898],
  [35.4600906, -22.0993189],
  [35.4598331, -22.1005913],
  [35.4614639, -22.1018636],
  [35.4622364, -22.1005913],
  [35.4636097, -22.1041698],
  [35.4622364, -22.1042493],
  [35.4619789, -22.1070326],
  [35.4577732, -22.110611],
  [35.4602623, -22.1065554],
  [35.459404, -22.1060783],
  [35.4564857, -22.1110881],
  [35.4551125, -22.1135531],
  [35.4541683, -22.1165747],
  [35.4518509, -22.1165747],
  [35.4503918, -22.1197553],
  [35.4473019, -22.1233334],
  [35.4506493, -22.1177674],
  [35.4501343, -22.1162567],
  [35.4501343, -22.1162567],
  [35.4473019, -22.1199143],
  [35.4430962, -22.1249236],
  [35.4418945, -22.1305687],
  [35.4485893, -22.1431303],
  [35.4505634, -22.1484567],
  [35.4481602, -22.1516366],
  [35.4467869, -22.1513186],
  [35.4464436, -22.152988],
  [35.4484177, -22.153624],
  [35.4418087, -22.1574396],
  [35.4397488, -22.158314],
  [35.431509, -22.1618911],
  [35.422411, -22.1677732],
  [35.4200935, -22.1699194],
  [35.4177761, -22.171827],
  [35.416832, -22.174291],
  [35.414257, -22.1755627],
  [35.4122829, -22.1807289],
  [35.4144287, -22.1832722],
  [35.4189777, -22.1841465],
  [35.4201794, -22.1856565],
  [35.4185486, -22.1851797],
  [35.4183769, -22.187246],
  [35.4197502, -22.1881203],
  [35.4219818, -22.1878024],
  [35.4215527, -22.1893919],
  [35.4187202, -22.1895508],
  [35.416832, -22.187246],
  [35.4151154, -22.187246],
  [35.4133129, -22.1881997],
  [35.4120255, -22.1897892],
  [35.4128838, -22.1914582],
  [35.4143429, -22.1944781],
  [35.4171753, -22.197339],
  [35.4162312, -22.1985311],
  [35.4121971, -22.1976569],
  [35.4091072, -22.1985311],
  [35.4067898, -22.2008357],
  [35.4057598, -22.1994847],
  [35.404644, -22.1994847],
  [35.4039574, -22.200041],
  [35.4031849, -22.2022661],
  [35.4016399, -22.2026634],
  [35.4000092, -22.2062394],
  [35.3987217, -22.216331],
  [35.3941727, -22.2076697],
  [35.3900528, -22.2101331],
  [35.3875637, -22.2079876],
  [35.3848171, -22.2095768],
  [35.3861046, -22.212358],
  [35.3857613, -22.2139472],
  [35.3844738, -22.2118018],
  [35.3837013, -22.2117223],
  [35.383358, -22.2129937],
  [35.3812981, -22.2145829],
  [35.381813, -22.2192709],
  [35.3792381, -22.2168872],
  [35.3751183, -22.2256274],
  [35.3738308, -22.2268986],
  [35.3725433, -22.2342081],
  [35.3722858, -22.2303151],
  [35.3722858, -22.2270575],
  [35.3733158, -22.2250712],
  [35.3736591, -22.2210984],
  [35.3734016, -22.2161721],
  [35.3729725, -22.2130731],
  [35.3705692, -22.2130731],
  [35.3673935, -22.2108482],
  [35.3685093, -22.2093384],
  [35.3653336, -22.209259],
  [35.3634453, -22.210292],
  [35.362587, -22.2078286],
  [35.3589821, -22.208067],
  [35.3570938, -22.2060804],
  [35.3594112, -22.2013919],
  [35.3513432, -22.1972596],
  [35.3461075, -22.185736],
  [35.3364086, -22.1881203],
  [35.3350353, -22.1863718],
  [35.3329754, -22.1854181],
  [35.330143, -22.1865308],
  [35.3300571, -22.1819211],
  [35.3303146, -22.1699988],
  [35.3328896, -22.1556113],
  [35.333147, -22.1430508],
  [35.3345203, -22.1375652],
  [35.3323746, -22.1309662],
  [35.3337479, -22.1239694],
  [35.3332329, -22.1207095],
  [35.333662, -22.110929],
  [35.3338337, -22.1012274],
  [35.3333187, -22.0989212],
  [35.3339195, -22.0918433],
  [35.333147, -22.0877077],
  [35.3275681, -22.0749026],
  [35.3251648, -22.060744],
  [35.3249931, -22.0517549],
  [35.3237915, -22.0477773],
  [35.3243065, -22.041731],
  [35.3237057, -22.039583],
  [35.3242207, -22.0364006],
  [35.323019, -22.034332],
  [35.3214741, -22.02558],
  [35.3214741, -22.0208059],
  [35.3201008, -22.0196919],
  [35.322504, -22.0138036],
  [35.323019, -22.0071989],
  [35.325079, -21.9939886],
  [35.3238773, -21.9837218],
  [35.3229332, -21.9824484],
  [35.3214741, -21.9840402],
  [35.3173542, -21.9810953],
  [35.3151226, -21.9775137],
  [35.3132343, -21.9746483],
  [35.3120327, -21.9708277],
  [35.3125477, -21.9637433],
  [35.3138351, -21.9579323],
  [35.3154659, -21.9520415],
  [35.3158092, -21.9517231],
  [35.3195, -21.9436824],
  [35.320015, -21.9397018],
  [35.3188992, -21.9410552],
  [35.3164959, -21.9384279],
  [35.3158092, -21.9352433],
  [35.3165817, -21.9315808],
  [35.320015, -21.9198763],
  [35.3199291, -21.9174875],
  [35.3207874, -21.9123117],
  [35.3201866, -21.911595],
  [35.3201866, -21.9100024],
  [35.3197145, -21.9095644],
  [35.3195858, -21.908569],
  [35.3170967, -21.9058615],
  [35.3141356, -21.9017603],
  [35.3131485, -21.9006454],
  [35.3125477, -21.9006852],
  [35.3105736, -21.8984554],
  [35.3092861, -21.8959468],
  [35.3081274, -21.8925223],
  [35.3079987, -21.8917259],
  [35.3076124, -21.8903321],
  [35.3067541, -21.8892171],
  [35.3061104, -21.888142],
  [35.3057241, -21.886788],
  [35.3051233, -21.8863101],
  [35.30375, -21.882965],
  [35.3033638, -21.8794207],
  [35.3026342, -21.8751197],
  [35.3022909, -21.8706591],
  [35.3011751, -21.8672738],
  [35.3011751, -21.8646054],
  [35.3013468, -21.8615386],
  [35.3019047, -21.85887],
  [35.3018188, -21.8575954],
  [35.3022051, -21.8561615],
  [35.3020334, -21.8550064],
  [35.3013897, -21.8549666],
  [35.3006172, -21.8530149],
  [35.3000593, -21.8509834],
  [35.2995014, -21.8495097],
  [35.2991581, -21.8481155],
  [35.2979994, -21.8476773],
  [35.2960682, -21.8452475],
  [35.2952957, -21.8422201],
  [35.2944803, -21.839113],
  [35.2929783, -21.8374797],
  [35.2927637, -21.8343725],
  [35.2922487, -21.8341335],
  [35.2920341, -21.8346514],
  [35.2901459, -21.8333367],
  [35.2892447, -21.8304684],
  [35.2882147, -21.8282375],
  [35.2878714, -21.8280383],
  [35.2869272, -21.8253691],
  [35.2864122, -21.8239349],
  [35.2858543, -21.8222218],
  [35.2852106, -21.8208274],
  [35.2846527, -21.8180784],
  [35.2842236, -21.8170026],
  [35.2838802, -21.8151301],
  [35.284009, -21.8105482],
  [35.2846098, -21.8077591],
  [35.285511, -21.8020214],
  [35.2853823, -21.8014636],
  [35.2854252, -21.8000291],
  [35.2852106, -21.798754],
  [35.2849531, -21.797997],
  [35.2846098, -21.7975586],
  [35.284009, -21.7971602],
  [35.2829361, -21.7956061],
  [35.2827215, -21.7934942],
  [35.2827644, -21.7914221],
  [35.2830219, -21.7895093],
  [35.2833652, -21.7879552],
  [35.2839661, -21.7854446],
  [35.2836227, -21.7852454],
  [35.282979, -21.7851258],
  [35.2817774, -21.7845281],
  [35.2806187, -21.7829341],
  [35.2798891, -21.7813002],
  [35.2798891, -21.780543],
  [35.27946, -21.7785504],
  [35.2788162, -21.7752825],
  [35.277915, -21.7709782],
  [35.2788591, -21.7624093],
  [35.2795887, -21.7579851],
  [35.2791595, -21.753242],
  [35.2798891, -21.7511294],
  [35.2795887, -21.7505315],
  [35.2805758, -21.747821],
  [35.2803183, -21.7471434],
  [35.2807474, -21.7439146],
  [35.281992, -21.7411242],
  [35.284481, -21.7341481],
  [35.2839231, -21.7331515],
  [35.2838373, -21.7310785],
  [35.2833223, -21.7278095],
  [35.2819061, -21.725577],
  [35.2801895, -21.7235836],
  [35.2791595, -21.7230653],
  [35.2793741, -21.7236235],
  [35.2797604, -21.7239424],
  [35.2789879, -21.7241417],
  [35.2777004, -21.7235039],
  [35.2759838, -21.7221484],
  [35.2749968, -21.7217098],
  [35.2729368, -21.7184406],
  [35.2727222, -21.7167262],
  [35.2710485, -21.715889],
  [35.2698469, -21.7142543],
  [35.2698898, -21.7127392],
  [35.2695465, -21.7119019],
  [35.2702332, -21.7097888],
  [35.2701473, -21.7092704],
  [35.2696323, -21.7093103],
  [35.2691174, -21.710028],
  [35.2685165, -21.708473],
  [35.2685165, -21.7062003],
  [35.2684736, -21.7013757],
  [35.2689028, -21.7008972],
  [35.2688169, -21.7001396],
  [35.2697182, -21.6978269],
  [35.2699327, -21.6951951],
  [35.2694178, -21.6946369],
  [35.2700615, -21.6937596],
  [35.2701473, -21.6899714],
  [35.2695894, -21.689772],
  [35.2696753, -21.6879376],
  [35.2702332, -21.6867014],
  [35.2713919, -21.6808792],
  [35.2727222, -21.6756947],
  [35.2730656, -21.6720257],
  [35.2734518, -21.6628127],
  [35.2729368, -21.6615763],
  [35.273366, -21.6513255],
  [35.2725935, -21.6417522],
  [35.2718639, -21.6395981],
  [35.2716064, -21.6382817],
  [35.270834, -21.6368456],
  [35.2700186, -21.6361675],
  [35.2700186, -21.6354893],
  [35.2686882, -21.6328564],
  [35.2652121, -21.6290666],
  [35.2632809, -21.625915],
  [35.2604055, -21.6183348],
  [35.2583885, -21.6123502],
  [35.2573156, -21.6089987],
  [35.2568436, -21.6061259],
  [35.256629, -21.6010984],
  [35.2553844, -21.5937963],
  [35.2526808, -21.5891674],
  [35.2507067, -21.585137],
  [35.2489471, -21.5824234],
  [35.2458143, -21.5793905],
  [35.2426386, -21.5763575],
  [35.23736, -21.5710496],
  [35.2314806, -21.5661805],
  [35.2222538, -21.5576393],
  [35.2190781, -21.554566],
  [35.2136707, -21.5482594],
  [35.2085209, -21.5407949],
  [35.2021694, -21.5305755],
  [35.1991653, -21.5253857],
  [35.1966333, -21.5200361],
  [35.1924706, -21.5112127],
  [35.1913977, -21.5092163],
  [35.1891661, -21.5057826],
  [35.1864624, -21.5014304],
  [35.1837587, -21.4975174],
  [35.1780939, -21.488453],
  [35.170455, -21.4761933],
  [35.1702404, -21.475075],
  [35.167923, -21.4718402],
  [35.1637602, -21.4650108],
  [35.1556492, -21.4513911],
  [35.1480532, -21.4382094],
  [35.1437616, -21.4307793],
  [35.1375818, -21.4180752],
  [35.1350498, -21.4122022],
  [35.1289558, -21.3976185],
  [35.124836, -21.385471],
  [35.1193857, -21.3665685],
  [35.1155233, -21.3513807],
  [35.1147938, -21.3485028],
  [35.1128626, -21.3371907],
  [35.1112318, -21.3225196],
  [35.1110172, -21.305848],
  [35.1113176, -21.2913737],
  [35.1124763, -21.2798572],
  [35.1133776, -21.2742986],
  [35.1134634, -21.2659003],
  [35.114193, -21.2621409],
  [35.1135063, -21.2601411],
  [35.1134634, -21.2591413],
  [35.1126909, -21.2583014],
  [35.1102448, -21.2591013],
  [35.1102018, -21.2602611],
  [35.1105022, -21.2619009],
  [35.1099014, -21.2636206],
  [35.1099014, -21.2648204],
  [35.1107168, -21.2647805],
  [35.1109743, -21.2657003],
  [35.1093006, -21.2681799],
  [35.1095152, -21.2690597],
  [35.110116, -21.2703394],
  [35.1099873, -21.2719391],
  [35.1090431, -21.2733788],
  [35.1086569, -21.2735388],
  [35.1086569, -21.2747385],
  [35.108099, -21.2748585],
  [35.1077127, -21.2762581],
  [35.1071548, -21.2774978],
  [35.107069, -21.2780977],
  [35.1070261, -21.2788175],
  [35.1067257, -21.2796573],
  [35.1065969, -21.280657],
  [35.1068544, -21.2814968],
  [35.1070261, -21.2824565],
  [35.1059532, -21.2848558],
  [35.1059103, -21.2853756],
  [35.1047087, -21.2868552],
  [35.1029921, -21.2881348],
  [35.1015759, -21.2908139],
  [35.0996447, -21.2936929],
  [35.0988722, -21.2968517],
  [35.0977135, -21.2977713],
  [35.095911, -21.2976914],
  [35.0946665, -21.2990508],
  [35.093894, -21.3006102],
  [35.093379, -21.3026494],
  [35.0923061, -21.3044486],
  [35.0905037, -21.305808],
  [35.0885296, -21.3064877],
  [35.0874138, -21.3075672],
  [35.0861692, -21.307967],
  [35.0848818, -21.3082869],
  [35.0840235, -21.3087267],
  [35.0834227, -21.3096862],
  [35.083251, -21.3102859],
  [35.0818348, -21.3104059],
  [35.0799465, -21.3100061],
  [35.0762558, -21.3076072],
  [35.0745821, -21.3051683],
  [35.0744104, -21.3008101],
  [35.0753975, -21.2994907],
  [35.0756979, -21.2981312],
  [35.0775003, -21.2951723],
  [35.0779724, -21.2939728],
  [35.0785303, -21.2928132],
  [35.0780582, -21.2924133],
  [35.0778437, -21.2916536],
  [35.0778437, -21.2908938],
  [35.077157, -21.2895343],
  [35.0770283, -21.2884147],
  [35.0762987, -21.2856955],
  [35.0771141, -21.2822565],
  [35.0769424, -21.2816167],
  [35.0729084, -21.2793773],
  [35.0727367, -21.2772179],
  [35.0734234, -21.2736187],
  [35.073595, -21.2720191],
  [35.0739384, -21.2713792],
  [35.0735092, -21.2708193],
  [35.0718784, -21.2707394],
  [35.0709343, -21.2696196],
  [35.0688744, -21.2709793],
  [35.0684452, -21.2701795],
  [35.0681877, -21.2694596],
  [35.070591, -21.2688997],
  [35.0716209, -21.2676999],
  [35.0758266, -21.2641006],
  [35.0780582, -21.2606611],
  [35.0781441, -21.2594612],
  [35.079689, -21.2571415],
  [35.0797749, -21.2557817],
  [35.076685, -21.2552217],
  [35.0739384, -21.2544218],
  [35.0734234, -21.2533019],
  [35.0716209, -21.2534619],
  [35.0687027, -21.2530619],
  [35.0669003, -21.2537019],
  [35.0662136, -21.2534619],
  [35.0626945, -21.2541818],
  [35.0603771, -21.2539418],
  [35.054369, -21.252422],
  [35.0534248, -21.2506621],
  [35.0511074, -21.2505021],
  [35.0501633, -21.2446624],
  [35.0564289, -21.2425024],
  [35.058918, -21.2426624],
  [35.0632954, -21.2412224],
  [35.0718784, -21.2386624],
  [35.0729084, -21.2393824],
  [35.0778866, -21.2377024],
  [35.0853539, -21.2364224],
  [35.0896454, -21.2365824],
  [35.0927353, -21.2465023],
  [35.0961685, -21.2436224],
  [35.10252, -21.2263418],
  [35.10355, -21.196896],
  [35.1016617, -21.1956156],
  [35.10149, -21.2079388],
  [35.0956535, -21.200577],
  [35.0917053, -21.2068186],
  [35.090847, -21.2125798],
  [35.0848389, -21.21354],
  [35.0855255, -21.2061784],
  [35.0819206, -21.2034578],
  [35.080204, -21.1975362],
  [35.0714493, -21.1909741],
  [35.0740242, -21.1895336],
  [35.0779724, -21.1916143],
  [35.079689, -21.1898537],
  [35.0793457, -21.1847319],
  [35.069046, -21.1813706],
  [35.0625229, -21.1748078],
  [35.0551414, -21.1677645],
  [35.0539398, -21.1608809],
  [35.0554848, -21.1578393],
  [35.0623512, -21.1563985],
  [35.0649261, -21.1530365],
  [35.0662994, -21.1357453],
  [35.0650978, -21.1333435],
  [35.0590897, -21.1237363],
  [35.0549698, -21.1232559],
  [35.0518799, -21.1242166],
  [35.0518799, -21.1306215],
  [35.0441551, -21.1306215],
  [35.036602, -21.1216546],
  [35.0321388, -21.1213343],
  [35.0321388, -21.1190925],
  [35.0297356, -21.1152493],
  [35.024929, -21.1138081],
  [35.0209808, -21.1066017],
  [35.025444, -21.1016372],
  [35.0271606, -21.0984341],
  [35.0244141, -21.092188],
  [35.025959, -21.0894652],
  [35.0256157, -21.0872229],
  [35.0290489, -21.0838594],
  [35.0336838, -21.0830586],
  [35.0417519, -21.0846603],
  [35.0484467, -21.0899457],
  [35.0529099, -21.0909067],
  [35.0597763, -21.0862619],
  [35.0688744, -21.0820975],
  [35.0834656, -21.0889847],
  [35.0884438, -21.0873831],
  [35.091877, -21.0553465],
  [35.0810623, -21.0458944],
  [35.0808907, -21.0377234],
  [35.0831223, -21.0332372],
  [35.0831223, -21.0303532],
  [35.0783157, -21.0221814],
  [35.0795174, -21.0194573],
  [35.0867271, -21.016573],
  [35.0946236, -21.0170537],
  [35.100975, -21.0218609],
  [35.0997734, -21.02811],
  [35.1002884, -21.0321157],
  [35.0984001, -21.0325963],
  [35.0985718, -21.0300327],
  [35.0959969, -21.0327566],
  [35.0973701, -21.0349997],
  [35.1011467, -21.037403],
  [35.1032066, -21.0343588],
  [35.1071548, -21.0229825],
  [35.1090431, -21.0092017],
  [35.1124763, -20.9960606],
  [35.1174545, -20.9830787],
  [35.1258659, -20.9617602],
  [35.1311874, -20.9468515],
  [35.1298141, -20.9420419],
  [35.1329041, -20.9357892],
  [35.1241493, -20.921359],
  [35.1160812, -20.9181521],
  [35.1045799, -20.9022768],
  [35.0985718, -20.8888056],
  [35.0886154, -20.8854376],
  [35.0863838, -20.8872018],
  [35.092392, -20.8881641],
  [35.0968552, -20.8905698],
  [35.0985718, -20.8955414],
  [35.0963402, -20.8993902],
  [35.0973701, -20.9056444],
  [35.092392, -20.9080498],
  [35.0891304, -20.905003],
  [35.0850105, -20.9048426],
  [35.0795174, -20.9074084],
  [35.0660218, -20.9108348],
  [35.0697327, -20.9181521],
  [35.0601196, -20.9319413],
  [35.0439835, -20.9370719],
  [35.0388336, -20.940599],
  [35.0415802, -20.9428435],
  [35.0525665, -20.9457293],
  [35.0559998, -20.9636838],
  [35.0312805, -20.9825979],
  [35.0182343, -20.9809951],
  [35.0079346, -20.9877267],
  [34.993515, -20.9938169],
  [34.9777222, -20.9870856],
  [34.9677658, -20.9918937],
  [34.9602127, -20.9886883],
  [34.9530029, -20.9925348],
  [34.9457932, -21.000548],
  [34.9317169, -21.0095222],
  [34.9269104, -21.0050351],
  [34.9183273, -21.0066377],
  [34.9142075, -21.0143296],
  [34.906311, -21.0265077],
  [34.8991013, -21.0268281],
  [34.8894882, -21.031635],
  [34.8802185, -21.0319554],
  [34.8730087, -21.0383643],
  [34.8709488, -21.0454138],
  [34.854126, -21.0527833],
  [34.8403931, -21.0524629],
  [34.8270035, -21.0691231],
  [34.8215103, -21.0787339],
  [34.8091507, -21.0755304],
  [34.8026276, -21.0755304],
  [34.7957611, -21.0800153],
  [34.7834015, -21.0947505],
  [34.7724152, -21.1133277],
  [34.767952, -21.1245369],
  [34.7487259, -21.1315822],
  [34.7236633, -21.1203736],
  [34.7150803, -21.119733],
  [34.7040939, -21.106922],
  [34.6848679, -21.1005161],
  [34.6711349, -21.0893051],
  [34.6663284, -21.0896254],
  [34.6515656, -21.0963521],
  [34.6419525, -21.0947505],
  [34.637146, -21.0937896],
  [34.6323395, -21.0963521],
  [34.6247864, -21.1082032],
  [34.6203232, -21.1053206],
  [34.5983505, -21.1158899],
  [34.5949173, -21.1226154],
  [34.5863342, -21.131262],
  [34.5777512, -21.1293406],
  [34.5705414, -21.1331834],
  [34.562645, -21.1376666],
  [34.5537186, -21.1331834],
  [34.5389557, -21.1399081],
  [34.5207596, -21.1379868],
  [34.5101166, -21.1440709],
  [34.5049667, -21.1597603],
  [34.4778442, -21.1639226],
  [34.4593048, -21.1677645],
  [34.4503784, -21.2023375],
  [34.431839, -21.2177007],
  [34.4301224, -21.2253817],
  [34.4187927, -21.2346623],
  [34.389267, -21.2497022],
  [34.389267, -21.261221],
  [34.3796539, -21.2708193],
  [34.3738174, -21.272419],
  [34.3690109, -21.2784976],
  [34.355278, -21.2797772],
  [34.3528748, -21.2832962],
  [34.354248, -21.2973715],
  [34.3432617, -21.3015298],
  [34.3384552, -21.3028093],
  [34.3284988, -21.316243],
  [34.3058395, -21.303449],
  [34.2934799, -21.3018497],
  [34.29039, -21.2967317],
  [34.2653275, -21.2692197],
  [34.2588043, -21.2682598],
  [34.2457581, -21.2596212],
  [34.2248154, -21.2618609],
  [34.2124557, -21.2736987],
  [34.2073059, -21.2871351],
  [34.1994095, -21.2992907],
  [34.193573, -21.3066476],
  [34.1630173, -21.316243],
  [34.1523743, -21.3111255],
  [34.1427612, -21.3117652],
  [34.1348648, -21.3015298],
  [34.1279984, -21.2999305],
  [34.1067123, -21.287455],
  [34.0984726, -21.2884147],
  [34.0878296, -21.296092],
  [34.0401077, -21.2980112],
  [34.0270615, -21.3063278],
  [34.0181351, -21.3060079],
  [34.0078354, -21.3066476],
  [33.9992523, -21.3130446],
  [33.9752197, -21.3015298],
  [33.9388275, -21.318162],
  [33.9415741, -21.3379902],
  [33.9299011, -21.3443858],
  [33.9333344, -21.3507812],
  [33.9038086, -21.4025731],
  [33.8900757, -21.4060892],
  [33.870163, -21.4118426],
  [33.847847, -21.4076874],
  [33.8368607, -21.3968194],
  [33.8166046, -21.3872293],
  [33.7953186, -21.3830734],
  [33.7771225, -21.390426],
  [33.7661362, -21.4086463],
  [33.7544632, -21.4102445],
  [33.7475967, -21.4198331],
  [33.7414169, -21.4172762],
  [33.7318039, -21.4214311],
  [33.7252808, -21.4156781],
  [33.717041, -21.418235],
  [33.6930084, -21.4012945],
  [33.6820221, -21.4022535],
  [33.6682892, -21.3833931],
  [33.6610794, -21.3833931],
  [33.629837, -21.390426],
  [33.6181641, -21.3891473],
  [33.610611, -21.3926637],
  [33.5972214, -21.3907457],
  [33.5704422, -21.387549],
  [33.5450363, -21.3747612],
  [33.5364532, -21.3766794],
  [33.5086441, -21.4000159],
  [33.4859848, -21.4096052],
  [33.4767151, -21.4096052],
  [33.4643555, -21.4198331],
  [33.4664154, -21.429421],
  [33.4602356, -21.4361322],
  [33.4506226, -21.439967],
  [33.445816, -21.4457191],
  [33.4434128, -21.4537077],
  [33.4300232, -21.4543468],
  [33.4224701, -21.4581811],
  [33.4066772, -21.4610568],
  [33.391571, -21.4700031],
  [33.3867645, -21.4783098],
  [33.3551788, -21.4700031],
  [33.3414459, -21.4725591],
  [33.3301163, -21.4811851],
  [33.3270693, -21.4890919],
  [33.3224773, -21.4939236],
  [33.3163404, -21.4979566],
  [33.3117056, -21.4999531],
  [33.3043671, -21.5025884],
  [33.3005905, -21.5027082],
  [33.2971466, -21.5023189],
];
const gaza = [
  [33.1338215, -25.3826496],
  [33.1352806, -25.3817579],
  [33.1484127, -25.3737316],
  [33.1587982, -25.3684581],
  [33.1739473, -25.3604697],
  [33.1884098, -25.3521318],
  [33.202529, -25.34422],
  [33.2086229, -25.3407681],
  [33.2164335, -25.3369282],
  [33.2209396, -25.3347949],
  [33.2324409, -25.329442],
  [33.2408094, -25.3265716],
  [33.2417107, -25.3269983],
  [33.2414103, -25.3275025],
  [33.2419252, -25.3280456],
  [33.2426119, -25.3275413],
  [33.2478046, -25.3246708],
  [33.2493925, -25.3229252],
  [33.2516241, -25.3219166],
  [33.2516241, -25.3214123],
  [33.2526541, -25.3204425],
  [33.2538557, -25.3206365],
  [33.2597351, -25.3172227],
  [33.2777596, -25.3084938],
  [33.2938099, -25.2998808],
  [33.3118343, -25.2909954],
  [33.3274555, -25.2842825],
  [33.3440208, -25.2767155],
  [33.3618307, -25.2686435],
  [33.3816576, -25.2604545],
  [33.3901978, -25.2568062],
  [33.4036303, -25.2514887],
  [33.4100676, -25.2490433],
  [33.4165049, -25.2460157],
  [33.4270191, -25.2423669],
  [33.4328127, -25.2399214],
  [33.4349585, -25.2386016],
  [33.4419537, -25.2355349],
  [33.4472752, -25.233555],
  [33.4515667, -25.2317693],
  [33.4555578, -25.2302165],
  [33.4607077, -25.2285471],
  [33.464613, -25.2275378],
  [33.471694, -25.2253249],
  [33.4743977, -25.2244319],
  [33.4795046, -25.2235002],
  [33.4824657, -25.2227625],
  [33.4876585, -25.2209766],
  [33.4930229, -25.2185695],
  [33.4971428, -25.2164341],
  [33.5007477, -25.2139492],
  [33.504653, -25.2116973],
  [33.508687, -25.2097559],
  [33.5093307, -25.2079698],
  [33.5161114, -25.2018348],
  [33.5196304, -25.2034268],
  [33.5206175, -25.2043587],
  [33.5262394, -25.2023007],
  [33.5314322, -25.1993884],
  [33.5404444, -25.1960489],
  [33.5491133, -25.1927481],
  [33.5525894, -25.1917384],
  [33.5594988, -25.1895637],
  [33.5694981, -25.1862239],
  [33.5794115, -25.1836608],
  [33.583703, -25.1818355],
  [33.5858488, -25.1815636],
  [33.5866642, -25.1807092],
  [33.5885096, -25.1804762],
  [33.5912991, -25.1786508],
  [33.5939169, -25.1785343],
  [33.5966635, -25.1773692],
  [33.5988522, -25.1773692],
  [33.607564, -25.1743786],
  [33.6206102, -25.1685526],
  [33.6336136, -25.1626875],
  [33.648119, -25.1558509],
  [33.6590624, -25.1500629],
  [33.6682892, -25.1456731],
  [33.6774302, -25.1409335],
  [33.692193, -25.133241],
  [33.7080717, -25.1269468],
  [33.7154102, -25.1239161],
  [33.7253666, -25.1211185],
  [33.7304735, -25.1183208],
  [33.7315893, -25.1181654],
  [33.7448072, -25.1147071],
  [33.7456656, -25.115018],
  [33.7566948, -25.1107824],
  [33.7673807, -25.1074015],
  [33.7684965, -25.1060414],
  [33.7729168, -25.104487],
  [33.7767792, -25.1022719],
  [33.7807274, -25.1010283],
  [33.7847614, -25.099979],
  [33.7865639, -25.0989685],
  [33.7879801, -25.0987742],
  [33.800211, -25.0937996],
  [33.8045025, -25.0929835],
  [33.8219261, -25.0866872],
  [33.8398218, -25.0801185],
  [33.8541555, -25.0747933],
  [33.8623524, -25.0719168],
  [33.8788319, -25.0662025],
  [33.8980579, -25.0601381],
  [33.910675, -25.0566004],
  [33.913765, -25.0560173],
  [33.9245796, -25.0521684],
  [33.9329481, -25.0501467],
  [33.9543629, -25.044237],
  [33.9568949, -25.0441981],
  [33.9701557, -25.0397268],
  [33.9828157, -25.0361885],
  [33.9860344, -25.0360719],
  [33.9896393, -25.0342832],
  [33.9967632, -25.0327667],
  [34.0092087, -25.0263118],
  [34.0225124, -25.020401],
  [34.0260315, -25.0191954],
  [34.0296364, -25.0176399],
  [34.0386057, -25.0149565],
  [34.0396786, -25.0140621],
  [34.0410948, -25.0132843],
  [34.0428972, -25.0127009],
  [34.0446997, -25.0127787],
  [34.0458155, -25.0121564],
  [34.0471029, -25.0120398],
  [34.04809, -25.0114175],
  [34.0494204, -25.0114175],
  [34.0497637, -25.0109119],
  [34.0507078, -25.0109119],
  [34.0518665, -25.0100174],
  [34.0542269, -25.0084229],
  [34.0583467, -25.0071394],
  [34.0601921, -25.0066727],
  [34.0747404, -25.0020833],
  [34.0761995, -25.0022778],
  [34.0861559, -24.9994385],
  [34.0901041, -24.9983495],
  [34.0922928, -24.997766],
  [34.0934944, -24.9976105],
  [34.0952969, -24.9971048],
  [34.0976572, -24.9958991],
  [34.0990305, -24.9958991],
  [34.1044378, -24.9937598],
  [34.1070986, -24.9919316],
  [34.1095877, -24.9908036],
  [34.1131926, -24.9895978],
  [34.1184282, -24.9874584],
  [34.1217756, -24.9864859],
  [34.1235352, -24.9860191],
  [34.1250801, -24.98528],
  [34.12714, -24.9853967],
  [34.1283417, -24.9845021],
  [34.1292, -24.9843076],
  [34.1368818, -24.9812345],
  [34.1556787, -24.9733763],
  [34.1621161, -24.9709254],
  [34.1847754, -24.9618994],
  [34.2000961, -24.9559077],
  [34.204731, -24.9537288],
  [34.2080355, -24.9526393],
  [34.2117691, -24.9511607],
  [34.2179918, -24.9492541],
  [34.2252874, -24.946258],
  [34.2312527, -24.9438844],
  [34.2342567, -24.9433785],
  [34.2371321, -24.9417831],
  [34.2482901, -24.9392148],
  [34.2506075, -24.9395261],
  [34.2552853, -24.9386311],
  [34.2610788, -24.9382809],
  [34.2648554, -24.9376972],
  [34.266572, -24.9369189],
  [34.2749405, -24.935012],
  [34.2791891, -24.9334944],
  [34.2880726, -24.9315096],
  [34.2902613, -24.9307313],
  [34.2932224, -24.9300308],
  [34.2973852, -24.9288244],
  [34.2992306, -24.9284352],
  [34.3058825, -24.9264504],
  [34.3125343, -24.9241543],
  [34.3194437, -24.9215078],
  [34.3235636, -24.920457],
  [34.3301725, -24.9181996],
  [34.339056, -24.9159033],
  [34.3402576, -24.9153195],
  [34.3415451, -24.9144632],
  [34.343648, -24.9137626],
  [34.3477678, -24.9120889],
  [34.3519735, -24.9104153],
  [34.3541622, -24.9097147],
  [34.355793, -24.9086248],
  [34.3570375, -24.9082745],
  [34.3601274, -24.907535],
  [34.3604279, -24.90699],
  [34.3609858, -24.906484],
  [34.3616295, -24.9060948],
  [34.3636036, -24.905472],
  [34.3650627, -24.9052774],
  [34.3684959, -24.9041875],
  [34.3743324, -24.9027472],
  [34.3783665, -24.900256],
  [34.3809843, -24.8977647],
  [34.3827009, -24.8968305],
  [34.3890095, -24.8925873],
  [34.3973351, -24.8890448],
  [34.3997812, -24.8884998],
  [34.4014549, -24.8873319],
  [34.4026995, -24.8875265],
  [34.4083643, -24.8853075],
  [34.4181061, -24.881648],
  [34.4216251, -24.8799739],
  [34.42523, -24.8782219],
  [34.4294357, -24.8766646],
  [34.4308519, -24.8761974],
  [34.43995, -24.8734331],
  [34.4432545, -24.8725765],
  [34.4457865, -24.8719535],
  [34.4535112, -24.869267],
  [34.4584036, -24.8668919],
  [34.4604206, -24.8655291],
  [34.4625235, -24.8651787],
  [34.4647121, -24.8644389],
  [34.4672441, -24.8640106],
  [34.4730806, -24.8628035],
  [34.4636607, -24.848532],
  [34.4537687, -24.8345314],
  [34.444263, -24.8212304],
  [34.4345427, -24.8076163],
  [34.3013763, -24.796085],
  [34.2986298, -24.7125292],
  [34.4490051, -24.6382796],
  [34.4112396, -24.5927097],
  [34.3913269, -24.5739775],
  [34.3796539, -24.5533689],
  [34.3700409, -24.5421264],
  [34.3528748, -24.5415018],
  [34.4263458, -24.4602757],
  [34.4139862, -24.4546504],
  [34.3803406, -24.4665257],
  [34.3453217, -24.4690256],
  [34.3281555, -24.4877734],
  [34.3027496, -24.4821494],
  [34.2670441, -24.4890232],
  [34.2169189, -24.5140157],
  [34.1860199, -24.5177642],
  [34.1757202, -24.5227619],
  [34.1441345, -24.5402525],
  [34.096756, -24.4734003],
  [34.0500641, -24.4208934],
  [33.9992523, -24.3546034],
  [33.9937592, -24.0872161],
  [33.9745331, -24.0753053],
  [33.9415741, -24.0796936],
  [33.9216614, -24.0245152],
  [33.934021, -24.0188706],
  [33.9237213, -24.0025626],
  [33.9374542, -23.9900166],
  [33.9257813, -23.9856252],
  [33.9264679, -23.9322893],
  [33.924408, -23.9015327],
  [33.9161682, -23.8808149],
  [33.9415741, -23.8638615],
  [33.9196014, -23.8393694],
  [33.9257813, -23.8192699],
  [33.9333344, -23.7960259],
  [33.9202881, -23.7790614],
  [33.9353943, -23.738212],
  [33.9367676, -23.7206114],
  [33.9546204, -23.7099241],
  [33.9470673, -23.6904333],
  [33.9250946, -23.6615062],
  [33.8955688, -23.6633929],
  [33.9278412, -23.6181034],
  [33.9470673, -23.5872725],
  [33.9292145, -23.5828674],
  [33.9463806, -23.5463631],
  [33.9202881, -23.4846602],
  [33.8852692, -23.4834007],
  [33.8811493, -23.440569],
  [33.8660431, -23.4348991],
  [33.8653564, -23.4033951],
  [33.8413239, -23.3939424],
  [33.8454437, -23.352973],
  [33.8626099, -23.36495],
  [33.870163, -23.3479297],
  [33.8887024, -23.345408],
  [33.9024353, -23.3271243],
  [33.8784027, -23.3315378],
  [33.8756561, -23.2722578],
  [33.8605499, -23.2463934],
  [33.8433838, -23.241977],
  [33.848877, -23.1801324],
  [33.8262177, -23.1832884],
  [33.8303375, -23.1624572],
  [33.8131714, -23.1491993],
  [33.8049316, -23.1277314],
  [33.7733459, -23.113207],
  [33.7719727, -23.0885751],
  [33.7369537, -23.0885751],
  [33.7362671, -23.0645704],
  [33.7184143, -23.0424571],
  [33.6888885, -23.0279235],
  [33.6950684, -22.9982195],
  [33.6476898, -22.9179229],
  [33.6470032, -22.8945205],
  [33.6312103, -22.9040084],
  [33.5385132, -22.8268204],
  [33.5041809, -22.788843],
  [33.4883881, -22.7806132],
  [33.4933662, -22.7567122],
  [33.4743118, -22.7499052],
  [33.4598923, -22.7340736],
  [33.4571457, -22.7210904],
  [33.4506226, -22.7058889],
  [33.4204102, -22.7036719],
  [33.3963776, -22.6862512],
  [33.3884811, -22.6795991],
  [33.3768082, -22.673897],
  [33.3634186, -22.6732634],
  [33.3562088, -22.6593239],
  [33.339386, -22.6596408],
  [33.3283997, -22.6666107],
  [33.3232498, -22.6561557],
  [33.3215332, -22.6501358],
  [33.3177137, -22.639838],
  [33.3158684, -22.6366693],
  [33.3154821, -22.6353226],
  [33.3161688, -22.6343323],
  [33.3161259, -22.6331044],
  [33.3139372, -22.6285887],
  [33.3113194, -22.6279549],
  [33.3102894, -22.6273212],
  [33.3103323, -22.6254198],
  [33.309989, -22.6245879],
  [33.3086586, -22.6236768],
  [33.307972, -22.6221319],
  [33.3059978, -22.6216961],
  [33.3053541, -22.6234787],
  [33.3042812, -22.6233203],
  [33.3026505, -22.6212604],
  [33.3024359, -22.6171009],
  [33.301878, -22.6135751],
  [33.3009768, -22.6122678],
  [33.2993889, -22.6116735],
  [33.2979298, -22.6099304],
  [33.2981443, -22.6087022],
  [33.298316, -22.6075137],
  [33.2974148, -22.606761],
  [33.2955694, -22.6056913],
  [33.2952261, -22.6042254],
  [33.2938099, -22.6030368],
  [33.2939386, -22.6018483],
  [33.2930803, -22.6003427],
  [33.2929516, -22.5987975],
  [33.2932091, -22.5978863],
  [33.293767, -22.597292],
  [33.2934237, -22.5957071],
  [33.2912779, -22.59436],
  [33.2905483, -22.5927355],
  [33.2906771, -22.591428],
  [33.2897758, -22.589962],
  [33.2863855, -22.5897638],
  [33.2850981, -22.5891299],
  [33.2848406, -22.5882185],
  [33.2856131, -22.5877034],
  [33.286171, -22.5867128],
  [33.2850981, -22.5856034],
  [33.2833815, -22.5861977],
  [33.2816219, -22.585643],
  [33.2809353, -22.5837807],
  [33.2801628, -22.57962],
  [33.2802486, -22.5781539],
  [33.2811499, -22.5766084],
  [33.2817936, -22.5748648],
  [33.2823515, -22.5734779],
  [33.2821798, -22.5716154],
  [33.281579, -22.570268],
  [33.2803345, -22.5694755],
  [33.2787037, -22.5693962],
  [33.2769871, -22.5684451],
  [33.2757854, -22.567494],
  [33.2741117, -22.5669788],
  [33.2723951, -22.5646407],
  [33.2695627, -22.5630951],
  [33.2681894, -22.5607965],
  [33.265872, -22.5581412],
  [33.2667303, -22.5566352],
  [33.2681465, -22.5561199],
  [33.2700348, -22.5558029],
  [33.2711935, -22.554931],
  [33.2712364, -22.5535834],
  [33.2705498, -22.5520774],
  [33.2707214, -22.5496201],
  [33.2715797, -22.5470042],
  [33.2726955, -22.5452999],
  [33.2738972, -22.543437],
  [33.275013, -22.5430802],
  [33.2762146, -22.5423271],
  [33.2771587, -22.5422082],
  [33.279047, -22.5425253],
  [33.2796049, -22.5422082],
  [33.2797337, -22.5411777],
  [33.2793474, -22.5397508],
  [33.2784462, -22.5388787],
  [33.2769442, -22.5371743],
  [33.27703, -22.535351],
  [33.2789183, -22.533369],
  [33.2810211, -22.5322988],
  [33.2828236, -22.5305943],
  [33.2829094, -22.5281366],
  [33.2817507, -22.5252824],
  [33.2820511, -22.5232607],
  [33.2850122, -22.5214768],
  [33.2864714, -22.5199704],
  [33.2867718, -22.5172746],
  [33.2860851, -22.515451],
  [33.2853985, -22.5119623],
  [33.2853556, -22.5103368],
  [33.2857847, -22.5091078],
  [33.2867289, -22.5082752],
  [33.2886171, -22.5074427],
  [33.2905483, -22.5064911],
  [33.2918787, -22.5047467],
  [33.2920074, -22.5024471],
  [33.2925653, -22.5015749],
  [33.2929516, -22.5001475],
  [33.2917929, -22.4986409],
  [33.2910204, -22.4978082],
  [33.2905912, -22.4969756],
  [33.2899904, -22.4961033],
  [33.2899904, -22.4951914],
  [33.2904625, -22.4940415],
  [33.29175, -22.4924555],
  [33.2934237, -22.4909487],
  [33.2953119, -22.487737],
  [33.2957411, -22.4848423],
  [33.2963419, -22.4822649],
  [33.2969856, -22.4799649],
  [33.2977581, -22.478696],
  [33.2992172, -22.4778236],
  [33.300333, -22.4767132],
  [33.30235, -22.4687819],
  [33.3019638, -22.4666801],
  [33.3022642, -22.4649748],
  [33.3043671, -22.4624366],
  [33.3042812, -22.4612865],
  [33.3050966, -22.4608502],
  [33.305397, -22.4594224],
  [33.3053541, -22.4580343],
  [33.3045816, -22.4565669],
  [33.3050108, -22.4545441],
  [33.3056545, -22.4533543],
  [33.3070278, -22.4526404],
  [33.3077145, -22.4516092],
  [33.307457, -22.4506573],
  [33.3061695, -22.4491897],
  [33.3062124, -22.4483171],
  [33.3068562, -22.4470876],
  [33.3087015, -22.4470479],
  [33.3096457, -22.4464529],
  [33.3108044, -22.444906],
  [33.3102465, -22.4435178],
  [33.3061695, -22.4411379],
  [33.3055258, -22.4378853],
  [33.3063841, -22.4368143],
  [33.3067703, -22.4356243],
  [33.305912, -22.4340772],
  [33.3054399, -22.4327682],
  [33.3061266, -22.4313798],
  [33.308959, -22.4300311],
  [33.3096886, -22.4273336],
  [33.3103752, -22.4262229],
  [33.3130789, -22.4238427],
  [33.3127356, -22.4222558],
  [33.3117485, -22.4219385],
  [33.3114481, -22.4208277],
  [33.3116627, -22.4197169],
  [33.3106327, -22.4185664],
  [33.3087444, -22.4176539],
  [33.307972, -22.4161464],
  [33.3053541, -22.4145991],
  [33.3043242, -22.4130519],
  [33.3046246, -22.4116633],
  [33.3091736, -22.4079736],
  [33.309474, -22.4038077],
  [33.3111477, -22.4012287],
  [33.3129501, -22.3985306],
  [33.3146667, -22.3967848],
  [33.3158255, -22.3961896],
  [33.3166409, -22.3949993],
  [33.3167267, -22.3934518],
  [33.3157825, -22.3924995],
  [33.3154392, -22.3911901],
  [33.316083, -22.38996],
  [33.3176279, -22.3893251],
  [33.3184004, -22.3879363],
  [33.3177137, -22.3859523],
  [33.3166838, -22.3853174],
  [33.3161259, -22.3840079],
  [33.3164692, -22.3835317],
  [33.3165979, -22.3824206],
  [33.3190441, -22.3808333],
  [33.3206749, -22.3809523],
  [33.3227777, -22.3813492],
  [33.3246231, -22.3830158],
  [33.3273697, -22.385238],
  [33.3293438, -22.3857142],
  [33.3331633, -22.3849602],
  [33.3349228, -22.3826984],
  [33.339386, -22.3798015],
  [33.3420467, -22.3781745],
  [33.3453941, -22.3752776],
  [33.3469391, -22.3741664],
  [33.3494711, -22.3737299],
  [33.353548, -22.3747617],
  [33.3549213, -22.3757935],
  [33.3571959, -22.3767062],
  [33.3586121, -22.3782142],
  [33.3626032, -22.3811904],
  [33.3648777, -22.3815872],
  [33.3695126, -22.3809126],
  [33.3712721, -22.3810714],
  [33.3749199, -22.3848015],
  [33.376894, -22.3856348],
  [33.3786106, -22.3849602],
  [33.3804989, -22.3836507],
  [33.3820009, -22.3835317],
  [33.3829021, -22.3830952],
  [33.3837605, -22.3815476],
  [33.3832455, -22.3797222],
  [33.3801556, -22.3772221],
  [33.3775377, -22.3761506],
  [33.3759928, -22.3736902],
  [33.3756924, -22.3706345],
  [33.3752203, -22.3683724],
  [33.3733749, -22.3654355],
  [33.3733749, -22.3645624],
  [33.3720875, -22.3628956],
  [33.3693838, -22.3613874],
  [33.367238, -22.3596014],
  [33.3656502, -22.3574582],
  [33.3664656, -22.3551562],
  [33.366766, -22.3529733],
  [33.3659506, -22.3510681],
  [33.361659, -22.34567],
  [33.3612299, -22.3414228],
  [33.3605862, -22.3395572],
  [33.359127, -22.3368579],
  [33.3590841, -22.3341586],
  [33.3596849, -22.3319753],
  [33.360157, -22.3303478],
  [33.360672, -22.3282438],
  [33.3595133, -22.3256634],
  [33.3598995, -22.3243931],
  [33.359642, -22.3222493],
  [33.356123, -22.3208201],
  [33.3547926, -22.3190336],
  [33.3544493, -22.3168104],
  [33.3549213, -22.3145077],
  [33.3543634, -22.3111331],
  [33.3564234, -22.3069642],
  [33.3574533, -22.3029541],
  [33.361187, -22.2968394],
  [33.361659, -22.2952114],
  [33.3644056, -22.2900097],
  [33.364234, -22.2873889],
  [33.3659077, -22.285602],
  [33.3681393, -22.2844107],
  [33.3708858, -22.284093],
  [33.3725595, -22.2832591],
  [33.3729029, -22.2819089],
  [33.3721733, -22.2799631],
  [33.3697271, -22.2788115],
  [33.3688688, -22.2772627],
  [33.3694696, -22.2753962],
  [33.3719158, -22.2738077],
  [33.3721733, -22.2716235],
  [33.3715725, -22.2690818],
  [33.3699417, -22.2664209],
  [33.3689117, -22.2643159],
  [33.3660793, -22.2609401],
  [33.3636332, -22.2599471],
  [33.3616161, -22.2593911],
  [33.3572388, -22.2599868],
  [33.3553076, -22.2599471],
  [33.3538485, -22.2591925],
  [33.3500719, -22.2560151],
  [33.3492994, -22.2546249],
  [33.3443642, -22.2518843],
  [33.3431625, -22.2504941],
  [33.338871, -22.2477137],
  [33.3308029, -22.2436622],
  [33.3304596, -22.2422719],
  [33.329215, -22.2394516],
  [33.3302879, -22.2390147],
  [33.3304596, -22.2372668],
  [33.3284855, -22.2350423],
  [33.329258, -22.2318246],
  [33.3284426, -22.2305137],
  [33.3284426, -22.2292027],
  [33.3291292, -22.2283685],
  [33.3287859, -22.2267397],
  [33.3261681, -22.2235616],
  [33.3263826, -22.2203833],
  [33.3268976, -22.2165296],
  [33.3280993, -22.2149405],
  [33.3297729, -22.2128348],
  [33.3323479, -22.2110469],
  [33.3332062, -22.2096166],
  [33.3337212, -22.2079081],
  [33.3337212, -22.2049679],
  [33.3344507, -22.2016701],
  [33.3353949, -22.1982529],
  [33.3362103, -22.1965046],
  [33.3383131, -22.1942],
  [33.3387852, -22.1920145],
  [33.3375835, -22.1882792],
  [33.3390856, -22.1841067],
  [33.3390427, -22.1827159],
  [33.3390427, -22.1815237],
  [33.3399868, -22.1800136],
  [33.3402014, -22.1770828],
  [33.341682, -22.1760793],
  [33.3428299, -22.1756223],
  [33.3417785, -22.1724927],
  [33.3411992, -22.1717078],
  [33.3399117, -22.1704459],
  [33.3394718, -22.1683893],
  [33.340137, -22.1674255],
  [33.3395898, -22.1656072],
  [33.3392143, -22.1653091],
  [33.3379698, -22.1636001],
  [33.3374119, -22.1618116],
  [33.3381844, -22.1603013],
  [33.3382702, -22.1572012],
  [33.3376694, -22.1548164],
  [33.3383989, -22.1543792],
  [33.339386, -22.1538624],
  [33.340416, -22.1515571],
  [33.3427334, -22.1516366],
  [33.3467674, -22.1527495],
  [33.3483982, -22.1520341],
  [33.3497715, -22.1521136],
  [33.3516598, -22.1517956],
  [33.354578, -22.1516366],
  [33.3611012, -22.1480592],
  [33.3613586, -22.1461513],
  [33.3605003, -22.1435278],
  [33.3628178, -22.1401888],
  [33.3632469, -22.1381217],
  [33.361187, -22.1335105],
  [33.3626461, -22.1304892],
  [33.3630753, -22.128263],
  [33.3616161, -22.1264343],
  [33.3620453, -22.124685],
  [33.3612728, -22.1222997],
  [33.3592987, -22.1206299],
  [33.359127, -22.1184036],
  [33.3578396, -22.1166542],
  [33.3582687, -22.1142688],
  [33.3598137, -22.1094977],
  [33.359127, -22.1075892],
  [33.3562088, -22.1070326],
  [33.3553505, -22.1053626],
  [33.3565521, -22.1037722],
  [33.3554363, -22.101466],
  [33.3529472, -22.0994779],
  [33.3526897, -22.0958197],
  [33.3515739, -22.0935929],
  [33.349514, -22.0918433],
  [33.3473682, -22.0889007],
  [33.3430767, -22.0872305],
  [33.3350945, -22.086992],
  [33.3308029, -22.0831744],
  [33.3296013, -22.0812655],
  [33.3283997, -22.0786409],
  [33.3290863, -22.0749026],
  [33.3314037, -22.0711642],
  [33.3305454, -22.0681416],
  [33.3312321, -22.0664712],
  [33.3290005, -22.0628917],
  [33.3265972, -22.0617781],
  [33.3251381, -22.0594712],
  [33.3265114, -22.0531868],
  [33.3253098, -22.0492888],
  [33.3245373, -22.0414128],
  [33.3262539, -22.0370371],
  [33.3285713, -22.0356845],
  [33.3307171, -22.0319451],
  [33.3346653, -22.0275691],
  [33.3365536, -22.0219994],
  [33.3374119, -22.0181005],
  [33.3402443, -22.0098249],
  [33.3407593, -22.0049708],
  [33.3423042, -22.0020263],
  [33.2902908, -21.9802198],
  [33.2781029, -21.9701113],
  [33.2538986, -21.9500513],
  [33.2525253, -21.9473446],
  [33.2499504, -21.9420902],
  [33.2477188, -21.9393037],
  [33.2403374, -21.9275202],
  [33.2214546, -21.8989332],
  [33.1957054, -21.8607818],
  [33.1957054, -21.8598259],
  [33.1946754, -21.8571175],
  [33.181715, -21.8259667],
  [33.1683254, -21.7931356],
  [33.1671238, -21.7908243],
  [33.166523, -21.787477],
  [33.1678963, -21.7848469],
  [33.1690979, -21.7820573],
  [33.1749344, -21.7695435],
  [33.1897831, -21.741483],
  [33.1719303, -21.7068781],
  [33.1612873, -21.6856646],
  [33.1743336, -21.6519238],
  [33.1854916, -21.6228829],
  [33.1869507, -21.6202498],
  [33.1876373, -21.6176167],
  [33.1890965, -21.6173773],
  [33.1922722, -21.61634],
  [33.1955338, -21.6172177],
  [33.1987095, -21.6193721],
  [33.1999111, -21.620569],
  [33.2013702, -21.6216861],
  [33.2052326, -21.6200104],
  [33.2064342, -21.6183348],
  [33.2072926, -21.6157016],
  [33.2073784, -21.6113128],
  [33.2062626, -21.6086795],
  [33.2054043, -21.6050885],
  [33.2047176, -21.6026945],
  [33.2046318, -21.600061],
  [33.2056618, -21.5960708],
  [33.2072067, -21.5927189],
  [33.210125, -21.5872121],
  [33.2116699, -21.5860149],
  [33.215189, -21.5833811],
  [33.2314968, -21.5797097],
  [33.2357025, -21.578273],
  [33.2399082, -21.573484],
  [33.241539, -21.5704509],
  [33.243084, -21.5656617],
  [33.2417965, -21.5591959],
  [33.2389641, -21.5532887],
  [33.2351875, -21.5465031],
  [33.2338142, -21.542671],
  [33.2329559, -21.542192],
  [33.2329559, -21.5401163],
  [33.2338142, -21.5371623],
  [33.2353592, -21.5335696],
  [33.2374191, -21.5306155],
  [33.2406807, -21.5301364],
  [33.2453156, -21.5302163],
  [33.2561302, -21.5295775],
  [33.2610226, -21.527821],
  [33.2624817, -21.5272621],
  [33.2635975, -21.5261443],
  [33.2647991, -21.5256652],
  [33.2665157, -21.5251861],
  [33.2671165, -21.5264636],
  [33.2665157, -21.5286194],
  [33.2664299, -21.5306155],
  [33.2674599, -21.5319728],
  [33.2708931, -21.5316534],
  [33.2733822, -21.530855],
  [33.2749271, -21.5297372],
  [33.2762146, -21.5279807],
  [33.2778454, -21.526304],
  [33.2801628, -21.5235892],
  [33.283596, -21.5176007],
  [33.2885742, -21.5128097],
  [33.2916641, -21.5092163],
  [33.2971466, -21.5023189],
  [33.2956928, -21.5018946],
  [33.2938635, -21.5006319],
  [33.29072, -21.4985555],
  [33.2847548, -21.497717],
  [33.2742405, -21.5011509],
  [33.2714081, -21.4998732],
  [33.2669449, -21.4946025],
  [33.2607651, -21.4853382],
  [33.253212, -21.4767124],
  [33.2460022, -21.4741565],
  [33.2305527, -21.475754],
  [33.2243729, -21.4747955],
  [33.2089233, -21.4779903],
  [33.2054901, -21.4834214],
  [33.1852341, -21.4888523],
  [33.1752777, -21.5022689],
  [33.1591415, -21.5057826],
  [33.1474686, -21.5169619],
  [33.1323624, -21.5191977],
  [33.122406, -21.5246272],
  [33.1141663, -21.5389986],
  [33.1086731, -21.5351664],
  [33.0997467, -21.5409147],
  [33.0939102, -21.5504946],
  [33.0794907, -21.567737],
  [33.0698776, -21.5757189],
  [33.0657578, -21.5824234],
  [33.0413818, -21.5856159],
  [33.0331421, -21.5747611],
  [33.0231857, -21.5763575],
  [32.9802704, -21.5718877],
  [32.959671, -21.5565616],
  [32.9534912, -21.5543265],
  [32.9565811, -21.5370825],
  [32.9558945, -21.5290985],
  [32.9610443, -21.5195171],
  [32.9555511, -21.5134485],
  [32.9445648, -21.5067409],
  [32.9363251, -21.4968386],
  [32.9188156, -21.4946025],
  [32.9201889, -21.4878939],
  [32.9129791, -21.4840604],
  [32.9085159, -21.4805462],
  [32.9037094, -21.4799072],
  [32.8995895, -21.4834214],
  [32.8755569, -21.5029078],
  [32.8721237, -21.5041855],
  [32.863884, -21.5038661],
  [32.8525543, -21.4965191],
  [32.8484344, -21.4977969],
  [32.8456879, -21.5064215],
  [32.8388214, -21.5054632],
  [32.8360748, -21.5006718],
  [32.8271484, -21.5009912],
  [32.8195953, -21.5016301],
  [32.8116989, -21.4961997],
  [32.8106689, -21.4866161],
  [32.8024292, -21.4882134],
  [32.7952194, -21.4840604],
  [32.7880096, -21.4834214],
  [32.7814865, -21.4763929],
  [32.77668, -21.4616959],
  [32.7684402, -21.4524296],
  [32.7622604, -21.4533882],
  [32.7505875, -21.4476364],
  [32.7399445, -21.4524296],
  [32.7351379, -21.442204],
  [32.722435, -21.4374105],
  [32.7076721, -21.4211115],
  [32.6963425, -21.4118426],
  [32.6891327, -21.41408],
  [32.683661, -21.4086463],
  [32.6829314, -21.4079271],
  [32.6790905, -21.4058095],
  [32.6778245, -21.4056097],
  [32.6766658, -21.4060892],
  [32.6734686, -21.4083666],
  [32.6716232, -21.4088461],
  [32.6698637, -21.408906],
  [32.6645851, -21.4064488],
  [32.6651859, -21.4034122],
  [32.6650786, -21.4023933],
  [32.6623106, -21.3991768],
  [32.6611948, -21.3967195],
  [32.6600146, -21.3940423],
  [32.6590705, -21.393343],
  [32.6548862, -21.3915649],
  [32.652483, -21.3915649],
  [32.6485562, -21.3919245],
  [32.646389, -21.3926238],
  [32.6449299, -21.3946017],
  [32.64184, -21.3966396],
  [32.6392865, -21.3967994],
  [32.6360893, -21.3960002],
  [32.6326561, -21.395261],
  [32.6309609, -21.3949414],
  [32.6292014, -21.3951412],
  [32.6279998, -21.3952011],
  [32.6214981, -21.3983977],
  [32.6172066, -21.3979182],
  [32.6133442, -21.3960802],
  [32.6100397, -21.3936427],
  [32.6080656, -21.3923441],
  [32.6061773, -21.3905259],
  [32.6054907, -21.3900864],
  [32.6020575, -21.3886079],
  [32.601521, -21.3886478],
  [32.5999331, -21.3896269],
  [32.5968218, -21.3912252],
  [32.5925946, -21.3927636],
  [32.5916719, -21.3929834],
  [32.5907707, -21.3935228],
  [32.5892043, -21.3949813],
  [32.5877881, -21.3952211],
  [32.5814366, -21.3937626],
  [32.5780463, -21.3933031],
  [32.5747633, -21.3932831],
  [32.5729609, -21.3926038],
  [32.5719309, -21.3917047],
  [32.570622, -21.3899266],
  [32.5661373, -21.3856508],
  [32.5649786, -21.3849915],
  [32.5635195, -21.3847717],
  [32.5624895, -21.3848516],
  [32.5598073, -21.3857308],
  [32.5575757, -21.3859505],
  [32.5560737, -21.3865499],
  [32.5548077, -21.3877288],
  [32.553606, -21.3878886],
  [32.5506234, -21.3868896],
  [32.5481558, -21.3853112],
  [32.5438643, -21.379297],
  [32.5421262, -21.378258],
  [32.5410104, -21.3767394],
  [32.5403237, -21.3732426],
  [32.5400448, -21.3697257],
  [32.5394654, -21.3689464],
  [32.5386715, -21.3683669],
  [32.5370622, -21.3672679],
  [32.5339079, -21.3660289],
  [32.5317621, -21.3663486],
  [32.5300455, -21.365909],
  [32.5263119, -21.3645702],
  [32.5254536, -21.3639107],
  [32.5223422, -21.3601738],
  [32.5204325, -21.3581155],
  [32.5170851, -21.3546182],
  [32.5157976, -21.3526797],
  [32.5118923, -21.3492223],
  [32.5076437, -21.3484229],
  [32.5044894, -21.3468041],
  [32.5031376, -21.3448655],
  [32.4993825, -21.3419075],
  [32.4946189, -21.3406484],
  [32.490027, -21.339789],
  [32.4884391, -21.3391694],
  [32.4868941, -21.3383699],
  [32.4838257, -21.3361314],
  [32.4830532, -21.335252],
  [32.4827099, -21.3328335],
  [32.4821305, -21.3315943],
  [32.4813795, -21.3306149],
  [32.4806714, -21.3302951],
  [32.4799848, -21.3299953],
  [32.4794698, -21.3296355],
  [32.4790621, -21.3285761],
  [32.4765515, -21.3279965],
  [32.4750066, -21.3272169],
  [32.4734402, -21.3257178],
  [32.4712729, -21.325398],
  [32.4693847, -21.3233991],
  [32.4659729, -21.3201609],
  [32.4649858, -21.3188416],
  [32.4650288, -21.3171725],
  [32.4650931, -21.3163429],
  [32.4650502, -21.3158232],
  [32.4648893, -21.3153434],
  [32.4646103, -21.3148937],
  [32.4642456, -21.3145239],
  [32.4626684, -21.3135044],
  [32.4613059, -21.3124349],
  [32.460115, -21.3118452],
  [32.4588811, -21.3114754],
  [32.4567354, -21.3111655],
  [32.4549007, -21.3109456],
  [32.4539459, -21.3109256],
  [32.4528837, -21.3107457],
  [32.4520469, -21.3104658],
  [32.4512744, -21.3104758],
  [32.4500191, -21.3112655],
  [32.4493968, -21.3114754],
  [32.4479914, -21.3118152],
  [32.4475944, -21.3118452],
  [32.4444723, -21.3125448],
  [32.4417579, -21.3130546],
  [32.4358356, -21.3144839],
  [32.4337113, -21.3151635],
  [32.4326706, -21.3156933],
  [32.4302888, -21.3164929],
  [32.427671, -21.3176023],
  [32.4268556, -21.3178422],
  [32.425096, -21.3178522],
  [32.4219954, -21.3174524],
  [32.4211049, -21.3175323],
  [32.4190235, -21.318082],
  [32.4157834, -21.3183119],
  [32.4123394, -21.3175223],
  [32.4107516, -21.3167927],
  [32.4091852, -21.3168227],
  [32.4055997, -21.3159275],
  [32.4052048, -21.3165628],
  [32.399025, -21.3303151],
  [32.2761154, -21.4597787],
  [31.6814804, -22.0729141],
  [31.5032959, -22.2551016],
  [31.3762665, -22.3824603],
  [31.3076019, -22.4239617],
  [31.5638065, -23.1985152],
  [31.5612316, -23.2284904],
  [31.5592575, -23.2310143],
  [31.5505886, -23.4102478],
  [31.56106, -23.4819049],
  [31.6625118, -23.5860926],
  [31.6897202, -23.6257314],
  [31.697402, -23.7219079],
  [31.7677832, -23.8849744],
  [31.8806076, -23.9526851],
  [31.8808651, -23.9601366],
  [31.8823242, -23.9650778],
  [31.88447, -23.9841353],
  [31.9080305, -24.1818755],
  [31.9517183, -24.2515133],
  [31.9728434, -24.2462211],
  [31.9764805, -24.2451646],
  [31.9824135, -24.2405667],
  [31.9991827, -24.233699],
  [32.0130444, -24.2289246],
  [32.0317984, -24.2171444],
  [32.041111, -24.2130739],
  [32.0493078, -24.2111169],
  [32.0543718, -24.2101384],
  [32.0578051, -24.2110777],
  [32.0668173, -24.2076725],
  [32.069478, -24.2059502],
  [32.074542, -24.2057154],
  [32.0968151, -24.2090424],
  [32.1073723, -24.2120954],
  [32.1160841, -24.2115866],
  [32.1179295, -24.2122911],
  [32.118144, -24.2132696],
  [32.1169853, -24.2137001],
  [32.1176291, -24.2146395],
  [32.117157, -24.2156571],
  [32.1508026, -24.218162],
  [32.1940613, -24.2194144],
  [32.2263336, -24.2432081],
  [32.2386932, -24.2381992],
  [32.2846985, -24.2569813],
  [32.3368835, -24.2457124],
  [32.4089813, -24.2601114],
  [32.4323273, -24.2832716],
  [32.440567, -24.2776384],
  [32.4563599, -24.3083048],
  [32.537384, -24.3489734],
  [32.5634766, -24.3358357],
  [32.6115417, -24.3814988],
  [32.6527405, -24.380248],
  [32.7220917, -24.409639],
  [32.7289581, -24.4802746],
  [32.7179718, -24.5165147],
  [32.7529907, -24.5621124],
  [32.8340149, -24.6775937],
  [32.8429413, -24.701301],
  [32.8401947, -24.7237564],
  [32.874527, -24.753067],
  [32.8690338, -24.7836176],
  [32.8882599, -24.7630435],
  [32.9514313, -24.766161],
  [32.9067993, -24.8584034],
  [32.9212189, -24.8995164],
  [32.9370117, -24.9026305],
  [32.9425049, -24.9206905],
  [32.9150391, -24.935012],
  [32.9067993, -24.9736097],
  [32.9273987, -24.9810789],
  [32.9205322, -25.0128176],
  [32.9164124, -25.0451702],
  [32.9253387, -25.0569892],
  [32.9215622, -25.0669411],
  [32.9253387, -25.0703619],
  [32.9418182, -25.0734717],
  [32.9641342, -25.0840442],
  [32.9960632, -25.0846661],
  [32.9984665, -25.0943049],
  [32.9843903, -25.1197974],
  [32.9816437, -25.1241493],
  [32.9943466, -25.1359606],
  [33.0049896, -25.135339],
  [33.0111694, -25.1421767],
  [33.0077362, -25.1480817],
  [33.0111694, -25.151811],
  [33.0104828, -25.1580262],
  [33.0039597, -25.1592692],
  [33.002243, -25.1676592],
  [32.9970932, -25.1738737],
  [32.99263, -25.1794664],
  [32.9967499, -25.1828841],
  [32.9943466, -25.1974857],
  [32.9898834, -25.1987283],
  [32.9905701, -25.2055624],
  [33.0994034, -25.2043199],
  [33.1268692, -25.2471802],
  [33.1316757, -25.2577377],
  [33.132019, -25.2745035],
  [33.1381989, -25.2974752],
  [33.134079, -25.3306833],
  [33.1338215, -25.3826496],
];
const maputo = [
  [32.0876312, -26.0092755],
  [32.0864296, -26.0285584],
  [32.0864296, -26.0393555],
  [32.0878029, -26.0485322],
  [32.0941544, -26.0870048],
  [32.1068573, -26.1646826],
  [32.074585, -26.3124971],
  [32.0800781, -26.4097056],
  [32.1343231, -26.5307939],
  [32.1356964, -26.8436774],
  [32.1425629, -26.8436774],
  [32.1459961, -26.8522542],
  [32.1569824, -26.8602178],
  [32.1762085, -26.8651183],
  [32.1892548, -26.8645057],
  [32.1981812, -26.857155],
  [32.2077942, -26.8577676],
  [32.2064209, -26.8491912],
  [32.2119141, -26.8436774],
  [32.2057343, -26.8381634],
  [32.2215271, -26.8320364],
  [32.2338867, -26.8375507],
  [32.2503662, -26.8406141],
  [32.2572327, -26.8498038],
  [32.2654724, -26.8485785],
  [32.2750854, -26.8522542],
  [32.2840118, -26.8473533],
  [32.2881317, -26.8553172],
  [32.2949982, -26.854092],
  [32.3121643, -26.8596053],
  [32.3204041, -26.8528668],
  [32.3313904, -26.8663433],
  [32.3416901, -26.857155],
  [32.3506165, -26.8596053],
  [32.3492432, -26.8675684],
  [32.494812, -26.8663433],
  [32.7268982, -26.870631],
  [32.77771, -26.8651183],
  [32.8573608, -26.8663433],
  [32.8937531, -26.8596053],
  [32.8978729, -26.8467406],
  [32.8875732, -26.8320364],
  [32.8951263, -26.7995577],
  [32.8896332, -26.789751],
  [32.9040527, -26.7474499],
  [32.9013062, -26.733346],
  [32.9067993, -26.7118803],
  [32.8992462, -26.702066],
  [32.8992462, -26.6867295],
  [32.8951263, -26.6775266],
  [32.9067993, -26.5909832],
  [32.905426, -26.5774741],
  [32.9205322, -26.5228073],
  [32.9150391, -26.5062178],
  [32.9308319, -26.453976],
  [32.9212189, -26.4379914],
  [32.9239655, -26.4047856],
  [32.9342651, -26.3537284],
  [32.9294586, -26.3420375],
  [32.9310036, -26.3192675],
  [32.9322052, -26.3023409],
  [32.9322052, -26.2995709],
  [32.9356384, -26.2831032],
  [32.9426765, -26.2626308],
  [32.9404449, -26.259244],
  [32.9385567, -26.256319],
  [32.9375267, -26.2509306],
  [32.9406166, -26.2373816],
  [32.939415, -26.2339941],
  [32.9459381, -26.2045802],
  [32.9450798, -26.2005757],
  [32.9483414, -26.1896395],
  [32.9469681, -26.1830157],
  [32.949543, -26.1646826],
  [32.9493713, -26.1534349],
  [32.951088, -26.1415696],
  [32.9570961, -26.1252337],
  [32.9570961, -26.1196851],
  [32.9603577, -26.1115159],
  [32.9582977, -26.1095121],
  [32.9567528, -26.1082789],
  [32.9564095, -26.1039627],
  [32.9588127, -26.0956383],
  [32.9610443, -26.0885466],
  [32.960701, -26.0837672],
  [32.960701, -26.0709697],
  [32.9629326, -26.0615634],
  [32.9646492, -26.0518479],
  [32.9684258, -26.0371961],
  [32.9701424, -26.0325689],
  [32.9696274, -26.0291754],
  [32.9747772, -26.0118981],
  [32.9843903, -25.9930754],
  [32.9934883, -25.9771817],
  [32.99263, -25.9742496],
  [32.9938316, -25.9713174],
  [32.9914284, -25.9702372],
  [32.9888535, -25.9720891],
  [32.9862785, -25.9713174],
  [32.9831886, -25.9703915],
  [32.9787254, -25.9714718],
  [32.9794121, -25.9737866],
  [32.9802704, -25.9771817],
  [32.9766655, -25.9870576],
  [32.9680824, -25.9913781],
  [32.9615593, -25.9938469],
  [32.960186, -25.9963156],
  [32.9579544, -25.9998643],
  [32.9533195, -26.0021786],
  [32.9467964, -26.0063442],
  [32.9428482, -26.0052642],
  [32.9438782, -26.00403],
  [32.9426765, -26.0020243],
  [32.9359818, -25.9994014],
  [32.9430199, -26.0000186],
  [32.9457664, -25.9987842],
  [32.948513, -25.9966242],
  [32.9435349, -25.9935383],
  [32.9395866, -25.9926125],
  [32.9346085, -25.993384],
  [32.9303169, -25.9936926],
  [32.9275703, -25.9950812],
  [32.9261971, -25.9990928],
  [32.9224205, -26.0021786],
  [32.9167557, -26.0024871],
  [32.9136658, -26.0041843],
  [32.9114342, -26.014675],
  [32.9066277, -26.022851],
  [32.9030228, -26.0274786],
  [32.9009628, -26.0339571],
  [32.9033661, -26.039047],
  [32.9025078, -26.0441367],
  [32.8959846, -26.0518479],
  [32.8990746, -26.054007],
  [32.8983879, -26.057554],
  [32.9045677, -26.0623344],
  [32.9038811, -26.0698903],
  [32.9047394, -26.072049],
  [32.8923798, -26.16345],
  [32.872467, -26.2071985],
  [32.8676605, -26.2601677],
  [32.8738403, -26.2761768],
  [32.8649139, -26.2847962],
  [32.8374481, -26.2878744],
  [32.8072357, -26.2687883],
  [32.798996, -26.2466197],
  [32.7220917, -26.1844021],
  [32.7028656, -26.1868668],
  [32.6898193, -26.1954928],
  [32.6802063, -26.1911799],
  [32.6664734, -26.1283161],
  [32.6506805, -26.0981048],
  [32.6019287, -26.0271701],
  [32.6019287, -26.0271701],
  [32.5696564, -26.0135951],
  [32.5593567, -25.9765644],
  [32.5943756, -25.9808853],
  [32.6445007, -25.9209965],
  [32.6740265, -25.8907318],
  [32.700119, -25.8752876],
  [32.7018356, -25.8246166],
  [32.7141953, -25.8227624],
  [32.7231216, -25.8088547],
  [32.7299881, -25.8082366],
  [32.7334213, -25.8131817],
  [32.7368546, -25.8317242],
  [32.7351379, -25.8431573],
  [32.7389145, -25.8533534],
  [32.7409744, -25.862004],
  [32.7450943, -25.8660202],
  [32.7450943, -25.870654],
  [32.7512741, -25.874052],
  [32.7423477, -25.8508817],
  [32.7396011, -25.8391404],
  [32.7337646, -25.8048367],
  [32.7478409, -25.736818],
  [32.7742767, -25.6743301],
  [32.8031158, -25.6235733],
  [32.8247452, -25.5941616],
  [32.8693771, -25.5514244],
  [32.9263687, -25.5074325],
  [32.9716873, -25.4786122],
  [33.134079, -25.3846658],
  [33.134079, -25.3306833],
  [33.1381989, -25.2974752],
  [33.132019, -25.2745035],
  [33.1316757, -25.2577377],
  [33.1268692, -25.2471802],
  [33.0994034, -25.2043199],
  [32.9905701, -25.2055624],
  [32.9898834, -25.1987283],
  [32.9943466, -25.1974857],
  [32.9967499, -25.1828841],
  [32.99263, -25.1794664],
  [32.9970932, -25.1738737],
  [33.002243, -25.1676592],
  [33.0039597, -25.1592692],
  [33.0104828, -25.1580262],
  [33.0111694, -25.151811],
  [33.0077362, -25.1480817],
  [33.0111694, -25.1421767],
  [33.0049896, -25.135339],
  [32.9943466, -25.1359606],
  [32.9816437, -25.1241493],
  [32.9843903, -25.1197974],
  [32.9984665, -25.0943049],
  [32.9960632, -25.0846661],
  [32.9641342, -25.0840442],
  [32.9418182, -25.0734717],
  [32.9253387, -25.0703619],
  [32.9215622, -25.0669411],
  [32.9253387, -25.0569892],
  [32.9164124, -25.0451702],
  [32.9205322, -25.0128176],
  [32.9273987, -24.9810789],
  [32.9067993, -24.9736097],
  [32.9150391, -24.935012],
  [32.9425049, -24.9206905],
  [32.9370117, -24.9026305],
  [32.9212189, -24.8995164],
  [32.9067993, -24.8584034],
  [32.9514313, -24.766161],
  [32.8882599, -24.7630435],
  [32.8690338, -24.7836176],
  [32.874527, -24.753067],
  [32.8401947, -24.7237564],
  [32.8429413, -24.701301],
  [32.8340149, -24.6775937],
  [32.7529907, -24.5621124],
  [32.7179718, -24.5165147],
  [32.7289581, -24.4802746],
  [32.7220917, -24.409639],
  [32.6527405, -24.380248],
  [32.6115417, -24.3814988],
  [32.5634766, -24.3358357],
  [32.537384, -24.3489734],
  [32.4563599, -24.3083048],
  [32.440567, -24.2776384],
  [32.4323273, -24.2832716],
  [32.4089813, -24.2601114],
  [32.3368835, -24.2457124],
  [32.2846985, -24.2569813],
  [32.2386932, -24.2381992],
  [32.2263336, -24.2432081],
  [32.1940613, -24.2194144],
  [32.1508026, -24.218162],
  [32.117157, -24.2156571],
  [32.1176291, -24.2146395],
  [32.1169853, -24.2137001],
  [32.118144, -24.2132696],
  [32.1179295, -24.2122911],
  [32.1160841, -24.2115866],
  [32.1073723, -24.2120954],
  [32.0968151, -24.2090424],
  [32.074542, -24.2057154],
  [32.069478, -24.2059502],
  [32.0668173, -24.2076725],
  [32.0578051, -24.2110777],
  [32.0543718, -24.2101384],
  [32.0493078, -24.2111169],
  [32.041111, -24.2130739],
  [32.0317984, -24.2171444],
  [32.0130444, -24.2289246],
  [31.9991827, -24.233699],
  [31.9824135, -24.2405667],
  [31.9764805, -24.2451646],
  [31.9728434, -24.2462211],
  [31.9517183, -24.2515133],
  [31.9832611, -24.3058017],
  [32.0079803, -24.4577756],
  [32.0052338, -24.5920853],
  [31.996994, -24.6875763],
  [32.0059204, -24.7904748],
  [32.0165634, -24.9234927],
  [32.0210266, -24.9793672],
  [32.0306396, -25.0656972],
  [32.0338154, -25.1323086],
  [32.0282364, -25.2555641],
  [32.0148468, -25.385131],
  [32.0054054, -25.4031973],
  [31.9904709, -25.4301754],
  [31.9867373, -25.4430421],
  [31.9795275, -25.4573799],
  [31.9779396, -25.4741956],
  [31.9774246, -25.5097564],
  [31.9799137, -25.5243186],
  [31.9956207, -25.5261001],
  [32.0008564, -25.5851045],
  [32.0054913, -25.6049984],
  [32.0064354, -25.6243472],
  [32.0063496, -25.644931],
  [31.9966507, -25.6761093],
  [31.9785404, -25.6941318],
  [31.9305611, -25.8415351],
  [31.9419765, -25.8692639],
  [31.9527912, -25.8972949],
  [31.9754505, -25.9528741],
  [31.9836044, -25.9653758],
  [31.9900417, -25.9757928],
  [32.0033455, -25.997087],
  [32.0107269, -25.9941555],
  [32.0295238, -25.9999414],
  [32.0487499, -26.0028729],
  [32.0872879, -26.0085812],
  [32.0877171, -26.0085812],
  [32.0876312, -26.0092755],
];

export { caboDelgado, niassa, nampula, zambezia, tete, manica, sofala, inhambane, gaza, maputo };
