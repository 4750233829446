import React from "react";

export default function RegistUsers() {
  return (
    <div className="">
      <h1>HELLO MY BEST FRIEND!!!!!</h1>
      <h1>THIS IS THE RegistUsers</h1>
    </div>
  );
}
