import { Button, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React from "react";
import { NavLink } from "react-router-dom";

export default function CardCancelados(props) {
  const {
    imagem,
    anuncio,
    showPublicar,
    showOcultar,
    setSelectedAnuncio,
    showEliminar,
    userData,
  } = props;
  return (
    <div
      className={`card card-blog shadow border-danger-1 border ${
        anuncio.estado_pub === "Sim" && "cardio"
      }`}
    >
      {/* class="col-xl-3 col-sm-6 col-12" */}
      <div className="card-image shadow-sm ">
        <img alt="Cancelados" className="img" src={imagem} style={{ width: 40 }} />{" "}
        <div className="ripple-cont"></div>
      </div>
      <div className="table">
        <span className="category" style={{ color: "red" }}>
          <i className="fa fa-close"></i> Cancelado
        </span>

        <div className="row mb-2">
          <div className="col-12 my-2 text-mains">
            <small style={{ fontSize: 12, textDecoration: "underline" }}>
              Ref/Conc: {anuncio.ref_concurso}
            </small>
          </div>
          <div className="col-12 mt-2">
            <div className="d-flex justify-content-start">
              <small className="text-secondary">Contratante:</small>
              <small className="text-mains texto ml-2" style={{ fontSize: 12 }}>
                {anuncio?.entidade_contratante?.nome}
              </small>
            </div>
          </div>
          <div className="col-12 mt-2">
            <div className="d-flex justify-content-start">
              <small className="text-secondary">Contratada:</small>
              <small className="text-mains texto ml-2" style={{ fontSize: 12 }}>
                {anuncio?.entidade_contratada?.nome || "---"}
              </small>
            </div>
          </div>
        </div>
        <p className="card-description mt-3"> {anuncio.descricao}</p>
        <div className="d-flex justify-content-end">
          <small className="text-mains texto ml-2" style={{ fontSize: 12 }}>
            {anuncio?.provincia}{" "}
            {anuncio?.distrito ? `, ${anuncio?.distrito}` : ""}
          </small>
        </div>
        <hr />
        <div className="d-flex w-100 justify-content-between text-center align-items-center">
          {anuncio.estado_pub === "Sim" ? (
            <Button
              size="small"
              color="primary"
              className="fw-bold p-2 actions-button"
              style={{ color: "#A8585E", textTransform: "capitalize" }}
              onClick={() => {
                showOcultar();
                setSelectedAnuncio(anuncio);
              }}
            >
              Ocultar
            </Button>
          ) : (
            <Button
              size="small"
              color="primary"
              className="fw-bold p-2 actions-button"
              style={{ color: "#A8585E", textTransform: "capitalize" }}
              onClick={() => {
                showPublicar();
                setSelectedAnuncio(anuncio);
              }}
            >
              Publicar
            </Button>
          )}
          <NavLink to={`/admin/anuncios/detalhes/${anuncio.id}`}>
            <Button
              size="small"
              color="primary"
              className="fw-bold p-2 actions-button"
              style={{ color: "#A8585E", textTransform: "capitalize" }}
              //onClick={() => openModalApprove(caso.id, caso.nome, caso.d_curta)}
            >
              Detalhes
            </Button>
          </NavLink>
          {userData?.role === "Super administrador" && (
            <div className="text-end ">
              <IconButton
                size="small"
                onClick={() => {
                  showEliminar();
                  setSelectedAnuncio(anuncio);
                }}
              >
                <Delete fontSize="small" style={{ color: "#914C51" }} />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
