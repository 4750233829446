const colorsDensity = [
  { id: "1", color: "#FFEDA0", label: "0 - 500.000" },
  { id: "2", color: "#FED976", label: "500.000 - 1.000.000" },
  { id: "3", color: "#FEB24C", label: "1.000.000 - 5.000.000" },
  { id: "4", color: "#FD8D3C", label: "5.000.000 - 10.000.000" },
  { id: "5", color: "#FC4E2A", label: "10.000.000 - 30.000.000" },
  { id: "6", color: "#E31A1C", label: "30.000.000 - 50.000.000" },
  { id: "7", color: "#BD0026", label: "50.000.000 - 100.000.000" },
  { id: "8", color: "#800026", label: "100.000.000+" },
];

const getValueDensity = (value) => {
  switch (true) {
    case value >= 500000 && value < 1000000:
      return colorsDensity[1].color;
    case value >= 1000000 && value < 5000000:
      return colorsDensity[2].color;
    case value >= 5000000 && value < 10000000:
      return colorsDensity[3].color;
    case value >= 10000000 && value < 30000000:
      return colorsDensity[4].color;
    case value >= 30000000 && value < 50000000:
      return colorsDensity[5].color;
    case value >= 50000000 && value < 100000000:
      return colorsDensity[6].color;
    case value >= 100000000:
      return colorsDensity[7].color;
    default:
      return colorsDensity[0].color;
  }
};

export { getValueDensity, colorsDensity };
