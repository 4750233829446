import React from "react";
import Configuracoes from "../../components/dashboard/settings/configuracoes";
import AppbarDrawer from "../../components/dashboard/drawer";



const ConfiguracoesPage = () => {
  return (
    <div >
      <AppbarDrawer>
        <Configuracoes/>
      </AppbarDrawer>
    </div>
  );
};
export default ConfiguracoesPage;

