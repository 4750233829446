import React from "react";
import { Col, Row } from "react-bootstrap";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import exporting from "highcharts/modules/exporting";
import mapModule from "highcharts/modules/exporting";
import dataModule from "highcharts/modules/data";
import fullScreen from "highcharts/modules/full-screen";
import princeIndicator from "highcharts/modules/data";

mapModule(Highcharts);
exporting(Highcharts);
dataModule(Highcharts);
fullScreen(Highcharts);
princeIndicator(Highcharts);

/* require('highcharts/indicators/indicators')(Highcharts)
require('highcharts/indicators/pivot-points')(Highcharts)
require('highcharts/indicators/macd')(Highcharts)
require('highcharts/modules/exporting')(Highcharts) */
require("highcharts/modules/map")(Highcharts);

export default function MapHighChart({
  title,
  subtitle,
  zoneName,
  zoneData,
  data,
}) {
  const mapOptions = {
    chart: {
      map: zoneData,
    },

    title: {
      text: title,
    },

    subtitle: {
      text: subtitle,
    },

    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },

    colorAxis: {
      min: 0,
    },

    series: [
      {
        data: data,
        name: zoneName,
        states: {
          hover: {
            color: "#FF7C4A",
          },
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}",
        },
      },
    ],
  };

  return (
    <div className="">
      <HighchartsReact
        options={mapOptions}
        constructorType={"mapChart"}
        highcharts={Highcharts}
      />
    </div>
  );
}
