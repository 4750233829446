import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  createTheme,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Save } from "@material-ui/icons";
import bcc from "../../../assets/images/logo.png";
import { Card, Spinner } from "react-bootstrap";
import dateFormat from "dateformat";
import provinces from "../../utils/mozambique.json";
import { BASE_URL } from "../../api/requests/useGetData";
import axios from "axios";
import SnackBar from "../../commons/snackbar";
import { categorias } from "../../utils/resourcesFields";
import FormEdit from "./formEdit";

const today = dateFormat(new Date(), "yyyy-mm-dd");
export default function EditarAnuncios() {
  const { id } = useParams();
  let navigate = useNavigate();
  const [announce, setAnnounce] = useState(0);

  const [districts, setDistricts] = useState([]);
  const [contratantes, setContratantes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAnnounce, setLoadingAnnounce] = useState(true);
  const [loadingEntidades, setLoadingEntidades] = useState(true);
  const [fontes, setFontes] = useState([]);
  const [modalidades, setModalidades] = useState([]);
  const [regulamentos, setRegulamentos] = useState([]);
  const [garantias, setGarantias] = useState([]);

  const [entidades, setEntidades] = useState();
  const [cambio, setCambio] = useState(1);
  const [disableCambio, setDisableCambio] = useState(true);

  const fetchData = () => {
    setLoadingAnnounce(true);
    axios
      .get(`${BASE_URL}/concurso/${id}`)
      .then((response) => {
        setAnnounce(response.data.data);
        setLoadingAnnounce(false);
        console.log("concurso", response);
      })
      .catch((error) => {
        setLoadingAnnounce(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const getExchange = () => {
    axios
      .get(`http://economia.awesomeapi.com.br/json/last/USD-MZN`)
      .then((response) => {
        setCambio(response.data.USDMZN.ask);
        console.log("response", response);
      })
      .catch((error) => {
        setDisableCambio(false);
        console.log("Erro ao obter a taxa de câmbio:", error);
      });
  };

  const getEntidadesContratadas = () => {
    axios.get(`${BASE_URL}/entidade-contratada`).then((response) => {
      setEntidades(response.data.data);
    });
  };

  useEffect(() => {
    getExchange();
    getEntidadesContratadas();
  }, []);

  const getResources = () => {
    setLoading(true);
    axios.get(`${BASE_URL}/config`).then((response) => {
      setFontes(response.data.data.filter((el) => el.nome === "Fonte"));
      setGarantias(response.data.data.filter((el) => el.nome === "Garantia"));
      setRegulamentos(
        response.data.data.filter((el) => el.nome === "Regulamento")
      );
      setModalidades(
        response.data.data.filter((el) => el.nome === "Modalidade")
      );
      setLoading(false);
    });
  };

  const getEntidadesContratantes = () => {
    setLoadingEntidades(true);
    axios.get(`${BASE_URL}/entidade-contratante`).then((response) => {
      setContratantes(response.data.data);
      setLoadingEntidades(false);
    });
  };

  useEffect(() => {
    getEntidadesContratantes();
    getResources();
  }, []);

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  return (
    <div className="text-start container-fluid">
      {loading || loadingEntidades || loadingAnnounce ? (
        <div className="text-center my-5 py-5">
          <div class="my-5 py-5">
            <div class="loader"></div>
          </div>
        </div>
      ) : (
        <FormEdit fontes={fontes} modalidades={modalidades} regulamentos={regulamentos} contratantes={contratantes} garantias={garantias} entidades={entidades} announce={announce} />
      )}
    </div>
  );
}
