/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  Block,
  CallOutlined,
  CameraAlt,
  ClearAll,
  Edit,
  EmailOutlined,
  ImageOutlined,
  Language,
  MergeType,
  MoreVert,
  PersonAdd,
  PersonOutline,
  Save,
  Search,
  TextFormat,
} from "@material-ui/icons";
import nothing22 from "../../../assets/images/nothingWaw.gif";
import { Col, Row, Spinner } from "react-bootstrap";
import { useEffect } from "react";
import EntitiesCard from "../../commons/cards/entitiesCard";
import { entitieTypes } from "../../utils/resourcesFields";
import axios from "axios";
import { BASE_URL } from "../../api/requests/useGetData";
import { storage } from "../../../firebase";
import SnackBar from "../../commons/snackbar";
import Confirmacao from "../../commons/modal/confirmarOperacao";
import { useAuthUser } from "react-auth-kit";

export default function GestaoEntidades() {
  const auth = useAuthUser()()?.data;

  const [loader, setLoader] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [editing, setEditing] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [loading, setLoading] = useState(true);
  const [loadingContratantes, setLoadingContratantes] = useState(true);
  const [loadingContratadas, setLoadingContratadas] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [filter, setFilter] = useState("Contratante");
  const [entidades, setEntidades] = useState();
  const [contratantes, setContratantes] = useState(0);
  const [contratadas, setContratadas] = useState(0);

  const fetchContratantes = () => {
    setLoadingContratantes(true);
    axios
      .get(`${BASE_URL}/entidade-contratante`)
      .then((response) => {
        setContratantes(response.data.data.length);
        setLoadingContratantes(false);
      })
      .catch((error) => {
        setLoadingContratantes(false);
      });
  };
  const fetchContratadas = () => {
    setLoadingContratadas(true);
    axios
      .get(`${BASE_URL}/entidade-contratada`)
      .then((response) => {
        setContratadas(response.data.data.length);
        setLoadingContratadas(false);
      })
      .catch((error) => {
        setLoadingContratadas(false);
      });
  };

  const [nomeEnt, setNomeEnt] = useState("");
  const [siglaEnt, setSiglaEnt] = useState("");

  const getParams = () => {
    var paramss = "";
    if (nomeEnt) {
      paramss += `nome=${nomeEnt}`;
    }
    if (siglaEnt) {
      paramss += `&sigla=${siglaEnt}`;
    }
    return paramss;
  };

  const resetFilters = () => {
    setNomeEnt("");
    setSiglaEnt("");
  };

  const fetchData = () => {
    setLoading(true);
    axios
      .post(
        `${BASE_URL}/entidade-${filter.toLowerCase()}/e/filtrar-por-colunas?${getParams()}`
      )
      .then((response) => {
        setEntidades(
          response.data.data?.sort(function (a, b) {
            return a.nome.localeCompare(b.nome);
          })
        );
        setLoading(false);
        console.log("responseEntities", response);
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was an error!", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, [filter, nomeEnt, siglaEnt]);

  useEffect(() => {
    fetchContratantes();
    fetchContratadas();
  }, []);
  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [tipo, setTipo] = useState({});
  const [nome, setNome] = useState("");
  const [sigla, setSigla] = useState("");
  const [logoLink, setLogoLink] = useState("");
  const [contacto, setContacto] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");

  const [errorNome, setErrorNome] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");
  const [errorTipo, setErrorTipo] = useState(false);

  const [loadAction, setLoadAction] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState();
  const [checked, setChecked] = useState(false);
  const handleChangeChecked = (e) => setChecked(e.target.checked);

  const [showEliminar, setShowEliminar] = useState(false);
  const closeEliminar = () => {
    setShowEliminar(false);
    setChecked(false);
  };

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (errorTipo === false && errorNome === false && errorEmail === false) {
      handleUpload();
    } else {
      console.log("Não posso salvar");
    }
  };

  const requiredFields = () => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
    } else {
      setErrorNome(false);
    }
    if (email && !email?.match(mailformat)) {
      setErrorEmail(true);
      setErrorEmailMessage("Endereço de email inválido!");
    } else {
      setErrorEmail(false);
      setErrorEmailMessage(true);
    }
    if (tipo === "" || tipo === undefined) {
      setErrorTipo(true);
    } else {
      setErrorTipo(false);
    }
  };

  const usersStatiscs = [
    {
      id: 1,
      type: "Entidades Contratantes",
      value: () =>
        loadingContratantes ? <CircularProgress size={20} /> : contratantes,
      filter: "Contratante",
      icon: "ic:outline-business-center",
    },
    {
      id: 2,
      type: "Entidades Contratadas",
      value: () =>
        loadingContratadas ? <CircularProgress size={20} /> : contratadas,
      filter: "Contratada",
      icon: "mdi:business-outline",
    },
  ];
  const cleanFields = () => {
    if (editing) setShowForm(false);
    setTipo("");
    setNome("");
    setSigla("");
    setLogoLink("");
    setContacto("");
    setEmail("");
    setWebsite("");
    setImage1("Seleccionar logotipo da entidade");

    setErrorTipo(false);
    setErrorNome(false);
    setErrorEmail(false);
    setErrorEmailMessage("");

    setEditing(false);
  };

  const startEditing = (ent) => {
    setShowForm(true);
    setEditing(true);
    setSelectedEntity(ent);
    setTipo(filter);
    setNome(ent.nome);
    setSigla(ent.sigla);
    setLogoLink(ent.logo);
    setContacto(ent.telefone);
    setEmail(ent.email);
    setWebsite(ent.website);
    setImage1("Editar imagem");
  };

  const salvar = (link) => {
    setLoader(true);
    const dados = {
      nome: nome,
      sigla: sigla,
      logo: link,
      telefone: contacto,
      email: email,
      website: website,
    };
    axios
      .post(
        `${BASE_URL}/entidade-${
          tipo === "Contratante" ? "contratante" : "contratada"
        }/e/store`,
        dados
      )
      .then((response) => {
        setOpen(true);
        setmessage("Entidade registada com sucesso!");
        setColor("success");
        setLoader(false);
        cleanFields();
        fetchData();
        fetchContratantes();
        fetchContratadas();
        console.log("saved?", response);
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const [image, setImage] = useState(null);
  const [, setProgress] = useState(0);
  const [image1, setImage1] = useState("Seleccionar logotipo da entidade");

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setImage1(e.target.files[0].name);
    }
  };

  const handleUpload = () => {
    setLoader(true);
    if (image) {
      console.log("Com imagem");
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              setImage1(image.name);
              if (editing) {
                editar(url);
              } else {
                salvar(url);
              }
            });
        }
      );
    } else {
      if (editing) {
        editar(logoLink);
      } else {
        salvar("Sem imagem");
      }
    }
  };

  const eliminar = () => {
    setLoadAction(true);
    axios
      .delete(
        `${BASE_URL}/entidade-${
          filter === "Contratante" ? "contratante" : "contratada"
        }/e/${selectedEntity.id}`
      )
      .then((response) => {
        setOpen(true);
        setmessage("Entidade emininada com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        fetchContratantes();
        fetchContratadas();
        closeEliminar();
        console.log("saved?", response);
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const editar = (link) => {
    setLoader(true);
    const dados = {
      nome: nome,
      sigla: sigla,
      logo: link,
      telefone: contacto,
      email: email,
      website: website,
    };
    axios
      .put(
        `${BASE_URL}/entidade-${filter.toLowerCase()}/e/${selectedEntity.id}`,
        dados
      )
      .then((response) => {
        setOpen(true);
        setmessage("Dados actualizados com sucesso!");
        setColor("success");
        setLoader(false);
        cleanFields();
        fetchData();
        fetchContratantes();
        fetchContratadas();
        setShowForm(!showForm);
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  console.log("logoLink", logoLink);
  return (
    <div className="container-fluid">
      <div className="text-center wow fadeInUp mb-2" data-wow-delay="0.1s">
        <h3 className="my-4 mb-5">Gestão de Entidades</h3>
      </div>
      <Row className="d-flex justify-content-center">
        {usersStatiscs.map((card) => (
          <Col sm={3}>
            <EntitiesCard
              object={card}
              filter={filter}
              setFilter={setFilter}
              setPage={() => setPage(0)}
            />
          </Col>
        ))}
      </Row>
      <TableContainer component={"div"} className="bg-white shadow">
        {/* -------------------FORMULÁRIO DE REGISTO------------- */}
        <Collapse in={showForm} timeout="auto" unmountOnExit disablePadding>
          <div className="text-start py-3 text-secondary d-flex">
            <h4 className="px-3 text-secondary">Formulário de Registo</h4>
          </div>
          <div className="px-3">
            <form onSubmit={formSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <Autocomplete
                    id="clear-on-escape"
                    options={entitieTypes}
                    getOptionLabel={(option) => option}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(e, value) => setTipo(value)}
                    value={tipo}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label={
                          <span>
                            <MergeType
                              style={{ color: "#82B930", marginTop: "-5" }}
                            />{" "}
                            Tipo de entidade
                          </span>
                        }
                        className="rounded"
                        type={"text"}
                        error={errorTipo}
                      />
                    )}
                  />
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      required
                      size="small"
                      margin="normal"
                      fullWidth
                      name="nome"
                      label={
                        <span>
                          <PersonOutline
                            style={{ color: "#82B930", marginTop: "-5" }}
                          />{" "}
                          Nome
                        </span>
                      }
                      placeholder="Designação da entidade"
                      type={"text"}
                      id="nome"
                      className="rounded border-bottom"
                      value={nome}
                      defaultValue={nome}
                      error={errorNome}
                      onChange={(e) => setNome(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    size="small"
                    margin="normal"
                    fullWidth
                    name="sigla"
                    label={
                      <span>
                        <TextFormat
                          style={{ color: "#82B930", marginTop: "-3" }}
                        />{" "}
                        Sigla
                      </span>
                    }
                    placeholder="AAAMZ"
                    className="rounded border-bottom"
                    type={"text"}
                    id="sigla"
                    value={sigla}
                    defaultValue={sigla}
                    onChange={(e) => setSigla(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    disabled={loader}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    size="small"
                    margin="normal"
                    fullWidth
                    name="email"
                    label={
                      <span>
                        <EmailOutlined
                          style={{ color: "#82B930", marginTop: "-5" }}
                        />{" "}
                        Email
                      </span>
                    }
                    placeholder="example@gmail.com"
                    className=""
                    type={"text"}
                    id="email"
                    value={email}
                    defaultValue={email}
                    error={errorEmail}
                    helperText={errorEmailMessage}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    disabled={loader}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    size="small"
                    margin="normal"
                    fullWidth
                    name="contacto"
                    label={
                      <span>
                        <CallOutlined
                          style={{ color: "#82B930", marginTop: "-5" }}
                        />{" "}
                        Contacto
                      </span>
                    }
                    placeholder="+258 000 000 000"
                    type={"text"}
                    id="contacto"
                    className="rounded border-bottom"
                    value={contacto}
                    defaultValue={contacto}
                    //error={errorContacto}
                    onChange={(e) => setContacto(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{ style: { border: "none", paddingLeft: 10 } }}
                    disabled={loader}
                  />
                </div>

                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    size="small"
                    margin="normal"
                    fullWidth
                    name="website"
                    label={
                      <span>
                        <Language
                          style={{ color: "#82B930", marginTop: "-5" }}
                        />{" "}
                        Website
                      </span>
                    }
                    placeholder="website.mz"
                    className="rounded border-bottom"
                    type={"text"}
                    id="website"
                    value={website}
                    defaultValue={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    disabled={loader}
                  />
                </div>

                <div className="col-md-6 mt-3">
                  <div className="d-flex align-items-center">
                    {" "}
                    <input
                      className="upload-photo"
                      id="contained-button-file"
                      multiple
                      onChange={handleChangeImage}
                      type="file"
                      disabled={loader}
                    />
                    <label
                      htmlFor="contained-button-file"
                      style={{ cursor: "pointer" }}
                    >
                      <Badge
                        className="ml-2 mr-4"
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          image1 === "Seleccionar logotipo da entidade" ? (
                            <CameraAlt fontSize="small" />
                          ) : (
                            <Edit fontSize="small" />
                          )
                        }
                      >
                        <Avatar alt="Travis Howard">
                          {" "}
                          {editing ? (
                            <img
                              alt={selectedEntity?.nome}
                              src={selectedEntity?.logo}
                            />
                          ) : (
                            <ImageOutlined />
                          )}{" "}
                        </Avatar>
                      </Badge>
                    </label>
                    <div className="">
                      <small id="file-chosen" className={`text-secondary`}>
                        {image1}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3 d-flex justify-content-between align-items-center pb-2">
                  <Button
                    variant="text"
                    className="text-capitalize me-4 px-3"
                    style={{ color: "red" }}
                    onClick={() => cleanFields()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    className="text-light text-capitalize"
                    style={{ backgroundColor: "#82B930" }}
                    disabled={loader}
                    startIcon={
                      loader ? (
                        <Spinner
                          animation="border"
                          style={{ width: 24, height: 24 }}
                        />
                      ) : (
                        <Save />
                      )
                    }
                    onClick={() => requiredFields()}
                  >
                    {editing ? "Salvar alterações" : "Salvar"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Collapse>

        {/* -------------------TABELA DE LISTAGEM------------- */}
        {loading && <LinearProgress className="w-100" />}

        {/* --------------------------- */}
        <div className="row w-100 pt-3 border-top">
          <div className="col-6 text-start p-3 text-secondary d-flex">
            <h4 className="px-3 text-secondary">Lista</h4>
          </div>

          <div className="col-6 d-flex justify-content-end my-auto p-0">
            <Button
              value="default"
              size="small"
              variant="contained"
              className="text-light text-capitalize"
              onClick={() => setShowForm(!showForm)}
              style={{ backgroundColor: showForm ? "#D5D5D5" : "#82B930" }}
              startIcon={showForm ? <Block /> : <PersonAdd />}
            >
              {showForm ? "Ocultar formulário" : "Adicionar entidade"}
            </Button>
          </div>
        </div>
        {/* -------------------------- */}
        <div className="row w-100 px-3">
          <div className="col-md-12 d-flex justify-content-start align-items-center">
            <TextField
              fullWidth
              variant="outlined"
              size={"small"}
              name="searchDescricao"
              label={
                <span className="" style={{ fontSize: 13 }}>
                  Nome da entidade...
                </span>
              }
              style={{ width: "23%" }}
              type={"text"}
              id="standard-start-adornment"
              value={nomeEnt}
              onChange={(e) => setNomeEnt(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search style={{ color: "#82B930" }} size="small" />
                  </InputAdornment>
                ),
                disableUnderline: true,
                style: {
                  borderRadius: 10,
                  paddingLeft: 1,
                },
              }}
            />
            <TextField
              className="ml-3"
              fullWidth
              variant="outlined"
              size={"small"}
              name="searchDescricao"
              label={
                <span className="" style={{ fontSize: 13 }}>
                  Sigla...
                </span>
              }
              style={{ width: "23%" }}
              type={"text"}
              id="standard-start-adornment"
              value={siglaEnt}
              onChange={(e) => setSiglaEnt(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search style={{ color: "#82B930" }} size="small" />
                  </InputAdornment>
                ),
                disableUnderline: true,
                style: {
                  borderRadius: 10,
                  paddingLeft: 1,
                },
              }}
            />
            {getParams() && (
              <Button
                className="text-capitalize ml-3"
                style={{ color: "#2075A9", backgroundColor: "#F5F5F5" }}
                startIcon={<ClearAll />}
                onClick={() => resetFilters()}
              >
                Limpar filtros
              </Button>
            )}
          </div>
        </div>

        <Table className={"classes.table"} aria-label="simple table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell className="">ID</TableCell>
              <TableCell className="">Nome</TableCell>
              <TableCell className="">Contacto</TableCell>
              <TableCell className="">Email</TableCell>
              <TableCell className="">Website</TableCell>
              <TableCell className="" align="right">
                Acções
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entidades
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((d, index) => (
                <>
                  <TableRow key={d.id}>
                    <TableCell component="th" scope="row">
                      {d.id}
                    </TableCell>
                    <TableCell>{d.nome}</TableCell>
                    <TableCell>{d.telefone}</TableCell>
                    <TableCell>{d.email}</TableCell>
                    <TableCell>
                      <a
                        href={`https://${d.website}`}
                        target="_blank"
                        className="entity-link"
                        rel="noreferrer"
                      >
                        {d.website}
                      </a>
                    </TableCell>
                    <TableCell align="right" className="ms-4">
                      <div className="text-end dropdownrounded">
                        <IconButton
                          id="dropdownMenu2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          size="small"
                        >
                          <MoreVert style={{ color: "#82B930" }} />
                        </IconButton>
                        <ul
                          className="dropdown-menu shadow detalhes-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          <li
                            className={`dropdown-item ${
                              auth?.role === "Super administrador" &&
                              "border-bottom"
                            }`}
                            onClick={() => startEditing(d)}
                          >
                            Editar
                          </li>
                          {auth?.role === "Super administrador" && (
                            <li
                              className="dropdown-item"
                              onClick={() => {
                                setShowEliminar(true);
                                setSelectedEntity(d);
                              }}
                            >
                              Eliminar
                            </li>
                          )}
                        </ul>
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>

        {entidades?.length === 0 && !loading && (
          <div className="p-3 text-center d-inline-block w-100 align-items-center border-bottom justify-content-center">
            <div>
              <img
                src={nothing22}
                alt=""
                title="Nada para mostrar"
                style={{ width: "170px" }}
              />
            </div>
            <span className="text-secondary">Nenhuma entidade encontrada!</span>{" "}
          </div>
        )}

        <div>
          <TablePagination
            rowsPerPageOptions={[5, 25, 100]}
            component="div"
            count={entidades?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Linhas por página"}
          />
        </div>
      </TableContainer>

      <Confirmacao
        show={showEliminar}
        closeModal={closeEliminar}
        action={eliminar}
        actionType={"Eliminar"}
        objectType={`a entidade`}
        objectDescription={selectedEntity?.nome}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
}
