import React from "react";
import AppbarDrawer from "../../../components/dashboard/drawer";
import RegistarAnuncios from "../../../components/dashboard/announces/registo";



const RegistarAnunciosPage = () => {
  return (
    <div >
      <AppbarDrawer>
        <RegistarAnuncios/>
      </AppbarDrawer>
    </div>
  );
};
export default RegistarAnunciosPage;

