import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Spinner } from "react-bootstrap";
import { getCategory } from "../../utils/getAnnouncementLabels.js";
import { ChevronRightRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const CancelarAnuncio = (props) => {
  const {
    anuncio,
    show,
    closeModal,
    initialDadosCancelamento,
    dadosCancelamento,
    setDadosCancelamento,
  } = props;
  const classes = useStyles();
  const [loader, setLoader] = useState(false);

  /* --------------------DADOS DO FORMULÁRIO---------------------- */

  const dados = {
    fundamento_cancelamento: initialDadosCancelamento.motivoCancelamento,
    estado: "Cancelado",
  };

  const cleanFields = () => {
    setDadosCancelamento(initialDadosCancelamento);
    closeModal();
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" className="fw-bold text-center">
          Dados de Cancelamento do Concurso
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form>
          <Divider />
          <DialogContent>
            <div className="row">
              <div className="col-md-12">
                <label className="my-2">
                  Motivo de cancelamento do concurso:
                </label>
                <TextField
                  variant="outlined"
                  floatingLabelStyle={{ color: "#568C3E" }}
                  size="small"
                  margin="normal"
                  fullWidth
                  name="motivoCancelamento"
                  type={"text"}
                  id="motivoCancelamento"
                  multiline
                  rows={4}
                  className="rounded my-0"
                  value={dadosCancelamento.motivoCancelamento}
                  defaultValue={dadosCancelamento.motivoCancelamento}
                  onChange={(e) =>
                    setDadosCancelamento({
                      ...dadosCancelamento,
                      motivoCancelamento: e.target.value,
                    })
                  }
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{ style: { border: "none", paddingLeft: 1 } }}
                  disabled={loader}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions className="px-4 mt-1">
            <Button
              className="text-capitalize mr-3"
              style={{ width: 100 }}
              onClick={() => cleanFields()}
              color="secondary"
              disabled={loader}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              className="text-capitalize"
              style={{ color: "white", width: 100, backgroundColor: "#568C3E" }}
              onClick={() => closeModal()}
            >
              Continuar <ChevronRightRounded />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
