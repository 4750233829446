import React from "react";
import AppbarDrawer from "../../../components/dashboard/drawer";
import UserProfile from "../../../components/dashboard/users/profile";



const ProfilePage = () => {
  return (
    <div >
      <AppbarDrawer>
        <UserProfile/>
      </AppbarDrawer>
    </div>
  );
};
export default ProfilePage;

