import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Spinner } from "react-bootstrap";
import SnackBar from "../snackbar.js";
import { entitieTypes } from "../../utils/resourcesFields.js";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import { BASE_URL } from "../../api/requests/useGetData";
import { storage } from "../../../firebase/index.js";
import {
  CallOutlined,
  CameraAlt,
  Edit,
  EmailOutlined,
  ImageOutlined,
  Language,
  MergeType,
  PersonOutline,
  TextFormat,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const AdicionarEntidade = (props) => {
  const { show, closeModal, entityType, fetchEntities, isRegist, dadosAdjudicacao, setEntity } = props;
  const classes = useStyles();
  const [loader, setLoader] = useState(false);

  console.log("entityType", entityType);
  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [tipo, setTipo] = useState(entityType);
  const [nome, setNome] = useState("");
  const [sigla, setSigla] = useState("");
  const [contacto, setContacto] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");

  const [errorNome, setErrorNome] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");
  const [errorTipo, setErrorTipo] = useState(false);

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (errorTipo === false && errorNome === false && errorEmail === false) {
      handleUpload();
    } else {
      console.log("Não posso salvar");
    }
  };

  const requiredFields = () => {
    const mailformat = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
    } else {
      setErrorNome(false);
    }
    if (email && !email?.match(mailformat)) {
      setErrorEmail(true);
      setErrorEmailMessage("Endereço de email inválido!");
    } else {
      setErrorEmail(false);
      setErrorEmailMessage(true);
    }
    if (tipo === "" || tipo === undefined) {
      setErrorTipo(true);
    } else {
      setErrorTipo(false);
    }
  };

  const cleanFields = () => {
    setNome("");
    setSigla("");
    setContacto("");
    setEmail("");
    setWebsite("");
    setImage1("Seleccionar logotipo da entidade");

    setErrorTipo(false);
    setErrorNome(false);
    setErrorEmail(false);
    setErrorEmailMessage("");
  };

  const salvar = (link) => {
    setLoader(true);
    const dados = {
      nome: nome,
      sigla: sigla,
      logo: link,
      telefone: contacto,
      email: email,
      website: website,
    };
    axios
      .post(
        `${BASE_URL}/entidade-${
          tipo === "Contratante" ? "contratante" : "contratada"
        }/e/store`,
        dados
      )
      .then((response) => {
        setOpen(true);
        setmessage("Entidade registada com sucesso!");
        setColor("success");
        setLoader(false);
        cleanFields();

        if(isRegist) setEntity({...dadosAdjudicacao, entidadeContrada: response.data.data});
        else setEntity(response.data.data);
    
        closeModal();
        fetchEntities();
        console.log("saved?", response.data.data);
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const [image, setImage] = useState(null);
  const [, setProgress] = useState(0);
  const [image1, setImage1] = useState("Seleccionar logotipo da entidade");

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setImage1(e.target.files[0].name);
    }
  };

  const handleUpload = () => {
    setLoader(true);
    if (image) {
      console.log("Com imagem");
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              setImage1(image.name);
              salvar(url);
            });
        }
      );
    } else {
      salvar("Sem imagem");
    }
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" className="fw-bold text-center">
          Adicionar nova Entidade
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={formSubmit}>
          <Divider />
          <DialogContent>
            <div className="row">
              <div className="col-md-6">
                <Autocomplete
                  id="clear-on-escape"
                  options={entitieTypes}
                  getOptionLabel={(option) => option}
                  noOptionsText="Nenhuma opção encontrada"
                  onChange={(e, value) => setTipo(value)}
                  value={tipo}
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      margin="normal"
                      size="small"
                      variant="outlined"
                      label={
                        <span>
                          <MergeType
                            style={{ color: "#568C3E", marginTop: "-5" }}
                          />{" "}
                          Tipo de entidade
                        </span>
                      }
                      className="rounded"
                      type={"text"}
                      error={errorTipo}
                    />
                  )}
                />
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <div className="form-floating">
                  <TextField
                    variant="outlined"
                    required
                    size="small"
                    margin="normal"
                    fullWidth
                    name="nome"
                    label={
                      <span>
                        <PersonOutline
                          style={{ color: "#568C3E", marginTop: "-5" }}
                        />{" "}
                        Nome
                      </span>
                    }
                    placeholder="Designação da entidade"
                    type={"text"}
                    id="nome"
                    className="rounded border-bottom"
                    value={nome}
                    defaultValue={nome}
                    error={errorNome}
                    onChange={(e) => setNome(e.target.value)}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={loader}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  name="sigla"
                  label={
                    <span>
                      <TextFormat
                        style={{ color: "#568C3E", marginTop: "-3" }}
                      />{" "}
                      Sigla
                    </span>
                  }
                  placeholder="AAAMZ"
                  className="rounded border-bottom"
                  type={"text"}
                  id="sigla"
                  value={sigla}
                  defaultValue={sigla}
                  onChange={(e) => setSigla(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    style: { border: "none", paddingLeft: 10 },
                  }}
                  disabled={loader}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  name="email"
                  label={
                    <span>
                      <EmailOutlined
                        style={{ color: "#568C3E", marginTop: "-5" }}
                      />{" "}
                      Email
                    </span>
                  }
                  placeholder="example@gmail.com"
                  className=""
                  type={"text"}
                  id="email"
                  value={email}
                  defaultValue={email}
                  error={errorEmail}
                  helperText={errorEmailMessage}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    style: { border: "none", paddingLeft: 10 },
                  }}
                  disabled={loader}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  name="contacto"
                  label={
                    <span>
                      <CallOutlined
                        style={{ color: "#568C3E", marginTop: "-5" }}
                      />{" "}
                      Contacto
                    </span>
                  }
                  placeholder="+258 000 000 000"
                  type={"text"}
                  id="contacto"
                  className="rounded border-bottom"
                  value={contacto}
                  defaultValue={contacto}
                  //error={errorContacto}
                  onChange={(e) => setContacto(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{ style: { border: "none", paddingLeft: 10 } }}
                  disabled={loader}
                />
              </div>

              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  required
                  size="small"
                  margin="normal"
                  fullWidth
                  name="website"
                  label={
                    <span>
                      <Language style={{ color: "#568C3E", marginTop: "-5" }} />{" "}
                      Website
                    </span>
                  }
                  placeholder="website.mz"
                  className="rounded border-bottom"
                  type={"text"}
                  id="website"
                  value={website}
                  defaultValue={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{
                    style: { border: "none", paddingLeft: 10 },
                  }}
                  disabled={loader}
                />
              </div>

              <div className="col-md-6 mt-3">
                <div className="d-flex align-items-center">
                  {" "}
                  <input
                    className="upload-photo"
                    id="contained-button-file"
                    multiple
                    onChange={handleChangeImage}
                    type="file"
                    disabled={loader}
                  />
                  <label
                    htmlFor="contained-button-file"
                    style={{ cursor: "pointer" }}
                  >
                    <Badge
                      className="ml-2 mr-4"
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={
                        image1 === "Seleccionar logotipo da entidade" ? (
                          <CameraAlt fontSize="small" />
                        ) : (
                          <Edit fontSize="small" />
                        )
                      }
                    >
                      <Avatar alt="Travis Howard">
                        {" "}
                        <ImageOutlined />
                      </Avatar>
                    </Badge>
                  </label>
                  <div className="">
                    <small id="file-chosen" className={`text-secondary`}>
                      {image1}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="px-4">
            <Button
              className="text-capitalize mr-3"
              style={{ width: 100 }}
              onClick={() => {
                cleanFields();
                closeModal();
              }}
              color="secondary"
              disabled={loader}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              className="text-capitalize"
              style={{ color: "white", width: 100, backgroundColor: "#568C3E" }}
              onClick={() => requiredFields()}
              disabled={loader}
            >
              {loader ? (
                <Spinner animation="border" style={{ width: 24, height: 24 }} />
              ) : (
                "Salvar"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </>
  );
};
