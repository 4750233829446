import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/map")(Highcharts);

export default function BarHighChart({ title, subtitle, data }) {
  const chartOptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: title,
    },
    xAxis: {
      categories: ["Apples", "Bananas", "Oranges"],
    },
    yAxis: {
      title: {
        text: "Fruit eaten",
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
      series: {
        //stacking: true,
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: data,
  };

  return (
    <div className="">
      <HighchartsReact options={chartOptions} highcharts={Highcharts} />
    </div>
  );
}
