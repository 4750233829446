import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import HomeCard from "../commons/cards/homeCard";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../api/requests/useGetData";
import { CircularProgress } from "@material-ui/core";

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState();

  const fetchData = () => {
    axios
      .get(`${BASE_URL}/concurso/dashboard/contarEstadosPorCategoria`)
      .then((response) => {
        setDashboardData(response.data);
        setLoading(false);
        console.log("DashboardData", response);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const data = [
    {
      title: "Obras",
      content: [
        {
          type: "Lançadas",
          value: loading ? <CircularProgress size={20} style={{color: "#FFC107"}}/> : dashboardData?.Obras?.["Lançado"],
          color: "rgb(244, 231, 230)",
          borderClass: "border-warning",
          textColor: "text-warning",
          gradientClass: "bg-gradient-blooker",
          icon: "solar:graph-new-up-outline",
          route: "/admin/ver-anuncios/obras/lancados",
        },
        {
          type: "Adjudicadas",
          value: loading ? <CircularProgress size={20} style={{color: "#15CA20"}}/> : dashboardData?.Obras?.["Adjudicado"],
          color: "rgb(172, 205, 179, 0.5)",
          borderClass: "border-success",
          textColor: "text-success",
          gradientClass: "bg-gradient-ohhappiness",
          icon: "icon-park-outline:switching-done",
          route: "/admin/ver-anuncios/obras/adjudicados",
        },

        {
          type: "Canceladas",
          value: loading ? <CircularProgress size={20} style={{color: "#FD3550"}}/> : dashboardData?.Obras?.["Cancelado"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-danger",
          textColor: "text-danger",
          gradientClass: "bg-gradient-bloody",
          icon: "ic:outline-close",
          route: "/admin/ver-anuncios/obras/cancelados",
        },
        {
          type: "Publicadas",
          value: loading ? <CircularProgress size={20} style={{color: "#0DCAF0"}}/> : dashboardData?.Obras?.["TotalEstadoPub"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-info",
          textColor: "text-info",
          gradientClass: "bg-gradient-scooter",
          icon: "mingcute:announcement-line",
          route: "/admin/ver-anuncios/obras/publicados",
        },
      ],
    },
    {
      title: "Bens",
      content: [
        {
          type: "Lançados",
          value: loading ? <CircularProgress size={20} style={{color: "#FFC107"}}/> : dashboardData?.Bens?.["Lançado"],
          color: "rgb(244, 231, 230)",
          borderClass: "border-warning",
          textColor: "text-warning",
          gradientClass: "bg-gradient-blooker",
          icon: "solar:graph-new-up-outline",
          route: "/admin/ver-anuncios/bens/lancados",
        },
        {
          type: "Adjudicados",
          value: loading ? <CircularProgress size={20} style={{color: "#15CA20"}}/> : dashboardData?.Bens?.["Adjudicado"],
          color: "rgb(172, 205, 179, 0.5)",
          borderClass: "border-success",
          textColor: "text-success",
          gradientClass: "bg-gradient-ohhappiness",
          icon: "icon-park-outline:switching-done",
          route: "/admin/ver-anuncios/bens/adjudicados",
        },

        {
          type: "Cancelados",
          value: loading ? <CircularProgress size={20} style={{color: "#FD3550"}}/> : dashboardData?.Bens?.["Cancelado"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-danger",
          textColor: "text-danger",
          gradientClass: "bg-gradient-bloody",
          icon: "ic:outline-close",
          route: "/admin/ver-anuncios/bens/cancelados",
        },
        {
          type: "Publicados",
          value: loading ? <CircularProgress size={20} style={{color: "#0DCAF0"}}/> : dashboardData?.Bens?.["TotalEstadoPub"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-info",
          textColor: "text-info",
          gradientClass: "bg-gradient-scooter",
          icon: "mingcute:announcement-line",
          route: "/admin/ver-anuncios/bens/publicados",
        },
      ],
    },

    {
      title: "Serviços",
      content: [
        {
          type: "Lançados",
          value: loading ? <CircularProgress size={20} style={{color: "#FFC107"}}/> : dashboardData?.Serviços?.["Lançado"],
          color: "rgb(244, 231, 230)",
          borderClass: "border-warning",
          textColor: "text-warning",
          gradientClass: "bg-gradient-blooker",
          icon: "solar:graph-new-up-outline",
          route: "/admin/ver-anuncios/servicos/lancados",
        },
        {
          type: "Adjudicados",
          value: loading ? <CircularProgress size={20} style={{color: "#15CA20"}}/> : dashboardData?.Serviços?.["Adjudicado"],
          color: "rgb(172, 205, 179, 0.5)",
          borderClass: "border-success",
          textColor: "text-success",
          gradientClass: "bg-gradient-ohhappiness",
          icon: "icon-park-outline:switching-done",
          route: "/admin/ver-anuncios/servicos/adjudicados",
        },

        {
          type: "Cancelados",
          value: loading ? <CircularProgress size={20} style={{color: "#FD3550"}}/> : dashboardData?.Serviços?.["Cancelado"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-danger",
          textColor: "text-danger",
          gradientClass: "bg-gradient-bloody",
          icon: "ic:outline-close",
          route: "/admin/ver-anuncios/servicos/cancelados",
        },
        {
          type: "Publicados",
          value: loading ? <CircularProgress size={20} style={{color: "#0DCAF0"}}/> : dashboardData?.Serviços?.["TotalEstadoPub"],
          color: "rgb(244, 244, 244, 0.5)",
          borderClass: "border-info",
          textColor: "text-info",
          gradientClass: "bg-gradient-scooter",
          icon: "mingcute:announcement-line",
          route: "/admin/ver-anuncios/servicos/publicados",
        },
      ],
    },
  ];

  return (
    <div className="pt-3">
      {data.map((announce) => (
        <div className="my-4 text-start">
          <div className="d-flex mb-2">
            <h6 className="home-section-title main-title">{announce.title}</h6>
          </div>
          <Row>
            {announce.content.map((announce) => (
              <Col sm={3}>
                {" "}
                <Link to={announce.route}>
                  <HomeCard object={announce} />{" "}
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </div>
  );
}
