function getLabel(type) {
  switch (type) {
    case "adjudicados":
      return "Adjudicados";
    case "lancados":
      return "Lançados";
    case "publicados":
      return "Publicados";
    case "cancelados":
      return "Cancelados";
    default:
      return null;
  }
}

function getLabelStatus(type) {
  switch (type) {
    case "adjudicado":
      return "Adjudicado";
    case "lancado":
      return "Lançado";
    case "publicado":
      return "Publicado";
    case "cancelado":
      return "Cancelado";
    default:
      return null;
  }
}

function getStatusColor(type) {
  switch (type) {
    case "Adjudicado":
      return "success";
    case "Lançado":
      return "warning";
    case "Publicado":
      return "info";
    case "Cancelado":
      return "danger";
    default:
      return null;
  }
}

function getAnnounceLabel(type) {
  switch (type) {
    case "obras":
      return "Obras";
    case "servicos":
      return "Serviços";
    case "bens":
      return "Bens";
    default:
      return null;
  }
}

function getCategory(type) {
  switch (type) {
    case "Obras":
      return "Obra";
    case "Serviços":
      return "Serviço";
    case "Bens":
      return "Bem";
    default:
      return null;
  }
}

export {getLabel, getLabelStatus, getStatusColor, getAnnounceLabel, getCategory}
