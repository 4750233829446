import React, { useState } from "react";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { recursosConfig } from "../../utils/resourcesFields";
import SnackBar from "../../commons/snackbar";
import axios from "axios";
import { BASE_URL } from "../../api/requests/useGetData";

export default function ResourcesRegistration({
  nomeOpcao,
  setNomeOpcao,
  resourceId,
  tipoRecurso,
  setTipoRecurso,
  editing,
  setEditing,
  getResources,
}) {
  const [loader, setLoader] = useState(false);

  const [errorNomeOpcao, setErrorNomeOpcao] = useState(false);
  const [errorTipoRecurso, setErrorTipoRecurso] = useState(false);

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (errorNomeOpcao === false && errorTipoRecurso === false) {
      salvar();
    } else {
      console.log("Não posso salvar");
    }
  };

  const requiredFields = () => {
    if (nomeOpcao === "" || nomeOpcao === undefined) {
      setErrorNomeOpcao(true);
    } else {
      setErrorNomeOpcao(false);
    }
    if (tipoRecurso === "" || tipoRecurso === undefined) {
      setErrorTipoRecurso(true);
    } else {
      setErrorTipoRecurso(false);
    }
  };

  const cleanFields = () => {
    setNomeOpcao("");
    setTipoRecurso("");
    setEditing(false);
    setErrorNomeOpcao(false);
    setErrorTipoRecurso(false);
  };

  const dados = {
    nome: tipoRecurso,
    descricao: nomeOpcao,
  };

  const salvar = () => {
    setLoader(true);
    if (editing) {
      axios
        .put(`${BASE_URL}/config/${resourceId}`, dados)
        .then((response) => {
          setOpen(true);
          setmessage(tipoRecurso + " actualizado com sucesso!");
          setColor("success");
          setLoader(false);
          cleanFields();
          getResources();
        })
        .catch((error) => {
          setLoader(false);
          setOpen(true);
          setmessage(error?.response?.data?.message);
          setColor("error");
        });
    } else {
      axios
        .post(`${BASE_URL}/config/store`, dados)
        .then((response) => {
          setOpen(true);
          setmessage(tipoRecurso + " adicionado com sucesso!");
          setColor("success");
          setLoader(false);
          cleanFields();
          getResources();
        })
        .catch((error) => {
          setLoader(false);
          setOpen(true);
          setmessage(error?.response?.data?.message);
          setColor("error");
        });
    }
  };

  return (
    <form onSubmit={formSubmit}>
      <div className="row">
        <div className="col-md-12">
          <Autocomplete
            id="clear-on-escape"
            options={recursosConfig}
            getOptionLabel={(option) => option}
            noOptionsText="Nenhuma opção encontrada"
            onChange={(e, value) => setTipoRecurso(value)}
            value={tipoRecurso}
            defaultValue={tipoRecurso}
            disabled={loader || editing}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                margin="normal"
                size="small"
                variant="outlined"
                label="Tipo de recurso"
                className="rounded"
                type={"text"}
                error={errorTipoRecurso}
              />
            )}
          />
        </div>
        <div className="col-md-12">
          <div className="form-floating">
            <TextField
              variant="outlined"
              floatingLabelStyle={{ color: "#568C3E" }}
              size="small"
              margin="normal"
              fullWidth
              required
              name="nomeOpcao"
              label="Nome da opção"
              type={"text"}
              id="nomeOpcao"
              className="rounded"
              value={nomeOpcao}
              defaultValue={nomeOpcao}
              error={errorNomeOpcao}
              onChange={(e) => setNomeOpcao(e.target.value)}
              inputProps={{
                style: { border: "none", paddingLeft: 10 },
              }}
              InputProps={{
                disableUnderline: true,
              }}
              disabled={loader}
            />
          </div>
        </div>
        <div className="col-12 d-flex justify-content-between align-items-center pt-4">
          <Button
            variant="text"
            className="text-capitalize me-4 px-3 mt-2"
            style={{ color: "red" }}
            onClick={() => cleanFields()}
            disabled={loader}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="text-capitalize"
            style={{ color: "#568C3E" }}
            onClick={() => requiredFields()}
            disabled={loader}
          >
            {loader ? (
              <CircularProgress style={{ color: "#568C3E" }} size={20} />
            ) : editing ? (
              "Salvar alterações"
            ) : (
              "Adicionar"
            )}
          </Button>
        </div>
      </div>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </form>
  );
}
