import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export default function SnackBar(props) {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={props.snackPosition}
        open={props.open}
        onClose={props.handleClose}
        key={"top" + "center"}
        autoHideDuration={props.autoHideDuration}
      >
        <Alert onClose={props.handleClose} severity={props.cor} action={props.action}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
