import React from "react";
import AppbarDrawer from "../../../components/dashboard/drawer";
import ListUsers from "../../../components/dashboard/users/listUsers";



const ListUsersPage = () => {
  return (
    <div >
      <AppbarDrawer>
        <ListUsers/>
      </AppbarDrawer>
    </div>
  );
};
export default ListUsersPage;

