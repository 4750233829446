import { Navigate, Outlet } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";

const RequireAuth = () => {

    const auth = useIsAuthenticated();
    return (
        auth() ? <Outlet /> : <Navigate replace to={"/login"}/>
    )
}
 
export default RequireAuth;