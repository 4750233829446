import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Spinner } from "react-bootstrap";
import SnackBar from "../snackbar.js";
import dateFormat from "dateformat";
import { getCategory } from "../../utils/getAnnouncementLabels.js";
import axios from "axios";
import { BASE_URL } from "../../api/requests/useGetData.js";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const today = dateFormat(new Date(), "yyyy-mm-dd");

export const FecharAnuncio = (props) => {
  const { anuncio, show, closeModal, fetchAnnounces } = props;
  const classes = useStyles();

  const [loader, setLoader] = useState(false);

  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [tipoContrato, setTipoContrato] = useState("");
  const [dataConclusaoOriginal, setDataConclusaoOriginal] = useState(today);
  const [dataConclusaoFinal, setDataConclusaoFinal] = useState(today);
  const [valorFinalContrato, setValorFinalContrato] = useState("");

  const [errorTipoContrato, setErrorTipoContrato] = useState(false);
  const [errorDataConclusaoOriginal, setErrorDataConclusaoOriginal] =
    useState(false);
  const [errorDataConclusaoFinal, setErrorDataConclusaoFinal] = useState(false);
  const [errorValorFinalContrato, setErrorValorFinalContrato] = useState(false);

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (
      errorTipoContrato === false &&
      errorDataConclusaoOriginal === false &&
      errorDataConclusaoFinal === false &&
      errorValorFinalContrato === false
    ) {
      fechar();
    } else {
      console.log("Não posso salvar");
    }
  };

  const requiredFields = () => {
    if (tipoContrato === "" || tipoContrato === undefined) {
      setErrorTipoContrato(true);
    } else {
      setErrorTipoContrato(false);
    }
    if (dataConclusaoOriginal === "" || dataConclusaoOriginal === undefined) {
      setErrorDataConclusaoOriginal(true);
    } else {
      setErrorDataConclusaoOriginal(false);
    }
    if (dataConclusaoFinal === "" || dataConclusaoFinal === undefined) {
      setErrorDataConclusaoFinal(true);
    } else {
      setErrorDataConclusaoFinal(false);
    }
    if (valorFinalContrato === "" || valorFinalContrato === undefined) {
      setErrorValorFinalContrato(true);
    } else {
      setErrorValorFinalContrato(false);
    }
  };

  const cleanFields = () => {
    setTipoContrato("");
    setDataConclusaoOriginal(today);
    setDataConclusaoFinal("");
    setValorFinalContrato("");
  };

  const dados = {
    tipo_contracto: tipoContrato,
    data_conclusao_original: dataConclusaoOriginal,
    data_conclusao_real: dataConclusaoFinal,
    valor_final_MT: valorFinalContrato,
    estado: "Fechado",
  };

  const fechar = () => {
    setLoader(true);
    axios
      .put(`${BASE_URL}/concurso/${anuncio.id}`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Anúncio fechado com sucesso!");
        setColor("success");
        setLoader(false);
        cleanFields();
        closeModal();
        fetchAnnounces();
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" className="fw-bold text-center">
          Formulário de Encerramento de {getCategory(anuncio?.categoria)}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={formSubmit}>
          <Divider />
          <DialogContent className="p-4">
            <div className="row">
              <div className="col-md-6">
                <small>REF/Concurso: </small>
                <h6>{anuncio?.ref_concurso}</h6>
              </div>
              <div className="col-md-6">
                <small>REF/BCC: </small>
                <h6>{anuncio?.ref_bcc}</h6>
              </div>
              <div className="col-md-12 mt-2">
                <small className="" style={{ color: "red" }}>
                  Por favor, preencha os campos abaixo, os campos com (*) são
                  obrigatórios e, os dados não serão enviados sem o devido
                  preenchimento.
                </small>
              </div>
              <div className="col-md-6">
                <div className="form-floating">
                  <TextField
                    variant="outlined"
                    floatingLabelStyle={{ color: "#568C3E" }}
                    size="small"
                    margin="normal"
                    fullWidth
                    required
                    name="tipoContrato"
                    placeholder="Serie de Preços, VG, etc"
                    label="Tipo de Contrato"
                    type={"text"}
                    id="tipoContrato"
                    className="rounded border-bottom"
                    value={tipoContrato}
                    defaultValue={tipoContrato}
                    error={errorTipoContrato}
                    onChange={(e) => setTipoContrato(e.target.value)}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={loader}
                  />
                </div>
              </div>{" "}
              
              <div className="col-md-6">
                <div className="form-floating">
                  <TextField
                    variant="outlined"
                    floatingLabelStyle={{ color: "#568C3E" }}
                    size="small"
                    margin="normal"
                    fullWidth
                    required
                    name="valorFinalContrato"
                    label="Valor final do contrato"
                    type={"number"}
                    id="valorFinalContrato"
                    className="rounded border-bottom"
                    value={valorFinalContrato}
                    defaultValue={valorFinalContrato}
                    error={errorValorFinalContrato}
                    onChange={(e) => setValorFinalContrato(e.target.value)}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={loader}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating">
                  <TextField
                    variant="outlined"
                    floatingLabelStyle={{ color: "#568C3E" }}
                    size="small"
                    margin="normal"
                    fullWidth
                    required
                    name="dataConclusaoOriginal"
                    label="Data de conclusão original"
                    type={"date"}
                    id="dataConclusaoOriginal"
                    className="rounded border-bottom"
                    value={dataConclusaoOriginal}
                    defaultValue={dataConclusaoOriginal}
                    error={errorDataConclusaoOriginal}
                    onChange={(e) => setDataConclusaoOriginal(e.target.value)}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={loader}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating">
                  <TextField
                    variant="outlined"
                    floatingLabelStyle={{ color: "#568C3E" }}
                    size="small"
                    margin="normal"
                    fullWidth
                    required
                    name="dataConclusaoFinal"
                    label="Data de conclusão final"
                    type={"date"}
                    id="reglamentoAplicado"
                    className="rounded border-bottom"
                    value={dataConclusaoFinal}
                    defaultValue={dataConclusaoFinal}
                    error={errorDataConclusaoFinal}
                    onChange={(e) => setDataConclusaoFinal(e.target.value)}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={loader}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="px-4">
            <Button
              className="text-capitalize mr-3"
              style={{ width: 100 }}
              onClick={closeModal}
              color="secondary"
              disabled={loader}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              className="text-capitalize"
              style={{ color: "white", width: 100, backgroundColor: "#568C3E" }}
              onClick={() => requiredFields()}
              disabled={loader}
            >
              {loader ? (
                <Spinner animation="border" style={{ width: 24, height: 24 }} />
              ) : (
                "Salvar"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom" + "center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </>
  );
};
