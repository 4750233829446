import React from "react";
import AppbarDrawer from "../../components/dashboard/drawer";
import GestaoEntidades from "../../components/dashboard/entities/entities";



const EntitiesPage = () => {
  return (
    <div >
      <AppbarDrawer>
        <GestaoEntidades/>
      </AppbarDrawer>
    </div>
  );
};
export default EntitiesPage;

