import React from "react";
import AppbarDrawer from "../../../components/dashboard/drawer";
import EditarAnuncios from "../../../components/dashboard/announces/edit";



const EditarAnunciosPage = () => {
  return (
    <div >
      <AppbarDrawer>
        <EditarAnuncios/>
      </AppbarDrawer>
    </div>
  );
};
export default EditarAnunciosPage;

