import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import dateFormat from "dateformat";

export default function TableResources({
  resources,
  setNomeOpcao,
  setTipoRecurso,
  resourceType,
  setResourceId,
  setEditing,
  setShow
}) {

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell className="">ID</TableCell>
          <TableCell className="">Nome de exibição</TableCell>
          <TableCell className="">Última actualização</TableCell>
          <TableCell className="" align="center">
            Acções
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {resources.map((resource) => (
          <TableRow>
            <TableCell>{resource.id}</TableCell>
            <TableCell>{resource.descricao}</TableCell>
            <TableCell>
              {`${dateFormat(
                resource.created_at,
                "dd/mm/yyyy"
              )}, às ${dateFormat(resource.created_at, "HH:MM:ss")}`}
            </TableCell>
            <TableCell align="center" className="ms-4">
              <IconButton
                size="small"
                className="mr-2"
                onClick={() => {
                  setNomeOpcao(resource.descricao);
                  setTipoRecurso(resourceType);
                  setResourceId(resource.id);
                  setEditing(true);
                }}
              >
                <Edit fontSize="small" />
              </IconButton>
              <IconButton size="small">
                <Delete
                  fontSize="small"
                  onClick={() => {
                    setNomeOpcao(resource.descricao);
                    setTipoRecurso(resourceType);
                    setResourceId(resource.id);
                    setShow(true);
                  }}
                />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
