const contratantes = [
  "Presidência da República de Moçambique",
  "Assembleia da República de Moçambique",
  "Ministério da Saúde",
  "Ministério da Educação e Desenvolvimento Humano",
  "Ministério da Justiça, Assuntos Constitucionais e Religiosos",
  "Ministério da Economia e Finanças",
  "Banco de Moçambique",
  "Instituto Nacional de Estatística",
  "Instituto Nacional de Segurança Social",
  "Instituto Nacional de Transportes Terrestres",
  "Instituto Nacional de Petróleos",
  "Autoridade Tributária de Moçambique",
  "Autoridade Reguladora de Águas e Energia de Moçambique",
  "Instituto Nacional de Meteorologia",
  "Instituto Nacional de Desenvolvimento de Pequenas e Médias Empresas",
  "Instituto Nacional de Saúde",
  "Instituto Nacional de Investigação Agrária",
  "Instituto Nacional de Segurança Pública",
  "Instituto Nacional de Gestão de Calamidades",
  "Instituto Nacional de Tecnologia de Informação",
  "Instituto Nacional de Desminagem",
  "Instituto Nacional de Avaliação Educacional",
  "Instituto Nacional de Defesa do Consumidor",
  "Instituto Nacional de Gestão de Terras",
  "Instituto Nacional de Cinema e Audiovisual",
  "Instituto Nacional do Turismo",
  "Instituto Nacional de Saúde Animal",
  "Instituto Nacional de Cartografia e Cadastro",
  "Instituto de Fomento do Caju",
  "Instituto Nacional de Tecnologia Alimentar",
  "Instituto de Patrocínio e Assistência Jurídica",
  "Instituto Nacional de Estudos e Pesquisa",
  "Instituto Nacional de Desenvolvimento das Pescas",
  "Instituto de Formação de Professores de Moçambique",
  "Instituto Nacional de Emprego e Formação Profissional",
  "Instituto de Acreditação de Moçambique",
  "Autoridade Nacional de Minas",
  "Autoridade Nacional de Aviação Civil",
  "Instituto de Segurança Social das Forças Armadas de Defesa de Moçambique",
  "Instituto de Gestão de Fundos de Segurança Social",
  "Instituto de Bolsas de Estudo",
  "Instituto Nacional de Desenvolvimento do Desporto",
  "Instituto Nacional de Meteorologia e Geofísica",
  "Autoridade Reguladora das Comunicações de Moçambique",
  "Instituto Nacional de Petróleos e Gás",
  "Fundo de Investimento e Património do Abastecimento de Água",
  "Instituto de Desenvolvimento de Energias Renováveis",
  "Instituto Nacional de Tecnologia de Água e Saneamento",
  "Instituto Nacional de Acção Social",
  "Instituto de Previdência Social dos Funcionários e Agentes do Estado",
];
const fontes = ["Jornal Notícias"];

const categorias = ["Obras", "Bens", "Serviços"];

const startFromStatus = [
  {
    id: 1,
    value: "Lançado",
    name: "Lançamento",
  },
  {
    id: 2,
    value: "Adjudicado",
    name: "Adjudicação",
  },
  {
    id: 3,
    value: "Cancelado",
    name: "Cancelamento",
  },
];
const moedas = [
  {
    id: 1,
    code: "MT",
    name: "Metical (MT)",
  },
  {
    id: 2,
    code: "USD",
    name: "Dólar (USD)",
  },
  {
    id: 3,
    code: "EUR",
    name: "Euro (EUR)",
  },
];

const modalidades = [
  "Concurso público",
  "Concurso limitado",
  "Adjucacao directa",
  "Cotações",
  "Regime especial",
];

const recursosConfig = ["Fonte", "Modalidade", "Garantia"];

const provinces = [
  "Cabo Delgado",
  "Niassa",
  "Nampula",
  "Zambézia",
  "Tete",
  "Manica",
  "Sofala",
  "Inhambane",
  "Gaza",
  "Maputo Província",
  "Maputo Cidade",
];

const meses = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const estados = ["Lançados", "Adjudicados", "Cancelados"];
const entitieTypes = ["Contratante", "Contratada"];
const regulamentos = ["Decreto nº 5/2016, de 8 de Março"];
const niveisAcesso = ["Super administrador", "Administrador", "Funcionário"];

const chartTabs = [
  {
    id: 1,
    code: "modalidades",
    name: "Modalidades",
    title: "Relatório Por Modalidade do Concurso",
  },
  {
    id: 2,
    code: "categorias",
    name: "Categorias",
    title: "Relatório Por Categoria do Concurso",
  },
  {
    id: 3,
    code: "estados",
    name: "Estados",
    title: "Relatório Por Estado do Concurso",
  },

  {
    id: 4,
    code: "procedimento",
    name: "Tipo de procedimento",
    title: "Relatório Por NÃO DEFINIDO",
  },
  {
    id: 5,
    code: "valor",
    name: "Valor do contrato",
    title: "Relatório Por NÃO DEFINIDO",
  },
  {
    id: 6,
    code: "entidade",
    name: "Entidades",
    title: "Relatório Por NÃO DEFINIDO",
  },
];

export {
  contratantes,
  fontes,
  categorias,
  modalidades,
  recursosConfig,
  provinces,
  meses,
  estados,
  regulamentos,
  entitieTypes,
  niveisAcesso,
  moedas,
  startFromStatus,
  chartTabs
};
