import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { chartTabs, startFromStatus } from "../../utils/resourcesFields";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#568C3E",
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      //borderTopLeftRadius: 10,
      //borderTopRightRadius: 10,
      color: "#568C3E !important",
      //backgroundColor: "#568C3E",
      textTransform: "capitalize",
      fontWeight: "bold !important",
      lineHeight: 1.2,
    },
  },
}));

export default function TabsComponent({
  value,
  handleChange,
  children,
}) {
  const classes = useStyles();

  return (
    <div className="">
      <AppBar
        elevation={0}
        className="shadow-sm bg-white border"
        position="static"
        color="default"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabs}
        >
          {chartTabs?.map((status, index) => (
            <Tab
              className="text-capitalize tab-div-main"
              key={index}
              label={status.name}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {children}
    </div>
  );
}
