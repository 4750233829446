const pieData = [
  {
    name: "Concurso público",
    y: 50,
  },
  {
    name: "Concurso limitado",
    sliced: true,
    y: 27,
  },
  {
    name: "Adjucacao directa",
    y: 1,
  },
  {
    name: "Cotações",
    selected: true,
    y: 15,
  },
  {
    name: "Regime especial",
    y: 1,
  },
];

const columnData = [
  {
    name: "Concurso público",
    data: [422, 260, 100, 68, 270, 149, 103, 100, 280, 207, 145],
  },
  {
    name: "Concurso limitado",
    data: [507, 430, 555, 140, 100, 747, 290, 100, 368, 217, 329],
  },
  {
    name: "Adjucacao directa",
    data: [721, 260, 100, 298, 207, 594, 329, 110, 68, 274, 440],
  },
  {
    name: "Cotações",
    data: [251, 130, 550, 140, 190, 287, 570, 390, 648, 257, 104],
  },
  {
    name: "Regime especial",
    data: [560, 430, 505, 245, 400, 377, 260, 100, 168, 273, 142],
  },
];

const barData = [
  {
    name: "Concurso público",
    data: [422, 260, 100, 68, 270, 149, 103, 100, 280, 207, 145],
  },
  {
    name: "Concurso limitado",
    data: [507, 430, 555, 140, 100, 747, 290, 100, 368, 217, 329],
  },
  {
    name: "Adjucacao directa",
    data: [721, 260, 100, 298, 207, 594, 329, 110, 68, 274, 440],
  },
  {
    name: "Cotações",
    data: [251, 130, 550, 140, 190, 287, 570, 390, 648, 257, 104],
  },
  {
    name: "Regime especial",
    data: [560, 430, 505, 140, 400, 377, 260, 100, 168, 273, 142],
  },
];

const lineData = [
  {
    name: "Installation & Developers",
    data: [
      43934, 48656, 65165, 81827, 112143, 142383, 171533, 165174, 155157,
      161454, 154610,
    ],
  },
  {
    name: "Manufacturing",
    data: [
      24916, 37941, 29742, 29851, 32490, 30282, 38121, 36885, 33726, 34243,
      31050,
    ],
  },
  {
    name: "Sales & Distribution",
    data: [
      11744, 30000, 16005, 19771, 20185, 24377, 32147, 30912, 29243, 29213,
      25663,
    ],
  },
  {
    name: "Operations & Maintenance",
    data: [null, null, null, null, null, null, null, null, 11164, 11218, 10077],
  },
  {
    name: "Other",
    data: [
      21908, 5548, 8105, 11248, 8989, 11816, 18274, 17300, 13053, 11906, 10073,
    ],
  },
];

const areaData = [
  {
    name: "USA",
    data: [
      null,
      null,
      null,
      null,
      null,
      2,
      9,
      13,
      50,
      170,
      299,
      438,
      841,
      1169,
      1703,
      2422,
      3692,
      5543,
      7345,
      12298,
      18638,
      22229,
      25540,
      28133,
      29463,
      31139,
      31175,
      31255,
      29561,
      27552,
      26008,
      25830,
      26516,
      27835,
      28537,
      27519,
      25914,
      25542,
      24418,
      24138,
      24104,
      23208,
      22886,
      23305,
      23459,
      23368,
      23317,
      23575,
      23205,
      22217,
      21392,
      19008,
      13708,
      11511,
      10979,
      10904,
      11011,
      10903,
      10732,
      10685,
      10577,
      10526,
      10457,
      10027,
      8570,
      8360,
      7853,
      5709,
      5273,
      5113,
      5066,
      4897,
      4881,
      4804,
      4717,
      4571,
      4018,
      3822,
      3785,
      3805,
      3750,
      3708,
      3708,
    ],
  },
  {
    name: "USSR/Russia",
    data: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      1,
      5,
      25,
      50,
      120,
      150,
      200,
      426,
      660,
      863,
      1048,
      1627,
      2492,
      3346,
      4259,
      5242,
      6144,
      7091,
      8400,
      9490,
      10671,
      11736,
      13279,
      14600,
      15878,
      17286,
      19235,
      22165,
      24281,
      26169,
      28258,
      30665,
      32146,
      33486,
      35130,
      36825,
      38582,
      40159,
      38107,
      36538,
      35078,
      32980,
      29154,
      26734,
      24403,
      21339,
      18179,
      15942,
      15442,
      14368,
      13188,
      12188,
      11152,
      10114,
      9076,
      8038,
      7000,
      6643,
      6286,
      5929,
      5527,
      5215,
      4858,
      4750,
      4650,
      4600,
      4500,
      4490,
      4300,
      4350,
      4330,
      4310,
      4495,
      4477,
    ],
  },
];

const mozMapData = [
  ["mz-nm", 10],
  ["mz-in", 11],
  ["mz-mp", 12],
  ["mz-za", 13],
  ["mz-7278", 14],
  ["mz-te", 35],
  ["mz-mn", 16],
  ["mz-cd", 17],
  ["mz-ns", 18],
  ["mz-ga", 19],
  ["mz-so", 20],
];

export { mozMapData, pieData, barData, lineData, areaData, columnData };
