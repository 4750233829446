import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  createTheme,
  Divider,
  InputAdornment,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Save } from "@material-ui/icons";
import bcc from "../../../assets/img/main-logo.png";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import dateFormat from "dateformat";
import provinces from "../../utils/mozambique.json";
import { BASE_URL } from "../../api/requests/useGetData";
import axios from "axios";
import SnackBar from "../../commons/snackbar";
import { categorias, moedas } from "../../utils/resourcesFields";
import { AdicionarEntidade } from "../entities/modalAdd";
import TabsComponent from "../../commons/tabs/TabsComponent";
import { AdjudicarAnuncio } from "../../commons/modal/adjudicarRegisto";
import { CancelarAnuncio } from "../../commons/modal/cancelarRegisto";

const today = dateFormat(new Date(), "yyyy-mm-dd");

export default function RegistarAnuncios() {
  const [districts, setDistricts] = useState([]);
  const [contratantes, setContratantes] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingEntidades, setLoadingEntidades] = useState(true);
  const [fontes, setFontes] = useState([]);
  const [modalidades, setModalidades] = useState([]);
  const [regulamentos, setRegulamentos] = useState([]);
  const [garantias, setGarantias] = useState([]);

  const [value, setValue] = useState(0);

  const initialDadosAdjudicacao = {
    dataAdjudicacao: today,
    entidadeContrada: {},
    valorAdjudicadoMt: 0,
    valorAdjudicadoUsd: 0,
    valorAdjudicadoEur: 0,
    cambioDolar: 0,
    cambioEuro: 0,
    totalAdjudicadoMt: 0,
    totalAdjudicadoUsd: 0,
    totalAdjudicadoEur: 0,
    extraInfo: "",
    estado: "Adjudicado",
  };
  const initialDadosCancelamento = {
    motivoCancelamento: "",
    estado: "Cancelado",
  };

  const [dadosAdjudicacao, setDadosAdjudicacao] = useState(
    initialDadosAdjudicacao
  );

  const [dadosCancelamento, setDadosCancelamento] = useState(
    initialDadosCancelamento
  );

  console.log("dadosCancelamento", dadosCancelamento);

  const getResources = () => {
    setLoading(true);
    axios.get(`${BASE_URL}/config`).then((response) => {
      setFontes(response.data.data.filter((el) => el.nome === "Fonte"));
      setGarantias(response.data.data.filter((el) => el.nome === "Garantia"));
      setRegulamentos(
        response.data.data.filter((el) => el.nome === "Regulamento")
      );
      setModalidades(
        response.data.data.filter((el) => el.nome === "Modalidade")
      );
      setLoading(false);
    });
  };

  const getEntidadesContratantes = () => {
    setLoadingEntidades(true);
    axios.get(`${BASE_URL}/entidade-contratante`).then((response) => {
      setContratantes(response.data.data);
      setLoadingEntidades(false);
    });
  };

  useEffect(() => {
    getEntidadesContratantes();
    getResources();
  }, []);
  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [fonte, setFonte] = useState("");
  const [dataAnuncio, setDataAnuncio] = useState(today);
  const [tipoAnuncio, setTipoAnuncio] = useState({});
  const [descricao, setDescricao] = useState("");
  const [provincia, setProvincia] = useState("");
  const [distrito, setDistrito] = useState({});
  const [numeroConcurso, setNumeroConcurso] = useState("");
  const [modalidade, setModalidade] = useState("");
  const [dataSubmissao, setDataSubmissao] = useState(today);
  const [entidadeContratante, setEntidadeContratante] = useState({});
  const [regulamentoAplicado, setRegulamentoAplicado] = useState("");
  const [taxaCaderno, setTaxaCaderno] = useState("");
  const [garantia, setGarantia] = useState({});
  const [moedaGarantia, setMoedaGarantia] = useState("");
  const [valorGarantia, setValorGarantia] = useState("");
  const [prazoGarantia, setPrazoGarantia] = useState("");
  const [extraInfo, setExtraInfo] = useState("");

  const [cambioDolar, setCambioDolar] = useState(0);
  const [cambioEuro, setCambioEuro] = useState(0);

  const [errorFonte, setErrorFonte] = useState(false);
  const [errorDataAnuncio, setErrorDataAnuncio] = useState(false);
  const [errorTipoAnuncio, setErrorTipoAnuncio] = useState(false);
  const [errorDescricao, setErrorDescricao] = useState(false);
  const [errorProvincia, setErrorProvincia] = useState(false);
  const [errorDistrito, setErrorDistrito] = useState(false);
  const [errorNumeroConcurso, setErrorNumeroConcurso] = useState(false);
  const [errorModalidade, setErrorModalidade] = useState(false);
  const [errorDataSubmissao, setErrorDataSubmissao] = useState(false);
  const [errorEntContratante, setErrorEntContratante] = useState(false);
  const [errorRegulamento, setErrorRegulamento] = useState(false);
  const [errorTaxaCaderno, setErrorTaxaCaderno] = useState(false);
  const [errorGarantia, setErrorGarantia] = useState(false);
  const [errorValorGarantia, setErrorValorGarantia] = useState(false);
  const [errorPrazoGarantia, setErrorPrazoGarantia] = useState(false);
  const [errorExtraInfo, setErrorExtraInfo] = useState(false);

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const entityType = "Contratante";
  const [showAddEntity, setShowAddEntity] = useState(false);
  const closeAddEntity = () => {
    setShowAddEntity(false);
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (
      errorFonte === false &&
      errorDataAnuncio === false &&
      errorTipoAnuncio === false &&
      errorDescricao === false &&
      errorProvincia === false &&
      errorDistrito === false &&
      errorNumeroConcurso === false &&
      errorModalidade === false &&
      errorDataSubmissao === false &&
      errorEntContratante === false &&
      errorRegulamento === false &&
      errorTaxaCaderno === false &&
      errorGarantia === false &&
      errorValorGarantia === false &&
      errorPrazoGarantia === false &&
      errorExtraInfo === false
    ) {
      //console.log("Salvar dados", dados);
      //console.log("Salvar dados de adjudicação:", dadosAdjudicacao);
      salvar();
    } else {
      console.log("Não posso salvar");
    }
  };

  const requiredFields = () => {
    if (fonte === "" || fonte === undefined) {
      setErrorFonte(true);
    } else {
      setErrorFonte(false);
    }
    if (dataAnuncio === "" || dataAnuncio === undefined) {
      setErrorDataAnuncio(true);
    } else {
      setErrorDataAnuncio(false);
    }
    if (tipoAnuncio === "" || tipoAnuncio === undefined) {
      setErrorTipoAnuncio(true);
    } else {
      setErrorTipoAnuncio(false);
    }
    if (descricao === "" || descricao === undefined) {
      setErrorDescricao(true);
    } else {
      setErrorDescricao(false);
    }
    if (provincia === "" || provincia === undefined) {
      setErrorProvincia(true);
    } else {
      setErrorProvincia(false);
    }
    if (numeroConcurso === "" || numeroConcurso === undefined) {
      setErrorNumeroConcurso(true);
    } else {
      setErrorNumeroConcurso(false);
    }
    if (modalidade === "" || modalidade === undefined) {
      setErrorModalidade(true);
    } else {
      setErrorModalidade(false);
    }
    if (dataSubmissao === "" || dataSubmissao === undefined) {
      setErrorDataSubmissao(true);
    } else {
      setErrorDataSubmissao(false);
    }
    if (entidadeContratante === "" || entidadeContratante === undefined) {
      setErrorEntContratante(true);
    } else {
      setErrorEntContratante(false);
    }
    if (taxaCaderno === "" || taxaCaderno === undefined) {
      setErrorTaxaCaderno(true);
    } else {
      setErrorTaxaCaderno(false);
    }
    if (garantia === "" || garantia === undefined) {
      setErrorGarantia(true);
    } else {
      setErrorGarantia(false);
    }
    if (valorGarantia === "" || valorGarantia === undefined) {
      setErrorValorGarantia(true);
    } else {
      setErrorValorGarantia(false);
    }
    if (prazoGarantia === "" || prazoGarantia === undefined) {
      setErrorPrazoGarantia(true);
    } else {
      setErrorPrazoGarantia(false);
    }
    if (regulamentoAplicado === "" || regulamentoAplicado === undefined) {
      setErrorRegulamento(true);
    } else {
      setErrorRegulamento(false);
    }
  };

  const cleanFields = () => {
    setFonte("");
    setDataAnuncio(today);
    setTipoAnuncio("");
    setDescricao("");
    setProvincia("");
    setDistrito("");
    setNumeroConcurso("");
    setModalidade("");
    setDataSubmissao(today);
    setEntidadeContratante("");
    setRegulamentoAplicado("");
    setTaxaCaderno("");
    setGarantia("");
    setMoedaGarantia("");
    setValorGarantia("");
    setPrazoGarantia("");
    setExtraInfo("");

    setCambioDolar(0);
    setCambioEuro(0);

    setDadosAdjudicacao(initialDadosAdjudicacao);

    setDadosCancelamento(initialDadosCancelamento);
  };

  const handleChangeProvince = (event, value) => {
    setDistrito({});
    setProvincia(value);
    setDistricts(value?.districts);
  };

  const handleOptionChange = (event, value) => {
    setEntidadeContratante(value);
  };

  const handleGarantiaChange = (event, value) => {
    setGarantia(value);
  };

  const handleMoedaGarantiaChange = (event, value) => {
    setMoedaGarantia(value);
  };

  const getValorGarantia = (value) => {
    if (moedaGarantia) {
      if (moedaGarantia?.code === "MT") return value;
      if (moedaGarantia?.code === "USD") return value * cambioDolar;
      if (moedaGarantia?.code === "EUR") return value * cambioEuro;
    }
  };

  const adjudicacaoForPost = {
    data_adjudicacao: dadosAdjudicacao.dataAdjudicacao,
    entidade_contratada_id: dadosAdjudicacao.entidadeContrada?.id,
    valor_adjudicado_MT: dadosAdjudicacao.valorAdjudicadoMt,
    valor_adjudicado_USD: dadosAdjudicacao.valorAdjudicadoUsd,
    valor_adjudicado_EUR: dadosAdjudicacao.valorAdjudicadoEur,
    total_MT: dadosAdjudicacao.totalAdjudicadoMt,
    total_USD: dadosAdjudicacao.totalAdjudicadoUsd,
    total_EUR: dadosAdjudicacao.totalAdjudicadoEur,
    estado: "Adjudicado",
  };

  const cancelamentoForPost = {
    fundamento_cancelamento: dadosCancelamento.motivoCancelamento,
    estado: "Cancelado",
  };

  const dados = {
    ref_concurso: numeroConcurso,
    fonte: fonte?.descricao,
    data_anuncio: dataAnuncio,
    data_submissao: dataSubmissao,
    tipo: modalidade?.descricao,
    estado: "Lançado",
    categoria: tipoAnuncio,
    descricao: descricao,
    modalidade: modalidade?.descricao,
    regulamento: regulamentoAplicado?.descricao,
    estado_pub: "Não",
    entidade_contratante_id: entidadeContratante?.id,
    observacao: extraInfo,
    provincia: provincia?.province,
    distrito: distrito,
    taxa_caderno: taxaCaderno,
    garantia: garantia?.descricao,
    valor_garantia: getValorGarantia(valorGarantia),
    validade_proposta: prazoGarantia,
    ...(value === 1 ? adjudicacaoForPost : {}),
    ...(value === 2 ? cancelamentoForPost : {}),
  };

  const salvar = () => {
    setLoader(true);
    axios
      .post(`${BASE_URL}/concurso/store`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Anúncio registado com sucesso!");
        setColor("success");
        setLoader(false);
        cleanFields();
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  console.log("first", moedaGarantia);

  console.log("firstValue", value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const formatter = new Intl.NumberFormat(["ban", "id"]);

  const announce = {
    entidade_contratada: {
      nome: "Entidade Contratada Nome",
    },
    valor_adjudicado_MT: 10000,
    valor_adjudicado_USD: 20000,
    valor_adjudicado_EUR: 30000,
    total_MT: 70000,
    total_USD: 80000,
    total_EUR: 100000,
  };

  const [showAdjudicar, setShowAdjudicar] = useState(false);
  const closeAdjudicar = (event, reason) => {
    if (reason !== "backdropClick") {
      setShowAdjudicar(false);
    }
  };

  const [showCancelar, setShowCancelar] = useState(false);
  const closeCancelar = () => {
    setShowCancelar(false);
  };

  const disableSave =
    loader || (value === 1 && !dadosAdjudicacao?.entidadeContrada?.id);

  /* O concurso não se encontra alinhado com aquilo que se espera fazer na realidade. Há problemas de construção e não alinhamentos na adjudicação à empresa que se designa competente. Não há condições mínimas para avançar com a construção do edifício acima mencionado. */
  return (
    <div className="text-start container-fluid">
      <Paper elevation={1} component={"div"} className="bg-white shadow-sm p-4">
        {" "}
        <div className="text-center wow fadeInUp mb-2" data-wow-delay="0.1s">
          <Avatar
            className="mx-auto p-2"
            style={{
              backgroundColor: "#fff",
              width: 70,
              height: 70,
              border: "2px solid #568C3E",
            }}
          >
            <img src={bcc} alt="" />
          </Avatar>
          <h3 className="mt-2 mb-5">Formulário de Registo de Concursos</h3>
        </div>{" "}
        <TabsComponent
          value={value}
          handleChange={handleChange}
          handleChangeIndex={handleChangeIndex}
        >
          <div className="d-flex justify-content-between mt-4 mb-2">
            <small style={{ color: "red" }}>
              Por favor, preencha os campos abaixo, os campos com (*) são
              obrigatórios e, os dados não serão enviados sem o devido
              preenchimento.
            </small>
            <small
              className="add-new-entity"
              onClick={() => setShowAddEntity(true)}
            >
              Adicionar entidade contratante
            </small>
          </div>
          {/* -------------------FORMULÁRIO DE REGISTO------------- */}
          {loading || loadingEntidades ? (
            <div className="text-center my-5 py-5">
              <div class="my-5 py-5">
                <div class="loader"></div>
              </div>
            </div>
          ) : (
            <form onSubmit={formSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <Autocomplete
                    id="clear-on-escape"
                    options={categorias}
                    getOptionLabel={(option) => option}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(e, value) => setTipoAnuncio(value)}
                    value={tipoAnuncio}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label="Categoria do anúncio"
                        className="rounded"
                        type={"text"}
                        error={errorTipoAnuncio}
                      />
                    )}
                  />
                </div>{" "}
                <div className="col-md-6">
                  <Autocomplete
                    id="clear-on-escape"
                    options={fontes}
                    getOptionLabel={(option) => option.descricao}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(e, value) => setFonte(value)}
                    value={fonte}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label="Fonte"
                        className="rounded"
                        type={"text"}
                        error={errorFonte}
                      />
                    )}
                  />
                </div>{" "}
                <div className="col-md-6">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="numeroConcurso"
                      label="Referência do concurso"
                      //placeholder="Número do concurso"
                      type={"text"}
                      id="numeroConcurso"
                      className="rounded"
                      value={numeroConcurso}
                      defaultValue={numeroConcurso}
                      error={errorNumeroConcurso}
                      onChange={(e) => setNumeroConcurso(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <Autocomplete
                    id="clear-on-escape"
                    options={modalidades}
                    getOptionLabel={(option) => option.descricao}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(e, value) => setModalidade(value)}
                    value={modalidade}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label="Modalidade do concurso"
                        className="rounded"
                        type={"text"}
                        error={errorModalidade}
                      />
                    )}
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <Autocomplete
                      id="clear-on-escape"
                      options={regulamentos}
                      getOptionLabel={(option) => option.descricao}
                      noOptionsText="Nenhuma opção encontrada"
                      onChange={(e, value) => setRegulamentoAplicado(value)}
                      value={regulamentoAplicado}
                      disabled={loader}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          margin="normal"
                          size="small"
                          variant="outlined"
                          label="Regulamento aplicado"
                          className="rounded"
                          type={"text"}
                          error={errorRegulamento}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <Autocomplete
                      id="clear-on-escape"
                      options={contratantes}
                      getOptionLabel={(option) => option?.nome}
                      noOptionsText="Nenhuma opção encontrada"
                      onChange={handleOptionChange}
                      value={entidadeContratante}
                      disabled={loader}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          margin="normal"
                          size="small"
                          variant="outlined"
                          label="Entidade contratante"
                          className="rounded"
                          type={"text"}
                          error={errorEntContratante}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="taxaCaderno"
                      label="Taxa do caderno de encargo"
                      type={"number"}
                      id="taxaCaderno"
                      className="rounded"
                      value={taxaCaderno}
                      defaultValue={taxaCaderno}
                      error={errorTaxaCaderno}
                      onChange={(e) => setTaxaCaderno(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        /* startAdornment: (
                        <InputAdornment position="start">Mt</InputAdornment>
                      ), */
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-floating">
                    <Autocomplete
                      id="clear-on-escape"
                      options={garantias}
                      getOptionLabel={(option) => option.descricao}
                      noOptionsText="Nenhuma opção encontrada"
                      onChange={handleGarantiaChange}
                      value={garantia}
                      disabled={loader}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          margin="normal"
                          size="small"
                          variant="outlined"
                          label="Garantia"
                          className="rounded"
                          type={"text"}
                          error={errorGarantia}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="prazoGarantia"
                      label="Prazo da garantia"
                      //placeholder="Número do concurso"
                      type={"number"}
                      id="prazoGarantia"
                      className="rounded"
                      value={prazoGarantia}
                      defaultValue={prazoGarantia}
                      error={errorPrazoGarantia}
                      onChange={(e) => setPrazoGarantia(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div
                  className={
                    !moedaGarantia || moedaGarantia.code === "MT"
                      ? "col-md-6"
                      : "col-md-3"
                  }
                >
                  <div className="form-floating">
                    <Autocomplete
                      id="clear-on-escape"
                      options={moedas}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Nenhuma opção encontrada"
                      onChange={handleMoedaGarantiaChange}
                      value={moedaGarantia}
                      disabled={loader}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          margin="normal"
                          size="small"
                          variant="outlined"
                          label="Moeda para o valor da garantia"
                          className="rounded"
                          type={"text"}
                        />
                      )}
                    />
                  </div>
                </div>
                {moedaGarantia && moedaGarantia.code === "USD" && (
                  <div className="col-md-3">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="cambioDolar"
                      placeholder="Câmbio do dia"
                      label="Câmbio do Dólar"
                      className="rounded border-bottom"
                      type={"number"}
                      id="cambioDolar"
                      value={cambioDolar}
                      defaultValue={cambioDolar}
                      onChange={(e) => setCambioDolar(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      //disabled={loader}
                    />
                  </div>
                )}
                {moedaGarantia && moedaGarantia.code === "EUR" && (
                  <div className="col-md-3">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      label="Câmbio do Euro"
                      required
                      placeholder="Câmbio do dia"
                      size="small"
                      className="rounded border-bottom mt-3"
                      type={"number"}
                      style={{ width: "100%" }}
                      value={cambioEuro}
                      onChange={(e) => setCambioEuro(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      //disabled={loader || disableCambio}
                    />
                  </div>
                )}
                <div
                  className="col-md-6 text-start"
                  title={
                    moedaGarantia
                      ? ""
                      : "Seleccione a moeda correspondente a este campo"
                  }
                >
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="valorGarantia"
                      label={`Valor da garantia ${
                        moedaGarantia ? `(em  ${moedaGarantia.code})` : ""
                      }`}
                      //placeholder="Número do concurso"
                      type={"number"}
                      id="valorGarantia"
                      className="rounded"
                      value={valorGarantia}
                      defaultValue={valorGarantia}
                      error={errorValorGarantia}
                      onChange={(e) => setValorGarantia(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={
                        loader ||
                        !moedaGarantia ||
                        (moedaGarantia.code === "EUR" && !cambioEuro) ||
                        (moedaGarantia.code === "USD" && !cambioDolar)
                      }
                    />
                  </div>
                  {/* {moedaGarantia && moedaGarantia.code !== "MT" && (
                  <small>{getValorGarantia(valorGarantia)} Meticais</small>
                )} */}
                </div>
                <div className="col-md-3">
                  <Autocomplete
                    id="clear-on-escape"
                    options={provinces}
                    getOptionLabel={(option) => option?.province}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={handleChangeProvince}
                    value={provincia}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label="Província"
                        className="rounded"
                        type={"text"}
                        error={errorProvincia}
                      />
                    )}
                  />
                </div>
                <div className="col-md-3">
                  <Autocomplete
                    id="clear-on-escape"
                    options={districts || []}
                    getOptionLabel={(option) => option}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(event, value) => setDistrito(value)}
                    value={distrito}
                    disabled={loader || !provincia}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label="Distrito"
                        className="rounded"
                        type={"text"}
                      />
                    )}
                  />
                </div>{" "}
                <div className="col-md-3">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="dataAnuncio"
                      label="Data do anúncio"
                      type={"date"}
                      id="dataAnuncio"
                      className="rounded"
                      value={dataAnuncio}
                      defaultValue={dataAnuncio}
                      error={errorDataAnuncio}
                      onChange={(e) => setDataAnuncio(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="dataSubmissao"
                      label="Data de submissão"
                      type={"date"}
                      id="dataSubmissao"
                      className="rounded"
                      value={dataSubmissao}
                      defaultValue={dataSubmissao}
                      error={errorDataSubmissao}
                      onChange={(e) => setDataSubmissao(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    floatingLabelStyle={{ color: "#568C3E" }}
                    size="small"
                    margin="normal"
                    fullWidth
                    required
                    name="descricao"
                    label="Descrição"
                    type={"text"}
                    id="descricao"
                    multiline
                    rows={4}
                    className="rounded"
                    value={descricao}
                    defaultValue={descricao}
                    error={errorDescricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{ style: { border: "none", paddingLeft: 10 } }}
                    disabled={loader}
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      name="extraInfo"
                      label="Observações"
                      type={"text"}
                      id="extraInfo"
                      multiline
                      rows={4}
                      className="rounded"
                      value={extraInfo}
                      defaultValue={extraInfo}
                      onChange={(e) => {
                        setExtraInfo(e.target.value);
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                {value === 1 && (
                  /* Dados de adjudicação */
                  <div className="col-md-12">
                    <Card className="shadow-sm mt-4 w-100">
                      <Card.Header
                        as="h5"
                        className="d-flex justify-content-between align-items-center pr-0"
                        style={{ backgroundColor: "rgba(2, 159, 214, 0.04)" }}
                      >
                        Adjudicação do Concurso
                        <Button
                          variant="text"
                          className="text-capitalize px-2 mr-1"
                          style={{ color: "#2396EF" }}
                          onClick={() => setShowAdjudicar(true)}
                          size="small"
                          disabled={loader}
                        >
                          Editar dados de adjudicação
                        </Button>
                      </Card.Header>
                      <Card.Body>
                        {" "}
                        <Row className="mt-2 mb-3">
                          <Col sm={4} className="d-flex" style={{}}>
                            <div>
                              <div className="label-details mb-1 d-flex">
                                Entidade Contratada:
                              </div>
                              <h6 className="dados d-flex border-lef">
                                {" "}
                                {dadosAdjudicacao?.entidadeContrada?.nome ||
                                  "---"}
                              </h6>
                            </div>
                          </Col>{" "}
                          <Col sm={4} className="d-flex" style={{}}>
                            <div>
                              <div className="label-details mb-1 d-flex">
                                Data de Adjudicação:
                              </div>
                              <h6 className="dados d-flex border-lef">
                                {dadosAdjudicacao?.dataAdjudicacao
                                  ? dateFormat(
                                      dadosAdjudicacao?.dataAdjudicacao,
                                      "dd/mm/yyyy"
                                    )
                                  : "---"}
                              </h6>
                            </div>
                          </Col>{" "}
                        </Row>
                        <Row className="mt-2 mb-3">
                          <Col sm={4} className="d-flex" style={{}}>
                            <div>
                              <div className="label-details mb-1 d-flex">
                                {" "}
                                Valor Adjudicado em Meticais:{" "}
                              </div>
                              <h6 className="dados d-flex border-lef">
                                {" "}
                                {dadosAdjudicacao?.valorAdjudicadoMt
                                  ? formatter.format(
                                      dadosAdjudicacao?.valorAdjudicadoMt
                                    )
                                  : "---"}
                              </h6>
                            </div>
                          </Col>{" "}
                          <Col sm={4} className="d-flex" style={{}}>
                            <div>
                              <div className="label-details mb-1 d-flex">
                                {" "}
                                Valor Adjudicado em Dólares:{" "}
                              </div>
                              <h6 className="dados d-flex border-lef">
                                {" "}
                                {dadosAdjudicacao?.valorAdjudicadoUsd
                                  ? formatter.format(
                                      dadosAdjudicacao?.valorAdjudicadoUsd
                                    )
                                  : "---"}
                              </h6>
                            </div>
                          </Col>
                          <Col sm={4} className="d-flex" style={{}}>
                            <div>
                              <div className="label-details mb-1 d-flex">
                                {" "}
                                Valor Adjudicado Euros:{" "}
                              </div>
                              <h6 className="dados d-flex border-lef">
                                {" "}
                                {dadosAdjudicacao?.valorAdjudicadoEur
                                  ? formatter.format(
                                      dadosAdjudicacao?.valorAdjudicadoEur
                                    )
                                  : "---"}
                              </h6>
                            </div>
                          </Col>
                        </Row>
                        <Divider />
                        <Row className="mt-2 mb-3">
                          <Col sm={4} className="d-flex" style={{}}>
                            <div>
                              <div className="label-details mb-1 d-flex">
                                {" "}
                                Total Adjudicado (MT):{" "}
                              </div>
                              <h6 className="dados d-flex border-lef">
                                {" "}
                                {dadosAdjudicacao?.totalAdjudicadoMt
                                  ? formatter.format(
                                      dadosAdjudicacao?.totalAdjudicadoMt
                                    )
                                  : "---"}
                              </h6>
                            </div>
                          </Col>{" "}
                          <Col sm={4} className="d-flex" style={{}}>
                            <div>
                              <div className="label-details mb-1 d-flex">
                                {" "}
                                Total Adjudicado (USD):{" "}
                              </div>
                              <h6 className="dados d-flex border-lef">
                                {" "}
                                {dadosAdjudicacao?.totalAdjudicadoUsd
                                  ? formatter.format(
                                      dadosAdjudicacao?.totalAdjudicadoUsd
                                    )
                                  : "---"}
                              </h6>
                            </div>
                          </Col>
                          <Col sm={4} className="d-flex" style={{}}>
                            <div>
                              <div className="label-details mb-1 d-flex">
                                {" "}
                                Total Adjudicado (EUR):{" "}
                              </div>
                              <h6 className="dados d-flex border-lef">
                                {" "}
                                {dadosAdjudicacao?.totalAdjudicadoEur
                                  ? formatter.format(
                                      dadosAdjudicacao?.totalAdjudicadoEur
                                    )
                                  : "---"}
                              </h6>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                )}
                {value === 2 && (
                  /* Dados de cancelamento */
                  <div className="col-md-12">
                    <Card className="shadow-sm mt-4 w-100">
                      <Card.Header
                        as="h5"
                        className="d-flex justify-content-between align-items-center pr-0"
                        style={{ backgroundColor: "rgba(2, 159, 214, 0.04)" }}
                      >
                        Cancelamento do Concurso
                        <Button
                          variant="text"
                          className="text-capitalize px-2 mr-1"
                          style={{ color: "#2396EF" }}
                          onClick={() => setShowCancelar(true)}
                          size="small"
                          disabled={loader}
                        >
                          Editar dados de cancelamento
                        </Button>
                      </Card.Header>
                      <Card.Body>
                        {" "}
                        <Row className="mt-2 mb-3">
                          <Col
                            sm={12}
                            className="d-flex justify-content-start"
                            style={{}}
                          >
                            <div className="w-100 text-start">
                              <div className="label-details mb-1 d-flex">
                                Motivo do Cancelamento:
                              </div>
                              <div
                                className="d-flex border-left justify-content-start p-2 text-start"
                                style={{ minHeight: 100, textAlign: "left" }}
                              >
                                {dadosCancelamento?.motivoCancelamento || "---"}
                              </div>
                            </div>
                          </Col>{" "}
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                )}
                <div className="col-3 d-flex justify-content-start align-items-center pt-4">
                  <Button
                    variant="text"
                    className="text-capitalize me-4 px-3 mt-2"
                    style={{ color: "red" }}
                    onClick={() => cleanFields()}
                    disabled={loader}
                  >
                    Cancelar
                  </Button>
                </div>
                <div className="col-9 d-flex justify-content-end align-items-center pt-4">
                  <Button
                    variant="contained"
                    type="submit"
                    className="text-light text-capitalize"
                    style={{
                      backgroundColor: disableSave ? "#8E8D8A" : "#568C3E",
                    }}
                    disabled={disableSave}
                    startIcon={
                      loader ? (
                        <Spinner
                          animation="border"
                          style={{ width: 24, height: 24 }}
                        />
                      ) : (
                        <Save />
                      )
                    }
                    onClick={() => requiredFields()}
                  >
                    Salvar Anúncio
                  </Button>
                </div>
              </div>
            </form>
          )}
        </TabsComponent>
      </Paper>

      <AdicionarEntidade
        show={showAddEntity}
        closeModal={closeAddEntity}
        entityType={entityType}
        fetchEntities={getEntidadesContratantes}
        setEntity={setEntidadeContratante}
      />

      <AdjudicarAnuncio
        show={showAdjudicar}
        closeModal={closeAdjudicar}
        anuncio={announce}
        initialDadosAdjudicacao={initialDadosAdjudicacao}
        dadosAdjudicacao={dadosAdjudicacao}
        setDadosAdjudicacao={setDadosAdjudicacao}
      />

      <CancelarAnuncio
        show={showCancelar}
        closeModal={closeCancelar}
        anuncio={announce}
        initialDadosCancelamento={initialDadosCancelamento}
        dadosCancelamento={dadosCancelamento}
        setDadosCancelamento={setDadosCancelamento}
      />

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom" + "center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
}
