/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../api/requests/useGetData";
import SnackBar from "../../commons/snackbar";
import { Button, IconButton } from "@material-ui/core";
import "./details.css";
import { Card, Col, Row } from "react-bootstrap";
import dateFormat from "dateformat";
import { BlockOutlined, Done, Edit, MoreVert } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import { AdjudicarAnuncio } from "../../commons/modal/adjudicar";
import { CancelarAnuncio } from "../../commons/modal/cancelar";
import Confirmacao from "../../commons/modal/confirmarOperacao";
import { FecharAnuncio } from "../../commons/modal/fechar";
import FloatButton from "../floatButton";
import building from "../../../assets/images/hook2.png";
import services from "../../../assets/images/service.png";
import assets from "../../../assets/images/asset.png";
import { useAuthUser } from "react-auth-kit";

export default function AnnouNceDetails() {
  const auth = useAuthUser()()?.data;

  const { id } = useParams();
  let navigate = useNavigate();

  const [loadAction, setLoadAction] = useState(false);
  const [loading, setLoading] = useState(true);
  const [announce, setAnnounce] = useState(0);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/concurso/${id}`)
      .then((response) => {
        setAnnounce(response.data.data);
        setLoading(false);
        console.log("concurso", response);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const [checked, setChecked] = useState(false);
  const handleChangeChecked = (e) => setChecked(e.target.checked);

  const [showAdjudicar, setShowAdjudicar] = useState(false);
  const closeAdjudicar = () => {
    setShowAdjudicar(false);
    setChecked(false);
  };

  const [showPublicar, setShowPublicar] = useState(false);
  const closePublicar = () => {
    setShowPublicar(false);
    setChecked(false);
  };

  const [showOcultar, setShowOcultar] = useState(false);
  const closeOcultar = () => {
    setShowOcultar(false);
    setChecked(false);
  };

  const [showFechar, setShowFechar] = useState(false);
  const closeFechar = () => {
    setShowFechar(false);
    setChecked(false);
  };

  const [showCancelar, setShowCancelar] = useState(false);
  const closeCancelar = () => {
    setShowCancelar(false);
    setChecked(false);
  };

  const [showEliminar, setShowEliminar] = useState(false);
  const closeEliminar = () => {
    setShowEliminar(false);
    setChecked(false);
  };

  const publicar = () => {
    setLoadAction(true);
    const dados = {
      estado_pub: "Sim",
    };
    axios
      .put(`${BASE_URL}/concurso/${id}`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Anúncio publicado com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        closePublicar();
        console.log("saved?", response);
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const ocultar = () => {
    setLoadAction(true);
    const dados = {
      estado_pub: "Não",
    };
    axios
      .put(`${BASE_URL}/concurso/${id}`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Anúncio oculto com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        closeOcultar();
        console.log("saved?", response);
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const eliminar = () => {
    setLoadAction(true);
    axios
      .delete(`${BASE_URL}/concurso/${id}`)
      .then((response) => {
        setOpen(true);
        setmessage("Anúncio emininado com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        closeEliminar();
        console.log("saved?", response);
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const getEntidadesContratantes = () => {
    axios.get(`${BASE_URL}/entidade-contratante`).then((response) => {
      localStorage.setItem("contratantes", JSON.stringify(response.data.data));
    });
  };

  const getEntidadesContratadas = () => {
    axios.get(`${BASE_URL}/entidade-contratada`).then((response) => {
      localStorage.setItem("contratadas", JSON.stringify(response.data.data));
    });
  };

  const getResources = () => {
    setLoading(true);
    axios.get(`${BASE_URL}/config`).then((response) => {
      localStorage.setItem(
        "fontes",
        JSON.stringify(response.data.data.filter((el) => el.nome === "Fonte"))
      );
      localStorage.setItem(
        "garantias",
        JSON.stringify(
          response.data.data.filter((el) => el.nome === "Garantia")
        )
      );
      localStorage.setItem(
        "regulamentos",
        JSON.stringify(
          response.data.data.filter((el) => el.nome === "Regulamento")
        )
      );
      localStorage.setItem(
        "modalidades",
        JSON.stringify(
          response.data.data.filter((el) => el.nome === "Modalidade")
        )
      );
      setLoading(false);
    });
  };

  useEffect(() => {
    getEntidadesContratantes();
    getEntidadesContratadas();
    getResources();
  }, []);
  const formatter = new Intl.NumberFormat(["ban", "id"]);

  return (
    <div className="container-fluid">
      <div className="text-center wow fadeInUp mb-4" data-wow-delay="0.1s">
        {announce.categoria === "Obras" ? (
          <div class="foto-section">
            <img src={building} alt="Foto do Usuário" />{" "}
          </div>
        ) : announce.categoria === "Bens" ? (
          <div class="foto-section">
            <img src={assets} alt="Foto do Usuário" />{" "}
          </div>
        ) : (
          <div class="foto-section">
            <img src={services} alt="Foto do Usuário" />{" "}
          </div>
        )}

        {!loading && (
          <>
            <h3 className="mt-3">Dados do Concurso</h3>
            <h6 className="ml-2 text-mains" style={{ fontSize: 17 }}>
              {announce?.estado?.slice(0, -1)}
              {announce.categoria === "Obras" ? "a" : "o"}
            </h6>
          </>
        )}
      </div>
      <Card className="shadow-sm">
        <Card.Header
          as="h5"
          className="d-flex justify-content-between"
          style={{ backgroundColor: "rgba(2, 159, 214, 0.04)" }}
        >
          Apresentação da proposta
          <div className="d-flex align-items-center">
            {announce?.estado === "Lançado" ? (
              <Button
                className="text-capitalize mr-3 shadow-sm"
                style={{ color: "#2075A9", backgroundColor: "#FFF" }}
                startIcon={<Done />}
                onClick={() => {
                  setShowAdjudicar(true);
                }}
              >
                Adjudicar
              </Button>
            ) : (
              <></>
            )}
            {announce?.estado === "Adjudicado" ? (
              <Button
                className="text-capitalize mr-3 shadow-sm px-4"
                style={{ color: "#2075A9", backgroundColor: "#FFF" }}
                startIcon={<BlockOutlined />}
                onClick={() => {
                  setShowFechar(true);
                }}
              >
                Fechar
              </Button>
            ) : (
              <></>
            )}
            <div className="text-end dropdownrounded">
              <IconButton
                id="dropdownMenu2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                size="small"
              >
                <MoreVert style={{ color: "#2075A9" }} />
              </IconButton>
              <ul
                className="dropdown-menu shadow bg-white mt-2 border"
                aria-labelledby="dropdownMenu2"
              >
                {announce.estado_pub === "Sim" ? (
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setShowOcultar(true);
                    }}
                  >
                    Ocultar
                  </li>
                ) : (
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setShowPublicar(true);
                    }}
                  >
                    Publicar
                  </li>
                )}
                {(announce?.estado === "Lançado" ||
                  announce?.estado === "Adjudicado") && (
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setShowCancelar(true);
                    }}
                  >
                    Cancelar
                  </li>
                )}

                {auth?.role === "Super administrador" && (
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setShowEliminar(true);
                    }}
                  >
                    Eliminar
                  </li>
                )}
              </ul>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <div className="text-center my-5 py-5">
              <div class="my-4 py-4">
                <div class="my-5 py-5">
                  <div class="loader"></div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <Row className="mb-5">
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      Referência do Concurso:
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.ref_concurso || "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Referência BCC:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.ref_bcc || "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Entidade Contratante:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef texto">
                      {" "}
                      {announce?.entidade_contratante?.nome || "---"}
                    </h6>
                  </div>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      Fonte do Anúncio:
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.fonte || "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Regulamento Aplicado:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.regulamento || "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Modalidade:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.modalidade || "---"}
                    </h6>
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">Garantia:</div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.garantia || "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Valor da Garantia:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.valor_garantia
                        ? formatter.format(announce?.valor_garantia)
                        : "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Prazo da Garantia:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.prazo_garantia || "---"}
                    </h6>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4 mb-3">
                <Col sm={3} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">Província:</div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.provincia || "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={3} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex"> Distrito: </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.distrito || "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={3} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Data do anúncio:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.data_anuncio
                        ? dateFormat(announce?.data_anuncio, "dd/mm/yyyy")
                        : "---"}
                    </h6>
                  </div>
                </Col>
                <Col sm={3} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Data da submissão:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.data_submissao
                        ? dateFormat(announce?.data_submissao, "dd/mm/yyyy")
                        : "---"}
                    </h6>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3 mt-4">
                <Col sm={12} className="d-flex" style={{}}>
                  <div className="w-100">
                    <div className="label-details mb-1 d-flex">Descrição:</div>
                    <div
                      className="d-flex justify-content-start border-lef border p-2"
                      style={{ height: 150 }}
                    >
                      {announce?.descricao || "---"}
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>

      {!loading && (
        <>
          <Card className="shadow-sm mt-4">
            <Card.Header
              as="h5"
              className="d-flex justify-content-between"
              style={{ backgroundColor: "rgba(2, 159, 214, 0.04)" }}
            >
              Adjudicação do Concurso
            </Card.Header>
            <Card.Body>
              {" "}
              <Row className="mt-2 mb-3">
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      Entidade Contratada:
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.entidade_contratada?.nome || "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      Data de Adjudicação:
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {announce?.data_adjudicacao
                        ? dateFormat(announce?.data_adjudicacao, "dd/mm/yyyy")
                        : "---"}
                    </h6>
                  </div>
                </Col>{" "}
              </Row>
              <Row className="mt-2 mb-3">
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Valor Adjudicado em Meticais:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.valor_adjudicado_MT
                        ? formatter.format(announce?.valor_adjudicado_MT)
                        : "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Valor Adjudicado em Dólares:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.valor_adjudicado_USD
                        ? formatter.format(announce?.valor_adjudicado_USD)
                        : "---"}
                    </h6>
                  </div>
                </Col>
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Valor Adjudicado Euros:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.valor_adjudicado_EUR
                        ? formatter.format(announce?.valor_adjudicado_EUR)
                        : "---"}
                    </h6>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2 mb-3">
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Total Adjudicado (MT):{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.total_MT
                        ? formatter.format(announce?.total_MT)
                        : "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Total Adjudicado (USD):{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.total_USD
                        ? formatter.format(announce?.total_USD)
                        : "---"}
                    </h6>
                  </div>
                </Col>
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Total Adjudicado (EUR):{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.total_EUR
                        ? formatter.format(announce?.total_EUR)
                        : "---"}
                    </h6>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="shadow-sm mt-4">
            <Card.Header
              as="h5"
              className="d-flex"
              style={{ backgroundColor: "rgba(2, 159, 214, 0.04)" }}
            >
              Fecho do Concurso
            </Card.Header>
            <Card.Body>
              {" "}
              <Row className="mt-1 mb-5">
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      Tipo de Contrato:
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.tipo_contracto || "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Taxa de Caderno:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.taxa_caderno || "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Valor Final do Contrato (MT):{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.valor_final_MT
                        ? formatter.format(announce?.valor_final_MT)
                        : "---"}
                    </h6>
                  </div>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      Data de Conclusão Original:
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.data_conclusao_original
                        ? dateFormat(
                            announce?.data_conclusao_original,
                            "dd/mm/yyyy"
                          )
                        : "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Data de Conclusão Final:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.data_conclusao_real
                        ? dateFormat(
                            announce?.data_conclusao_real,
                            "dd/mm/yyyy"
                          )
                        : "---"}
                    </h6>
                  </div>
                </Col>{" "}
                <Col sm={4} className="d-flex" style={{}}>
                  <div>
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Data de Fechamento:{" "}
                    </div>
                    <h6 className="dados d-flex border-lef">
                      {" "}
                      {announce?.data_conclusao_real
                        ? dateFormat(
                            announce?.data_conclusao_real,
                            "dd/mm/yyyy"
                          )
                        : "---"}
                    </h6>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="shadow-sm mt-4">
            <Card.Header
              as="h5"
              className="d-flex"
              style={{ backgroundColor: "rgba(2, 159, 214, 0.04)" }}
            >
              Cancelamento do Concurso
            </Card.Header>
            <Card.Body>
              {" "}
              <Row className="mb-2">
                <Col sm={12} className="d-flex" style={{}}>
                  <div className="w-100">
                    <div className="label-details mb-1 d-flex">
                      {" "}
                      Motivo do Cancelamento:{" "}
                    </div>
                    <div
                      className="d-flex border-lef border p-2"
                      style={{ height: 150 }}
                    >
                      {announce?.fundamento_cancelamento || "---"}
                    </div>
                  </div>
                </Col>{" "}
              </Row>
            </Card.Body>
          </Card>

          <Card className="shadow-sm mt-4">
            <Card.Header
              as="h5"
              className="d-flex"
              style={{ backgroundColor: "rgba(2, 159, 214, 0.04)" }}
            >
              Observações Gerais
            </Card.Header>
            <Card.Body>
              {" "}
              <Row className="mb-2">
                <Col sm={12} className="d-flex" style={{}}>
                  <div className="w-100">
                    <div
                      className="d-flex border-lef  p-2"
                      style={{ height: 150 }}
                    >
                      {announce?.observacao || "---"}
                    </div>
                  </div>
                </Col>{" "}
              </Row>
            </Card.Body>
          </Card>
        </>
      )}

      <AdjudicarAnuncio
        show={showAdjudicar}
        closeModal={closeAdjudicar}
        anuncio={announce}
        fetchAnnounces={fetchData}
      />

      <CancelarAnuncio
        show={showCancelar}
        closeModal={closeCancelar}
        anuncio={announce}
        fetchAnnounces={fetchData}
      />

      <Confirmacao
        show={showPublicar}
        closeModal={closePublicar}
        action={publicar}
        actionType={"Publicar"}
        objectType={`o Anúncio`}
        objectDescription={announce?.ref_bcc}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <Confirmacao
        show={showOcultar}
        closeModal={closeOcultar}
        action={ocultar}
        actionType={"Ocultar"}
        objectType={`o Anúncio`}
        objectDescription={announce?.ref_bcc}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <Confirmacao
        show={showEliminar}
        closeModal={closeEliminar}
        action={eliminar}
        actionType={"Eliminar"}
        objectType={`o Anúncio`}
        objectDescription={announce?.ref_bcc}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <FecharAnuncio
        show={showFechar}
        closeModal={closeFechar}
        anuncio={announce}
        fetchAnnounces={fetchData}
      />
      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />

      <FloatButton
        size={"small"}
        title="Editar"
        color="#FFF"
        backgroundColor="#568C3E"
        icon={<Edit />}
        action={() =>
          navigate(`/admin/anuncios/editar/${id}`, {
            state: { state: announce },
          })
        }
      />
    </div>
  );
}
