import React from "react";
import Dashboard from "../../../components/dashboard/dashboard";
import AppbarDrawer from "../../../components/dashboard/drawer";



const DashboardHomePage = () => {
  return (
    <div >
      <AppbarDrawer>
        <Dashboard/>
      </AppbarDrawer>
    </div>
  );
};
export default DashboardHomePage;

