import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import nothing22 from "../../../assets/images/nothingWaw.gif";
import CardAdjudicados from "../../commons/cards/adjudicadosCard";
import building from "../../../assets/images/hook2.png";
import services from "../../../assets/images/service.png";
import assets from "../../../assets/images/asset.png";
import CardPublicados from "../../commons/cards/publicadosCard";
import CardLancados from "../../commons/cards/lancadosCard";
import CardCancelados from "../../commons/cards/canceladosCard";
import { getAnnounceLabel, getLabel } from "../../utils/getAnnouncementLabels";

export default function CardsAnnouncements({
  loading,
  anuncios,
  setShowAdjudicar,
  setShowFechar,
  setSelectedAnuncio,
  setShowPublicar,
  setShowCancelar,
  setShowOcultar,
  setShowEliminar,
  userData,
}) {
  const { type, announce } = useParams();

  return (
    <div>
      {anuncios?.length === 0 && !loading && (
        <div className="p-5 text-center d-inline-block w-100 align-items-center justify-content-center">
          <div>
            <img
              src={nothing22}
              alt=""
              title="Nada para mostrar"
              style={{ width: "170px" }}
            />
          </div>
          <span>
            {`Nenhum${announce === "obras" ? "a" : ""}`} {""}
            {getAnnounceLabel(announce).slice(0, -1)}{" "}
            {`${getLabel(type).slice(0, -2)}${
              announce === "obras" ? "a" : "o"
            }`}{" "}
            {`encontrad${announce === "obras" ? "a" : "o"}!`}
          </span>{" "}
        </div>
      )}
      {type === "lancados" && (
        <Row className="justify-content-center justify-content-sm-start">
          {/* class="col-xl-3 col-sm-6 col-12" */}
          {anuncios?.map((anuncio, index) => (
            <Col xs={12} sm={6} lg={4} xl={3}>
              <CardLancados
                anuncio={anuncio}
                imagem={
                  announce === "obras"
                    ? building
                    : announce === "bens"
                    ? assets
                    : services
                }
                setSelectedAnuncio={setSelectedAnuncio}
                showAdjudicar={() => setShowAdjudicar(true)}
                showPublicar={() => setShowPublicar(true)}
                showCancelar={() => setShowCancelar(true)}
                showEliminar={() => setShowEliminar(true)}
                showOcultar={() => setShowOcultar(true)}
                userData={userData}
              />
            </Col>
          ))}
        </Row>
      )}
      {type === "adjudicados" && (
        <Row className="justify-content-center justify-content-sm-start">
          {/* class="col-xl-3 col-sm-6 col-12" */}
          {anuncios?.map((anuncio, index) => (
            <Col xs={12} sm={6} lg={4} xl={3}>
              <CardAdjudicados
                anuncio={anuncio}
                imagem={
                  announce === "obras"
                    ? building
                    : announce === "bens"
                    ? assets
                    : services
                }
                setSelectedAnuncio={setSelectedAnuncio}
                showFechar={() => setShowFechar(true)}
                showPublicar={() => setShowPublicar(true)}
                showCancelar={() => setShowCancelar(true)}
                showEliminar={() => setShowEliminar(true)}
                showOcultar={() => setShowOcultar(true)}
                userData={userData}
              />
            </Col>
          ))}
        </Row>
      )}
      {type === "cancelados" && (
        <Row className="justify-content-center justify-content-sm-start">
          {/* class="col-xl-3 col-sm-6 col-12" */}
          {anuncios?.map((anuncio, index) => (
            <Col xs={12} sm={6} lg={4} xl={3}>
              <CardCancelados
                anuncio={anuncio}
                imagem={
                  announce === "obras"
                    ? building
                    : announce === "bens"
                    ? assets
                    : services
                }
                setSelectedAnuncio={setSelectedAnuncio}
                showPublicar={() => setShowPublicar(true)}
                showEliminar={() => setShowEliminar(true)}
                showOcultar={() => setShowOcultar(true)}
                userData={userData}
              />
            </Col>
          ))}
        </Row>
      )}{" "}
      {type === "publicados" && (
        <Row className="justify-content-center justify-content-sm-start">
          {/* class="col-xl-3 col-sm-6 col-12" */}
          {anuncios?.map((anuncio, index) => (
            <Col xs={12} sm={6} lg={4} xl={3}>
              <CardPublicados
                anuncio={anuncio}
                imagem={
                  announce === "obras"
                    ? building
                    : announce === "bens"
                    ? assets
                    : services
                }
                setSelectedAnuncio={setSelectedAnuncio}
                showOcultar={() => setShowOcultar(true)}
              />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}
