import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import PieHighChart from "../../commons/charts/PieHighChart";
import LineHighChart from "../../commons/charts/LineHighChart";
import ColumnHighChart from "../../commons/charts/ColumnHighChart";
import BarHighChart from "../../commons/charts/BarHighChart";
import AreaHighChart from "../../commons/charts/AreaHighChart";
import {
  areaData,
  barData,
  columnData,
  lineData,
  pieData,
} from "./demoData/chartsDemoData";
import MozambiqueMap from "./highcharts/MozambiqueMap";
import TabsComponent from "../../commons/tabs/TabsChartsComponent";
import { chartTabs, provinces } from "../../utils/resourcesFields";
import { Autocomplete } from "@material-ui/lab";
import { InputAdornment, TextField } from "@material-ui/core";
import { Event } from "@material-ui/icons";

const years = [
  "2024990000000",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
];

export default function GeneralCharts() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setfilter(chartTabs[newValue]);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [ano, setAno] = useState("2023");
  const [filter, setfilter] = useState(chartTabs[0]);

  return (
    <TabsComponent
      value={value}
      handleChange={handleChange}
      handleChangeIndex={handleChangeIndex}
    >
      <div
        className="mt-4 px-0 wow fadeInUp border-bottom d-flex"
        data-wow-delay="0.1s"
      >
        <h3 className="col-10 d-flex mt-1 pl-0">
          {filter.title} ({ano})
        </h3>

        <Autocomplete
          id="clear-on-escape"
          options={years}
          getOptionLabel={(option) => option}
          noOptionsText="Nenhuma opção encontrada"
          className="col-2 pr-0"
          onChange={(e, value) => setAno(value)}
          value={ano}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              size="small"
              variant="outlined"
              label="Seleccione o ano"
              className="rounded"
              type={"text"}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    {" "}
                    <Event fontSize="small" style={{ color: "#303F9F" }} />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              /* InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EventIcon
                        fontSize="small"
                        style={{ color: "#303F9F" }}
                      />
                    </InputAdornment>
                  ),
                }} */
            />
          )}
        />
      </div>
      <div className="mt-4 container-fluid">
        <Row className="mb-4 shadow-sm rounded bg-white">
          <Col sm={12} md={4} className="border-left p-0">
            <PieHighChart title="Obras" data={pieData} />
          </Col>
          <Col sm={12} md={4} className="border-left p-0">
            <PieHighChart title="Bens" data={pieData} />
          </Col>
          <Col sm={12} md={4} className="border-left p-0">
            <PieHighChart title="Serviços" data={pieData} />
          </Col>
        </Row>

        <Row className="mb-4 shadow-sm rounded bg-white">
          <Col sm={12} className="border-left p-0">
            <ColumnHighChart title="Concursos por província" data={columnData} categories={provinces} />
          </Col>
        </Row>

        <Row className="mb-4 shadow-sm rounded bg-white">
          <Col sm={12} className="border-left p-0">
            <LineHighChart title="Simple high chart" data={lineData} />
          </Col>
        </Row>

        <Row className="mb-4 shadow-sm rounded bg-white">
          <Col sm={12} className="border-left p-0">
            <BarHighChart title="Simple high chart" data={barData} />
          </Col>
        </Row>

        <Row className="mb-4 shadow-sm rounded bg-white">
          <Col sm={12} className="border-left p-0">
            <AreaHighChart title="Simple high chart" data={areaData} />
          </Col>
        </Row>

        <Row className="mb-4 shadow-sm rounded bg-white">
          <Col sm={12} className="border-left p-0">
            <MozambiqueMap />
          </Col>
        </Row>
      </div>
    </TabsComponent>
  );
}
