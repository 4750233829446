/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";
import { colors } from "../../../../assets/colors/barChartBkColor";
import "../../../../assets/css/chart.css";
import { CardHeader, CardContent, Card } from "@material-ui/core";
import ChartComponent from "../../../commons/charts/basicCharts";
import { meses, modalidades } from "../../../utils/resourcesFields";

const Modalidade_Mes = ({title}) => {
  var data = {
    labels: meses,
    datasets: [
      {
        label: modalidades[0],
        /* backgroundColor: "rgba(244, 67, 54, 1)", */
        backgroundColor: "rgba(244, 67, 54, 1)",
        borderColor: "rgba(255, 255, 255, 1)",
        borderRadius: 2,
        borderWidth: 1,
        barThickness: 15,
        data: [22, 23, 2, 4, 12, 4, 15, 17, 12, 14, 30, 7],
      },

      {
        label: modalidades[1],
        /* backgroundColor: "rgba(255, 152, 0, 1)", */
        backgroundColor: "rgba(255, 152, 0, 1)",
        borderColor: "rgba(255, 255, 255, 1)",
        borderRadius: 2,
        borderWidth: 1,
        barThickness: 15,
        data: [12, 17, 24, 4, 1, 0, 23, 10, 14, 20, 10, 17],
      },

      {
        label: modalidades[2],
        /* backgroundColor: "rgba(45, 109, 155, 1)", */
        backgroundColor: "rgba(45, 109, 155, 1)",
        borderColor: "rgba(255, 255, 255, 1)",
        borderRadius: 2,
        borderWidth: 1,
        barThickness: 15,
        data: [14, 13, 4, 5, 1, 20, 13, 10, 14, 27, 10, 10],
      },
      {
        label: modalidades[3],
        /* backgroundColor: "rgba(94, 209, 209, 1)", */
        backgroundColor: "rgba(94, 209, 209, 1)",
        borderColor: "rgba(255, 255, 255, 1)",
        borderRadius: 2,
        borderWidth: 1,
        barThickness: 15,
        data: [2, 4, 4, 4, 1, 0, 23, 0, 24, 27, 10, 7],
      }
    ],
  };

  return (
    <ChartComponent
      title={title}
      //loader={isFetching}
      data={data}
      chartType="bar"
      indexAxis="x"
      legendPosition="top"
    />
  );
};

export default Modalidade_Mes;
