import React from "react";
import AppbarDrawer from "../../../components/dashboard/drawer";
import GeneralCharts from "../../../components/dashboard/reports/reportCharts";



const GeneralChartsPage = () => {
  return (
    <div >
      <AppbarDrawer>
        <GeneralCharts/>
      </AppbarDrawer>
    </div>
  );
};
export default GeneralChartsPage;

