/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../../../../assets/css/chart.css";
import ChartComponent from "../../../commons/charts/basicCharts";
import { estados, provinces } from "../../../utils/resourcesFields";

const ProvinciaEstado = ({ title }) => {
  var data = {
    labels: provinces,
    datasets: [
      {
        label: estados[0],
        /* backgroundColor: "rgba(244, 67, 54, 1)", */
        backgroundColor: "rgba(244, 67, 54, 1)",
        borderColor: "rgba(255, 255, 255, 1)",
        borderRadius: 2,
        borderWidth: 1,
        barThickness: 15,
        data: [22, 23, 2, 4, 12, 4, 15, 17, 12, 14, 30],
      },

      {
        label: estados[1],
        /* backgroundColor: "rgba(255, 152, 0, 1)", */
        backgroundColor: "rgba(255, 152, 0, 1)",
        borderColor: "rgba(255, 255, 255, 1)",
        borderRadius: 2,
        borderWidth: 1,
        barThickness: 15,
        data: [12, 17, 24, 4, 1, 0, 23, 10, 14, 20, 10],
      },

      {
        label: estados[2],
        /* backgroundColor: "rgba(45, 109, 155, 1)", */
        backgroundColor: "rgba(45, 109, 155, 1)",
        borderColor: "rgba(255, 255, 255, 1)",
        borderRadius: 2,
        borderWidth: 1,
        barThickness: 15,
        data: [14, 13, 4, 5, 1, 20, 13, 10, 14, 27, 10],
      },
    ],
  };

  return (
    <ChartComponent
      title={title}
      //loader={isFetching}
      data={data}
      chartType="bar"
      indexAxis="x"
      legendPosition="top"
    />
  );
};

export default ProvinciaEstado;
