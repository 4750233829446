import "./App.css";
import "./assets/css/style.css";
import "./assets/css/chart.css";
import "./assets/css/animate.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/map-box.css";
import "./assets/css/cards-style.css";
import "./assets/css/density-map.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl-controls/lib/controls.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Routing from "./routes/routes";

function App() {
  return (
    <div className="App">
      <Routing />
    </div>
  );
}

export default App;
