import {
  Button,
  ButtonGroup,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import EventIcon from "@material-ui/icons/Event";
import ModalidadeMes from "./charts/Modalidade_Mes";
import ModalidadeTipo from "./charts/Modalidade_Tipo";
import ProvinciaAnunciosCategorias from "./charts/Provincia_Anuncios_Categorias";
import MesCategoriaEstado from "./charts/Mes_Categoria_Estado";
import CategoriaEstado from "./charts/Categoria_Estado";
import ModalidadeProvincia from "./charts/Modalidade_Provincia";
import EstadoCategoria from "./charts/Estado_Categoria";
import ProvinciaEstado from "./charts/Provincia_Estado";
import EstadoMes from "./charts/Estado_Mes";
import CategoriaQuantidade from "./charts/CategoriaQuantidade";
import { chartTabs } from "../../utils/resourcesFields";

export default function GeneralReport() {

  const years = [
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
    "2007",
    "2006",
    "2005",
    "2004",
    "2003",
    "2002",
    "2001",
    "2000",
  ];

  const [ano, setAno] = useState("2023");
  const [filter, setfilter] = useState(chartTabs[0]);
  const changeFilter = (filter) => {
    setfilter(filter);
  };
  return (
    <div className="">
      <Row className="">
        <Col sm={10} className="">
          {" "}
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
            style={{ height: 40 }}
          >
            {chartTabs.map((value, index) => (
              <Button
                key={index}
                className="text-capitalize"
                color={filter.code === value.code ? "primary" : "default"}
                //variant={filter === value.code ? "contained" : "outlined"}
                onClick={() => changeFilter(value)}
                style={{
                  minWidth: 150,
                  borderBottom:
                    filter.code === value.code && "7px solid #3f50b5",
                }}
              >
                {value.name}
              </Button>
            ))}
          </ButtonGroup>
        </Col>
        <Col sm={2} className="">
          <Autocomplete
            id="clear-on-escape"
            options={years}
            getOptionLabel={(option) => option}
            noOptionsText="Nenhuma opção encontrada"
            onChange={(e, value) => setAno(value)}
            value={ano}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                size="small"
                variant="outlined"
                label="Seleccione o ano"
                className="rounded"
                type={"text"}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      <EventIcon
                        fontSize="small"
                        style={{ color: "#303F9F" }}
                      />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                /* InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EventIcon
                        fontSize="small"
                        style={{ color: "#303F9F" }}
                      />
                    </InputAdornment>
                  ),
                }} */
              />
            )}
          />
        </Col>
      </Row>
      <div
        className="text-center wow fadeInUp border-bottom"
        data-wow-delay="0.1s"
      >
        <h3 className="mt-4">
          {filter.title} ({ano})
        </h3>
      </div>
      {filter.code === "modalidades" && (
        <div className="mt-4 px-5 shadow rounded">
          <Row>
            <Col sm={4}>
              <ModalidadeTipo title={"Obras"} />
            </Col>
            <Col sm={4}>
              <ModalidadeTipo title={"Bens"} />
            </Col>
            <Col sm={4}>
              <ModalidadeTipo title={"Serviços"} />
            </Col>
          </Row>
          <Row className="mt-5 d-flex justify-content-center">
            <Col sm={10} className="">
              {/* Filtrar por categoria */}
              <ModalidadeProvincia title={"Concursos por província"} />
            </Col>

            <Col sm={10} className="mt-5">
              {/* Filtrar por categoria */}
              <ModalidadeMes title={"Concursos por mês"} />
            </Col>
          </Row>
        </div>
      )}

      {filter.code === "categorias" && (
        <div className="mt-4 px-5 shadow rounded">
          <Row className="mt-4 d-flex justify-content-center align-items-center">
            <Col sm={4}>
              <CategoriaQuantidade title={"Categorias"} />
            </Col>
            <Col sm={8}>
              <CategoriaEstado title={""} />
            </Col>
          </Row>
          <Row className="mt-5 d-flex justify-content-center">
            <Col sm={10} className="">
              {/* Filtrar por estado */}
              <ProvinciaAnunciosCategorias title={"Concursos por Província"} />
            </Col>
          </Row>
          <Row className="mt-5 d-flex justify-content-center">
            <Col sm={10} className="">
              {/* Filtrar por estado */}
              <MesCategoriaEstado title={"Concursos por mês - 2023"} />
            </Col>
          </Row>
        </div>
      )}

      {filter.code === "estados" && (
        <div className="mt-4 px-5 shadow rounded">
          <Row>
            <Col sm={4}>
              <EstadoCategoria title={"Obras"} />
            </Col>
            <Col sm={4}>
              <EstadoCategoria title={"Bens"} />
            </Col>
            <Col sm={4}>
              <EstadoCategoria title={"Serviços"} />
            </Col>
          </Row>
          <Row className="mt-5 d-flex justify-content-center">
            <Col sm={10} className="">
              {/* Filtrar por categoria */}
              <ProvinciaEstado title={"Concursos por Província"} />
            </Col>

            <Col sm={10} className="mt-5">
              {/* Filtrar por categoria */}
              <EstadoMes title={"Concursos por mês - 2023"} />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
