import React, { useEffect, useRef } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import * as turf from "@turf/turf";
import { useHistory, useLocation } from "react-router-dom";
import { StylesControl } from "mapbox-gl-controls";
import mapboxStyles from "../../../assets/css/mapboxStyles.json";
import coordinates from "../../utils/coordinates.json";
import { provinces } from "./provincesData";
import { colorsDensity, getValueDensity } from "../../utils/getValueDensity";

const Map = ({}) => {
  mapboxgl.accessToken =
    "pk.eyJ1IjoieGVyaW5kYTE3IiwiYSI6ImNsZ3hwN2t3aDAxcjUzZ3F2YzRxZHBreWgifQ.t6i13u5Rsh1qOH2bVvpZfQ";

  const mapContainer = useRef(null);
  const center = [34.760742, -19.311143];

  const getCenterPoint = (coordinates) => {
    if (coordinates) {
      var features = turf.points(coordinates);
      var center = turf.center(features);
    }

    return center.geometry.coordinates;
  };

  useEffect(() => {
    // Initialize the map
    const map = new mapboxgl.Map({
      container: "mapHome",
      style: "mapbox://styles/mapbox/navigation-day-v1",
      center: center,
      zoom: 4.7,
      attributionControl: false,
      preserveDrawingBuffer: true,
      scrollZoom: false,
      boxZoom: false,
      doubleClickZoom: false,
    });

    // Add controls to the map
    //map.addControl(new mapboxgl.FullscreenControl());
    //map.addControl(new mapboxgl.NavigationControl(), "top-right");
    //Add popups and markers
    //const popup = new mapboxgl.Popup();
    map.on("load", () => {
      for (const province of provinces) {
        map.style.stylesheet.layers.forEach(function (layer) {
          if (layer.type === "symbol") {
            map.removeLayer(layer.id);
          }
        });
        map.addSource(`maine${province.id}`, {
          type: "geojson",
          data: {
            type: "Feature",
            id: province.id,
            properties: { name: province.name, density: province.density },
            geometry: {
              type: "Polygon",
              coordinates: [province.limits],
            },
          },
        });
        map.addLayer({
          id: `maine${province.id}`,
          type: "fill",
          source: `maine${province.id}`,
          layout: {},
          paint: {
            "fill-color": getValueDensity(province.value),
            "fill-opacity": 0.87,
          },
        });

        // Adding a black border outline around the polygon.
        map.addLayer({
          id: `outline${province.id}`,
          type: "line",
          source: `maine${province.id}`,
          layout: {},
          paint: {
            "line-color": "white",
            "line-opacity": 0.5,
            "line-width": 1,
          },
        });

        console.log(
          "centerPoint",
          getCenterPoint(province.limits),
          province.name
        );
        map.addSource(`point${province.id}`, {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: getCenterPoint(province.limits),
            },
            properties: {
              title: province.name,
            },
          },
        });
        // Customizing the land number
        map.addLayer({
          id: `point${province.id}`,
          type: "symbol",
          source: `point${province.id}`,
          layout: {
            "icon-image": "custom-marker",
            // get the title name from the source's "title" property
            "text-field": ["get", "title"],
            "text-font": ["Open Sans Regular", "Arial Unicode MS Bold"],
            //"text-anchor": "left",
            "text-size": 10,
          },
        });
      }
    });
  }, []);

  return (
    <div className="border">
      <div id={"mapHome"} className="" ref={mapContainer}></div>

      <div className="map-overlay" id="legend">
        {colorsDensity.map((color) => (
          <div key={color.id} className="d-flex">
            <div>
              <span
                className="legend-key"
                style={{ backgroundColor: color.color }}
              ></span>
              <span className="">{color.label}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Map;
