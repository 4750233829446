import { Button } from "@material-ui/core";
import React from "react";
import { getStatusColor } from "../../utils/getAnnouncementLabels";
import { NavLink } from "react-router-dom";

export default function CardPublicados(props) {
  const { imagem, anuncio, showOcultar, setSelectedAnuncio } = props;

  return (
    <div
      className={`card card-blog shadow border-${getStatusColor(
        anuncio.estado
      )}-1 border cardio`}
    >
      <div className="card-image shadow-sm ">
        <img className="img" alt="Anúncios publicados" src={imagem} style={{ width: 40 }} />{" "}
        <div className="ripple-cont"></div>
      </div>
      <div className="table">
        <span className={`category text-${getStatusColor(anuncio.estado)}`}>
          {anuncio?.estado}
        </span>

        <div className="row mb-2">
          <div className="col-12 my-2 text-mains">
            <small style={{ fontSize: 12, textDecoration: "underline" }}>
              Ref/Conc: {anuncio.ref_concurso}
            </small>
          </div>
          <div className="col-12 mt-2">
            <div className="d-flex justify-content-start">
              <small className="text-secondary">Contratante:</small>
              <small className="text-mains texto ml-2" style={{ fontSize: 12 }}>
                {anuncio?.entidade_contratante?.nome}
              </small>
            </div>
          </div>
          <div className="col-12 mt-2">
            <div className="d-flex justify-content-start">
              <small className="text-secondary">Contratada:</small>
              <small className="text-mains texto ml-2" style={{ fontSize: 12 }}>
                {anuncio?.entidade_contratada?.nome || "---"}
              </small>
            </div>
          </div>
        </div>
        <p className="card-description mt-3"> {anuncio.descricao}</p>
        <div className="d-flex justify-content-end">
          <small className="text-mains texto ml-2" style={{ fontSize: 12 }}>
            {anuncio?.provincia}{" "}
            {anuncio?.distrito ? `, ${anuncio?.distrito}` : ""}
          </small>
        </div>
        <hr />
        <div className="d-flex w-100 justify-content-between text-center align-items-center">
          <Button
            size="small"
            color="primary"
            className="fw-bold p-2 actions-button"
            style={{ color: "#A8585E", textTransform: "capitalize" }}
            onClick={() => {
              showOcultar();
              setSelectedAnuncio(anuncio);
            }}
          >
            Ocultar
          </Button>{" "}
          <NavLink
            to={`/admin/anuncios/detalhes/${anuncio.id}`}
            className="text-dark"
          >
            {" "}
            <Button
              size="small"
              color="primary"
              className="fw-bold p-2 actions-button"
              style={{ color: "#A8585E", textTransform: "capitalize" }}
            >
              Detalhes
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
