/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";
import { colors } from "../../../../assets/colors/barChartBkColor";
import "../../../../assets/css/chart.css";
import { CardHeader, CardContent, Card } from "@material-ui/core";
import ChartComponent from "../../../commons/charts/basicCharts";
import { categorias, estados } from "../../../utils/resourcesFields";

const CategoriaQuantidade = ({title}) => {
  var data = {
    labels: categorias,
    datasets: [
      {
        label: "Categorias",
        /* backgroundColor: "rgba(255, 0, 0, 1)", */
        backgroundColor: ["rgba(244, 67, 54, 1)", "rgba(255, 152, 0, 1)", "rgba(45, 109, 155, 1)", "rgba(94, 209, 209, 1)"],
        borderColor: ["rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)"],
        borderRadius: 3,
        borderWidth: 1,
        barThickness: 25,
        data: [14, 15, 17],
      },
    ],
  };

  return (
    <ChartComponent
      title={title}
      //loader={isFetching}
      data={data}
      chartType="pie"
      indexAxis="y"
      legendPosition="bottom"
    />
  );
};

export default CategoriaQuantidade;
