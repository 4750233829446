import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Spinner } from "react-bootstrap";
import SnackBar from "../snackbar.js";
import dateFormat from "dateformat";
import { contratantes } from "../../utils/resourcesFields.js";
import { Autocomplete } from "@material-ui/lab";
import { getCategory } from "../../utils/getAnnouncementLabels.js";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../api/requests/useGetData.js";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const today = dateFormat(new Date(), "yyyy-mm-dd");

export const CancelarAnuncio = (props) => {
  const { anuncio, show, closeModal, fetchAnnounces } = props;
  const classes = useStyles();
  const { announce } = useParams();
  const [loader, setLoader] = useState(false);

  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [motivoCancelamento, setMotivoCancelamento] = useState("");

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const dados = {
    fundamento_cancelamento: motivoCancelamento,
    estado: "Cancelado",
  };

  const salvar = () => {
    setLoader(true);
    axios
      .put(`${BASE_URL}/concurso/${anuncio.id}`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Anúncio cancelado com sucesso!");
        setColor("success");
        setLoader(false);
        cleanFields();
        fetchAnnounces();
        console.log("saved?", response);
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const cleanFields = () => {
    setMotivoCancelamento("");
    closeModal();
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" className="fw-bold text-center">
          Formulário de Cancelamento de {getCategory(anuncio?.categoria)}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form>
          <Divider />
          <DialogContent>
            <div className="row">
              <div className="col-md-6">
                <small>REF/Concurso: </small>
                <h6>{anuncio?.ref_concurso}</h6>
              </div>
              <div className="col-md-6">
                <small>REF/BCC: </small>
                <h6>{anuncio?.ref_bcc}</h6>
              </div>
              <div className="col-md-12">
                <TextField
                  variant="outlined"
                  floatingLabelStyle={{ color: "#568C3E" }}
                  size="small"
                  margin="normal"
                  fullWidth
                  name="motivoCancelamento"
                  label="Motivo do cancelamento"
                  type={"text"}
                  id="motivoCancelamento"
                  multiline
                  rows={4}
                  className="rounded"
                  value={motivoCancelamento}
                  defaultValue={motivoCancelamento}
                  onChange={(e) => setMotivoCancelamento(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{ style: { border: "none", paddingLeft: 10 } }}
                  disabled={loader}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions className="px-4">
            <Button
              className="text-capitalize mr-3"
              style={{ width: 100 }}
              onClick={() => cleanFields()}
              color="secondary"
              disabled={loader}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              className="text-capitalize"
              style={{ color: "white", width: 100, backgroundColor: "#568C3E" }}
              onClick={() => salvar()}
              disabled={loader}
            >
              {loader ? (
                <Spinner animation="border" style={{ width: 24, height: 24 }} />
              ) : (
                "Salvar"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom" + "center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </>
  );
};
