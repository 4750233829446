import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RegistarAnunciosPage from "../pages/announces/registo";
import ConfiguracoesPage from "../pages/configuracoes";
import DashboardHomePage from "../pages/dashboard/home";
import EntitiesPage from "../pages/entities/";
import LoginPage from "../pages/login";
import NotFoundPage from "../pages/notFoundPage";
import GeneralReportsPage from "../pages/reports/general";
import MapReportsPage from "../pages/reports/map";
import ListUsersPage from "../pages/users/list";
import RegistUsersPage from "../pages/users/regist";
import AnunciosPage from "../pages/announces/list";
import ProfilePage from "../pages/users/profile";
import AnnounceDetailsPage from "../pages/announces/details";
import EditarAnunciosPage from "../pages/announces/edit";
import RequireAuth from "../RequireAuth";
import GeneralChartsPage from "../pages/reports/charts";

const Routing = () => {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route exact path="/" element={<Navigate replace to={'admin/dashboard'} />} />
        <Route exact path="/admin/dashboard" element={<DashboardHomePage />} />
        <Route exact path="/admin/configuracoes" element={<ConfiguracoesPage />} />
        <Route exact path="/admin/anuncios/registar" element={<RegistarAnunciosPage />} />
        <Route exact path="/admin/anuncios/editar/:id" element={<EditarAnunciosPage />} />
        <Route exact path="/admin/anuncios/detalhes/:id" element={<AnnounceDetailsPage />} />
        <Route exact path="/admin/ver-anuncios/:announce/:type" element={<AnunciosPage />} />
        <Route exact path="/admin/entidades" element={<EntitiesPage />} />
        <Route exact path="/admin/relatorios/charts" element={<GeneralChartsPage />} />
        <Route exact path="/admin/relatorios/geral" element={<GeneralReportsPage />} />
        <Route exact path="/admin/relatorios/mapa" element={<MapReportsPage />} />
        <Route exact path="/admin/perfil" element={<ProfilePage />} />
        <Route exact path="/admin/utilizadores/registo" element={<RegistUsersPage />} />
        <Route exact path="/admin/utilizadores/lista" element={<ListUsersPage />} />
      </Route>
      
      <Route exact path="*" element={<NotFoundPage />} />
      <Route exact path="/" element={<RequireAuth />} />
      <Route exact path="/login" element={<LoginPage />} />
    </Routes>
  );
};

export default Routing;
