import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import {
  FiberManualRecord,
  FiberManualRecordOutlined,
  MoreVert,
} from "@material-ui/icons";
import nothing22 from "../../../assets/images/nothingWaw.gif";
import { NavLink, useParams } from "react-router-dom";
import { getAnnounceLabel, getLabel } from "../../utils/getAnnouncementLabels";

export default function TabelaAnuncios({
  loading,
  anuncios,
  setShowAdjudicar,
  setShowFechar,
  setSelectedAnuncio,
  setShowPublicar,
  setShowCancelar,
  setShowOcultar,
  setShowEliminar,
  userData,
}) {
  const { type, announce } = useParams();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <div className="py-3">
      <TableContainer component={"div"} className="bg-white shadow-sm">
        <Table className={"classes.table"} aria-label="simple table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Ref/BCC</TableCell>
              <TableCell>Ref/Concurso</TableCell>
              <TableCell>Contratante</TableCell>
              {type !== "lancados" && <TableCell>Contratada</TableCell>}
              {type === "publicados" && <TableCell>Estado</TableCell>}
              <TableCell>Província, Distrito</TableCell>
              <TableCell align="right">Acções</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {anuncios
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((anuncio, index) => (
                <>
                  <TableRow key={anuncio.id}>
                    <TableCell align="center">
                      {anuncio.estado_pub === "Sim" ? (
                        <FiberManualRecord
                          style={{ fontSize: 14, color: "#0DCAF0" }}
                        />
                      ) : (
                        <FiberManualRecordOutlined
                          style={{ fontSize: 12, color: "gray" }}
                        />
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {anuncio.ref_bcc}
                    </TableCell>
                    <TableCell>{anuncio.ref_concurso}</TableCell>
                    <TableCell>{anuncio?.entidade_contratante?.nome}</TableCell>
                    {type !== "lancados" && (
                      <TableCell>
                        {anuncio?.entidade_contratada?.nome || "---"}
                      </TableCell>
                    )}
                    {type === "publicados" && (
                      <TableCell>{anuncio.estado}</TableCell>
                    )}
                    <TableCell>
                      {anuncio?.provincia}
                      {anuncio?.distrito ? `, ${anuncio?.distrito}` : ""}
                    </TableCell>
                    <TableCell align="right" className="ms-4">
                      <div className="text-end dropdownrounded">
                        <IconButton
                          id="dropdownMenu2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          size="small"
                        >
                          <MoreVert style={{ color: "#568C3E" }} />
                        </IconButton>
                        <ul
                          className="dropdown-menu shadow detalhes-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          <NavLink
                            to={`/admin/anuncios/detalhes/${anuncio.id}`}
                            className="text-dark"
                          >
                            <li className="border-bottom dropdown-item">
                              Detalhes
                            </li>
                          </NavLink>
                          {anuncio.estado === "Lançado" && (
                            <li
                              className="border-botto"
                              onClick={() => {
                                setShowAdjudicar(true);
                                setSelectedAnuncio(anuncio);
                              }}
                            >
                              <button className="dropdown-item" type="button">
                                Adjudicar
                              </button>
                            </li>
                          )}
                          {anuncio.estado_pub === "Não" && (
                            <li
                              className="border-botto"
                              onClick={() => {
                                setShowPublicar(true);
                                setSelectedAnuncio(anuncio);
                              }}
                            >
                              <button className="dropdown-item" type="button">
                                Publicar
                              </button>
                            </li>
                          )}
                          {anuncio.estado === "Adjudicado" && (
                            <li
                              className="border-botto"
                              onClick={() => {
                                setShowFechar(true);
                                setSelectedAnuncio(anuncio);
                              }}
                            >
                              <button className="dropdown-item" type="button">
                                Fechar
                              </button>
                            </li>
                          )}
                          {anuncio.estado_pub === "Sim" && (
                            <li
                              className=""
                              onClick={() => {
                                setShowOcultar(true);
                                setSelectedAnuncio(anuncio);
                              }}
                            >
                              <button className="dropdown-item" type="button">
                                Ocultar
                              </button>
                            </li>
                          )}
                          {(anuncio.estado === "Lançado" ||
                            anuncio.estado === "Adjudicado") && (
                            <li
                              className=""
                              onClick={() => {
                                setShowCancelar(true);
                                setSelectedAnuncio(anuncio);
                              }}
                            >
                              <button className="dropdown-item" type="button">
                                Cancelar
                              </button>
                            </li>
                          )}
                          {userData?.role === "Super administrador" && (
                            <li
                              className=""
                              onClick={() => {
                                setShowEliminar(true);
                                setSelectedAnuncio(anuncio);
                              }}
                            >
                              <button className="dropdown-item" type="button">
                                Eliminar
                              </button>
                            </li>
                          )}
                        </ul>
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
        {anuncios?.length === 0 && !loading && (
          <div className="p-5 text-center d-inline-block w-100 align-items-center border-bottom justify-content-center">
            <div>
              <img
                src={nothing22}
                alt=""
                title="Nada para mostrar"
                style={{ width: "170px" }}
              />
            </div>
            <span>
              <span>
                {`Nenhum${announce === "obras" ? "a" : ""}`} {""}
                {getAnnounceLabel(announce).slice(0, -1)}{" "}
                {`${getLabel(type).slice(0, -2)}${
                  announce === "obras" ? "a" : "o"
                }`}{" "}
                {`encontrad${announce === "obras" ? "a" : "o"}!`}
              </span>{" "}
            </span>{" "}
          </div>
        )}
        <div className="py-1 rounded bg-white" hidden={loading}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={anuncios?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Linhas por página"}
          />
        </div>
      </TableContainer>
    </div>
  );
}
