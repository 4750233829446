import React from "react";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import MultilineChartIcon from "@material-ui/icons/MultilineChart";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { NavLink } from "react-router-dom";
import avatar from "../../assets/images/avatar-admin.png";
import ListIcon from "@material-ui/icons/List";
import {
  AddAlert,
  AssignmentTurnedIn,
  Business,
  BusinessCenter,
  Equalizer,
  ExitToApp,
  FiberManualRecordOutlined,
  NotificationsActiveOutlined,
  People,
  PersonAdd,
  Room,
  Settings,
} from "@material-ui/icons";
import { useAuthUser } from "react-auth-kit";

export default function DrawerMenu({ classes, logout }) {
  const auth = useAuthUser()()?.data

  const [openAnnounces, setOpenAnnounces] = React.useState(true);
  const [openReports, setOpenReports] = React.useState(false);
  const [openUsers, ] = React.useState(false);
  const [openEntities, ] = React.useState(false);
  const [openObras, setOpenObras] = React.useState(false);
  const [openBens, setOpenBens] = React.useState(false);
  const [openServices, setOpenServices] = React.useState(false);

  const handleClickAnnounces = () => {
    setOpenAnnounces(!openAnnounces);
  };
  const handleClickReports = () => {
    setOpenReports(!openReports);
  };

  const handleClickObras = () => {
    setOpenObras(!openObras);
  };
  const handleClickBens = () => {
    setOpenBens(!openBens);
  };
  const handleClickServices = () => {
    setOpenServices(!openServices);
  };

  let activeStyle = {
    color: "#000",
    textDecoration: "none",
  };

  let unactiveStyle = {
    color: "white",
    textDecoration: "none",
  };

  return (
    <div>
      <div className="text-center py-3">
        <div className="d-flex justify-content-center pb-3">
          <NavLink to="/admin/dashboard">
            <Avatar
              alt={auth?.nome}
              title={auth?.nome}
              className="avatar"
              src={avatar}
            />
          </NavLink>
        </div>

        <h6 className="text-white">{auth?.nome}</h6>
        <small className="text-white">{auth?.role}</small>
      </div>
      <List className="">
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
          to={"/admin/dashboard"}
        >
          <ListItem button>
            <ListItemIcon>
              <DashboardOutlinedIcon fontSize="small" className="text-white" />
            </ListItemIcon>
            <ListItemText className="text-white" primary="Dashboard" />
          </ListItem>
        </NavLink>
        <Divider />
        <ListItem button onClick={handleClickAnnounces}>
          <ListItemIcon>
            <NotificationsActiveOutlined
              fontSize="small"
              className="text-white"
            />
          </ListItemIcon>
          <ListItemText className="text-white" primary="Concursos" />
          {openAnnounces ? (
            <ExpandMore fontSize="small" className="text-white" />
          ) : (
            <ChevronRightOutlinedIcon fontSize="small" className="text-white" />
          )}
        </ListItem>
        <Collapse in={openAnnounces} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={"/admin/anuncios/registar"}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <AddAlert fontSize="small" className="text-white" />
                </ListItemIcon>
                <ListItemText className="text-white" primary="Registar" />
              </ListItem>
            </NavLink>
            <ListItem
              button
              className={classes.nested}
              onClick={handleClickObras}
            >
              <ListItemIcon>
                {openObras ? (
                  <ExpandMore fontSize="small" className="text-white" />
                ) : (
                  <ChevronRightOutlinedIcon
                    fontSize="small"
                    className="text-white"
                  />
                )}
              </ListItemIcon>
              <ListItemText className="text-white" primary="Obras" />
            </ListItem>
            <Collapse in={openObras} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {" "}
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : unactiveStyle
                  }
                  to={"/admin/ver-anuncios/obras/lancados"}
                >
                  <ListItem button className={classes.nestedLevel3}>
                    <ListItemText
                      className="text-white"
                      primary={
                        <span>
                          {" "}
                          <FiberManualRecordOutlined
                            style={{ fontSize: 10, marginRight: 5 }}
                          />{" "}
                          Lançadas{" "}
                        </span>
                      }
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : unactiveStyle
                  }
                  to={"/admin/ver-anuncios/obras/adjudicados"}
                >
                  <ListItem button className={classes.nestedLevel3}>
                    <ListItemText
                      className="text-white"
                      primary={
                        <span>
                          {" "}
                          <FiberManualRecordOutlined
                            style={{ fontSize: 10, marginRight: 5 }}
                          />{" "}
                          Adjudicadas{" "}
                        </span>
                      }
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : unactiveStyle
                  }
                  to={"/admin/ver-anuncios/obras/cancelados"}
                >
                  <ListItem button className={classes.nestedLevel3}>
                    <ListItemText
                      className="text-white"
                      primary={
                        <span>
                          {" "}
                          <FiberManualRecordOutlined
                            style={{ fontSize: 10, marginRight: 5 }}
                          />{" "}
                          Canceladas{" "}
                        </span>
                      }
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : unactiveStyle
                  }
                  to={"/admin/ver-anuncios/obras/publicados"}
                >
                  <ListItem button className={classes.nestedLevel3}>
                    <ListItemText
                      className="text-white"
                      primary={
                        <span>
                          {" "}
                          <FiberManualRecordOutlined
                            style={{ fontSize: 10, marginRight: 5 }}
                          />{" "}
                          Publicadas{" "}
                        </span>
                      }
                    />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>

            <ListItem
              button
              className={classes.nested}
              onClick={handleClickServices}
            >
              <ListItemIcon>
                {openServices ? (
                  <ExpandMore fontSize="small" className="text-white" />
                ) : (
                  <ChevronRightOutlinedIcon
                    fontSize="small"
                    className="text-white"
                  />
                )}
              </ListItemIcon>
              <ListItemText className="text-white" primary="Serviços" />
            </ListItem>
            <Collapse in={openServices} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {" "}
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : unactiveStyle
                  }
                  to={"/admin/ver-anuncios/servicos/lancados"}
                >
                  <ListItem button className={classes.nestedLevel3}>
                    <ListItemText
                      className="text-white"
                      primary={
                        <span>
                          {" "}
                          <FiberManualRecordOutlined
                            style={{ fontSize: 10, marginRight: 5 }}
                          />{" "}
                          Lançados{" "}
                        </span>
                      }
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : unactiveStyle
                  }
                  to={"/admin/ver-anuncios/servicos/adjudicados"}
                >
                  <ListItem button className={classes.nestedLevel3}>
                    <ListItemText
                      className="text-white"
                      primary={
                        <span>
                          {" "}
                          <FiberManualRecordOutlined
                            style={{ fontSize: 10, marginRight: 5 }}
                          />{" "}
                          Adjudicados{" "}
                        </span>
                      }
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : unactiveStyle
                  }
                  to={"/admin/ver-anuncios/servicos/cancelados"}
                >
                  <ListItem button className={classes.nestedLevel3}>
                    <ListItemText
                      className="text-white"
                      primary={
                        <span>
                          {" "}
                          <FiberManualRecordOutlined
                            style={{ fontSize: 10, marginRight: 5 }}
                          />{" "}
                          Cancelados{" "}
                        </span>
                      }
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : unactiveStyle
                  }
                  to={"/admin/ver-anuncios/servicos/publicados"}
                >
                  <ListItem button className={classes.nestedLevel3}>
                    <ListItemText
                      className="text-white"
                      primary={
                        <span>
                          {" "}
                          <FiberManualRecordOutlined
                            style={{ fontSize: 10, marginRight: 5 }}
                          />{" "}
                          Publicados{" "}
                        </span>
                      }
                    />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>

            <ListItem
              button
              className={classes.nested}
              onClick={handleClickBens}
            >
              <ListItemIcon>
                {openBens ? (
                  <ExpandMore fontSize="small" className="text-white" />
                ) : (
                  <ChevronRightOutlinedIcon
                    fontSize="small"
                    className="text-white"
                  />
                )}
              </ListItemIcon>
              <ListItemText className="text-white" primary="Bens" />
            </ListItem>
            <Collapse in={openBens} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {" "}
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : unactiveStyle
                  }
                  to={"/admin/ver-anuncios/bens/lancados"}
                >
                  <ListItem button className={classes.nestedLevel3}>
                    <ListItemText
                      className="text-white"
                      primary={
                        <span>
                          {" "}
                          <FiberManualRecordOutlined
                            style={{ fontSize: 10, marginRight: 5 }}
                          />{" "}
                          Lançados{" "}
                        </span>
                      }
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : unactiveStyle
                  }
                  to={"/admin/ver-anuncios/bens/adjudicados"}
                >
                  <ListItem button className={classes.nestedLevel3}>
                    <ListItemText
                      className="text-white"
                      primary={
                        <span>
                          {" "}
                          <FiberManualRecordOutlined
                            style={{ fontSize: 10, marginRight: 5 }}
                          />{" "}
                          Adjudicados{" "}
                        </span>
                      }
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : unactiveStyle
                  }
                  to={"/admin/ver-anuncios/bens/cancelados"}
                >
                  <ListItem button className={classes.nestedLevel3}>
                    <ListItemText
                      className="text-white"
                      primary={
                        <span>
                          {" "}
                          <FiberManualRecordOutlined
                            style={{ fontSize: 10, marginRight: 5 }}
                          />{" "}
                          Cancelados{" "}
                        </span>
                      }
                    />
                  </ListItem>
                </NavLink>
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : unactiveStyle
                  }
                  to={"/admin/ver-anuncios/bens/publicados"}
                >
                  <ListItem button className={classes.nestedLevel3}>
                    <ListItemText
                      className="text-white"
                      primary={
                        <span>
                          {" "}
                          <FiberManualRecordOutlined
                            style={{ fontSize: 10, marginRight: 5 }}
                          />{" "}
                          Publicados{" "}
                        </span>
                      }
                    />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>
          </List>
        </Collapse>

        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
          to={"/admin/entidades"}
        >
          <ListItem button>
            <ListItemIcon>
              <Business fontSize="small" className="text-white" />
            </ListItemIcon>
            <ListItemText className="text-white" primary="Entidades" />
          </ListItem>
        </NavLink>

        <Collapse in={openEntities} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={"/admin/entidades/contratantes"}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <BusinessCenter fontSize="small" className="text-white" />
                </ListItemIcon>
                <ListItemText className="text-white" primary="Contratantes" />
              </ListItem>
            </NavLink>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={"/admin/entidades/contratadas"}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <AssignmentTurnedIn fontSize="small" className="text-white" />
                </ListItemIcon>
                <ListItemText className="text-white" primary="Contratadas" />
              </ListItem>
            </NavLink>
          </List>
        </Collapse>

        <ListItem button onClick={handleClickReports}>
          <ListItemIcon>
            <MultilineChartIcon fontSize="small" className="text-white" />
          </ListItemIcon>
          <ListItemText className="text-white" primary="Relatórios" />
          {openReports ? (
            <ExpandMore fontSize="small" className="text-white" />
          ) : (
            <ChevronRightOutlinedIcon fontSize="small" className="text-white" />
          )}
        </ListItem>
        <Collapse in={openReports} timeout="auto" unmountOnExit>
          {" "}
          <List component="div" disablePadding>
          <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={"/admin/relatorios/charts"}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <Equalizer fontSize="small" className="text-white" />
                </ListItemIcon>
                <ListItemText className="text-white" primary="Try highcharts" />
              </ListItem>
            </NavLink>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={"/admin/relatorios/geral"}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <Equalizer fontSize="small" className="text-white" />
                </ListItemIcon>
                <ListItemText className="text-white" primary="Geral" />
              </ListItem>
            </NavLink>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={"/admin/relatorios/mapa"}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <Room fontSize="small" className="text-white" />
                </ListItemIcon>
                <ListItemText className="text-white" primary="Mapa" />
              </ListItem>
            </NavLink>
          </List>
        </Collapse>

        {(auth?.role === "Super administrador" ||
          auth?.role === "Administrador") && (
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
            to={"/admin/utilizadores/lista"}
          >
            <ListItem button /* onClick={handleClickUsers} */>
              <ListItemIcon>
                <People fontSize="small" className="text-white" />
              </ListItemIcon>
              <ListItemText
                className="text-white"
                primary="Gestão de Utilizadores"
              />
              {/*           {openUsers ? (
            <ExpandMore fontSize="small" className="text-white" />
          ) : (
            <ChevronRightOutlinedIcon fontSize="small" className="text-white" />
          )} */}
            </ListItem>
          </NavLink>
        )}
        <Collapse in={openUsers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={"/admin/utilizadores/lista"}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <ListIcon fontSize="small" className="text-white" />
                </ListItemIcon>
                <ListItemText className="text-white" primary="Lista" />
              </ListItem>
            </NavLink>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={"/admin/utilizadores/registo"}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <PersonAdd fontSize="small" className="text-white" />
                </ListItemIcon>
                <ListItemText className="text-white" primary="Registo" />
              </ListItem>
            </NavLink>
          </List>
        </Collapse>
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
          to={"/admin/configuracoes"}
        >
          <ListItem button>
            <ListItemIcon>
              <Settings fontSize="small" className="text-white" />
            </ListItemIcon>
            <ListItemText className="text-white" primary="Configurações" />
          </ListItem>
        </NavLink>
        <Divider />
        <ListItem button onClick={logout}>
          <ListItemIcon>
            <ExitToApp fontSize="small" className="text-white" />
          </ListItemIcon>
          <ListItemText className="text-white" primary="Terminar sessão" />
        </ListItem>
      </List>
    </div>
  );
}
