import React from "react";
import AppbarDrawer from "../../../components/dashboard/drawer";
import MapReports from "../../../components/dashboard/reports/reportMap";



const MapReportsPage = () => {
  return (
    <div >
      <AppbarDrawer>
        <MapReports/>
      </AppbarDrawer>
    </div>
  );
};
export default MapReportsPage;

