/* eslint-disable react-hooks/exhaustive-deps */
import { Button, IconButton, LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAnnounceLabel, getLabel } from "../../utils/getAnnouncementLabels";
import { Apps, ClearAll, List } from "@material-ui/icons";
import TabelaAnuncios from "../../commons/table/tableAnnounces";
import { Collapse, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { AdjudicarAnuncio } from "../../commons/modal/adjudicar";
import { FecharAnuncio } from "../../commons/modal/fechar";
import { BASE_URL } from "../../api/requests/useGetData";
import axios from "axios";
import CardsAnnouncements from "./cardsAnnouncements";
import Confirmacao from "../../commons/modal/confirmarOperacao";
import SnackBar from "../../commons/snackbar";
import { CancelarAnuncio } from "../../commons/modal/cancelar";
import { Autocomplete } from "@material-ui/lab";
import provinces from "../../utils/mozambique.json";
import { useAuthUser } from "react-auth-kit";

export default function Announcements() {
  const auth = useAuthUser()()?.data;
  const years = [
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
  ];
  const { type, announce } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadAction, setLoadAction] = useState(false);
  const [selectedAnuncio, setSelectedAnuncio] = useState({});
  const [announcements, setAnnouncements] = useState([]);
  const [listView, setlistView] = useState("grid");
  const [filter, setFilter] = useState(false);
  const [contratantes, setContratantes] = useState([]);
  const [contratadas, setContratadas] = useState([]);

  const getEntidadesContratantes = () => {
    axios.get(`${BASE_URL}/entidade-contratante`).then((response) => {
      setContratantes(response.data.data);
    });
  };
  const getEntidadesContratadas = () => {
    axios.get(`${BASE_URL}/entidade-contratada`).then((response) => {
      setContratadas(response.data.data);
    });
  };

  useEffect(() => {
    getEntidadesContratantes();
    getEntidadesContratadas();
  }, []);

  const [districts, setDistricts] = useState([]);

  const [ref_bcc, setRef_bcc] = useState("");
  const [ref_concurso, setRef_concurso] = useState("");
  const [entidadeContratante, setEntidadeContratante] = useState({});
  const [entidadeContratada, setEntidadeContratada] = useState({});
  const [ano, setAno] = useState("");
  const [provincia, setProvincia] = useState("");
  const [distrito, setDistrito] = useState("");

  const handleChangeProvince = (event, value) => {
    setDistrito("");
    setProvincia(value);
    setDistricts(value?.districts);
  };
  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const [checked, setChecked] = useState(false);
  const handleChangeChecked = (e) => setChecked(e.target.checked);

  const [showAdjudicar, setShowAdjudicar] = useState(false);
  const closeAdjudicar = (event, reason) => {
    if (reason !== "backdropClick") {
      setShowAdjudicar(false);
      setChecked(false);
    }
  };

  const [showPublicar, setShowPublicar] = useState(false);
  const closePublicar = (event, reason) => {
    if (reason !== "backdropClick") {
      setShowPublicar(false);
      setChecked(false);
    }
  };

  const [showOcultar, setShowOcultar] = useState(false);
  const closeOcultar = (event, reason) => {
    if (reason !== "backdropClick") {
      setShowOcultar(false);
      setChecked(false);
    }
  };

  const [showFechar, setShowFechar] = useState(false);
  const closeFechar = (event, reason) => {
    if (reason !== "backdropClick") {
      setShowFechar(false);
      setChecked(false);
    }
  };

  const [showCancelar, setShowCancelar] = useState(false);
  const closeCancelar = (event, reason) => {
    if (reason !== "backdropClick") {
      setShowCancelar(false);
      setChecked(false);
    }
  };

  const [showEliminar, setShowEliminar] = useState(false);
  const closeEliminar = (event, reason) => {
    if (reason !== "backdropClick") {
      setShowEliminar(false);
      setChecked(false);
    }
  };

  const getFilters = () => {
    if (type === "publicados") {
      return `estado_pub=Sim&categoria=${announce}`;
    } else return `estado=${type.slice(0, -1)}&categoria=${announce}`;
  };

  const getParams = () => {
    var paramss = "";
    if (ref_bcc) {
      paramss += `&ref_bcc=${ref_bcc}`;
    }
    if (ref_concurso) {
      paramss += `&ref_concurso=${ref_concurso}`;
    }
    if (entidadeContratante?.id) {
      paramss += `&entidade_contratante_id=${entidadeContratante.id}`;
    }
    if (entidadeContratada?.id) {
      paramss += `&entidade_contratada_id=${entidadeContratada.id}`;
    }
    if (ano) {
      paramss += `&ano_anuncio=${ano}`;
    }
    if (provincia?.province) {
      paramss += `&provincia=${provincia?.province}`;
    }
    if (distrito) {
      paramss += `&distrito=${distrito}`;
    }

    return paramss;
  };

  const resetFilters = () => {
    setRef_bcc("");
    setRef_concurso("");
    setEntidadeContratante({});
    setEntidadeContratada({});
    setAno("");
    setProvincia("");
    setDistrito("");
  };

  const fetchData = () => {
    setLoading(true);
    axios
      .post(
        `${BASE_URL}/concurso/filtrar-por-colunas/concurso?${getFilters()}${getParams()}`
      )
      .then((response) => {
        setAnnouncements(response.data.data);
        setLoading(false);
        console.log("response", response);
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was an error!", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, [
    type,
    announce,
    ref_bcc,
    ref_concurso,
    entidadeContratante,
    entidadeContratada,
    ano,
    provincia,
    distrito,
  ]);

  const publicar = () => {
    setLoadAction(true);
    const dados = {
      estado_pub: "Sim",
    };
    axios
      .put(`${BASE_URL}/concurso/${selectedAnuncio.id}`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Anúncio publicado com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        closePublicar();
        console.log("saved?", response);
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const ocultar = () => {
    setLoadAction(true);
    const dados = {
      estado_pub: "Não",
    };
    axios
      .put(`${BASE_URL}/concurso/${selectedAnuncio.id}`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Anúncio oculto com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        closeOcultar();
        console.log("saved?", response);
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const eliminar = () => {
    setLoadAction(true);
    axios
      .delete(`${BASE_URL}/concurso/${selectedAnuncio.id}`)
      .then((response) => {
        setOpen(true);
        setmessage("Anúncio emininado com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchData();
        closeEliminar();
        console.log("saved?", response);
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };
  return (
    <div>
      <div className="mt-3">
        <div className="d-flex justify-content-between">
          <h3>
            Lista de {getAnnounceLabel(announce)} - Concursos {getLabel(type)}  (<strong style={{color: "red"}}>{loading ? "--" : announcements.length}</strong>)
          </h3>
          <div>
            <IconButton
              size="small"
              title="Vista de tabela"
              color={listView === "list" ? "primary" : "default"}
              onClick={() => setlistView("list")}
              className="mr-1"
            >
              <List />
            </IconButton>
            <IconButton
              size="small"
              title="Vista de grelha"
              color={listView === "grid" ? "primary" : "default"}
              onClick={() => setlistView("grid")}
              className="mr-1"
            >
              <Apps />
            </IconButton>
            <IconButton
              size="small"
              variant="contained"
              onClick={() => setFilter(!filter)}
              style={{
                color: filter ? "#fff" : "#6E6E6E",
                backgroundColor: filter ? "#3F51B5" : "#FFFF",
                width: 35,
                height: 35,
              }}
              className="me-1"
            >
              <Search />
            </IconButton>
          </div>
        </div>
        <hr />
      </div>
      {/* -----------FILTER-------------------- */}
      <Collapse in={filter}>
        <div className="bg-white px-3 pt-2 pb-1 shadow-sm border border-top-0">
          <div>
            <div className="d-flex my-2">Digite os dados para pesquisar...</div>
          </div>

          <div className="row">
            <div className="col-md-10 row d-flex justify-content-start align-items-end">
              <div className="col-md-3">
                <TextField
                  className="mr-2"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="searchDescricao"
                  label="Ref/Bcc"
                  style={{ paddingBottom: 7 }}
                  value={ref_bcc}
                  onChange={(e) => setRef_bcc(e.target.value)}
                  type={"text"}
                  id="standard-start-adornment"
                />
              </div>
              <div className="col-md-3">
                <TextField
                  className="mr-2"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="searchDescricao"
                  label="Ref/Concurso"
                  style={{ paddingBottom: 7 }}
                  value={ref_concurso}
                  onChange={(e) => setRef_concurso(e.target.value)}
                  type={"text"}
                  id="standard-start-adornment"
                />
              </div>
              <div className="col-md-3">
                <Autocomplete
                  className="mr-2"
                  id="clear-on-escape"
                  options={contratantes}
                  getOptionLabel={(option) => option.nome}
                  noOptionsText="Nenhuma opção encontrada"
                  style={{ width: "100%" }}
                  onChange={(e, value) => setEntidadeContratante(value)}
                  value={entidadeContratante}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      size="small"
                      variant="outlined"
                      label="Contratante"
                      className="rounded"
                      type={"text"}
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Autocomplete
                  className="mr-2"
                  id="clear-on-escape"
                  options={contratadas}
                  getOptionLabel={(option) => option.nome}
                  noOptionsText="Nenhuma opção encontrada"
                  style={{ width: "100%" }}
                  onChange={(e, value) => setEntidadeContratada(value)}
                  value={entidadeContratada}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      size="small"
                      variant="outlined"
                      label="Contratada"
                      className="rounded"
                      type={"text"}
                    />
                  )}
                />
              </div>

              <div className="col-md-3">
                <Autocomplete
                  className="mr-2"
                  id="clear-on-escape"
                  options={years}
                  getOptionLabel={(option) => option}
                  noOptionsText="Nenhuma opção encontrada"
                  style={{ width: "100%" }}
                  onChange={(e, value) => setAno(value)}
                  value={ano}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      size="small"
                      variant="outlined"
                      label="Ano de anúncio"
                      className="rounded"
                      type={"text"}
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Autocomplete
                  className="mr-2"
                  id="clear-on-escape"
                  options={provinces}
                  getOptionLabel={(option) => option?.province}
                  noOptionsText="Nenhuma opção encontrada"
                  style={{ width: "100%" }}
                  onChange={handleChangeProvince}
                  value={provincia}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      size="small"
                      variant="outlined"
                      label="Província"
                      className="rounded"
                      type={"text"}
                    />
                  )}
                />
              </div>
              <div className="col-md-3">
                <Autocomplete
                  className="mr-2"
                  id="clear-on-escape"
                  options={districts || []}
                  getOptionLabel={(option) => option}
                  noOptionsText="Nenhuma opção encontrada"
                  style={{ width: "100%" }}
                  onChange={(event, value) => setDistrito(value)}
                  value={distrito}
                  disabled={!provincia}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      size="small"
                      variant="outlined"
                      label="Distrito"
                      className="rounded"
                      type={"text"}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-2 d-flex justify-content-start align-items-center">
              {getParams() && (
                <Button
                  className="text-capitalize mt-2"
                  style={{ color: "#2075A9", backgroundColor: "#F5F5F5" }}
                  startIcon={<ClearAll />}
                  onClick={() => resetFilters()}
                >
                  Limpar filtros
                </Button>
              )}
            </div>
          </div>
        </div>
      </Collapse>

      {loading ? (
        <LinearProgress />
      ) : listView === "grid" ? (
        <CardsAnnouncements
          loading={loading}
          anuncios={announcements}
          setShowAdjudicar={setShowAdjudicar}
          setShowPublicar={setShowPublicar}
          setShowFechar={setShowFechar}
          setShowCancelar={setShowCancelar}
          setShowEliminar={setShowEliminar}
          setShowOcultar={setShowOcultar}
          setSelectedAnuncio={setSelectedAnuncio}
          userData={auth}
        />
      ) : (
        <TabelaAnuncios
          loading={loading}
          anuncios={announcements}
          setShowAdjudicar={setShowAdjudicar}
          setShowPublicar={setShowPublicar}
          setShowFechar={setShowFechar}
          setShowCancelar={setShowCancelar}
          setShowEliminar={setShowEliminar}
          setShowOcultar={setShowOcultar}
          setSelectedAnuncio={setSelectedAnuncio}
          userData={auth}
        />
      )}

      <AdjudicarAnuncio
        show={showAdjudicar}
        closeModal={closeAdjudicar}
        anuncio={selectedAnuncio}
        fetchAnnounces={fetchData}
      />

      <CancelarAnuncio
        show={showCancelar}
        closeModal={closeCancelar}
        anuncio={selectedAnuncio}
        fetchAnnounces={fetchData}
      />

      <Confirmacao
        show={showPublicar}
        closeModal={closePublicar}
        action={publicar}
        actionType={"Publicar"}
        objectType={`o Anúncio`}
        objectDescription={selectedAnuncio.ref_bcc}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <Confirmacao
        show={showOcultar}
        closeModal={closeOcultar}
        action={ocultar}
        actionType={"Ocultar"}
        objectType={`o Anúncio`}
        objectDescription={selectedAnuncio.ref_bcc}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <Confirmacao
        show={showEliminar}
        closeModal={closeEliminar}
        action={eliminar}
        actionType={"Eliminar"}
        objectType={`o Anúncio`}
        objectDescription={selectedAnuncio.ref_bcc}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <FecharAnuncio
        show={showFechar}
        closeModal={closeFechar}
        anuncio={selectedAnuncio}
        fetchAnnounces={fetchData}
      />

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
}
