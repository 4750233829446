import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { CheckCircleOutline, Close } from "@material-ui/icons";
import CustomizedCheckbox from "../checkBox/customizedCheckBox";
import { Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const Confirmacao = ({
  show,
  closeModal,
  action,
  actionType,
  objectType,
  objectDescription,
  loader,
  checked,
  handleChangeChecked,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={show}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title" className="fw-bold text-start">
        {`${actionType} ${objectType}`}
        <IconButton
          size="small"
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>

      <Divider />
      <DialogContent>
        {" "}
        <div className="pt-3 pb-2">
          {" "}
          Tem certeza que pretende{" "}
          <span className="text-danger">
            {actionType.toUpperCase()}
          </span> {objectType} <strong>{objectDescription}</strong>?
        </div>
        <CustomizedCheckbox
          checked={checked}
          handleChangeChecked={handleChangeChecked}
        />
      </DialogContent>
      <Divider />
      <DialogActions className="px-4">
        <Button
          className="text-capitalize mr-3"
          style={{ width: 100, height: 33, color: !loader && 'red' }}
          onClick={closeModal}
          disabled={loader}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          className="text-capitalize"
          style={{ width: 100, height: 33, color: checked && "#2075A9", backgroundColor: loader && "#" }}
          onClick={action}
          disabled={!checked || loader}
        >
          {loader ? (
            <Spinner animation="border" style={{ width: 20, height: 20 }} />
          ) : (
            "Confirmar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmacao;
