import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/map")(Highcharts);

export default function PieHighChart({ title, subtitle, data }) {
  const chartOptions = {
    chart: {
      type: "pie",
    },
    title: {
      text: title,
      style: {
        fontSize: "1em",
        color: "black"
      },
    },
    subtitle: {
      text: subtitle,
    },
    tooltip: {
      valueSuffix: "%",
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        //showInLegend: true,
        dataLabels: [
          {
            enabled: true,
            distance: 7,
            style: {
              fontSize: "0.7em",
              color: "black"
            },
          },
          {
            enabled: true,
            distance: -40,
            format: "{point.percentage:.1f}%",
            style: {
              fontSize: "0.7em",
              textOutline: "none",
              opacity: 0.7,
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
      },
    },
    series: [
      {
        name: "Percentagem",
        colorByPoint: true,
        data: data,
      },
    ],
  };

  return (
    <div className="">
      <HighchartsReact options={chartOptions} highcharts={Highcharts} />
    </div>
  );
}
