/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import { BASE_URL } from "../../api/requests/useGetData.js";
import { AdicionarEntidade } from "../../dashboard/entities/modalAdd.js";
import { ChevronRightRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const AdjudicarAnuncio = (props) => {
  const {
    show,
    closeModal,
    initialDadosAdjudicacao,
    dadosAdjudicacao,
    setDadosAdjudicacao,
  } = props;
  const classes = useStyles();
  const [entidades, setEntidades] = useState();

  const getEntidadesContratadas = () => {
    axios.get(`${BASE_URL}/entidade-contratada`).then((response) => {
      setEntidades(response.data.data);
    });
  };

  useEffect(() => {
    getEntidadesContratadas();
  }, []);

  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [errorEntContratada, setErrorEntContratada] = useState(false);
  const [errorDataAdjudicacao, setErrorDataAdjudicacao] = useState(false);

  const [errorCambioDolar, setErrorCambioDolar] = useState(false);
  const [errorCambioEur, setErrorCambioEur] = useState(false);

  const [errorTotalAdjudicadoMt, setErrorTotalAdjudicadoMt] = useState(false);
  const [errorTotalAdjudicadoUsd, setErrorTotalAdjudicadoUsd] = useState(false);
  const [errorTotalAdjudicadoEur, setErrorTotalAdjudicadoEur] = useState(false);


  const entityType = "Contratada";
  const [showAddEntity, setShowAddEntity] = useState(false);
  const closeAddEntity = () => {
    setShowAddEntity(false);
  };

  const formSubmit = (event) => {
    event.preventDefault();
    if (
      errorEntContratada === false &&
      errorDataAdjudicacao === false &&
      errorCambioDolar === false &&
      errorCambioEur === false &&
      errorTotalAdjudicadoMt === false &&
      errorTotalAdjudicadoUsd === false &&
      errorTotalAdjudicadoEur === false
    ) {
      closeModal();
    } else {
      console.log("Não posso salvar");
    }
  };

  const requiredFields = () => {
    if ((dadosAdjudicacao.valorAdjudicadoMt || dadosAdjudicacao.valorAdjudicadoUsd || dadosAdjudicacao.valorAdjudicadoEur) && !dadosAdjudicacao.cambioDolar) {
      setErrorCambioDolar(true);
    } else {
      setErrorCambioDolar(false);
    }
    if ((dadosAdjudicacao.valorAdjudicadoMt || dadosAdjudicacao.valorAdjudicadoUsd || dadosAdjudicacao.valorAdjudicadoEur) && !dadosAdjudicacao.cambioEuro) {
      setErrorCambioEur(true);
    } else {
      setErrorCambioEur(false);
    }
  };

  const cleanFields = () => {
    setDadosAdjudicacao(initialDadosAdjudicacao)

    setErrorDataAdjudicacao(false);
    setErrorEntContratada(false);

    setErrorCambioDolar(false);
    setErrorCambioEur(false);

    setErrorTotalAdjudicadoMt(false);
    setErrorTotalAdjudicadoUsd(false);
    setErrorTotalAdjudicadoEur(false);
  };
  const handleValorMt = (event) => {
    setDadosAdjudicacao({...dadosAdjudicacao, valorAdjudicadoMt: event.target.value});

    //setValorAdjudicadoUsd((event.target.value / cambio).toFixed(2));
  };

  useEffect(() => {
    if (dadosAdjudicacao.cambioDolar && dadosAdjudicacao.cambioEuro) {
      const valorTotalMt = (
        Number(dadosAdjudicacao.valorAdjudicadoMt) +
        Number(dadosAdjudicacao.valorAdjudicadoUsd) * Number(dadosAdjudicacao.cambioDolar) +
        Number(dadosAdjudicacao.valorAdjudicadoEur) * Number(dadosAdjudicacao.cambioEuro)
      ).toFixed(2);
  
      const valorTotalUSD = (
        Number(dadosAdjudicacao.valorAdjudicadoUsd) +
        Number(dadosAdjudicacao.valorAdjudicadoMt) / Number(dadosAdjudicacao.cambioDolar) +
        (Number(dadosAdjudicacao.valorAdjudicadoEur) * Number(dadosAdjudicacao.cambioEuro)) / Number(dadosAdjudicacao.cambioDolar)
      ).toFixed(2);
  
      const valorTotalEUR = (
        Number(dadosAdjudicacao.valorAdjudicadoEur) +
        Number(dadosAdjudicacao.valorAdjudicadoMt) / Number(dadosAdjudicacao.cambioEuro) +
        Number(dadosAdjudicacao.valorAdjudicadoUsd) * (Number(dadosAdjudicacao.cambioDolar) / Number(dadosAdjudicacao.cambioEuro))
      ).toFixed(2);
  
      setDadosAdjudicacao(prevState => ({
        ...prevState,
        totalAdjudicadoMt: valorTotalMt,
        totalAdjudicadoUsd: valorTotalUSD,
        totalAdjudicadoEur: valorTotalEUR
      }));
    }
  }, [dadosAdjudicacao.cambioDolar, dadosAdjudicacao.cambioEuro, dadosAdjudicacao.valorAdjudicadoMt, dadosAdjudicacao.valorAdjudicadoUsd, dadosAdjudicacao.valorAdjudicadoEur]);

  const handleOptionChange = (event, value) => {
      setDadosAdjudicacao({...dadosAdjudicacao, entidadeContrada: value})
  };
  
  return (
    <>
      <Dialog
        open={show}
        onClose={() => {
          closeModal();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" className="fw-bold text-center">
          Dados de Adjudicação do Concurso
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              closeModal();
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={formSubmit}>
          <Divider />
          <DialogContent>
            <div className="row">
              <div className="col-md-12 mt-2 d-flex justify-content-between">
                <small className="" style={{ color: "red" }}>
                  Por favor, preencha os campos abaixo, os campos com (*) são
                  obrigatórios e, os dados não serão enviados sem o devido
                  preenchimento.
                </small>
                <small
                  className="add-new-entity"
                  onClick={() => setShowAddEntity(true)}
                >
                  Adicionar nova entidade contratada
                </small>
              </div>
              <div className="col-md-6">
                <div className="form-floating">
                  <Autocomplete
                    id="clear-on-escape"
                    noOptionsText="Nenhuma opção encontrada"
                    options={entidades}
                    getOptionLabel={(option) => option.nome}
                    onChange={handleOptionChange}
                    value={dadosAdjudicacao.entidadeContrada}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label="Entidade Contratada"
                        className="rounded border-bottom"
                        type={"text"}
                        error={errorEntContratada}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating">
                  <TextField
                    variant="outlined"
                    floatingLabelStyle={{ color: "#568C3E" }}
                    size="small"
                    margin="normal"
                    fullWidth
                    required
                    name="dataAdjudicacao"
                    label="Data da adjudicação"
                    type={"date"}
                    id="dataAdjudicacao"
                    className="rounded border-bottom"
                    value={dadosAdjudicacao.dataAdjudicacao}
                    defaultValue={dadosAdjudicacao.dataAdjudicacao}
                    error={errorDataAdjudicacao}
                    onChange={(e) => setDadosAdjudicacao({...dadosAdjudicacao, dataAdjudicacao: e.target.value})}
                    inputProps={{
                      style: { border: "none", paddingLeft: 2 },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </div>
              </div>
              {/* 
=
=
=
=
=
=
=
=
=
=
=





=
=
=
=
=
=
=
=
=
=
=
 */}
              <div className="col-md-12 row py-3 mt-4 border-top">
                <div className="col-md-4">
                  {" "}
                  <strong className="col-md-12">Câmbio do dia:</strong>
                  <div className="col-md-12">{""}</div>
                  <div className="col-md-12">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="cambioDolar"
                      placeholder="Câmbio do Dólar"
                      label="Dólar"
                      className="rounded border-bottom"
                      type={"number"}
                      id="cambioDolar"
                      value={dadosAdjudicacao.cambioDolar}
                      defaultValue={dadosAdjudicacao.cambioDolar}
                      error={errorCambioDolar}
                      onChange={(e) => setDadosAdjudicacao({...dadosAdjudicacao, cambioDolar: e.target.value})}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                    />
                  </div>
                  <div className="col-md-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      label="Euro"
                      required
                      placeholder="Câmbio do Euro"
                      size="small"
                      className="rounded border-bottom mt-3"
                      type={"number"}
                      style={{ width: "100%" }}
                      value={dadosAdjudicacao.cambioEuro}
                      error={errorCambioEur}
                      onChange={(e) => setDadosAdjudicacao({...dadosAdjudicacao, cambioEuro: e.target.value})}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                    />
                  </div>{" "}
                </div>
                <div className="col-md-4">
                  {" "}
                  <strong className="col-md-12">Valores adjudicados:</strong>
                  <div className="col-md-12">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      name="valorAdjudicadoMt"
                      label="Valor Adjudicado (MT)"
                      placeholder="Total Adjudicado (MT)"
                      className="rounded border-bottom"
                      type={"number"}
                      id="valorAdjudicadoMt"
                      value={dadosAdjudicacao.valorAdjudicadoMt}
                      defaultValue={dadosAdjudicacao.valorAdjudicadoMt}
                      onChange={handleValorMt}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">MT</InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 2 },
                      }}
                    />
                  </div>
                  <div className="col-md-12">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      name="valorAdjudicadoUsd"
                      label="Valor Adjudicado (USD)"
                      placeholder="Total Adjudicado (USD)"
                      className="rounded border-bottom"
                      type={"number"}
                      id="valorAdjudicadoUsd"
                      value={dadosAdjudicacao.valorAdjudicadoUsd}
                      defaultValue={dadosAdjudicacao.valorAdjudicadoUsd}
                      onChange={(e) => setDadosAdjudicacao({...dadosAdjudicacao, valorAdjudicadoUsd: e.target.value})}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 2 },
                      }}
                    />
                    {dadosAdjudicacao.cambioDolar !== 0 && (
                      <small>{dadosAdjudicacao.valorAdjudicadoUsd * dadosAdjudicacao.cambioDolar} Meticais</small>
                    )}
                  </div>
                  <div className="col-md-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      label="Valor Adjudicado (EUR)"
                      placeholder="Valor Adjudicado (EUR)"
                      size="small"
                      className="rounded border-bottom mt-3"
                      type={"number"}
                      style={{ width: "100%" }}
                      value={dadosAdjudicacao.valorAdjudicadoEur}
                      onChange={(e) => setDadosAdjudicacao({...dadosAdjudicacao, valorAdjudicadoEur: e.target.value})}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 2 },
                      }}
                    />
                    {dadosAdjudicacao.cambioEuro !== 0 && (
                      <small>{dadosAdjudicacao.valorAdjudicadoEur * dadosAdjudicacao.cambioEuro} Meticais</small>
                    )}
                  </div>{" "}
                </div>
                <div className="col-md-4">
                  {" "}
                  <strong className="col-md-12">Total adjudicado:</strong>
                  <div className="col-md-12">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="totalAdjudicadoMt"
                      label="Total Adjudicado (MT)"
                      placeholder="Total Adjudicado (MT)"
                      className="rounded border-bottom"
                      type={"number"}
                      id="totalAdjudicadoMt"
                      value={dadosAdjudicacao.totalAdjudicadoMt}
                      defaultValue={dadosAdjudicacao.totalAdjudicadoMt}
                      error={errorTotalAdjudicadoMt}
                      onChange={(e) => setDadosAdjudicacao({...dadosAdjudicacao, totalAdjudicadoMt: e.target.value})}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">MT</InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 2 },
                      }}
                    />
                  </div>
                  <div className="col-md-12">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="totalAdjudicadoUsd"
                      label="Total Adjudicado (USD)"
                      placeholder="Total Adjudicado (USD)"
                      className="rounded border-bottom"
                      type={"number"}
                      id="totalAdjudicadoUsd"
                      value={dadosAdjudicacao.totalAdjudicadoUsd}
                      defaultValue={dadosAdjudicacao.totalAdjudicadoUsd}
                      error={errorTotalAdjudicadoUsd}
                      onChange={(e) => setDadosAdjudicacao({...dadosAdjudicacao, totalAdjudicadoUsd: e.target.value})}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 2 },
                      }}
                    />
                  </div>
                  <div className="col-md-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      label="Total Adjudicado (EUR)"
                      required
                      placeholder="Total Adjudicado (EUR)"
                      size="small"
                      className="rounded border-bottom mt-3"
                      type={"number"}
                      style={{ width: "100%" }}
                      value={dadosAdjudicacao.totalAdjudicadoEur}
                      error={errorTotalAdjudicadoEur}
                      onChange={(e) => setDadosAdjudicacao({...dadosAdjudicacao, totalAdjudicadoEur: e.target.value})}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 2 },
                      }}
                    />
                  </div>{" "}
                </div>
              </div>
            </div>
          </DialogContent>
          <Divider />
          <DialogActions className="px-4">
            <Button
              className="text-capitalize mr-3"
              style={{ width: 100 }}
              onClick={() => {
                cleanFields();
                closeModal();
              }}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              className="text-capitalize"
              style={{ color: "white", width: 100, backgroundColor: "#568C3E" }}
              onClick={() => requiredFields()}
              >
              Continuar <ChevronRightRounded />
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <AdicionarEntidade
        show={showAddEntity}
        closeModal={closeAddEntity}
        entityType={entityType}
        fetchEntities={getEntidadesContratadas}
        isRegist
        dadosAdjudicacao={dadosAdjudicacao}
        setEntity={setDadosAdjudicacao}
      />
    </>
  );
};
