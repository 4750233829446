import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableResources from "./tableResources";
import ResourcesRegistration from "./resourcesRegistration";
import axios from "axios";
import { BASE_URL } from "../../api/requests/useGetData";
import { Collapse, LinearProgress } from "@material-ui/core";
import Confirmacao from "../../commons/modal/confirmarOperacao";
import SnackBar from "../../commons/snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    textAlign: "start",
    color: "#568C3E",
    fontWeight: 550,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function Configuracoes() {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [LoadDelete, setLoadDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fontes, setFontes] = useState([]);
  const [modalidades, setModalidades] = useState([]);
  const [garantias, setGarantias] = useState([]);
  const [regulamentos, setRegulamentos] = useState([]);

  const getResources = () => {
    setLoading(true);
    axios.get(`${BASE_URL}/config`).then((response) => {
      setFontes(response.data.data.filter((el) => el.nome === "Fonte"));
      setRegulamentos(
        response.data.data.filter((el) => el.nome === "Regulamento")
      );
      setGarantias(response.data.data.filter((el) => el.nome === "Garantia"));
      setModalidades(
        response.data.data.filter((el) => el.nome === "Modalidade")
      );
      setLoading(false);
    });
  };
  useEffect(() => {
    getResources();
  }, []);

  const [resourceId, setResourceId] = useState();
  const [nomeOpcao, setNomeOpcao] = useState("");
  const [tipoRecurso, setTipoRecurso] = useState("");

  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const [show, setShow] = useState(false);
  const closeModal = () => {
    setShow(false);
    setChecked(false);
  };

  const [checked, setChecked] = useState(false);
  const handleChangeChecked = (e) => setChecked(e.target.checked);

  const deleteResource = () => {
    setLoadDelete(true);
    axios
      .delete(`${BASE_URL}/config/${resourceId}`)
      .then((response) => {
        setOpen(true);
        setmessage(`${tipoRecurso} eliminado(a) com sucesso!`);
        setColor("success");
        setLoadDelete(false);
        getResources();
        closeModal();
      })
      .catch((error) => {
        setLoadDelete(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  return (
    <div className="container-fluid">
      <div className="text-center wow fadeInUp mb-2" data-wow-delay="0.1s">
        <h3 className="my-4 mb-5">Configurações</h3>
      </div>
      <Row className="g-4">
        <Col sm={editing ? 9 : 12}>
          <div className="bg-white">
            <div className="col-6 text-start p-3 text-secondary d-flex">
              <h4>Lista de recursos</h4>
            </div>
            {loading && <LinearProgress />}
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <span className={classes.heading}>Fontes</span>
                <span className={classes.secondaryHeading}>
                  Proveniência dos concursos
                </span>
              </AccordionSummary>
              <AccordionDetails className="p-0">
                <TableResources
                  setShow={setShow}
                  setNomeOpcao={setNomeOpcao}
                  setTipoRecurso={setTipoRecurso}
                  setEditing={setEditing}
                  setResourceId={setResourceId}
                  resourceType="Fonte"
                  resources={fontes}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <span className={classes.heading}>Modalidades</span>
                <span className={classes.secondaryHeading}>
                  Modalidade do concurso
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <TableResources
                  setShow={setShow}
                  setNomeOpcao={setNomeOpcao}
                  setTipoRecurso={setTipoRecurso}
                  setEditing={setEditing}
                  setResourceId={setResourceId}
                  resourceType="Modalidade"
                  resources={modalidades}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <span className={classes.heading}>Garantias</span>
                <span className={classes.secondaryHeading}>
                  Tipos de garantia do contrato
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <TableResources
                  setShow={setShow}
                  setNomeOpcao={setNomeOpcao}
                  setTipoRecurso={setTipoRecurso}
                  setEditing={setEditing}
                  setResourceId={setResourceId}
                  resourceType="Garantia"
                  resources={garantias}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <span className={classes.heading}>Regulamentos</span>
                <span className={classes.secondaryHeading}>
                  Regulamentos aplicados aos concursos
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <TableResources
                  setShow={setShow}
                  setNomeOpcao={setNomeOpcao}
                  setTipoRecurso={setTipoRecurso}
                  setEditing={setEditing}
                  setResourceId={setResourceId}
                  resourceType="Regulamento"
                  resources={regulamentos}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </Col>{" "}
        <Col sm={3} className="bg-white shadow my-auto" hidden={!editing}>
          <Collapse in={editing} timeout={1000}>
            <div className="text-start p-3 border-bottom">
              {editing ? (
                <h4>Editar {tipoRecurso}</h4>
              ) : (
                <h4>Adicionar recurso</h4>
              )}
            </div>
            <ResourcesRegistration
              nomeOpcao={nomeOpcao}
              setNomeOpcao={setNomeOpcao}
              tipoRecurso={tipoRecurso}
              resourceId={resourceId}
              setTipoRecurso={setTipoRecurso}
              editing={editing}
              setEditing={setEditing}
              getResources={getResources}
            />{" "}
          </Collapse>
        </Col>
      </Row>

      <Confirmacao
        show={show}
        closeModal={closeModal}
        action={deleteResource}
        actionType={"Eliminar"}
        objectType={`o(a) ${tipoRecurso}`}
        objectDescription={nomeOpcao}
        loader={LoadDelete}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
}
