import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
//const TOKEN = process.env.REACT_APP_BASE_TOKEN;

const requestHeader = () => {
  return {
    headers: {
      Accept: "application/json",
      //Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "X-Requested-With",
      "Access-Control-Max-Age": "60",
    },
  };
};

export { requestHeader, BASE_URL };
