import React from "react";
import NotFound from "../../components/404";



const NotFoundPage = () => {
  return (
    <div >
      <NotFound />
    </div>
  );
};
export default NotFoundPage;

