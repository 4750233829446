/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Collapse,
  FormHelperText,
  IconButton,
  InputAdornment,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  Block,
  ClearAll,
  Email,
  Lock,
  MoreVert,
  Person,
  PersonAdd,
  Save,
  Search,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import nothing22 from "../../../assets/images/nothingWaw.gif";
import { Col, Row, Spinner } from "react-bootstrap";
import { useEffect } from "react";
import EntitiesCard from "../../commons/cards/entitiesCard";
import { Autocomplete } from "@material-ui/lab";
import { niveisAcesso } from "../../utils/resourcesFields";
import SnackBar from "../../commons/snackbar";
import axios from "axios";
import { BASE_URL } from "../../api/requests/useGetData";
import Confirmacao from "../../commons/modal/confirmarOperacao";
import { useAuthUser } from "react-auth-kit";

export default function ListUsers() {
  const auth = useAuthUser()()?.data

  const [loading, setLoading] = useState(true);
  const [loadingTotal, setLoadingTotal] = useState(true);
  const [loader, setLoader] = useState(false);
  const [loadAction, setLoadAction] = useState(false);
  const [editing, setEditing] = useState(false);
  const [totalUsers, setTotalUsers] = useState();

  const fetchTotalUsers = () => {
    axios
      .get(`${BASE_URL}/usuario`)
      .then((response) => {
        setTotalUsers(response.data.data);
        setLoadingTotal(false);
      })
      .catch((error) => {
        setLoadingTotal(false);
      });
  };
  useEffect(() => {
    fetchTotalUsers();
  }, []);

  const [showForm, setShowForm] = useState(false);
  const [filter, setFilter] = useState("");
  const [utilizadores, setUtilizadores] = useState();

  const [nomeUser, setNomeUser] = useState("");

  const getParams = () => {
    var paramss = "";
    if (nomeUser) {
      paramss += `?nome=${nomeUser}`;
    }
    if (filter) {
      paramss += `?role=${filter}`;
    }
    return paramss;
  };

  const resetFilters = () => {
    setNomeUser("");
    setFilter("");
  };

  const fetchUsers = () => {
    setLoading(true);
    axios
      .post(`${BASE_URL}/usuario/u/filtrar-por-colunas${getParams()}`)
      .then((response) => {
        console.log("utilizadores", response);
        setUtilizadores(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [nomeUser, filter]);

  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [userId, setUserId] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [nome, setNome] = useState();
  const [username, setUsername] = useState();
  const [senha, setSenha] = useState();
  const [email, setEmail] = useState();
  const [tipo, setTipo] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorUsername, setErrorUsername] = useState(false);
  const [errorUsernameMessage, setErrorUsernameMessage] = useState("");

  const [errorSenha, setErrorSenha] = useState(false);
  const [, setErrorSenhaMessage] = useState(
    "*Pelo menos 6 digitos"
  );

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");

  const [errorTipo, setErrorTipo] = useState(false);
  const [errorTipoMessage, setErrorTipoMessage] = useState("");

  const [checked, setChecked] = useState(false);
  const handleChangeChecked = (e) => setChecked(e.target.checked);

  const [showEliminar, setShowEliminar] = useState(false);
  const closeEliminar = () => {
    setShowEliminar(false);
    setChecked(false);
  };

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (
      errorNome === false &&
      errorUsername === false &&
      errorSenha === false &&
      errorEmail === false &&
      errorTipo === false
    ) {
      if (editing) {
        editar();
      } else {
        salvar();
      }
    } else {
      console.log("Não posso salvar");
    }
  };

  const requiredFields = () => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }
    if (username === "" || username === undefined) {
      setErrorUsername(true);
      setErrorUsernameMessage("Por favor, preencha este campo!");
    } else {
      setErrorUsername(false);
      setErrorUsernameMessage(true);
    }
    if (senha === "" || senha === undefined || senha?.length < 6) {
      setErrorSenha(true);
      setErrorSenhaMessage("Senha inválida, pelo menos 6 dígitos!");
    } else {
      setErrorSenha(false);
      setErrorSenhaMessage(true);
    }
    if (email === "" || email === undefined || !email?.match(mailformat)) {
      setErrorEmail(true);
      setErrorEmailMessage("Endereço de email inválido, campo obrigatório!");
    } else {
      setErrorEmail(false);
      setErrorEmailMessage(true);
    }

    if (tipo === "" || tipo === undefined) {
      setErrorTipo(true);
      setErrorTipoMessage("Por favor, seleccione uma opção");
    } else {
      setErrorTipo(false);
      setErrorTipoMessage(true);
    }
  };

  const dados = {
    nome: nome,
    usuario: username,
    email: email,
    senha: senha,
    role: tipo,
  };
  const cleanFields = () => {
    if (editing) setShowForm(false);
    setNome("");
    setUsername("");
    setEmail("");
    setSenha("");
    setTipo("");
    setEditing(false);
    setUserId();
  };

  const startEditing = (user) => {
    setShowForm(true);
    setEditing(true);
    setUserId(user.id);
    setNome(user.nome);
    setEmail(user.email);
    setUsername(user.usuario);
    setSenha(user.senha);
    setTipo(user.role);
  };

  const salvar = () => {
    setLoader(true);
    axios
      .post(`${BASE_URL}/usuario`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Utilizador registado com sucesso!");
        setColor("success");
        setLoader(false);
        cleanFields();
        fetchUsers();
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const editar = () => {
    setLoader(true);
    axios
      .put(`${BASE_URL}/usuario/${userId}`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Dados actualizados com sucesso!");
        setColor("success");
        setLoader(false);
        cleanFields();
        fetchUsers();
        setShowForm(!showForm);
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const eliminar = () => {
    setLoadAction(true);
    axios
      .delete(`${BASE_URL}/usuario/${selectedUser.id}`)
      .then((response) => {
        setOpen(true);
        setmessage("Utilizador emininado com sucesso!");
        setColor("success");
        setLoadAction(false);
        fetchUsers();
        closeEliminar();
        console.log("saved?", response);
      })
      .catch((error) => {
        setLoadAction(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const usersStatiscs = [
    {
      id: 1,
      type: "Super Administradores",
      value: () =>
        loadingTotal ? (
          <CircularProgress size={20} />
        ) : (
          totalUsers?.filter((x) => x.role === "Super administrador").length
        ),
      filter: "Super administrador",
      icon: "material-symbols:admin-panel-settings-outline",
    },
    {
      id: 2,
      type: "Administradores",
      value: () =>
        loadingTotal ? (
          <CircularProgress size={20} />
        ) : (
          totalUsers?.filter((x) => x.role === "Administrador").length
        ),
      filter: "Administrador",
      icon: "ri:admin-line",
    },
    {
      id: 3,
      type: "Funcionários",
      value: () =>
        loadingTotal ? (
          <CircularProgress size={20} />
        ) : (
          totalUsers?.filter((x) => x.role === "Funcionário").length
        ),
      filter: "Funcionário",
      icon: "icon-park-outline:user-business",
    },
  ];

  return (
    <div className="container-fluid">
      <div className="text-center wow fadeInUp mb-2" data-wow-delay="0.1s">
        <h3 className="my-4 mb-5">Gestão de Utilizadores</h3>
      </div>
      <Row className="d-flex justify-content-center">
        {usersStatiscs.map((card) => (
          <Col sm={3}>
            <EntitiesCard
              object={card}
              filter={filter}
              setFilter={setFilter}
              setPage={() => setPage(0)}
            />
          </Col>
        ))}
      </Row>
      <TableContainer component={"div"} className="bg-white shadow">
        {/* -------------------FORMULÁRIO DE REGISTO------------- */}
        <Collapse in={showForm} timeout="auto" unmountOnExit disablePadding>
          <div className="text-start py-3 text-secondary d-flex">
            <h4 className="px-3 text-secondary">Formulário de Registo</h4>
          </div>
          <div className="px-3">
            <form onSubmit={formSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <Autocomplete
                    id="clear-on-escape"
                    options={niveisAcesso}
                    getOptionLabel={(option) => option}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(event, value) => setTipo(value)}
                    value={tipo}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label="Nível de acesso"
                        className="rounded"
                        type={"text"}
                        error={errorTipo}
                        helperText={errorTipoMessage}
                      />
                    )}
                  />
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      required
                      size="small"
                      margin="normal"
                      fullWidth
                      name="nome"
                      label={
                        <span>
                          <Person
                            style={{ color: "#568C3E", marginTop: "-5" }}
                          />{" "}
                          Nome
                        </span>
                      }
                      placeholder="Nome Completo"
                      type={"text"}
                      id="nome"
                      className="rounded"
                      value={nome}
                      defaultValue={nome}
                      error={errorNome}
                      helperText={errorNomeMessage}
                      onChange={(e) => setNome(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    required
                    size="small"
                    margin="normal"
                    fullWidth
                    name="email"
                    label={
                      <span>
                        <Email style={{ color: "#568C3E", marginTop: "-5" }} />{" "}
                        Email
                      </span>
                    }
                    placeholder="example@gmail.com"
                    className="rounded"
                    type={"text"}
                    id="email"
                    value={email}
                    defaultValue={email}
                    error={errorEmail}
                    helperText={errorEmailMessage}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    disabled={loader}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    required
                    margin="normal"
                    size="small"
                    fullWidth
                    name="username"
                    label={
                      <span>
                        <Lock style={{ color: "#568C3E", marginTop: "-5" }} />{" "}
                        Username
                      </span>
                    }
                    placeholder="@username"
                    type={"text"}
                    id="username"
                    className="rounded"
                    value={username}
                    defaultValue={username}
                    error={errorUsername}
                    helperText={errorUsernameMessage}
                    onChange={(e) => setUsername(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    disabled={loader}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    required
                    size="small"
                    margin="normal"
                    fullWidth
                    name="senha"
                    className="rounded"
                    label={
                      <span>
                        <Lock style={{ color: "#568C3E", marginTop: "-5" }} />{" "}
                        Senha
                      </span>
                    }
                    placeholder="******"
                    type={showPassword ? "text" : "password"}
                    id="senha"
                    value={senha}
                    defaultValue={senha}
                    error={errorSenha}
                    onChange={(e) => setSenha(e.target.value)}
                    autoComplete="new-password"
                    inputProps={{ style: { border: "none", paddingLeft: 10 } }}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{ color: "#568C3E" }}
                            disabled={editing}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <Visibility fontSize="small" />
                            ) : (
                              <VisibilityOff fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    disabled={loader || editing}
                  />
                  {errorSenha ? (
                    <FormHelperText className="text-danger">
                      Campo obrigatório!
                    </FormHelperText>
                  ) : (
                    <FormHelperText>*Pelo menos 6 digitos</FormHelperText>
                  )}
                </div>

                <div className="col-12 d-flex justify-content-between align-items-center pb-2">
                  <Button
                    variant="text"
                    className="text-capitalize me-4 px-3"
                    style={{ color: "red" }}
                    onClick={() => cleanFields()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    className="text-light text-capitalize"
                    style={{ backgroundColor: "#568C3E" }}
                    disabled={loader}
                    startIcon={
                      loader ? (
                        <Spinner
                          animation="border"
                          style={{ width: 24, height: 24 }}
                        />
                      ) : (
                        <Save />
                      )
                    }
                    onClick={() => requiredFields()}
                  >
                    {editing ? "Salvar alterações" : "Salvar"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Collapse>

        {/* -------------------TABELA DE LISTAGEM------------- */}
        {loading && <LinearProgress className="w-100" />}

        {/* --------------------------- */}
        <div className="row w-100 pt-3 border-top">
          <div className="col-6 text-start p-3 text-secondary d-flex">
            <h4 className="px-3 text-secondary">Lista</h4>
          </div>

          <div className="col-6 d-flex justify-content-end my-auto p-0">
            <Button
              value="default"
              size="small"
              variant="contained"
              className="text-light text-capitalize"
              onClick={() => setShowForm(!showForm)}
              style={{ backgroundColor: showForm ? "#D5D5D5" : "#568C3E" }}
              startIcon={showForm ? <Block /> : <PersonAdd />}
            >
              {showForm ? "Ocultar formulário" : "Adicionar utilizador"}
            </Button>
          </div>
        </div>
        {/* -------------------------- */}
        <div className="row w-100 px-3">
          <div className="col-md-12 d-flex justify-content-start align-items-center">
            <TextField
              fullWidth
              variant="outlined"
              size={"small"}
              name="searchDescricao"
              disabled={!utilizadores?.length}
              label={<span style={{ fontSize: 13 }}>Nome...</span>}
              style={{ width: "23%" }}
              type={"text"}
              id="standard-start-adornment"
              value={nomeUser}
              onChange={(e) => setNomeUser(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ color: "#568C3E" }}
                      size="small"
                      className=""
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
                disableUnderline: true,
                style: {
                  borderRadius: 10,
                  paddingLeft: 1,
                },
              }}
            />

            {getParams() && (
              <Button
                className="text-capitalize ml-3"
                style={{ color: "#2075A9", backgroundColor: "#F5F5F5" }}
                startIcon={<ClearAll />}
                onClick={() => resetFilters()}
              >
                Limpar filtros
              </Button>
            )}
          </div>
        </div>

        <Table className={"classes.table"} aria-label="simple table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell className="">ID</TableCell>
              <TableCell className="">Nome completo</TableCell>
              <TableCell className="">Email</TableCell>
              <TableCell className="">Nível</TableCell>
              <TableCell className="" align="right">
                Acções
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {utilizadores
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((d, index) => (
                <>
                  <TableRow key={d.id}>
                    <TableCell component="th" scope="row">
                      {d.id}
                    </TableCell>
                    <TableCell>{d.nome}</TableCell>
                    <TableCell>{d.email}</TableCell>
                    <TableCell>{d.role}</TableCell>
                    <TableCell align="right" className="ms-4">
                      <div className="text-end dropdownrounded">
                        <IconButton
                          id="dropdownMenu2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          size="small"
                        >
                          <MoreVert style={{ color: "#568C3E" }} />
                        </IconButton>
                        <ul
                          className="dropdown-menu shadow detalhes-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          <li
                            className={`dropdown-item ${
                              auth?.role === "Super administrador" &&
                              "border-bottom"
                            }`}
                            onClick={() => startEditing(d)}
                          >
                            Editar
                          </li>
                          {auth?.role === "Super administrador" && (
                            <li
                              className="dropdown-item"
                              onClick={() => {
                                setShowEliminar(true);
                                setSelectedUser(d);
                              }}
                            >
                              Eliminar
                            </li>
                          )}
                        </ul>
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>

        {utilizadores?.length === 0 && !loading && (
          <div className="p-3 text-center d-inline-block w-100 align-items-center border-bottom justify-content-center">
            <div>
              <img
                src={nothing22}
                alt=""
                title="Nada para mostrar"
                style={{ width: "170px" }}
              />
            </div>
            <span className="text-secondary">
              Nenhum utilizador encontrado!
            </span>{" "}
          </div>
        )}

        <TablePagination
          rowsPerPageOptions={[5, 25, 100]}
          component="div"
          count={utilizadores?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Linhas por página"}
        />
      </TableContainer>

      <Confirmacao
        show={showEliminar}
        closeModal={closeEliminar}
        action={eliminar}
        actionType={"Eliminar"}
        objectType={`o utilizador`}
        objectDescription={selectedUser?.nome}
        loader={loadAction}
        checked={checked}
        handleChangeChecked={handleChangeChecked}
      />

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
}
