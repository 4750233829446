import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Spinner } from "react-bootstrap";
import SnackBar from "../snackbar.js";
import dateFormat from "dateformat";
import { Autocomplete } from "@material-ui/lab";
import { getCategory } from "../../utils/getAnnouncementLabels.js";
import axios from "axios";
import { BASE_URL } from "../../api/requests/useGetData";
import { AdicionarEntidade } from "../../dashboard/entities/modalAdd.js";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const today = dateFormat(new Date(), "yyyy-mm-dd");

export const AdjudicarAnuncio = (props) => {
  const { anuncio, show, closeModal, fetchAnnounces } = props;
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [entidades, setEntidades] = useState();

  const getEntidadesContratadas = () => {
    axios.get(`${BASE_URL}/entidade-contratada`).then((response) => {
      setEntidades(response.data.data);
    });
  };

  useEffect(() => {
    getEntidadesContratadas();
  }, []);

  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [entidadeContrada, setEntidadeContrada] = useState({});
  const [dataAdjudicacao, setDataAdjudicacao] = useState(today);

  const [cambioDolar, setCambioDolar] = useState(0);
  const [cambioEuro, setCambioEuro] = useState(0);

  const [valorAdjudicadoMt, setValorAdjudicadoMt] = useState(0);
  const [valorAdjudicadoUsd, setValorAdjudicadoUsd] = useState(0);
  const [valorAdjudicadoEur, setValorAdjudicadoEur] = useState(0);

  const [totalAdjudicadoMt, setTotalAdjudicadoMt] = useState(0);
  const [totalAdjudicadoUsd, setTotalAdjudicadoUsd] = useState(0);
  const [totalAdjudicadoEur, setTotalAdjudicadoEur] = useState(0);

  console.log(anuncio?.observacao);
  const [extraInfo, setExtraInfo] = useState(anuncio?.observacao);

  const [errorEntContratada, setErrorEntContratada] = useState(false);
  const [errorDataAdjudicacao, setErrorDataAdjudicacao] = useState(false);

  const [errorCambioDolar, setErrorCambioDolar] = useState(false);
  const [errorCambioEur, setErrorCambioEur] = useState(false);

  const [errorTotalAdjudicadoMt, setErrorTotalAdjudicadoMt] = useState(false);
  const [errorTotalAdjudicadoUsd, setErrorTotalAdjudicadoUsd] = useState(false);
  const [errorTotalAdjudicadoEur, setErrorTotalAdjudicadoEur] = useState(false);

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const entityType = "Contratada";
  const [showAddEntity, setShowAddEntity] = useState(false);
  const closeAddEntity = () => {
    setShowAddEntity(false);
  };

  const formSubmit = (event) => {
    event.preventDefault();
    if (
      errorEntContratada === false &&
      errorDataAdjudicacao === false &&
      errorCambioDolar === false &&
      errorCambioEur === false &&
      errorTotalAdjudicadoMt === false &&
      errorTotalAdjudicadoUsd === false &&
      errorTotalAdjudicadoEur === false
    ) {
      salvar();
    } else {
      console.log("Não posso salvar");
    }
  };

  const requiredFields = () => {
    if (dataAdjudicacao === "" || dataAdjudicacao === undefined) {
      setErrorDataAdjudicacao(true);
    } else {
      setErrorDataAdjudicacao(false);
    }
    if (entidadeContrada === "" || entidadeContrada === undefined) {
      setErrorEntContratada(true);
    } else {
      setErrorEntContratada(false);
    }
    if (errorCambioDolar === 0 || errorCambioDolar === undefined) {
      setErrorCambioDolar(true);
    } else {
      setErrorCambioDolar(false);
    }
    if (errorCambioEur === 0 || errorCambioEur === undefined) {
      setErrorCambioEur(true);
    } else {
      setErrorCambioEur(false);
    }
    if (totalAdjudicadoMt === 0 || totalAdjudicadoMt === undefined) {
      setErrorTotalAdjudicadoMt(true);
    } else {
      setErrorTotalAdjudicadoMt(false);
    }
    if (totalAdjudicadoUsd === 0 || totalAdjudicadoUsd === undefined) {
      setErrorTotalAdjudicadoUsd(true);
    } else {
      setErrorTotalAdjudicadoUsd(false);
    }
    if (totalAdjudicadoEur === 0 || totalAdjudicadoEur === undefined) {
      setErrorTotalAdjudicadoEur(true);
    } else {
      setErrorTotalAdjudicadoEur(false);
    }
  };

  const dados = {
    data_adjudicacao: dataAdjudicacao,
    entidade_contratada_id: entidadeContrada?.id,
    valor_adjudicado_MT: valorAdjudicadoMt,
    valor_adjudicado_USD: valorAdjudicadoUsd,
    valor_adjudicado_EUR: valorAdjudicadoEur,
    total_MT: totalAdjudicadoMt,
    total_USD: totalAdjudicadoUsd,
    total_EUR: totalAdjudicadoEur,
    observacao: extraInfo,
    estado: "Adjudicado",
  };

  const test = {
    total_EUR: "0",
    total_MT: "0",
    total_USD: "0",
    updated_at: "2023-10-07T14:03:44.000000Z",
    validade_proposta: null,
    valor_adjudicado_EUR: "0",
    valor_adjudicado_MT: "3000000",
    valor_adjudicado_USD: "600000",
  };

  const salvar = () => {
    setLoader(true);
    axios
      .put(`${BASE_URL}/concurso/${anuncio.id}`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Adjudicação feita com sucesso!");
        setColor("success");
        setLoader(false);
        cleanFields();
        fetchAnnounces();
        closeModal();
        console.log("saved?", response);
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const cleanFields = () => {
    setDataAdjudicacao(today);
    setEntidadeContrada("");

    setCambioDolar(0);
    setCambioEuro(0);

    setValorAdjudicadoMt(0);
    setValorAdjudicadoUsd(0);
    setValorAdjudicadoEur(0);

    setTotalAdjudicadoMt(0);
    setTotalAdjudicadoUsd(0);
    setTotalAdjudicadoEur(0);

    setErrorDataAdjudicacao(false);
    setErrorEntContratada(false);

    setErrorCambioDolar(false);
    setErrorCambioEur(false);

    setErrorTotalAdjudicadoMt(false);
    setErrorTotalAdjudicadoUsd(false);
    setErrorTotalAdjudicadoEur(false);
    setExtraInfo("");
  };
  const handleValorMt = (event) => {
    setValorAdjudicadoMt(event.target.value);

    //setValorAdjudicadoUsd((event.target.value / cambio).toFixed(2));
  };

  useEffect(() => {
    if (cambioDolar && cambioEuro) {
      const valorTotalMt = (
        Number(valorAdjudicadoMt) +
        Number(valorAdjudicadoUsd) * Number(cambioDolar) +
        Number(valorAdjudicadoEur) * Number(cambioEuro)
      ).toFixed(2);

      const valorTotalUSD = (
        Number(valorAdjudicadoUsd) +
        Number(valorAdjudicadoMt) / Number(cambioDolar) +
        (Number(valorAdjudicadoEur) * Number(cambioEuro)) / Number(cambioDolar)
      ).toFixed(2);

      const valorTotalEUR = (
        Number(valorAdjudicadoEur) +
        Number(valorAdjudicadoMt) / Number(cambioEuro) +
        Number(valorAdjudicadoUsd) * (Number(cambioDolar) / Number(cambioEuro))
      ).toFixed(2);

      setTotalAdjudicadoMt(valorTotalMt);
      setTotalAdjudicadoUsd(valorTotalUSD);
      setTotalAdjudicadoEur(valorTotalEUR);
    }
  }, [
    cambioDolar,
    cambioEuro,
    valorAdjudicadoMt,
    valorAdjudicadoUsd,
    valorAdjudicadoEur,
  ]);

  const getTotals = (value1, value2, value3) => {
    var total = value1 + value2 * cambioDolar + value3 * cambioEuro;
    return total;
  };

  const handleOptionChange = (event, value) => {
    setEntidadeContrada(value);
  };
  return (
    <>
      <Dialog
        open={show}
        onClose={() => {
          cleanFields();
          closeModal();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" className="fw-bold text-center">
          Formulário de Adjudicação de {getCategory(anuncio?.categoria)}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              cleanFields();
              closeModal();
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={formSubmit}>
          <Divider />
          <DialogContent>
            <div className="row">
              <div className="col-md-6">
                <small>REF/Concurso: </small>
                <h6>{anuncio?.ref_concurso}</h6>
              </div>
              <div className="col-md-6">
                <small>REF/BCC: </small>
                <h6>{anuncio?.ref_bcc}</h6>
              </div>
              <div className="col-md-12 mt-4 d-flex justify-content-between">
                <small className="" style={{ color: "red" }}>
                  Por favor, preencha os campos abaixo, os campos com (*) são
                  obrigatórios e, os dados não serão enviados sem o devido
                  preenchimento.
                </small>
                <small
                  className="add-new-entity"
                  onClick={() => setShowAddEntity(true)}
                >
                  Adicionar entidade contratante
                </small>
              </div>
              <div className="col-md-6">
                <div className="form-floating">
                  <Autocomplete
                    id="clear-on-escape"
                    noOptionsText="Nenhuma opção encontrada"
                    options={entidades}
                    getOptionLabel={(option) => option.nome}
                    onChange={handleOptionChange}
                    value={entidadeContrada}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label="Entidade Contratada"
                        className="rounded border-bottom"
                        type={"text"}
                        error={errorEntContratada}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating">
                  <TextField
                    variant="outlined"
                    floatingLabelStyle={{ color: "#568C3E" }}
                    size="small"
                    margin="normal"
                    fullWidth
                    required
                    name="dataAdjudicacao"
                    label="Data da adjudicação"
                    type={"date"}
                    id="dataAdjudicacao"
                    className="rounded border-bottom"
                    value={dataAdjudicacao}
                    defaultValue={dataAdjudicacao}
                    error={errorDataAdjudicacao}
                    onChange={(e) => setDataAdjudicacao(e.target.value)}
                    inputProps={{
                      style: { border: "none", paddingLeft: 2 },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={loader}
                  />
                </div>
              </div>
              {/* 
=
=
=
=
=
=
=
=
=
=
=





=
=
=
=
=
=
=
=
=
=
=
 */}
              <div className="col-md-12 row py-3 mt-4 border-top">
                <div className="col-md-4">
                  {" "}
                  <strong className="col-md-12">Câmbio do dia:</strong>
                  <div className="col-md-12">{""}</div>
                  <div className="col-md-12">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="cambioDolar"
                      placeholder="Câmbio do Dólar"
                      label="Dólar"
                      className="rounded border-bottom"
                      type={"number"}
                      id="cambioDolar"
                      value={cambioDolar}
                      defaultValue={cambioDolar}
                      error={errorCambioDolar}
                      onChange={(e) => setCambioDolar(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      //disabled={loader}
                    />
                  </div>
                  <div className="col-md-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      label="Euro"
                      required
                      placeholder="Câmbio do Euro"
                      size="small"
                      className="rounded border-bottom mt-3"
                      type={"number"}
                      style={{ width: "100%" }}
                      value={cambioEuro}
                      error={errorCambioEur}
                      onChange={(e) => setCambioEuro(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      //disabled={loader || disableCambio}
                    />
                  </div>{" "}
                </div>
                <div className="col-md-4">
                  {" "}
                  <strong className="col-md-12">Valores adjudicados:</strong>
                  <div className="col-md-12">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="valorAdjudicadoMt"
                      label="Valor Adjudicado (MT)"
                      placeholder="Total Adjudicado (MT)"
                      className="rounded border-bottom"
                      type={"number"}
                      id="valorAdjudicadoMt"
                      value={valorAdjudicadoMt}
                      defaultValue={valorAdjudicadoMt}
                      onChange={handleValorMt}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">MT</InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 2 },
                      }}
                      //disabled={loader}
                    />
                  </div>
                  <div className="col-md-12">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="valorAdjudicadoUsd"
                      label="Valor Adjudicado (USD)"
                      placeholder="Total Adjudicado (USD)"
                      className="rounded border-bottom"
                      type={"number"}
                      id="valorAdjudicadoUsd"
                      value={valorAdjudicadoUsd}
                      defaultValue={valorAdjudicadoUsd}
                      onChange={(e) => setValorAdjudicadoUsd(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 2 },
                      }}
                      //disabled={loader}
                    />
                    {cambioDolar !== 0 && (
                      <small>{valorAdjudicadoUsd * cambioDolar} Meticais</small>
                    )}
                  </div>
                  <div className="col-md-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      label="Valor Adjudicado (EUR)"
                      required
                      placeholder="Valor Adjudicado (EUR)"
                      size="small"
                      className="rounded border-bottom mt-3"
                      type={"number"}
                      style={{ width: "100%" }}
                      value={valorAdjudicadoEur}
                      onChange={(e) => setValorAdjudicadoEur(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 2 },
                      }}
                      //disabled={loader || disableCambio}
                    />
                    {cambioEuro !== 0 && (
                      <small>{valorAdjudicadoEur * cambioEuro} Meticais</small>
                    )}
                  </div>{" "}
                </div>
                <div className="col-md-4">
                  {" "}
                  <strong className="col-md-12">Total adjudicado:</strong>
                  <div className="col-md-12">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="totalAdjudicadoMt"
                      label="Total Adjudicado (MT)"
                      placeholder="Total Adjudicado (MT)"
                      className="rounded border-bottom"
                      type={"number"}
                      id="totalAdjudicadoMt"
                      value={totalAdjudicadoMt}
                      defaultValue={totalAdjudicadoMt}
                      error={errorTotalAdjudicadoMt}
                      onChange={(e) => setTotalAdjudicadoMt(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">MT</InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 2 },
                      }}
                      //disabled={loader}
                    />
                  </div>
                  <div className="col-md-12">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="totalAdjudicadoUsd"
                      label="Total Adjudicado (USD)"
                      placeholder="Total Adjudicado (USD)"
                      className="rounded border-bottom"
                      type={"number"}
                      id="totalAdjudicadoUsd"
                      value={totalAdjudicadoUsd}
                      defaultValue={totalAdjudicadoUsd}
                      error={errorTotalAdjudicadoUsd}
                      onChange={(e) => setTotalAdjudicadoUsd(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 2 },
                      }}
                      //disabled={loader}
                    />
                  </div>
                  <div className="col-md-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      label="Total Adjudicado (EUR)"
                      required
                      placeholder="Total Adjudicado (EUR)"
                      size="small"
                      className="rounded border-bottom mt-3"
                      type={"number"}
                      style={{ width: "100%" }}
                      value={totalAdjudicadoEur}
                      error={errorTotalAdjudicadoEur}
                      onChange={(e) => setTotalAdjudicadoEur(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      inputProps={{
                        style: { border: "none", paddingLeft: 2 },
                      }}
                      //disabled={loader || disableCambio}
                    />
                  </div>{" "}
                </div>
              </div>
              <div className="col-md-12 border-bottom pb-3">
                <div className="d-flex justify-content-between align-items-center mt-2">
                  Observações a acrescentar sobre o concurso:
                </div>
                <div className="form-floating">
                  <TextField
                    variant="outlined"
                    floatingLabelStyle={{ color: "#568C3E" }}
                    size="small"
                    margin="normal"
                    fullWidth
                    name="extraInfo"
                    // label="Observações a acrescentar sobre o concurso:"
                    type={"text"}
                    id="extraInfo"
                    multiline
                    rows={4}
                    className="rounded"
                    value={extraInfo}
                    defaultValue={anuncio?.observacao}
                    onChange={(e) => setExtraInfo(e.target.value)}
                    inputProps={{
                      style: { border: "none", paddingLeft: 1 },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    disabled={loader}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="px-4">
            <Button
              className="text-capitalize mr-3"
              style={{ width: 100 }}
              onClick={() => {
                cleanFields();
                closeModal();
              }}
              color="secondary"
              disabled={loader}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              className="text-capitalize"
              style={{ color: "white", width: 100, backgroundColor: "#568C3E" }}
              onClick={() => requiredFields()}
              disabled={loader}
            >
              {loader ? (
                <Spinner animation="border" style={{ width: 24, height: 24 }} />
              ) : (
                "Salvar"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <AdicionarEntidade
        show={showAddEntity}
        closeModal={closeAddEntity}
        entityType={entityType}
        fetchEntities={getEntidadesContratadas}
        setEntity={setEntidadeContrada}
      />

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom" + "center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </>
  );
};
