import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, ButtonGroup } from "@material-ui/core";
import Map from "../../commons/map/map";
import { provinces } from "../../commons/map/provincesData";

export default function MapReports() {
  const filters = [
    {
      id: 1,
      code: "valor",
      name: "Todos concursos",
      title: "Valor adjudicado aos concursos",
    },
    {
      id: 2,
      code: "adiamento",
      name: "Obras",
      title: "Valor adjudicado às obras",
    },
    {
      id: 3,
      code: "cancelamento",
      name: "Bens",
      title: "Valor adjudicado aos bens",
    },
    {
      id: 4,
      code: "entidade",
      name: "Serviços",
      title: "Valor adjudicado aos serviços",
    },
  ];
  const [filter, setfilter] = useState(filters[0]);
  const changeFilter = (filter) => {
    setfilter(filter);
  };

  const formatter = new Intl.NumberFormat(["ban", "id"]);
  return (
    <div className="">
      <ButtonGroup
        className="mt-3 mb-4"
        color="primary"
        aria-label="outlined primary button group"
        style={{ height: 40 }}
      >
        {filters.map((value, index) => (
          <Button
            key={index}
            className="text-capitalize"
            color={filter.code === value.code ? "primary" : "default"}
            //variant={filter === value.code ? "contained" : "outlined"}
            onClick={() => changeFilter(value)}
            style={{
              width: 150,
              borderBottom: filter.code === value.code && "7px solid #3f50b5",
            }}
          >
            {value.name}
          </Button>
        ))}
      </ButtonGroup>

      <Row>
        <Col sm={6}>
          <Map />
        </Col>
        <Col sm={6}>
          {/* <h1>Reportes</h1> */}
          <div className="mt-3">
            {/* <div className="d-flex align-items-center my-2">
              <FiberManualRecord className="mr-2" style={{ color: "green" }} />
              <h4>
                Concluídos dentro do prazo:{" "}
                <strong style={{ color: "green" }}>120</strong>{" "}
              </h4>
            </div>
            <div className="d-flex align-items-center my-2">
              <FiberManualRecord className="mr-2" style={{ color: "red" }} />
              <h4>
                Concluídos fora do prazo:{" "}
                <strong style={{ color: "red" }}>40</strong>{" "}
              </h4>
            </div> */}
            <h3>Mapa densidade - {filter.title}</h3>
          </div>

          <Row className="border mt-4">
            <Col sm={12}>
              <div className="py-2 border-bottom">
                <h5 className="">Vista detalhada por província</h5>
              </div>
            </Col>
            <Col sm={6} className="border-right">
              {/* <h1>Reportes</h1> */}
              <div className="border-bottom">
                <div className="my-2">
                  <h5>Província </h5>
                </div>
              </div>
              <div className="mt-2">
                {provinces.map((province) => (
                  <p>{province.name}</p>
                ))}
              </div>
            </Col>
            <Col sm={6} className="">
              {/* <h1>Reportes</h1> */}
              <div className="border-bottom">
                <div className="my-2">
                  <h5>Valor adjudicado (Mt) </h5>
                </div>
              </div>
              <div className="mt-2">
                {provinces.map((province) => (
                  <p>{formatter.format(province.value)}</p>
                ))}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
