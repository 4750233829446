import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/map")(Highcharts);

export default function ColumnHighChart({ title, subtitle, data, categories }) {
  const chartOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: title,
      style: {
        fontSize: "1em",
        color: "black",
        margin: 100,
      },
    },
    subtitle: {
      text: subtitle,
    },
    xAxis: {
      categories,
      crosshair: true,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total de concursos",
      },
    },
    tooltip: {
      valueSuffix: " Concursos",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: true,
      },
      series: {
        //stacking: true,
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: data,
  };

  return (
    <div className="">
      <HighchartsReact options={chartOptions} highcharts={Highcharts} />
    </div>
  );
}
