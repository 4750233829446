import React from "react";
import { mapMozTopo } from "../../../mozambiquehighChartsMap/MozambiqueTopJson.topo";
import MapHighChart from "../../../commons/charts/MapHighChart";
import { mozMapData } from "../demoData/chartsDemoData";

export default function MozambiqueMap() {
  return (
    <div>
      <MapHighChart
        title="Dados de Moçambique"
        data={mozMapData}
        zoneName="Moçambique"
        zoneData={mapMozTopo}
      />
    </div>
  );
}
