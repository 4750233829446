import { caboDelgado, gaza, inhambane, manica, maputo, nampula, niassa, sofala, tete, zambezia } from "./provincesLimits/provincesLimits";

const provinces = [
    { id: "1", name: "Cabo Delgado", limits: caboDelgado, density: 0.94, value: 10000000.45  },
    { id: "2", name: "Niassa", limits: niassa, density: 0.39, value: 105047.97 },
    { id: "3", name: "Nampula", limits: nampula, density: 0.49, value: 45097300.81 },
    { id: "4", name: "Zambézia", limits: zambezia, density: 0.70, value: 7225714.49 },
    { id: "5", name: "Tete", limits: tete, density: 0.59, value: 10000000.95 },
    { id: "6", name: "Manica", limits: manica, density: 0.39, value: 200425579.25 },
    { id: "7", name: "Sofala", limits: sofala, density: 0.79, value: 45000000.77 },
    { id: "8", name: "Inhambane", limits: inhambane, density: 0.39, value: 27495297.23 },
    { id: "9", name: "Gaza", limits: gaza, density: 0.77, value: 745675429.07 },
    { id: "10", name: "Maputo", limits: maputo, density: 0.39, value: 21000000.05 },
  ];
export { provinces };
