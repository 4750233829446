/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../api/requests/useGetData";
import SnackBar from "../../commons/snackbar";
import avatar from "../../../assets/images/avatar-admin.png";
import "./profile.css";
import { Col, Row } from "react-bootstrap";
import dateFormat from "dateformat";
import { useAuthUser } from "react-auth-kit";

export default function UserProfile() {
  const auth = useAuthUser()()?.data

  var lastSync = localStorage.getItem("lastSync");

  const [loading, setLoading] = useState(true);
  const [utilizador, setUtilizador] = useState(0);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/usuario/${auth?.id}`)
      .then((response) => {
        setUtilizador(response.data.data);
        setLoading(false);
        console.log(response);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [open, setOpen] = useState();
  const [message, ] = useState();
  const [color, ] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  return (
    <div className="container-fluid">
      <div className="text-center wow fadeInUp mb-2" data-wow-delay="0.1s">
        <h3 className="mt-5">Dados do Utilizador</h3>
      </div>
      <Row className="d-flex justify-content-center">
        <Col sm={4}>
          <div class="profile-section pb-4">
            <img src={avatar} alt="Foto do Usuário" />{" "}
            {loading ? (
              <div className="text-center">
                <div class="py-4">
                  <div class="loader"></div>
                </div>
              </div>
            ) : (
              <>
                <h4>{utilizador?.nome}</h4>
                <p>{utilizador?.email}</p>
                <p>Username: {utilizador?.usuario}</p>
                <small className="text-secondary pb-5">
                  Último início de sessão aos{" "}
                  {`${dateFormat(lastSync, "dd-mm-yyyy")}, às ${dateFormat(
                    lastSync,
                    "HH:MM:ss"
                  )}`}
                </small>
              </>
            )}
          </div>
        </Col>
      </Row>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
}
