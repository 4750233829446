import React from "react";
import { useNavigate } from "react-router-dom";
import ologo from "../assets/img/main-logo.png";
import { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import { IconButton, InputAdornment } from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Col, Row } from "react-bootstrap";
import SnackBar from "./commons/snackbar";
import axios from "axios";
import { BASE_URL } from "./api/requests/useGetData";
import { useSignIn } from "react-auth-kit";

const theme = createTheme({
  palette: {
    primary: {
      main: "#568C3E", //your color
    },
  },
});

export default function Login() {
  const [usuario, setUsuario] = useState();
  const [senha, setSenha] = useState();
  const [errorUsuario, setErrorUsuario] = useState(false);
  const [errorUsuarioMessage, setErrorUsuarioMessage] = useState("");

  const [errorSenha, setErrorSenha] = useState(false);
  const [errorSenhaMessage, setErrorSenhaMessage] = useState("");

  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const formSubmit = (event) => {
    event.preventDefault();
    if (errorUsuario === false && errorSenha === false) {
      login();
    } else {
      console.log("Não posso salvar");
    }
  };

  const requiredFields = () => {
    if (usuario === "" || usuario === undefined) {
      setErrorUsuario(true);
      setErrorUsuarioMessage("Por favor, preencha este campo!");
    } else {
      setErrorUsuario(false);
      setErrorUsuarioMessage(true);
    }
    if (senha === "" || senha === undefined) {
      setErrorSenha(true);
      setErrorSenhaMessage("Por favor, preencha este campo!");
    } else {
      setErrorSenha(false);
      setErrorSenhaMessage(true);
    }
  };
  const dados = {
    usuario: usuario,
    email: usuario,
    senha: senha,
  };
  const signIn = useSignIn();
  const login = () => {
    setLoader(true);
    axios
      .post(`${BASE_URL}/usuario/u/login`, dados)
      .then((res) => {
        if (res.data.message === 200) {
          setLoader(false);
          signIn({token : true, authState : {data : res.data?.data?.[0]}, tokenType : 'Bearer', expiresIn: 43800 })
          navigate("/admin/dashboard");
        } else {
          setLoader(false);
          setOpen(true);
          setmessage("Dados incorrectos, por favor tente novamente.");
          setColor("error");
        }
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  return (
    <div>
      <div className="container-fluid hero-header-login d-flex justify-content-center align-items-center">
        <Row className="container-fluid">
          <Col
            xs={12}
            sm={8}
            md={6}
            lg={5}
            xl={4}
            xxl={11}
            className="mx-auto px-xl-5"
            style={{}}
          >
            <div className="row g-4 rounded border shadow">
              <div
                className="col-md-12 wow fadeIn p-3 shadow bg-white"
                data-wow-delay="0.1s"
              >
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                  {/* <h5 className="section-title ff-secondary text-center text-primary fw-normal ">
                    Hope for the world
                  </h5> */}
                  <h3 className="mt-3 mb-2 text-">Bem vindo ao</h3>
                </div>
                <h1 className="m-0 " style={{ color: "#fff" }}>
                  <img
                    src={ologo}
                    alt="Change Logo"
                    width="250px"
                    style={{ }}
                    className=""
                  />
                </h1>
              </div>
              <div className="col-md-12 bg-light mt-0 pb-4 pt-3">
                <div className="my-4">
                  <h5>Introduza os seus dados de acesso:</h5>
                </div>
                <div className="wow fadeInUp" data-wow-delay="0.2s">
                  <form onSubmit={formSubmit}>
                    <div className="row g-3">
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size="small"
                            name="usuario"
                            label="Email"
                            type={"text"}
                            id="usuario"
                            value={usuario}
                            defaultValue={usuario}
                            error={errorUsuario}
                            helperText={errorUsuarioMessage}
                            onChange={(e) => setUsuario(e.target.value)}
                            autoComplete="current-password"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon style={{ color: "#568C3E" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>

                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size="small"
                            name="senha"
                            label="Senha"
                            type={showPassword ? "text" : "password"}
                            id="senha"
                            value={senha}
                            defaultValue={senha}
                            error={errorSenha}
                            helperText={errorSenhaMessage}
                            onChange={(e) => setSenha(e.target.value)}
                            autoComplete="new-password"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon style={{ color: "#568C3E" }} />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>

                      {/* <div className="col-12 d-flex justify-content-end mb-3">
                        <span
                          style={{
                            textDecoration: "underline",
                            color: "grey",
                            cursor: "pointer",
                          }}
                          className="link-login"
                          onClick={() => setShow(true)}
                        >
                          <small for="senha">Recuperar senha</small>
                        </span>
                      </div> */}

                      <div className="col-12 mt-5">
                        <Button
                          variant="contained"
                          type="submit"
                          className=" w-100 text-light fw-bold text-capitalize"
                          //startIcon={<SaveIcon />}
                          style={{ backgroundColor: "#82B930" }}
                          onClick={() => requiredFields()}
                          disabled={loader}
                        >
                          {loader ? "A verificar..." : "Iniciar sessão"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
}
