import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Save } from "@material-ui/icons";
import bcc from "../../../assets/images/logo.png";
import { Card, Spinner } from "react-bootstrap";
import dateFormat from "dateformat";
import provinces from "../../utils/mozambique.json";
import { BASE_URL } from "../../api/requests/useGetData";
import axios from "axios";
import SnackBar from "../../commons/snackbar";
import { categorias } from "../../utils/resourcesFields";

const today = dateFormat(new Date(), "yyyy-mm-dd");
export default function FormEdit({
  announce,
  fontes,
  garantias,
  modalidades,
  regulamentos,
  contratantes,
  entidades,
}) {
  const { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [cambio, setCambio] = useState(1);
  const [disableCambio, setDisableCambio] = useState(true);

  const getExchange = () => {
    axios
      .get(`http://economia.awesomeapi.com.br/json/last/USD-MZN`)
      .then((response) => {
        setCambio(response.data.USDMZN.ask);
        console.log("response", response);
      })
      .catch((error) => {
        setDisableCambio(false);
        console.log("Erro ao obter a taxa de câmbio:", error);
      });
  };

  useEffect(() => {
    getExchange();
  }, []);

  /* --------------------DADOS DO FORMULÁRIO---------------------- */
  const [fonte, setFonte] = useState(
    fontes?.filter((x) => x?.descricao === announce?.fonte)[0]
  );
  const [dataAnuncio, setDataAnuncio] = useState(announce?.data_anuncio);
  const [tipoAnuncio, setTipoAnuncio] = useState(announce?.categoria);
  const [descricao, setDescricao] = useState(announce.descricao);
  const [provincia, setProvincia] = useState(
    provinces?.filter((x) => x?.nome === announce?.provincia)[0]
  );
  const [distrito, setDistrito] = useState(
    provincia?.districts?.filter((x) => x?.nome === announce?.distrito)[0]
  );
  const [numeroConcurso, setNumeroConcurso] = useState(announce?.ref_concurso);
  const [modalidade, setModalidade] = useState(modalidades?.filter((x) => x?.descricao === announce?.modalidade)[0]);
  const [dataSubmissao, setDataSubmissao] = useState(announce?.data_submissao);
  const [entidadeContratante, setEntidadeContratante] = useState(
    contratantes?.filter(
      (x) => x?.nome === announce?.entidadeContratante?.nome
    )[0]
  );
  const [regulamentoAplicado, setRegulamentoAplicado] = useState(
    regulamentos?.filter((x) => x?.descricao === announce?.regulamento)[0]
  );
  const [garantia, setGarantia] = useState(
    garantias?.filter((x) => x?.descricao === announce?.garantia)[0]
  );
  const [valorGarantia, setValorGarantia] = useState(announce?.valor_garantia);
  const [prazoGarantia, setPrazoGarantia] = useState(announce?.prazo_garantia);
  const [extraInfo, setExtraInfo] = useState(announce?.observacao);

  const [errorFonte, setErrorFonte] = useState(false);
  const [errorDataAnuncio, setErrorDataAnuncio] = useState(false);
  const [errorTipoAnuncio, setErrorTipoAnuncio] = useState(false);
  const [errorDescricao, setErrorDescricao] = useState(false);
  const [errorProvincia, setErrorProvincia] = useState(false);
  const [errorDistrito, ] = useState(false);
  const [errorNumeroConcurso, setErrorNumeroConcurso] = useState(false);
  const [errorModalidade, setErrorModalidade] = useState(false);
  const [errorDataSubmissao, setErrorDataSubmissao] = useState(false);
  const [errorEntContratante, setErrorEntContratante] = useState(false);
  const [errorRegulamento, setErrorRegulamento] = useState(false);
  const [errorGarantia, setErrorGarantia] = useState(false);
  const [errorValorGarantia, setErrorValorGarantia] = useState(false);
  const [errorPrazoGarantia, setErrorPrazoGarantia] = useState(false);
  const [errorExtraInfo, ] = useState(false);

  /* --------------------DADOS DE ADJUDICAÇÃO---------------------- */
  const [entidadeContrada, setEntidadeContrada] = useState({});
  const [dataAdjudicacao, setDataAdjudicacao] = useState(
    announce?.data_adjudicacao
  );
  const [valorAdjudicadoMt, setValorAdjudicadoMt] = useState(
    announce?.valor_adjudicado_MT
  );
  const [valorAdjudicadoUsd, setValorAdjudicadoUsd] = useState(
    announce?.valor_adjudicado_USD
  );

  const [errorEntContratada, setErrorEntContratada] = useState(false);
  const [errorDataAdjudicacao, setErrorDataAdjudicacao] = useState(false);
  const [errorValorAdjudicadoMt, setErrorValorAdjudicadoMt] = useState(false);
  const [errorValorAdjudicadoUsd, setErrorValorAdjudicadoUsd] = useState(false);

  /* --------------------DADOS DE FECHAMENTO---------------------- */
  const [taxaCaderno, setTaxaCaderno] = useState(announce?.taxa_caderno);
  const [tipoContrato, setTipoContrato] = useState(announce?.tipo_contrato);
  const [dataConclusaoOriginal, setDataConclusaoOriginal] = useState(
    announce?.data_conclusao_original
  );
  const [dataConclusaoFinal, setDataConclusaoFinal] = useState(
    announce?.data_conclusao_real
  );
  const [valorFinalContrato, setValorFinalContrato] = useState(
    announce?.valor_final_MT
  );

  const [errorTaxaCaderno, setErrorTaxaCaderno] = useState(false);
  const [errorTipoContrato, setErrorTipoContrato] = useState(false);
  const [errorDataConclusaoOriginal, setErrorDataConclusaoOriginal] =
    useState(false);
  const [errorDataConclusaoFinal, setErrorDataConclusaoFinal] = useState(false);
  const [errorValorFinalContrato, setErrorValorFinalContrato] = useState(false);

  /* --------------------DADOS DE CANCELAMENTO---------------------- */
  const [motivoCancelamento, setMotivoCancelamento] = useState(
    announce?.fundamento_cancelamento
  );

  const [open, setOpen] = useState();
  const [message, setmessage] = useState();
  const [color, setColor] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (
      errorFonte === false &&
      errorDataAnuncio === false &&
      errorTipoAnuncio === false &&
      errorDescricao === false &&
      errorProvincia === false &&
      errorDistrito === false &&
      errorNumeroConcurso === false &&
      errorModalidade === false &&
      errorDataSubmissao === false &&
      errorEntContratante === false &&
      errorRegulamento === false &&
      errorGarantia === false &&
      errorValorGarantia === false &&
      errorPrazoGarantia === false &&
      errorExtraInfo === false &&
      errorValorAdjudicadoUsd === false &&
      errorDataAdjudicacao === false &&
      errorValorAdjudicadoMt === false &&
      errorEntContratada === false &&
      errorTaxaCaderno === false &&
      errorTipoContrato === false &&
      errorDataConclusaoOriginal === false &&
      errorDataConclusaoFinal === false &&
      errorValorFinalContrato === false
    ) {
      salvar();
    } else {
      console.log("Não posso salvar");
    }
  };

  const requiredFields = () => {
    if (fonte === "" || fonte === undefined) {
      setErrorFonte(true);
    } else {
      setErrorFonte(false);
    }
    if (dataAnuncio === "" || dataAnuncio === undefined) {
      setErrorDataAnuncio(true);
    } else {
      setErrorDataAnuncio(false);
    }
    if (tipoAnuncio === "" || tipoAnuncio === undefined) {
      setErrorTipoAnuncio(true);
    } else {
      setErrorTipoAnuncio(false);
    }
    if (descricao === "" || descricao === undefined) {
      setErrorDescricao(true);
    } else {
      setErrorDescricao(false);
    }
    if (provincia === "" || provincia === undefined) {
      setErrorProvincia(true);
    } else {
      setErrorProvincia(false);
    }
    if (numeroConcurso === "" || numeroConcurso === undefined) {
      setErrorNumeroConcurso(true);
    } else {
      setErrorNumeroConcurso(false);
    }
    if (modalidade === "" || modalidade === undefined) {
      setErrorModalidade(true);
    } else {
      setErrorModalidade(false);
    }
    if (dataSubmissao === "" || dataSubmissao === undefined) {
      setErrorDataSubmissao(true);
    } else {
      setErrorDataSubmissao(false);
    }
    if (entidadeContratante === "" || entidadeContratante === undefined) {
      setErrorEntContratante(true);
    } else {
      setErrorEntContratante(false);
    }
    if (garantia === "" || garantia === undefined) {
      setErrorGarantia(true);
    } else {
      setErrorGarantia(false);
    }
    if (valorGarantia === "" || valorGarantia === undefined) {
      setErrorValorGarantia(true);
    } else {
      setErrorValorGarantia(false);
    }
    if (prazoGarantia === "" || prazoGarantia === undefined) {
      setErrorPrazoGarantia(true);
    } else {
      setErrorPrazoGarantia(false);
    }
    if (regulamentoAplicado === "" || regulamentoAplicado === undefined) {
      setErrorRegulamento(true);
    } else {
      setErrorRegulamento(false);
    }

    /* =======DADOS DE ADJUDICAÇÃO======= */
    if (valorAdjudicadoUsd === "" || valorAdjudicadoUsd === undefined) {
      setErrorValorAdjudicadoUsd(true);
    } else {
      setErrorValorAdjudicadoUsd(false);
    }
    if (dataAdjudicacao === "" || dataAdjudicacao === undefined) {
      setErrorDataAdjudicacao(true);
    } else {
      setErrorDataAdjudicacao(false);
    }
    if (valorAdjudicadoMt === "" || valorAdjudicadoMt === undefined) {
      setErrorValorAdjudicadoMt(true);
    } else {
      setErrorValorAdjudicadoMt(false);
    }
    if (entidadeContrada === "" || entidadeContrada === undefined) {
      setErrorEntContratada(true);
    } else {
      setErrorEntContratada(false);
    }

    /* =======DADOS DE FECHAMENTO======= */
    if (taxaCaderno === "" || taxaCaderno === undefined) {
      setErrorTaxaCaderno(true);
    } else {
      setErrorTaxaCaderno(false);
    }
    if (tipoContrato === "" || tipoContrato === undefined) {
      setErrorTipoContrato(true);
    } else {
      setErrorTipoContrato(false);
    }
    if (dataConclusaoOriginal === "" || dataConclusaoOriginal === undefined) {
      setErrorDataConclusaoOriginal(true);
    } else {
      setErrorDataConclusaoOriginal(false);
    }
    if (dataConclusaoFinal === "" || dataConclusaoFinal === undefined) {
      setErrorDataConclusaoFinal(true);
    } else {
      setErrorDataConclusaoFinal(false);
    }
    if (valorFinalContrato === "" || valorFinalContrato === undefined) {
      setErrorValorFinalContrato(true);
    } else {
      setErrorValorFinalContrato(false);
    }
  };

  const cleanFields = () => {
    setFonte("");
    setDataAnuncio(today);
    setTipoAnuncio("");
    setDescricao("");
    setProvincia("");
    setDistrito("");
    setNumeroConcurso("");
    setModalidade("");
    setDataSubmissao(today);
    setEntidadeContratante("");
    setRegulamentoAplicado("");
    setGarantia("");
    setValorGarantia("");
    setPrazoGarantia("");
    setExtraInfo("");
  };

  const handleChangeProvince = (event, value) => {
    setDistrito({});
    setProvincia(value);
    setDistricts(value.districts);
  };

  const handleOptionChange = (event, value) => {
    setEntidadeContratante(value);
  };

  const handleGarantiaChange = (event, value) => {
    setGarantia(value);
  };

  const dados = {
    ref_concurso: numeroConcurso,
    fonte: fonte?.descricao,
    data_anuncio: dataAnuncio,
    data_submissao: dataSubmissao,
    tipo: modalidade?.descricao,
    estado: announce?.estado,
    categoria: tipoAnuncio,
    descricao: descricao,
    modalidade: modalidade?.descricao,
    regulamento: regulamentoAplicado?.descricao,
    estado_pub: announce?.estado_pub,
    entidade_contratante_id: entidadeContratante?.id,
    observacao: extraInfo,
    provincia: provincia?.province,
    distrito: distrito,
    garantia: garantia?.descricao,
    valor_garantia: valorGarantia,
    validade_proposta: prazoGarantia,
    valor_adjudicado_USD: valorAdjudicadoUsd,
    data_adjudicacao: dataAdjudicacao,
    valor_adjudicado_MT: valorAdjudicadoMt,
    entidade_contratada_id: entidadeContrada?.id,
    taxa_caderno: taxaCaderno,
    tipo_contracto: tipoContrato,
    data_conclusao_original: dataConclusaoOriginal,
    data_conclusao_real: dataConclusaoFinal,
    valor_final_MT: valorFinalContrato,
    fundamento_cancelamento: motivoCancelamento,
  };

  const salvar = () => {
    setLoader(true);
    axios
      .put(`${BASE_URL}/concurso/${id}`, dados)
      .then((response) => {
        setOpen(true);
        setmessage("Anúncio registado com sucesso!");
        setColor("success");
        setLoader(false);
        cleanFields();
      })
      .catch((error) => {
        setLoader(false);
        setOpen(true);
        setmessage(error?.response?.data?.message);
        setColor("error");
      });
  };

  const handleValorMt = (event) => {
    setValorAdjudicadoMt(event.target.value);
    setValorAdjudicadoUsd((event.target.value / cambio).toFixed(2));
  };

  const handleOptionChangeContratada = (event, value) => {
    setEntidadeContrada(value);
  };

  return (
    <div className="text-start container-fluid">
      <Paper elevation={1} component={"div"} className="bg-white shadow-sm p-4">
        {" "}
        <div className="text-center wow fadeInUp mb-2" data-wow-delay="0.1s">
          <Avatar
            className="mx-auto p-2"
            style={{
              backgroundColor: "#fff",
              width: 70,
              height: 70,
              border: "2px solid #568C3E",
            }}
          >
            <img src={bcc} alt="" />
          </Avatar>
          <h3 className="mt-2 mb-3">Editar Concurso</h3>
        </div>
        <small className="d-flex mb-2" style={{ color: "red" }}>
          Por favor, preencha os campos abaixo, os campos com (*) são
          obrigatórios e, os dados não serão enviados sem o devido
          preenchimento.
        </small>
        {/* -------------------FORMULÁRIO DE REGISTO------------- */}
        <form onSubmit={formSubmit}>
          <Card className="shadow-sm mb-3 border">
            <Card.Header
              as="h5"
              className="d-flex justify-content-between"
              style={{ backgroundColor: "rgba(2, 159, 214, 0.04)" }}
            >
              Secção de Apresentação da proposta
            </Card.Header>
            <Card.Body className="py-2">
              <div className="row">
                <div className="col-md-6">
                  <Autocomplete
                    id="clear-on-escape"
                    options={categorias}
                    getOptionLabel={(option) => option}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(e, value) => setTipoAnuncio(value)}
                    value={tipoAnuncio}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label="Categoria do anúncio"
                        className="rounded"
                        type={"text"}
                        error={errorTipoAnuncio}
                      />
                    )}
                  />
                </div>{" "}
                <div className="col-md-6">
                  <Autocomplete
                    id="clear-on-escape"
                    options={fontes || []}
                    getOptionLabel={(option) => option?.descricao}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(e, value) => setFonte(value)}
                    value={fonte}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label="Fonte"
                        className="rounded"
                        type={"text"}
                        error={errorFonte}
                      />
                    )}
                  />
                </div>{" "}
                <div className="col-md-6">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="numeroConcurso"
                      label="Referência do concurso"
                      //placeholder="Número do concurso"
                      type={"text"}
                      id="numeroConcurso"
                      className="rounded"
                      value={numeroConcurso}
                      defaultValue={numeroConcurso}
                      error={errorNumeroConcurso}
                      onChange={(e) => setNumeroConcurso(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <Autocomplete
                    id="clear-on-escape"
                    options={modalidades || []}
                    getOptionLabel={(option) => option?.descricao}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(e, value) => setModalidade(value)}
                    value={modalidade}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label="Modalidade do concurso"
                        className="rounded"
                        type={"text"}
                        error={errorModalidade}
                      />
                    )}
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <Autocomplete
                      id="clear-on-escape"
                      options={regulamentos || []}
                      getOptionLabel={(option) => option?.descricao}
                      noOptionsText="Nenhuma opção encontrada"
                      onChange={(e, value) => setRegulamentoAplicado(value)}
                      value={regulamentoAplicado}
                      disabled={loader}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          margin="normal"
                          size="small"
                          variant="outlined"
                          label="Regulamento aplicado"
                          className="rounded"
                          type={"text"}
                          error={errorRegulamento}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <Autocomplete
                      id="clear-on-escape"
                      options={contratantes || []}
                      getOptionLabel={(option) => option.nome}
                      noOptionsText="Nenhuma opção encontrada"
                      onChange={handleOptionChange}
                      value={entidadeContratante}
                      disabled={loader}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          margin="normal"
                          size="small"
                          variant="outlined"
                          label="Entidade Contratante"
                          className="rounded"
                          type={"text"}
                          error={errorEntContratante}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <Autocomplete
                      id="clear-on-escape"
                      options={garantias || []}
                      getOptionLabel={(option) => option?.descricao}
                      noOptionsText="Nenhuma opção encontrada"
                      onChange={handleGarantiaChange}
                      value={garantia}
                      disabled={loader}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          margin="normal"
                          size="small"
                          variant="outlined"
                          label="Garantia"
                          className="rounded"
                          type={"text"}
                          error={errorGarantia}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="valorGarantia"
                      label="Valor da Garantia"
                      //placeholder="Número do concurso"
                      type={"number"}
                      id="valorGarantia"
                      className="rounded"
                      value={valorGarantia}
                      defaultValue={valorGarantia}
                      error={errorValorGarantia}
                      onChange={(e) => setValorGarantia(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="prazoGarantia"
                      label="Prazo da Garantia"
                      //placeholder="Número do concurso"
                      type={"number"}
                      id="prazoGarantia"
                      className="rounded"
                      value={prazoGarantia}
                      defaultValue={prazoGarantia}
                      error={errorPrazoGarantia}
                      onChange={(e) => setPrazoGarantia(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <Autocomplete
                    id="clear-on-escape"
                    options={provinces}
                    getOptionLabel={(option) => option.province}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={handleChangeProvince}
                    value={provincia}
                    disabled={loader}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label="Província"
                        className="rounded"
                        type={"text"}
                        error={errorProvincia}
                      />
                    )}
                  />
                </div>
                <div className="col-md-3">
                  <Autocomplete
                    id="clear-on-escape"
                    options={districts}
                    getOptionLabel={(option) => option}
                    noOptionsText="Nenhuma opção encontrada"
                    onChange={(event, value) => setDistrito(value)}
                    value={distrito}
                    disabled={loader || !provincia}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        size="small"
                        variant="outlined"
                        label="Distrito"
                        className="rounded"
                        type={"text"}
                      />
                    )}
                  />
                </div>{" "}
                <div className="col-md-3">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="dataAnuncio"
                      label="Data do anúncio"
                      type={"date"}
                      id="dataAnuncio"
                      className="rounded"
                      value={dataAnuncio}
                      defaultValue={dataAnuncio}
                      error={errorDataAnuncio}
                      onChange={(e) => setDataAnuncio(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="dataSubmissao"
                      label="Data de submissão"
                      type={"date"}
                      id="dataSubmissao"
                      className="rounded"
                      value={dataSubmissao}
                      defaultValue={dataSubmissao}
                      error={errorDataSubmissao}
                      onChange={(e) => setDataSubmissao(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    floatingLabelStyle={{ color: "#568C3E" }}
                    size="small"
                    margin="normal"
                    fullWidth
                    required
                    name="descricao"
                    label="Descrição"
                    type={"text"}
                    id="descricao"
                    multiline
                    rows={4}
                    className="rounded"
                    value={descricao}
                    defaultValue={descricao}
                    error={errorDescricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    disabled={loader}
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      name="extraInfo"
                      label="Observações"
                      type={"text"}
                      id="extraInfo"
                      multiline
                      rows={4}
                      className="rounded"
                      value={extraInfo}
                      defaultValue={extraInfo}
                      onChange={(e) => setExtraInfo(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card className="shadow-sm mb-3 border">
            <Card.Header
              as="h5"
              className="d-flex justify-content-between"
              style={{ backgroundColor: "rgba(2, 159, 214, 0.04)" }}
            >
              Secção de Adjudicação
            </Card.Header>
            <Card.Body className="py-2">
              {" "}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-floating">
                    <Autocomplete
                      id="clear-on-escape"
                      noOptionsText="Nenhuma opção encontrada"
                      options={entidades || []}
                      getOptionLabel={(option) => option.nome}
                      onChange={handleOptionChangeContratada}
                      value={entidadeContrada}
                      disabled={loader}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          margin="normal"
                          size="small"
                          variant="outlined"
                          label="Entidade Contratada"
                          className="rounded border-bottom"
                          type={"text"}
                          error={errorEntContratada}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="dataAdjudicacao"
                      label="Data da adjudicação"
                      type={"date"}
                      id="dataAdjudicacao"
                      className="rounded border-bottom"
                      value={dataAdjudicacao}
                      defaultValue={dataAdjudicacao}
                      error={errorDataAdjudicacao}
                      onChange={(e) => setDataAdjudicacao(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    floatingLabelStyle={{ color: "#568C3E" }}
                    size="small"
                    margin="normal"
                    fullWidth
                    required
                    name="valorAdjudicadoMt"
                    label="Valor Adjudicado (MT)"
                    className="rounded border-bottom"
                    type={"number"}
                    id="valorAdjudicadoMt"
                    value={valorAdjudicadoMt}
                    defaultValue={valorAdjudicadoMt}
                    error={errorValorAdjudicadoMt}
                    onChange={handleValorMt}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    disabled={loader}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    floatingLabelStyle={{ color: "#568C3E" }}
                    label="Valor Adjudicado (USD)"
                    required
                    placeholder="Valor Adjudicado (USD)"
                    size="small"
                    className="rounded border-bottom mt-3"
                    type={"number"}
                    style={{ width: "100%" }}
                    value={valorAdjudicadoUsd}
                    error={errorValorAdjudicadoUsd}
                    onChange={(e) => setValorAdjudicadoUsd(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    disabled={loader || disableCambio}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card className="shadow-sm mb-3 border">
            <Card.Header
              as="h5"
              className="d-flex justify-content-between"
              style={{ backgroundColor: "rgba(2, 159, 214, 0.04)" }}
            >
              Secção de Fechamento
            </Card.Header>
            <Card.Body className="py-2">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="tipoContrato"
                      placeholder="Serie de Preços, VG, etc"
                      label="Tipo de Contrato"
                      type={"text"}
                      id="tipoContrato"
                      className="rounded border-bottom"
                      value={tipoContrato}
                      defaultValue={tipoContrato}
                      error={errorTipoContrato}
                      onChange={(e) => setTipoContrato(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>{" "}
                <div className="col-md-3">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="taxaCaderno"
                      label="Taxa de Caderno"
                      type={"number"}
                      id="taxaCaderno"
                      className="rounded border-bottom"
                      value={taxaCaderno}
                      defaultValue={taxaCaderno}
                      error={errorTaxaCaderno}
                      onChange={(e) => setTaxaCaderno(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="valorFinalContrato"
                      label="Valor final do contrato"
                      type={"number"}
                      id="valorFinalContrato"
                      className="rounded border-bottom"
                      value={valorFinalContrato}
                      defaultValue={valorFinalContrato}
                      error={errorValorFinalContrato}
                      onChange={(e) => setValorFinalContrato(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="dataConclusaoOriginal"
                      label="Data de conclusão original"
                      type={"date"}
                      id="dataConclusaoOriginal"
                      className="rounded border-bottom"
                      value={dataConclusaoOriginal}
                      defaultValue={dataConclusaoOriginal}
                      error={errorDataConclusaoOriginal}
                      onChange={(e) => setDataConclusaoOriginal(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <TextField
                      variant="outlined"
                      floatingLabelStyle={{ color: "#568C3E" }}
                      size="small"
                      margin="normal"
                      fullWidth
                      required
                      name="dataConclusaoFinal"
                      label="Data de conclusão final"
                      type={"date"}
                      id="reglamentoAplicado"
                      className="rounded border-bottom"
                      value={dataConclusaoFinal}
                      defaultValue={dataConclusaoFinal}
                      error={errorDataConclusaoFinal}
                      onChange={(e) => setDataConclusaoFinal(e.target.value)}
                      inputProps={{
                        style: { border: "none", paddingLeft: 10 },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled={loader}
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card className="shadow-sm mb-3 border">
            <Card.Header
              as="h5"
              className="d-flex justify-content-between"
              style={{ backgroundColor: "rgba(2, 159, 214, 0.04)" }}
            >
              Secção de Cancelamento
            </Card.Header>
            <Card.Body className="py-2">
              <div className="row">
                <div className="col-md-12">
                  <TextField
                    variant="outlined"
                    floatingLabelStyle={{ color: "#568C3E" }}
                    size="small"
                    margin="normal"
                    fullWidth
                    name="motivoCancelamento"
                    label="Motivo do cancelamento"
                    type={"text"}
                    id="motivoCancelamento"
                    multiline
                    rows={4}
                    className="rounded"
                    value={motivoCancelamento}
                    defaultValue={motivoCancelamento}
                    onChange={(e) => setMotivoCancelamento(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      style: { border: "none", paddingLeft: 10 },
                    }}
                    disabled={loader}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center pt-4">
              <Button
                variant="text"
                className="text-capitalize me-4 px-3 mt-2"
                style={{ color: "red" }}
                onClick={() => navigate(`/admin/anuncios/detalhes/${id}`)}
                disabled={loader}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                type="submit"
                className="text-light text-capitalize"
                style={{ backgroundColor: "#568C3E" }}
                disabled={loader}
                startIcon={
                  loader ? (
                    <Spinner
                      animation="border"
                      style={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <Save />
                  )
                }
                onClick={() => requiredFields()}
              >
                Salvar Alterações
              </Button>
            </div>
          </div>
        </form>
      </Paper>

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom center"}
        autoHideDuration={5000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
}
