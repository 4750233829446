import React from "react";
import AppbarDrawer from "../../../components/dashboard/drawer";
import Announcements from "../../../components/dashboard/announces/announcementsList";



const AnunciosPage = () => {
  return (
    <div >
      <AppbarDrawer>
        <Announcements/>
      </AppbarDrawer>
    </div>
  );
};
export default AnunciosPage;

