import React from "react";
import AppbarDrawer from "../../../components/dashboard/drawer";
import AnnouNceDetails from "../../../components/dashboard/announces/details";



const AnnounceDetailsPage = () => {
  return (
    <div >
      <AppbarDrawer>
        <AnnouNceDetails/>
      </AppbarDrawer>
    </div>
  );
};
export default AnnounceDetailsPage;

