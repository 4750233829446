import React from "react";
import AppbarDrawer from "../../../components/dashboard/drawer";
import RegistUsers from "../../../components/dashboard/users/registUsers";



const RegistUsersPage = () => {
  return (
    <div >
      <AppbarDrawer>
        <RegistUsers/>
      </AppbarDrawer>
    </div>
  );
};
export default RegistUsersPage;

